import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Navbar from './Navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import LoginPage from './Login/LoginPage'; 
import LogoutPage from './Login/LogoutPage'; 
import Footer from './Footer/Footer';
import UserList from './UserRole/UserList';
import RoleList from './UserRole/RoleList';
import CreateRolePage from './UserRole/CreateRolePage';
import CreateUserPage from './UserRole/CreateUserPage';
import AcademyList from './Academy/AcademyList';
import DisabledAcademy  from './Academy/DisableAcademy';
import NewAcademy from './Academy/NewAcademy';
import SportList from './sport/SportList';
import NewSport from './sport/NewSport';
import CouponList from './coupons/CouponList';
import NewCoupon from './coupons/NewCoupon';
import Dues from './Payment/Dues';
import PaymentList from './Payment/PaymentList';
import SportPayment from './Payment/SportPayment';
import Summary from './Payment/Summary';
import AcademyPlayerList from './Coaching/AcademyPlayerList';
import AcademyCoachList from './Coaching/AcademyCoachList';
import NewCoach from './Coaching/NewCoach';
import NewPlayer from './Coaching/NewPlayer';
import NewBatch from './Coaching/NewBatch';
import BatchList from './Coaching/BatchList';
import DietList from './Coaching/DietList';
import PlayerBatch from './Coaching/PlayerBatch';
import CoachBatch from './Coaching/CoachBatch';
import BatchCancellation from './Coaching/BatchCancellation';
import CancelBatchList from './Coaching/CancelBatchList';
import AcademyFeedback from './Feedback/AcademyFeedback';
import CoachFeedback from './Feedback/CoachFeedback';
import ChallengeList from './Challenges/ChallengesList';
import Notification from './Notification/Notification';
import NewPlan from './PlayingPlan/NewPlan';
import PlanList from './PlayingPlan/PlanList';
import BlockCourt from './CourtBooking/BlockCourt';
import BookingCalendar from './CourtBooking/BookingCalendar';
import BulkBookingList from './CourtBooking/BulkBookingList';
import CourtList from './CourtBooking/CourtList';
import CourtPrice from './CourtBooking/CourtPrice';
import NewCourt from './CourtBooking/NewCourt';
import CourtBookings from './CourtBooking/CourtBookings';
import CourtBulkBooking from './CourtBooking/CourtBulkBooking';
import AcademyGallery from './AcademyProfile/AcademyGallery';
import BankDetails from './AcademyProfile/BankDetails';
import EditProfile from './AcademyProfile/EditProfile';
import ResetPassword from './AcademyProfile/ResetPassword';
import FeesSetting  from './AcademyProfile/FeesSetting';
import RazorPayauth from './RazorPay/RazorPayauth';
import SearchUser from './Checkin/SearchUser';
import SliderList from './sliders/SliderList';
import NewSlider from './sliders/NewSlider';
import CheckinReports from './Reports/CheckinReports';
import CouponsUsageReport from './Reports/CouponsUsageReport';
import LearnTrailReports from './Reports/LearnTrailReports';
import LeaveReport from './Reports/LeaveReport';
import LoginReport from './Reports/LoginReport';
import PlayingSubscription from './Reports/PlayingSubscription';
import PlayTrailReport from './Reports/PlayTrailReport';
import SalesRegister from './Reports/SalesRegister';
import { UserProvider } from './context/UserContext';
import CreateCoach from './Feedback/CreateCoach';
import ProgressAttribute from './sport/ProgressAttribute';
import NewProgress from './sport/NewProgress';
import AttributeQA from './sport/AttributeQA';
import ProgressParamter from './sport/Progressparameter';
import NewAttributeQA from './sport/NewAttributeQA'
import NewProgessParameter from './sport/NewProgessParameter';
import SettlePayment from './Payment/SettlePayment';
import CreatePlayer from './Coaching/CreatePlayer';
import Shop from './Coaching/Shop';
import BenefitList from './PlayingPlan/BenefitList';
import CreateBenfits from './PlayingPlan/CreateBenfits';
import UnavailableDate from './CourtBooking/UnavailableDate';
import AddUnavailableDate from './CourtBooking/AddUnavailableDate';
import Curriculum from './curriculum/Curriculum';
import CheckinDetails from './Checkin/CheckinDetails';
import SetGuardian from './Coaching/SetGuardian';
import SetPlayer from './Coaching/SetPlayer';
import CoachAttendance from './Coaching/CoachAttendance';
import PlayerAttendance from './Coaching/PlayerAttendance';
import PlayerPaymentDetail from './Coaching/PlayerPaymentDetail';
import LandingPageCustomization from './Marketing/LandingPageCustomization';
import LeadManagement from './Marketing/LeadManagement';
import Dashboard from './Marketing/Dashboard';
import Coldleads from './Marketing/ Coldleads';
import Trialrescheduled from './Marketing/Trialrescheduled';
import Trailattended from './Marketing/Trialattended';
import Trialmissed from './Marketing/Trialmissed';
import Trialconverted from './Marketing/Trialconverted';
import PeakHour from './Analytics/PeakHour';
import SportWiseRatio from './Analytics/SportWiseRevenue';
import RevenueRatio from './Analytics/RevenueRatio';
import CourtUsage from './Analytics/CourtUsage';
import RepeatRate from './Analytics/RepeatRate';
import Enquiry from './Marketing/Enquiry';
import Summer from './Marketing/Summer';
import SummerColdLead from './Marketing/SummerColdLead';
import Profile from './Login/Profile';
import BookingConfirmation from './CourtBooking/BookingConfirmation';
import DetailAttedanceList from './Coaching/DetailAttedanceList';
import BatchPlayerList from './Coaching/BatchPlayerList';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn'); 
    localStorage.removeItem('selectedCenter'); 
  };

  return (
    <UserProvider>
    <Router>
      {isLoggedIn ? (
        <>
          <Navbar onLogout={handleLogout} />
          <div style={{ display: 'flex' }}>
            <Sidebar />
            <div style={{ flexGrow: 1, padding: '10px' }}>
              <Routes>
              <Route path="/" /> 
                <Route path="/logout" element={<LogoutPage />} />
               <Route path="/UserList" element={<UserList />} />
                <Route path="/RoleList" element={<RoleList />} />
                <Route path="/create-user" element={<CreateUserPage />} />
                <Route path="/create-role" element={<CreateRolePage />} />  
                <Route path="/academylist" element={<AcademyList />} />
                <Route path="/disabledAcademy" element={<DisabledAcademy />} />
                <Route path="/newAcademy" element={<NewAcademy />} /> 
                <Route path="/sportList" element={<SportList />} />
                <Route path="/newSport" element={<NewSport />} />  
                <Route path="/coupon" element={<CouponList />} />
                <Route path="/newCoupon" element={<NewCoupon />} />  
                 <Route path="/dues" element={<Dues />} />
                <Route path="/paymentlist" element={<PaymentList />} />
                <Route path="/summary" element={<Summary />} />
                <Route path="/sportPaymentsummary" element={<SportPayment />} /> 
                <Route path="/academyPlayerList" element={<AcademyPlayerList />} />
                <Route path="/academyCoachList" element={<AcademyCoachList />} />
                <Route path="/createNewBatch" element={<NewBatch />} />
                <Route path="/createNewPlayer" element={<NewPlayer />} />
                <Route path="/createNewCoach" element={<NewCoach />} />
                <Route path="/batchList" element={<BatchList />} />
                <Route path="/playerBatch" element={<PlayerBatch />} />
                <Route path="/coachBatch" element={<CoachBatch />} />
                <Route path="/batchCancellation" element={<BatchCancellation />} />
                <Route path="/cancelBatchList" element={<CancelBatchList />} />
                <Route path="/diet" element={<DietList />} />
                <Route path="/academyfeedback" element={<AcademyFeedback />} />
                <Route path="/coachfeedback" element={<CoachFeedback />} />
                <Route path="/challengeList" element={<ChallengeList />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/planList" element={<PlanList />} />
                <Route path="/newPlan" element={<NewPlan />} />
                <Route path="/courtlist" element={<CourtList />} />
                <Route path="/bookingcalendar" element={<BookingCalendar />} />
                <Route path="/courtbookings" element={<CourtBookings />} />
                <Route path="/courtprice" element={<CourtPrice />} />
                <Route path="/courtbulkbooking" element={<CourtBulkBooking />} />
                <Route path="/bulkbookinglist" element={<BulkBookingList />} />
                <Route path="/newcourt" element={<NewCourt />} />
                <Route path="/blockcourt" element={<BlockCourt/>} />
                <Route path="/academygallery" element={<AcademyGallery />} />
                <Route path="/bankdetails" element={<BankDetails />} />
                <Route path="/editprofile" element={<EditProfile />} />
                <Route path="/feesSetting" element={<FeesSetting />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route path="/razorpayauth" element={<RazorPayauth />} />
                <Route path="/searchuser" element={<SearchUser />} />
                <Route path="/sliderlist" element={<SliderList />} />
                <Route path="/newslider" element={<NewSlider />} />
                <Route path="/checkinreport" element={<CheckinReports />} />
                <Route path="/couponsusagereport" element={<CouponsUsageReport />} />
                <Route path="/learntrailreports" element={<LearnTrailReports />} />
                <Route path="/leavereport" element={<LeaveReport />} />
                <Route path="/loginreport" element={<LoginReport />} />
                <Route path="/playingsubscription" element={<PlayingSubscription />} />
                <Route path="/playtrailreport" element={<PlayTrailReport />} />
                <Route path="/salesRegister" element={<SalesRegister />} /> 
                <Route path="/CreateCoach" element={<CreateCoach />} /> 
                <Route path="/ProgresAttribute" element={<ProgressAttribute />} /> 
                <Route path="/NewProgress" element={<NewProgress />} /> 
                <Route path="/AttributeQa" element={<AttributeQA />} /> 
                <Route path="/ProgessParameter" element={<ProgressParamter/>} /> 
                <Route path="/newAttribute" element={<NewAttributeQA/>} /> 
                <Route path="/newProgessParameter" element={<NewProgessParameter/>} /> 
                <Route path="/settlePayment" element={<SettlePayment/>}/>
                <Route path="/createplayer" element={<CreatePlayer/>}/>
                <Route path="/shop" element={<Shop/>}/>
                <Route path="/benfit" element={<BenefitList/>}/>
                <Route path ="/createbenefit" element={<CreateBenfits/>}/>
                <Route path ="/unavailableDate" element={<UnavailableDate/>}/>
                <Route path ="/addunavailableDate" element={<AddUnavailableDate/>}/>
                <Route path ="/curriculum" element={<Curriculum/>}/>
                <Route path ="/checkinDetails" element={<CheckinDetails/>}/>
                <Route path ="/setPlayer" element={<SetPlayer/>}/>
                <Route path ="/setGuardian" element={<SetGuardian/>}/>
                <Route path ="/playerAttendance" element={<PlayerAttendance/>}/>
                <Route path ="/coachAttendance" element={<CoachAttendance/>}/>
                <Route path ="/playerpaymentDetail" element={<PlayerPaymentDetail/>}/>
                <Route path ="/customization" element={<LandingPageCustomization/>}/>
                <Route path ="/leadmanagement" element={<LeadManagement/>}/>
                <Route path ="/dashboard" element={<Dashboard/>}/>
                <Route path ="/coldleads" element={<Coldleads/>}/>
                <Route path ="/Trialrescheduled" element={<Trialrescheduled/>}/>
                <Route path ="/Trialmissed" element={<Trialmissed/>}/>
                <Route path ="/Trialattended" element={<Trailattended/>}/>
                <Route path ="/Trialconverted" element={<Trialconverted/>}/>
                <Route path ="/PeakHour" element={<PeakHour/>}/>
                <Route path ="/SportWiseRevenue" element={<SportWiseRatio/>}/>
                <Route path ="/RevenueRatio" element={<RevenueRatio/>}/>
                <Route path ="/CourtUsage" element={<CourtUsage/>}/>
                <Route path ="/RepeatRate" element={<RepeatRate/>}/>
                <Route path ="/Enquiry" element={<Enquiry/>}/>
                <Route path ="/Summer" element={<Summer/>}/>
                <Route path ="/SummerColdLead" element={<SummerColdLead/>}/>
                <Route path ="/profile" element={<Profile/>}/>
                <Route path ="/bookingConfirmation" element={<BookingConfirmation/>}/>
                <Route path ="/detailsAttendance" element={<DetailAttedanceList/>}/>
                <Route path ="/batchPlayerList" element={<BatchPlayerList/>}/>
              </Routes>
           
            </div>
            
          </div>
          <Footer />
        </>
      ) : (
        <LoginPage setIsLoggedIn={handleLogin} />
      )}
    </Router>
    </UserProvider>
  );
}

export default App;