import React, { useState, useEffect } from 'react';
import './Challenge.css';
import { CSVLink } from 'react-csv';

const LoginReport = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString().split('T')[0];
  });
  const [endDateFilter, setEndDateFilter] = useState(() => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  });
  const [selectedAcademy, setSelectedAcademy] = useState("All");
  const [LoginReport, setLoginReport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const apiUrl = "https://admin.stage.machaxi.com/api/reports/last-active";

    const headers = {
      "Content-Type": "application/json",
      "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ" // Example if you need authorization
    };

    const fetchLoginReport = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: headers,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.success) {
          const transformedData = data.data.loginUsers.map((user) => ({
            parentName: user?.name,
            mobileNumber: user?.mobileNumber,
            academy: user?.academyNames || '-',
            playerName: user?.playerName || "-",
            lastActive: user?.lastActiveAt ? new Date(user.lastActiveAt).toLocaleDateString() : "-",
            lastLogin: user?.lastLoginAt ? new Date(user.lastLoginAt).toLocaleDateString() : "-",
            oneSignalDeviceId: user?.oneSignalDeviceId || "-",
          }));

          setLoginReport(transformedData);
        } else {
          console.error("Failed to fetch login report:", data.success_message);
          setError('Failed to load data: ' + data.success_message);
        }
      } catch (error) {
        setError('Error fetching data: ' + error.message);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoginReport();
  }, []);

  const headers = [
    { label: "Parent Name", key: "parentName" },
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Academy", key: "academy" },
    { label: "Player Name", key: "playerName" },
    { label: "Last Active", key: "lastActive" },
    { label: "Last Login", key: "lastLogin" },
    { label: "OneSignal Device ID", key: "oneSignalDeviceId" },
  ];

  const handleAcademyChange = (event) => {
    setSelectedAcademy(event.target.value);
    setCurrentPage(1);
  };

  const uniqueAcademies = ["All", ...new Set(LoginReport.map((row) => row.academy).flat())];
  const [sortOrder, setSortOrder] = useState({ column: '', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleStartDateFilterChange = (event) => {
    setStartDateFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleEndDateFilterChange = (event) => {
    setEndDateFilter(event.target.value);
    setCurrentPage(1);
  };

  const filteredLoginReport = LoginReport.filter((row) =>
    Object.values(row)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase()) &&
    (startDateFilter ? new Date(row.lastActive) >= new Date(startDateFilter) : true) &&
    (endDateFilter ? new Date(row.lastActive) <= new Date(endDateFilter) : true) &&
    (selectedAcademy === "All" || row.academy.includes(selectedAcademy))
  );

  const handleSort = (column) => {
    const isAsc = sortOrder.column === column && sortOrder.direction === 'asc';
    const direction = isAsc ? 'desc' : 'asc';

    const sortedData = [...LoginReport].sort((a, b) => {
      if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setLoginReport(sortedData);
    setSortOrder({ column, direction });
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredLoginReport.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredLoginReport.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredLoginReport.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="challenges-container">
      <div className="sales-filters-login">

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${startDateFilter ? "has-value" : ""}`}
            value={startDateFilter}
            onChange={handleStartDateFilterChange}
          />
          {startDateFilter && <label htmlFor="startDateFilter">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${endDateFilter ? "has-value" : ""}`}
            value={endDateFilter}
            onChange={handleEndDateFilterChange}
          />
          {endDateFilter && <label htmlFor="endDateFilter">End Date</label>}
        </div>

      </div>
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>

        <CSVLink
          data={filteredLoginReport}
          headers={headers}
          filename="LoginReport.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>

      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredLoginReport.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr className="challenges-header-row">
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortOrder.column === header.key && (
                    <span>{sortOrder.direction === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentRows.map((LoginReport) => (
              <tr key={LoginReport} className="challenges-row">
                <td className="challenges-cell">{LoginReport.parentName}</td>
                <td className="challenges-cell">{LoginReport.mobileNumber}</td>
                <td className="challenges-cell">{LoginReport.academy}</td>
                <td className="challenges-cell">{LoginReport.playerName}</td>
                <td className="challenges-cell">{LoginReport.lastActive}</td>
                <td className="challenges-cell">{LoginReport.lastLogin}</td>
                <td className="challenges-cell">{LoginReport.oneSignalDeviceId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LoginReport;