import React from 'react'

const SetPlayer = () => {
  return (
    <div>
      set
    </div>
  )
}

export default SetPlayer
