import React, { useState, useEffect } from 'react';
import './CourtPrice.css'; 
import { useUser } from '../context/UserContext';

const CourtPrice = () => {
  const { selectedCenter } = useUser(); 
  const [sport, setSport] = useState('');
  const [court, setCourt] = useState('');
  const [pricingData, setPricingData] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]); 
  const [daysOfWeek, setDaysOfWeek] = useState([]); 
  const [sportsList, setSportsList] = useState([]);
  const [courtsList, setCourtsList] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    if (!selectedCenter) return; 

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `http://localhost:8082/api/react/court/court-pricing?academyId=${selectedCenter}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
            },
          }
        );
        const result = await response.json();

        if (result.success) {
          const slotPricing = result.data.slotPricing;
          const weekdays = result.data.Weekdays;
          setDaysOfWeek(weekdays);

          const extractedTimeSlots = Object.keys(slotPricing);
          setTimeSlots(extractedTimeSlots);

          const newPricingData = extractedTimeSlots.map(() => Array(weekdays.length).fill(''));
          setPricingData(newPricingData);
          mapPricingData(slotPricing, weekdays);

          const sportsListData = result.data.academySports || [];
          setSportsList(sportsListData);
          console.log("sportlistdata",sportsListData)
          const courtsListData = result.data.courts || [];
          setCourtsList(courtsListData);
          console.log("Court",courtsListData)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCenter]); 

  const mapPricingData = (slotPricing, weekdays) => {
    const updatedPricingData = [...pricingData];

    Object.keys(slotPricing).forEach((timeSlot, timeIndex) => {
      if (!updatedPricingData[timeIndex]) {
        updatedPricingData[timeIndex] = [];
      }

      slotPricing[timeSlot].forEach((dayData, dayIndex) => {
        const weekdayIndex = weekdays.indexOf(dayData.weekDays);
        if (weekdayIndex !== -1) {
          if (!updatedPricingData[timeIndex][weekdayIndex]) {
            updatedPricingData[timeIndex][weekdayIndex] = '';
          }
          updatedPricingData[timeIndex][weekdayIndex] = dayData.price || '';
        }
      });
    });

    setPricingData(updatedPricingData); 
  };

  const handlePriceChange = (timeIndex, dayIndex, value) => {
    const updatedPricingData = [...pricingData];
    updatedPricingData[timeIndex][dayIndex] = value;
    setPricingData(updatedPricingData);
  };

  const handleSubmit = async () => {
    if (!sport || !court || !selectedCenter) {
      alert('Please select Sport, Court, and Academy Center!');
      return;
    }
    if (!pricingData || pricingData.length === 0) {
      alert('Pricing data is missing or not yet loaded.');
      return;
    }
    const updatedData = timeSlots.map((time, timeIndex) => ({
      timeSlot: time,
      prices: pricingData[timeIndex].map((price, dayIndex) => ({
        weekDay: dayIndex + 1,
        price: price || '',  
      })),
    }));
  
    const payload = {
      sportId: sport, 
      courtIds: [court],
      academyId: selectedCenter, 
      slotPricing: updatedData, 
    };

    console.log('Payload being sent to API:', JSON.stringify(payload));
  
    try {
      const response = await fetch(
        'http://localhost:8082/api/react/court/court-pricing',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token
          },
          body: JSON.stringify(payload),
        }
      );
  
      const result = await response.json();
      
      // Log the response for inspection
      console.log('API Response:', result);
  
      // Check if the response is successful
      if (result.success) {
        alert('Pricing updated successfully!');
      } else {
        // Provide better error message handling based on response
        const errorMessage = result.data?.errorMessage || result.success_message || 'Unknown error';
        alert('Failed to update pricing: ' + errorMessage);
      }
  
    } catch (error) {
      // Log and alert if an error occurs during the fetch process
      console.error('Error submitting pricing data:', error);
      alert('Error submitting pricing data. Please try again later.');
    }
  };
  useEffect(() => {
    if (!selectedCenter) return;
  
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `http://localhost:8082/api/react/court/court-pricing?academyId=${selectedCenter}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
            },
          }
        );
        const result = await response.json();
        console.log(result);
  
        if (result.success) {
          const { slotPricing, Weekdays, academySports, courts } = result.data;
  
          setDaysOfWeek(Weekdays);
          setTimeSlots(Object.keys(slotPricing));
          setSportsList(academySports || []);
          setCourtsList(courts || []);
  
          mapPricingData(slotPricing, Weekdays);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [selectedCenter]);
  

  useEffect(() => {
    if (!sport || !selectedCenter) return;
  
    const fetchCourtAndTimings = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `http://localhost:8082/api/react/court/court-pricing?academyId=${selectedCenter}&sportId=${sport}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
            },
          }
        );
        const result = await response.json();
        console.log('Court & Timing Data:', result);
  
        if (result.success) {
          const { courts, slotPricing } = result.data;
          setCourtsList(courts || []);
          setTimeSlots(Object.keys(slotPricing));
          mapPricingData(slotPricing, daysOfWeek);
        }
      } catch (error) {
        console.error('Error fetching courts and timings:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCourtAndTimings();
  }, [sport]); 
  

  return (
    <div className="court-price-container">
      <div className="select-container">
        <label className="select-label">
          Select Sport:
          <select
            className="select-input"
            value={sport}
            onChange={(e) => setSport(e.target.value)}
          >
            <option value="">Select a Sport</option>
            {sportsList.map((sportItem) => (
              <option key={sportItem.sportId} value={sportItem.sportId}>
                {sportItem.sport.name}
              </option>
            ))}
          </select>
        </label>

        <label className="select-label">
          Select Court:
          <select
            className="select-input"
            value={court}
            onChange={(e) => setCourt(e.target.value)}
          >
            <option value="">Select a Court</option>
            {courtsList.map((courtItem) => (
              <option key={courtItem.id} value={courtItem.id}>
                {courtItem.name}
              </option>
            ))}
          </select>
        </label>
      </div>

      <table className="pricing-table">
        <thead>
          <tr>
            <th>Time</th>
            {daysOfWeek.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {timeSlots.map((time, timeIndex) => (
            <tr key={time}>
              <td>{time}</td>
              {daysOfWeek.map((day, dayIndex) => (
                <td key={day}>
                  <input
                    className="price-input"
                    type="text"
                    placeholder="Price"
                    value={pricingData[timeIndex] && pricingData[timeIndex][dayIndex]}
                    onChange={(e) => handlePriceChange(timeIndex, dayIndex, e.target.value)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <button className="create-button" onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default CourtPrice;
