import React, { useState } from 'react';
import '../Challenges/challenges.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';

const Challenges = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [challenges, setChallenges] = useState([
    { id: 1, player1: 'Player A', player2: 'Player B', month: 'January', year: '2024', status: 'Pending', winner: '', },
    { id: 2, player1: 'Player C', player2: 'Player D', month: 'February', year: '2024', status: 'Completed', winner: 'Player C' },
    { id: 3, player1: 'Player E', player2: 'Player F', month: 'March', year: '2024', status: 'Pending', winner: '' },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedChallenge, setEditedChallenge] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredChallenges = challenges.filter((challenge) =>
    Object.values(challenge)
      .join(' ') // Join all fields into a single string
      .toLowerCase() // Case-insensitive comparison
      .includes(searchTerm.toLowerCase()) // Filter based on the search term
  );

  const handleEditClick = (challenge) => {
    setEditedChallenge(challenge);  // Set the selected challenge to be edited
    setIsModalOpen(true);  // Open the modal
  };

  const handleDeleteClick = (challenge) => {
    setChallengeToDelete(challenge); // Set the challenge to delete
    setIsDeleteModalOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = () => {
    // Proceed with deletion if confirmed
    const updatedChallenges = challenges.filter((challenge) => challenge.id !== challengeToDelete.id);
    setChallenges(updatedChallenges);
    setIsDeleteModalOpen(false); // Close the delete confirmation modal
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete confirmation modal without deleting
    setChallengeToDelete(null); // Clear the selected challenge to delete
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the edit modal
    setEditedChallenge(null); // Clear the edited challenge
  };

  const handleSaveChanges = () => {
    const updatedChallenges = challenges.map((challenge) =>
      challenge.id === editedChallenge.id ? editedChallenge : challenge
    );
    setChallenges(updatedChallenges); // Save changes to the challenges
    handleCloseModal(); // Close the modal after saving
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedChallenge((prevChallenge) => ({
      ...prevChallenge,
      [name]: value,
    }));
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredChallenges.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredChallenges.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  return (
    <div className="challenges-container">
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search Challenge"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <table className="challenges-table">
        <thead>
          <tr>
            <th>Player 1</th>
            <th>Player 2</th>
            <th>Month</th>
            <th>Year</th>
            <th>Status</th>
            <th>Winner</th>
            {hasPrivilege(userData, 'CHALLENGE_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((challenge) => (
            <tr key={challenge.id}>
              <td>{challenge.player1}</td>
              <td>{challenge.player2}</td>
              <td>{challenge.month}</td>
              <td>{challenge.year}</td>
              <td>{challenge.status}</td>
              <td>{challenge.winner}</td>
              {hasPrivilege(userData, 'CHALLENGE_LIST_MODIFY') && (<td>
                <button className="edit-button" onClick={() => handleEditClick(challenge)}>Edit</button>
                <button className="delete-button" onClick={() => handleDeleteClick(challenge)}>Delete</button>
              </td>)}

            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Challenges;
