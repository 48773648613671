import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import '../sliders/SliderList.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const Progressparameter = () => {
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [academies, setAcademies] = useState([]);
  const location = useLocation();
  const { progressAttributeId, sportId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (progressAttributeId) {
      const fetchAcademies = async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await fetch(
            `http://localhost:8082/api/admin/sport/progress-parameter?progressAttributeId=${progressAttributeId}`,
            {
              method: 'GET',
              headers: {
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
              }
            });

          const data = await response.json();

          if (data.success && data.data.progressParameter) {
            const academiesData = data.data.progressParameter.map(item => ({
              id: item.id,
              ProgressName: item.name,
            }));

            setAcademies(academiesData);
          } else {
            setError(data.success_message || 'Failed to fetch academies');
          }
        } catch (error) {
          setError('Error fetching academies: ' + error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchAcademies();
    } else {
      setError('No progressAttributeId found');
    }
  }, [progressAttributeId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ heading: '', subheading: '' });
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewSlider = () => {
    if (!progressAttributeId || !sportId) {
      console.error("progressAttributeId or sportId is missing");
      return;
    }
    navigate('/newProgessParameter', { state: { progressAttributeId, sportId } });
  };

  const handleEdit = (academyId) => {
    navigate('/newProgessParameter', { state: { progressAttributeId, sportId, academyId } });
  };

  const handleDeleteAcademy = async (academyId, sportId, progressAttributeId) => {
    const result = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (!result.isConfirmed) return;

    try {
      const response = await fetch(`http://localhost:8082/api/admin/delete-progress-parameter/${academyId}/${sportId}/${progressAttributeId}`, {
        method: 'GET',
        headers: {
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Progress attribute deleted successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });

        setAcademies(academies.filter(academy => academy.id !== academyId));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message || 'Failed to delete progress attribute.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong while deleting the progress attribute.',
      });
    }
  };


  const filteredAcademies = academies.filter((academy) =>
    Object.values(academy)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredAcademies.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAcademies.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredAcademies.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="slider-container">

      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt; Progess Parameter
      </div>

      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewSlider}>
          + Create Parameter
        </button>
      </div>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredAcademies.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              <th>Progress Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((academy) => (
              <tr key={academy.id}>
                <td>{academy.ProgressName}</td>
                <td className="action-buttons">
                  <button className="edit-btn" onClick={() => handleEdit(academy.id)}>Edit</button>
                  <button className="delete-btn" onClick={() => handleDeleteAcademy(academy.id, sportId, progressAttributeId)}>Delete</button>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Progressparameter
