import React, { useState, useEffect } from 'react';
import './NewCoupon.css';
import Select from "react-select";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const NewCoupon = () => {
  const location = useLocation();
  const edit = location.state?.couponData;
  const name = location.state?.name;
  const [title, setTitle] = useState(edit ? edit.title : '');
  const [description, setDescription] = useState(edit ? edit.description : '');
  const [price, setPrice] = useState(edit ? edit.price : '');
  const [mobileNumbers, setMobileNumbers] = useState(edit ? edit.mobileNumbers || [] : []);
  const [selectedScope, setSelectedScope] = useState(edit ? edit.scope || [] : []);
  const [selectedAcademy, setSelectedAcademy] = useState(edit ? edit.academy || [] : []);
  const [existingcoupon, setExistingcoupon] = useState(edit ? edit.existingcoupon || [] : []);
  const [minOrderAmount, setMinOrderAmount] = useState(edit ? edit.minOrderAmount : '');
  const [maxDiscount, setMaxDiscount] = useState(edit ? edit.maxDiscount : '');
  const [percentage, setPercentage] = useState(edit ? edit.percentage : '');
  const [maxUsagePerUser, setMaxUsagePerUser] = useState(edit ? edit.maxUsagePerUser : '');
  const [couponCode, setCouponCode] = useState(edit ? edit.couponCode : '');
  const [startTime, setStartTime] = useState(edit ? edit.startTime : '');
  const [endTime, setEndTime] = useState(edit ? edit.endTime : '');
  const [forAllUsers, setForAllUsers] = useState(edit ? edit.forAllUsers : 'None');
  const [scopeOptions, setScopeOptions] = useState([]);
  const [academyOptions, setAcademyOptions] = useState([]);
  const [ExistingCouponOptions, setExistingCouponOptions] = useState([]);
  const [sportOptions, setSportOptions] = useState([]);
  const [selectedSport, setSelectedSport] = useState(edit ? edit.sport || [] : []);
  const isSonam = name === "sonam";


  useEffect(() => {
    if (edit) {
      axios.get(`https://admin.stage.machaxi.com/api/react/coupons/update?coupon_id=${edit}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
      })
        .then(response => {
          if (response.data.success) {
            const couponData = response.data.data.couponForm;
            console.log(couponData);

            // Set basic fields
            setTitle(couponData.title || '');
            setDescription(couponData.description || '');
            setMinOrderAmount(couponData.minAmount || '');
            setMaxDiscount(couponData.maxAmount || '');
            setPercentage(couponData.percentage || '');
            setMaxUsagePerUser(couponData.maxUsagePerUser || '');
            setCouponCode(couponData.couponCode || '');
            setStartTime(couponData.startTime ? couponData.startTime.split('T')[0] : '');
            setEndTime(couponData.endTime ? couponData.endTime.split('T')[0] : '');
            setForAllUsers(couponData.isForAllUsers ? 'yes' : 'no');

            // Set multi-select fields
            setSelectedScope(couponData.scopes?.map(scope => ({
              value: scope,
              label: scope.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())
            })) || []);

            const matchedAcademies = couponData.academyIds?.map(id => {
              const academy = academyOptions.find(option => option.value === id.toString());
              return academy ? { value: academy.value, label: academy.label } : null;
            }).filter(academy => academy !== null) || [];

            setSelectedAcademy(matchedAcademies);


            setExistingcoupon(couponData.selections?.map(selection => ({
              value: selection,
              label: selection.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())
            })) || []);

            // Match sportIds with sportOptions to set selectedSport
            const matchedSports = couponData.sportIds?.map(id => {
              const sport = sportOptions.find(option => option.value === id.toString());
              return sport ? { value: sport.value, label: sport.label } : null;
            }).filter(sport => sport !== null) || [];
            console.log("match", matchedSports)
            setSelectedSport(matchedSports);
            console.log(couponData.sportIds);
            setMobileNumbers(couponData.mobileNumbers || ['']);
          }
        })
        .catch(error => {
          console.error("Error fetching coupon details:", error);
        });
    }
  }, [edit, sportOptions, academyOptions]);

  const fetchCouponOptions = async () => {
    try {
      const response = await fetch('https://admin.stage.machaxi.com/api/react/coupons/new', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const { data } = await response.json();

      // Mapping the couponScope array to scopeOptions
      const newScopeOptions = data.couponScope.map(scope => ({
        value: scope,
        label: scope.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase()),
      }));

      // Mapping the couponSelection array to ExistingCouponOptions
      const newExistingCouponOptions = data.couponSelection.map(coupon => ({
        value: coupon,
        label: coupon.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase()),
      }));

      // Convert the academies object to an array before mapping
      const academiesArray = Object.keys(data.academies).map(key => ({
        id: key,
        name: data.academies[key],
      }));

      const sportsArray = data.sports?.map(sport => ({
        value: sport.id.toString(),  // Ensure the ID is a string
        label: sport.name,
      })) || [];


      // Mapping the academies array to academyOptions
      const newAcademyOptions = academiesArray.map(academy => ({
        value: academy.id,
        label: academy.name,
      }));

      setSportOptions(sportsArray);
      setScopeOptions(newScopeOptions);
      setAcademyOptions(newAcademyOptions);
      setExistingCouponOptions(newExistingCouponOptions);
    } catch (error) {
      console.error('Error fetching coupon options:', error);
    }
  };

  const handleSportChange = (selected) => {
    setSelectedSport(selected || []); // Ensure it's always an array
  };


  useEffect(() => {
    fetchCouponOptions();
  }, []);

  const handleAddMobileNumber = () => {
    setMobileNumbers([...mobileNumbers, '']);
  };

  const handleScopeChange = (selected) => {
    setSelectedScope(selected);
  };

  const handleAcademyChange = (selected) => {
    setSelectedAcademy(selected);
  };

  const handleExistingCoupon = (selected) => {
    setExistingcoupon(selected);
  };

  const handleMobileNumberChange = (index, value) => {
    const updatedNumbers = [...mobileNumbers];
    updatedNumbers[index] = value;
    setMobileNumbers(updatedNumbers);
  };

  const handleRemoveMobileNumber = (index) => {
    const updatedNumbers = mobileNumbers.filter((_, i) => i !== index);
    setMobileNumbers(updatedNumbers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const sportIds = selectedSport.length > 0
      ? selectedSport.map(sport => sport.value)
      : [];

    const formData = {
      id: edit ? edit : null,
      title,
      description,
      price,
      academyIds: selectedAcademy && selectedAcademy.length > 0 ? selectedAcademy.map(option => option.value) : null,
      selections: existingcoupon && existingcoupon.length > 0 ? existingcoupon.map(option => option.value) : null,
      mobileNumbers,
      scopes: selectedScope && selectedScope.length > 0 ? selectedScope.map(option => option.value) : [],
      minAmount: minOrderAmount,
      maxAmount: maxDiscount,
      percentage,
      maxUsagePerUser,
      couponCode,
      startTime,
      endTime,
      isForAllUsers: e.target.forAllUsers.value === 'yes',
      isForAllUsersOption: e.target.forAllUsers.value === 'yes' ? 'YES' : 'NO',
      sportIds,
    };

    console.log('Form Data:', formData);

    const headers = {
      'Content-Type': 'application/json',
      'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Ensure the token is correct
    };

    axios.post('https://admin.stage.machaxi.com/api/react/coupons/new', formData, { headers })
      .then(response => {
        console.log('Coupon created/updated:', response.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message || (edit ? "Coupon updated successfully!" : "Coupon created successfully!"),
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

      })
      .catch(error => {
        console.error('There was an error submitting the form!', error.response?.data || error.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.data?.message || "There was an error submitting the form!",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <div className="form-container">

      <div className="breadcrumb">
        <Link to="/coupon" className="breadcrumb-link">Coupon List</Link> &gt; New Coupon
      </div>

      <form className="academy-form" onSubmit={handleSubmit}>

        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-input"
            value={title}
            onChange={(e) => setTitle(e.target.value)} // Update state on change
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Description</label>
          <textarea
            className="form-textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)} // Update state on change
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Minimum Order Amount</label>
          <input
            type="text"
            className="form-input"
            value={minOrderAmount}
            onChange={(e) => setMinOrderAmount(e.target.value)}
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Maximum Discount Amount</label>
          <input
            type="text"
            className="form-input"
            value={maxDiscount}
            onChange={(e) => setMaxDiscount(e.target.value)}
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Percentage</label>
          <input
            type="text"
            className="form-input"
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Maximum Usage per User</label>
          <input
            type="text"
            className="form-input"
            value={maxUsagePerUser}
            onChange={(e) => setMaxUsagePerUser(e.target.value)}
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Coupon Code</label>
          <input
            type="text"
            className="form-input"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Start Date</label>
          <input
            type="date"
            className="form-input date-picker-input"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-input date-picker-input"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            required
            disabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Select Scope</label>
          <Select
            isMulti
            name="scope"
            options={scopeOptions}
            value={selectedScope}
            onChange={handleScopeChange}
            placeholder="Select scope"
            closeMenuOnSelect={false}
            className="custom-select"
            isDisabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Select Sport</label>
          <Select
            isMulti
            name="sport"
            options={sportOptions}
            value={selectedSport}
            onChange={handleSportChange}
            placeholder="Select Sport"
            closeMenuOnSelect={false}
            className="custom-select"
            isDisabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Select Academy</label>
          <Select
            isMulti
            name="academy"
            options={academyOptions}
            value={selectedAcademy}
            onChange={handleAcademyChange}
            placeholder="Select academy"
            closeMenuOnSelect={false}
            className="custom-select"
            isDisabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Existing Coupon</label>
          <Select
            isMulti
            name="existingcoupon"
            options={ExistingCouponOptions}
            value={existingcoupon}
            onChange={handleExistingCoupon}
            placeholder="Select coupon"
            closeMenuOnSelect={false}
            className="custom-select"
            isDisabled={isSonam}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Mobile Number</label>
          {mobileNumbers.length > 0 && mobileNumbers.map((number, index) => (
            <div key={index} className="add-more-container">
              <input
                type="text"
                className="form-input"
                value={number}
                onChange={(e) => handleMobileNumberChange(index, e.target.value)}
                disabled={isSonam}
                maxLength={10}
              />

              <button
                type="button"
                className="remove-btn-mobile"
                onClick={() => handleRemoveMobileNumber(index)}
              >
                Remove
              </button>

            </div>
          ))}
          <button type="button" className="add-more-btn-mobile" onClick={handleAddMobileNumber}>
            Add
          </button>
        </div>
        
        <div className="form-group">
          <label className="form-label">Apply to All Users</label>
          <select
            name="forAllUsers" // Add the name attribute
            className="form-select"
            defaultValue={edit ? (edit.forAllUsers ? 'yes' : 'no') : 'none'}
            required
            disabled={isSonam}
          >
            <option value="none">None</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div className="form-actions">
          <button type="submit" className="form-submit-button">
            {edit ? 'Update Coupon' : 'Create Coupon'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewCoupon;
