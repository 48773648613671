import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import Select from 'react-select';

const CheckinReports = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const { selectedCenter, setSelectedCenter, userData } = useUser();
  const getFormattedDate = (date) => date.toISOString().split('T')[0];
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 30);
  const [fromDate, setFromDate] = useState(getFormattedDate(tenDaysAgo));
  const [toDate, setToDate] = useState(getFormattedDate(today));
  const [selectedType, setSelectedType] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedCenter) {
      setSelectedAcademy(Number(selectedCenter));
    }
  }, [selectedCenter]);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const [CheckinReports, setCheckinReports] = useState([]);

  const [academyNames, setAcademyNames] = useState({});

  useEffect(() => {
    setLoading(true);

    const headers = {
      'x-authorization':
        'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with a valid token
    };

    const requestBody = {
      fromDate: fromDate,
      toDate: toDate,
    };

    axios
      .post('https://admin.stage.machaxi.com/api/academy/checkIn-trials', requestBody, { headers })
      .then((response) => {
        if (!response.data.success) throw new Error(response.data.success_message);

        const reports = response.data.data.bookings.map((booking) => ({
          id: booking.id,
          player_name: booking?.user?.name || '-',
          phoneNumber: booking?.user?.mobile_number || '-',
          type: booking?.checkInType || '-',
          sport: response.data.data.sportNameMap[booking.sportId] || '-',
          time: '-',
          date: booking?.trialDate || '-',
          status: booking?.statusType || '-',
          preferredCenter: response.data.data.academyNameMap[booking.academyId] || '-',
          remark: booking?.remark || '-',
        }));

        const academyNames = response.data.data.academyNameMap;
        const sportNames = response.data.data.sportNameMap;

        setCheckinReports(reports);
        setAcademyNames(academyNames);

      })
      .catch((error) => {
        console.error('Error:', error.message);
        setError('Failed to load data: ' + error.message);
      })
      .finally(() => {
        console.log('API call completed');
        setLoading(false);
      });
  }, [fromDate, toDate]);

  const headers = [
    { label: "Academy", key: "preferredCenter" },
    { label: "Name", key: "player_name" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Type", key: "type" },
    { label: "Sport", key: "sport" },
    { label: "Time", key: "time" },
    { label: "Date", key: "date" },
    { label: "Status", key: "status" },
    { label: "Remark", key: "remark" }
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const resetPageToFirst = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedAcademy, fromDate, toDate]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getArrowIcon = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'ascending' ? <FaArrowUp /> : <FaArrowDown />;
  };

  const sortedRows = [...CheckinReports].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    }
    return 0;
  });

  const filteredCheckinReports = sortedRows.filter((checkin) => {
    const matchesSearch = Object.values(checkin)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const academyId = Object.keys(academyNames).find(
      (id) => academyNames[id] === checkin.preferredCenter
    );

    const matchesUserAcademy =
      userData?.userType !== "ACADEMY" ||
      (Array.isArray(userData.academyIds) &&
        userData.academyIds.includes(Number(academyId)));
    const matchesAcademy =
      selectedAcademy === "" ||
      checkin.preferredCenter?.trim().toLowerCase() ===
      academyNames[selectedAcademy]?.trim().toLowerCase();

    const matchesDate =
      (fromDate === "" || new Date(checkin.date) >= new Date(fromDate)) &&
      (toDate === "" || new Date(checkin.date) <= new Date(toDate));

    const matchesType = selectedType === "" || checkin.type === selectedType;
    const matchesSport = selectedSport === "" || checkin.sport === selectedSport;
    const matchesStatus = selectedStatus === "" || checkin.status === selectedStatus;

    return (
      matchesSearch &&
      matchesUserAcademy &&
      matchesAcademy &&
      matchesDate &&
      matchesType &&
      matchesSport &&
      matchesStatus
    );
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCheckinReports.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCheckinReports.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {

      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {

      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const typeOptions = [...new Set(CheckinReports.map((checkin) => checkin.type))].filter(type => type !== '-');
  const sportOptions = [...new Set(CheckinReports.map((checkin) => checkin.sport))].filter(sport => sport !== '-');
  const statusOptions = [...new Set(CheckinReports.map((checkin) => checkin.status))].filter(status => status !== '-');

  const academyOptions = userData?.userType === "ACADEMY"
    ? userData.academyIds.map(id => ({
      value: Number(id),
      label: academyNames[id] || `Academy ${id}`,
    }))
    : Object.keys(academyNames).map(id => ({
      value: Number(id),
      label: academyNames[id],
    }));

  const totalEntries = filteredCheckinReports.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  const getAcademyOptions = () => {
    return [...academyOptions];
  };

  const typeOptionsFormatted = typeOptions.map(type => ({ value: type, label: type }));
  const sportOptionsFormatted = sportOptions.map(sport => ({ value: sport, label: sport }));
  const statusOptionsFormatted = statusOptions.map(status => ({ value: status, label: status }));

  return (
    <div className="challenges-container">
      <div className="sales-filters-leadManagement">

        <div className="floating-select">
          <Select
            id="academySelect"
            className={`sales-filter-court-booking ${selectedAcademy ? "has-value" : ""}`}
            options={getAcademyOptions()}
            value={academyOptions.find(option => option.value === selectedAcademy) || null}
            onChange={(selectedOption) => {
              setSelectedAcademy(selectedOption ? selectedOption.value : '');
              resetPageToFirst();
            }}
            placeholder="Academy"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedAcademy && <label htmlFor="academySelect">Academy</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            id="fromDate"
            className={`sales-filter-academy ${fromDate ? "has-value" : ""}`}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {fromDate && <label htmlFor="fromDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            id="toDate"
            className={`sales-filter-academy ${toDate ? "has-value" : ""}`}
            value={toDate}
            placeholder="dd/mm/yyyy"
            onChange={(e) => setToDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />

          {toDate && <label htmlFor="toDate">End Date</label>}
        </div>

        <div className="floating-select">
          <Select
            id="typeSelect"
            className={`sales-filter-court-booking ${selectedType ? "has-value" : ""}`}
            options={typeOptionsFormatted}
            value={typeOptionsFormatted.find(option => option.value === selectedType) || null}
            onChange={(selectedOption) => {
              setSelectedType(selectedOption ? selectedOption.value : '');
              resetPageToFirst();
            }}
            placeholder="Select Type"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedType && <label htmlFor="typeSelect">Type</label>}
        </div>

        <div className="floating-select">
          <Select
            id="selectSport"
            className={`sales-filter-court-booking ${selectedSport ? "has-value" : ""}`}
            options={sportOptionsFormatted}
            value={sportOptionsFormatted.find(option => option.value === selectedSport) || null}
            onChange={(selectedOption) => {
              setSelectedSport(selectedOption ? selectedOption.value : '');
              resetPageToFirst();
            }}
            placeholder="Select Sport"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedSport && <label htmlFor="selectSport">Sport</label>}
        </div>

        <div className="floating-select">
          <Select
            id="selectStatus"
            className={`sales-filter-court-booking ${selectedStatus ? "has-value" : ""}`}
            options={statusOptionsFormatted}
            value={statusOptionsFormatted.find(option => option.value === selectedStatus) || null}
            onChange={(selectedOption) => {
              setSelectedStatus(selectedOption ? selectedOption.value : '');
              resetPageToFirst();
            }}
            placeholder="All Status"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedStatus && <label htmlFor="selectStatus">Status</label>}
        </div>

      </div>

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>

        <CSVLink
          data={filteredCheckinReports}
          headers={headers}
          filename="filtered_checkin_reports.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredCheckinReports.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="challenges-table">
          <thead>
            <tr className="challenges-header-row">
              <th onClick={() => handleSort('preferredCenter')}>
                Academy {getArrowIcon('preferredCenter')}
              </th>
              <th onClick={() => handleSort('player_name')}>
                Name {getArrowIcon('player_name')}
              </th>
              <th onClick={() => handleSort('phoneNumber')}>
                Mobile Number {getArrowIcon('phoneNumber')}
              </th>
              <th onClick={() => handleSort('type')}>
                Type {getArrowIcon('type')}
              </th>
              <th onClick={() => handleSort('date')}>
                Date {getArrowIcon('date')}
              </th>
              <th onClick={() => handleSort('time')}>
                Time {getArrowIcon('time')}
              </th>
              <th onClick={() => handleSort('remark')}>
                Remark {getArrowIcon('remark')}
              </th>
              <th onClick={() => handleSort('sport')}>
                Sport {getArrowIcon('sport')}
              </th>
              <th onClick={() => handleSort('status')}>
                Status {getArrowIcon('status')}
              </th>
            </tr>
          </thead>

          <tbody>
            {currentRows.reverse().map((checkin) => (
              <tr key={checkin.id}>
                <td>{checkin.preferredCenter}</td>
                <td>{checkin.player_name}</td>
                <td>{checkin.phoneNumber}</td>
                <td>{checkin.type}</td>
                <td>{checkin.date}</td>
                <td>{checkin.time}</td>
                <td>{checkin.remark}</td>
                <td>{checkin.sport}</td>
                <td>{checkin.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CheckinReports;
