import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PlayerBatch.css';
import { useUser } from '../context/UserContext';

const PlayerBatch = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedCenter, setSelectedCenter } = useUser();
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedCenter) {
      const fetchPlayersData = async () => {
        try {
          const response = await fetch(
            `http://localhost:8082/api/academy/react/${selectedCenter}/player-batches`, 
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // If authentication is needed
              },
            }
          );

          const result = await response.json();

          if (result.success) {
            const { parentPlayer, playerStatus ,academy} = result.data;

            const updatedPlayers = Object.keys(playerStatus).map((playerId) => {
              const player = {
                id: playerId,
                name: parentPlayer[playerId] || '-',
                parentName:parentPlayer[playerId] || '-',
                status: playerStatus[playerId] || '-',
                category: 'Football', 
                level: 'Intermediate', 
                mobileNumber: `+91${playerId}`, 
                parentNumber: '+91XXXXXXX',
                dob: '2000-01-01',
                batch: 'Batch A', 
                primary: 'Yes',
              };
              return player;
            });

            setPlayers(updatedPlayers);
          }
        } catch (error) {
          console.error('Error fetching players data:', error);
          setError('Error fetching data: ' + error.message);
        }
        finally {
          setLoading(false);
        }
      };

      fetchPlayersData();
    }
  }, [selectedCenter]);
  const headers = [
    { label: "Name", key: "name" },
    { label: "Parent Name", key: "parentName" },
    { label: "Category", key: "category" },
    { label: "Level", key: "level" },
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Parent Number", key: "parentNumber" },
    { label: "Date of Birth", key: "dob" },
    { label: "Batch", key: "batch" },
    { label: "Primary", key: "primary" },
    { label: "Status", key: "status" },
  ];
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    parentName: '',
    category: '',
    level: '',
    mobileNumber: '',
    parentNumber: '',
    dob: '',
    batch: '',
    primary: '',
    status: '',
  });

  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (playerId) => {
    setDropdownOpen(dropdownOpen === playerId ? null : playerId);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewPlayer = () => {
    navigate('/createNewPlayer');
  };

  const handleEditPlayer = (playerId) => {
    const player = players.find((p) => p.id === playerId);
    setSelectedPlayer(player);
    setFormData({ ...player });
    setAction('edit');
    setIsModalOpen(true);
  };

  const handleDeletePlayer = (playerId) => {
    setSelectedPlayer(players.find((player) => player.id === playerId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedPlayers = players.filter((player) => player.id !== selectedPlayer.id);
    setPlayers(updatedPlayers);
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const updatedPlayers = players.map((player) =>
      player.id === selectedPlayer.id ? { ...player, ...formData } : player
    );
    setPlayers(updatedPlayers);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredPlayers = players.filter((player) =>
    Object.values(player)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [sortConfig, setSortConfig] = useState({
    key: "name", // Default column to sort
    direction: "ascending", // Default direction
  });
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };
  const sortedData = sortData(filteredPlayers);
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-player');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.dots-btn-player')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };
  return (
    <div className="batch-container">
      <div className="search-bar">
      <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handleNewPlayer}>
          + Create Player
        </button>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <div className='space'></div>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredPlayers.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <table className="player-table">
        <thead>
          <tr>
          {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} // Sort by the column key
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> // Ascending arrow
                    ) : (
                      <span> ↓</span> // Descending arrow
                    )
                  ) : null}
                </th>

              ))}
              <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((player) => (
            <tr key={player.id}>
              <td>{player.name}</td>
              <td>{player.parentName}</td>
              <td>{player.category}</td>
              <td>{player.level}</td>
              <td>{player.mobileNumber}</td>
              <td>{player.parentNumber}</td>
              <td>{player.dob}</td>
              <td>{player.batch}</td>
              <td>{player.primary}</td>
              <td>{player.status}</td>

              <td className="action-buttons-player">
                <button
                  className="dots-btn-player"
                  onClick={() => toggleDropdown(player.id)}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '24px',
                  }}
                >
                  &#x22EE; {/* Unicode for vertical ellipsis (three dots) */}
                </button>
                {dropdownOpen === player.id && (
                  <div className="dropdown-menu-player">
                    <button className="dropdown-item" >Attendance</button>
                    <button className="dropdown-item" >Performance</button>
                    <button className="dropdown-item" >Make this batch as primary</button>
                    <button className="dropdown-item" >Update Player Payment </button>
                    <button className="dropdown-item" >Remove</button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* )} */}
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlayerBatch;
