import React, { useState } from "react";
import "./CreateCoach.css";
import { Link } from "react-router-dom";
const CreateCoach = () => {
  const [formData, setFormData] = useState({
    mobileNumber: "",
    name: "",
    about: "",
    profilePicture: null,
    gender: "",
    experienceYear: "",
    experienceMonth: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, profilePicture: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
  };

  return (
    <div className="create-coach-container">
       <div className="breadcrumb">
        <Link to="/academyCoachList" className="breadcrumb-link">
          Coach List
        </Link>
        &gt;
        <Link to="/createNewCoach" className="breadcrumb-link">
          Search Coach
        </Link>
        &gt; New Coach
      </div>
      <form className="create-coach-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">
            Mobile Number*:
            <input
              type="text"
              name="mobileNumber"
              className="form-input"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Name*:
            <input
              type="text"
              name="name"
              className="form-input"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            About:
            <textarea
              name="about"
              className="form-textarea"
              value={formData.about}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Profile Picture:
            <input
              type="file"
              className="form-file-input"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Gender:
            <select
              name="gender"
              className="form-select"
              value={formData.gender}
              onChange={handleChange}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Experience Year*:
            <input
              type="number"
              name="experienceYear"
              className="form-input"
              value={formData.experienceYear}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Experience Month*:
            <input
              type="number"
              name="experienceMonth"
              className="form-input"
              value={formData.experienceMonth}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <button type="submit" className="form-submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateCoach;
