import React, { useState, useEffect } from "react";
import "./NewSport.css";
import { Link } from 'react-router-dom';
import Select from "react-select";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const NewSport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action, sport } = location.state || {};
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    playingAreaName: "",
    isEntireCourtAllowed: false,
    isRequestAllowed: false,
    isRegistrationFeesApplicable: false,
    registrationFees: "",
    renewalFees: "",
    proficiency: [],
    courtBookingNotes: "",
    sportsImage: null,
  });

  const [proficiencyOptions, setProficiencyOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://localhost:8082/api/admin/sportnew", {
          method: "GET",
          headers: {
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
        });

        const data = await response.json();

        if (data.success) {
          const proficiencyList = data.data.allProficiencies.map((proficiency) => {
            return { value: proficiency, label: proficiency };
          });
          setProficiencyOptions(proficiencyList);
        } else {
          console.error("Error fetching data:", data.success_message);
        }
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    };

    fetchData();
  }, []);


  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      if (file) {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          setFormData(prevState => ({
            ...prevState,
            sportsImage: fileReader.result,
            sportsImageName: file.name
          }));
        };
        fileReader.readAsDataURL(file);
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      proficiency: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };

  useEffect(() => {
    console.log("useeffect data", sport);
    if (action === 'edit' && sport) {
      setFormData({
        id: sport.id || '',
        name: sport.name || '',
        description: sport.description || '',
        playingAreaName: sport.area || '',
        isEntireCourtAllowed: !!sport.bookingAllowed,
        isRequestAllowed: !!sport.requestAllowed,
        isRegistrationFeesApplicable: !!sport.isRegistrationFees,
        proficiency: sport.proficiencies.split(', ') || [],
        registrationFees: sport.registrationFees || '',
        renewalFees: sport.renewalFees || '',
        courtBookingNotes: sport.courtBookingNotes || '',
      });
      setImagePreview(sport.imageUrl || null);
    }
  }, [action, sport]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data before submission:", formData);
    const sportForm = {
      id: formData.id || null,
      name: formData.name,
      description: formData.description,
      playingAreaName: formData.playingAreaName,
      proficiencies: formData.proficiency,
      allowEntireCourtBooking: formData.isEntireCourtAllowed === "on" ? true : !!formData.isEntireCourtAllowed,
      courtBookingNotes: formData.courtBookingNotes,
      requestAllowed: formData.isRequestAllowed === "on" ? true : !!formData.isRequestAllowed,
      isRegistrationFees: formData.isRegistrationFeesApplicable === "on" ? true : !!formData.isRegistrationFeesApplicable,
      registrationFees: Number(formData.registrationFees) || 0,
      renewalFees: Number(formData.renewalFees) || 0,
    };

    // Create FormData object
    const formDataToSend = new FormData();
    formDataToSend.append("sportForm", new Blob([JSON.stringify(sportForm)], { type: "application/json" }));

    if (formData.sportsImage && formData.sportsImage.startsWith("data:image")) {
      const blob = await fetch(formData.sportsImage).then((res) => res.blob());
      const file = new File([blob], formData.sportsImageName || "uploaded_image.jpeg", { type: blob.type });
      formDataToSend.append("file", file);
    } else {
      console.log("No valid image found. Sending null as file...");
    }

    console.log("Final FormData to be submitted:", sportForm);
    try {
      const apiUrlBase = "http://localhost:8082/api/admin/sport-new-add";
      const apiUrl = formData.id ? `${apiUrlBase}?sportId=${formData.id}` : apiUrlBase;

      console.log("API URL:", apiUrl);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "x-authorization":
            "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
        },
        body: formDataToSend,
      });

      console.log("Response Status:", response.status);

      const responseText = await response.text();
      console.log("Raw Response Text:", responseText);

      if (!responseText.trim()) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text:formData.id ? 'Sport updated successfully!' : 'Sport submitted successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/sportList");
        return;
      }

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error("Failed to parse JSON:", parseError);
        return;
      }

      console.log("API Response (Parsed JSON):", data);

      if (data.success) {
        console.log("Form submitted successfully:", data);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text:formData.id ? 'Sport updated successfully!' : 'Sport submitted successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/sportList");
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.success_message || "No message provided",
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        console.error(
          "Error submitting form:",
          data.success_message || "No message provided"
        );
      }
    } catch (error) {
      console.error("Error during submission:", error.message);
    }
  };

  return (
    <div className="new-sport-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt; New Sport
      </div>
      <form className="new-sport-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            type="text"
            name="name"
            className="form-input"
            value={formData.name || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Description:</label>
          <input
            type="text"
            name="description"
            className="form-input"
            value={formData.description || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Playing Area Name (e.g., Court, Pool, Turf):</label>
          <input
            type="text"
            name="playingAreaName"
            className="form-input"
            value={formData.playingAreaName || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              name="isEntireCourtAllowed"
              className="form-checkbox"
              checked={formData.isEntireCourtAllowed}  // No need for fallback to false
              onChange={handleChange}
            />
            Is Entire Court Booking Allowed?
          </label>
        </div>

        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              name="isRequestAllowed"
              className="form-checkbox"
              checked={formData.isRequestAllowed}  // No need for fallback to false
              onChange={handleChange}
            />
            Is Request Allowed For Sport?
          </label>
        </div>

        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              name="isRegistrationFeesApplicable"
              className="form-checkbox"
              checked={formData.isRegistrationFeesApplicable || false}
              onChange={handleChange}
            />
            Is Registration Fees Applicable?
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">Registration Fees:</label>
          <input
            type="text"
            name="registrationFees"
            className="form-input"
            value={formData.registrationFees || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Renewal Fees:</label>
          <input
            type="text"
            name="renewalFees"
            className="form-input"
            value={formData.renewalFees || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Select Proficiency:</label>
          <Select
            isMulti
            name="proficiency"
            options={proficiencyOptions}
            value={proficiencyOptions.filter(option => formData.proficiency?.includes(option.value))}

            onChange={handleSelectChange}
            closeMenuOnSelect={false}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Court Booking Notes:</label>
          <textarea
            name="courtBookingNotes"
            className="form-textarea"
            value={formData.courtBookingNotes || ''}
            onChange={handleChange}
            placeholder="Use # to add new point. To highlight a text, wrap it in <highlight></highlight> tag. Ex: <highlight>TEXT</highlight>"
            rows="5"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sports Image:</label>
          {imagePreview && !formData.sportsImage && (
            <div className="image-preview">
              <img
                src={imagePreview}
                alt="Sport Image Preview"
                className="sport-image"
                style={{ maxWidth: '50%', height: 'auto' }}
              />
            </div>
          )}

          {formData.sportsImage && (
            <div className="image-preview">
              <img
                src={formData.sportsImage}
                alt="Current Sport Image"
                className="sport-image"
                style={{ maxWidth: '50%', height: 'auto' }}
              />
            </div>
          )}

          {/* Hidden file input */}
          <input
            type="file"
            name="sportsImage"
            className="form-file"
            accept="image/*"
            onChange={handleChange}
            id="uploadInput"
            style={{ display: 'none' }}
          />

          <button
            type="button"
            className="upload-btn"
            onClick={() => document.getElementById('uploadInput').click()}
          >
            Upload Image
          </button>
        </div>

        <div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewSport;