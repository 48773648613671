// export const hasPrivilege = (user, privilege) => {
//     if (!user || !user.privileges) return false;
//     return user.privileges.includes(privilege);
//   };
export const hasPrivilege = (user, section, privilege = null) => {
  if (!user || !user.privileges || !Array.isArray(user.privileges)) return false;

  // Find the privilege object that contains the section
  const privilegeObject = user.privileges.find(p => p[section]);

  // If the section exists but no specific privilege is required, check if it has any privileges
  if (privilegeObject && !privilege) {
    return privilegeObject[section].length > 0;
  }

  // If a specific privilege is required, check if it's present in the section
  return privilegeObject && privilegeObject[section]?.includes(privilege);
};