import React, { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './CancelBatchList.css';
import { useUser } from '../context/UserContext';
import Swal from 'sweetalert2';

const CancelBatchList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [batches, setBatches] = useState([]);
  const { selectedCenter, setSelectedCenter } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!selectedCenter) return;

    const fetchCancelledBatches = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `http://localhost:8082/api/academy/${selectedCenter}/cancelled`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-authorization":
                "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ", // Replace with your token
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();

        if (result.success && result.data?.canceledBatchesList) {
          const formattedBatches = result.data.canceledBatchesList.map(
            (batch) => ({
              id: batch.id,
              batchName: batch.batch.name,
              cancelledOn: batch.canceledOn.split("T")[0], // Extracting only the date
              cancellationReason: batch.cancellationReason,
            })
          );
          setBatches(formattedBatches);
        }
      } catch (error) {
        console.error("Error fetching cancelled batches:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCancelledBatches();
  }, [selectedCenter]);

  const headers = [
    { label: "Batch Name", key: "batchName" },
    { label: "Cancelled On", key: "cancelledOn" },
    { label: "Cancellation Reason", key: "cancellationReason" },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [action, setAction] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const navigate = useNavigate();

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle batch selection
  const handleBatchSelect = (batchId) => {
    const updatedSelection = selectedBatches.includes(batchId)
      ? selectedBatches.filter(id => id !== batchId)
      : [...selectedBatches, batchId];
    setSelectedBatches(updatedSelection);
  };

  // Handle "Select All" functionality
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedBatches([]);
    } else {
      setSelectedBatches(batches.map(batch => batch.id));
    }
    setSelectAll(!selectAll);
  };


  const filteredBatches = batches.filter(batch =>
    batch.batchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    batch.cancelledOn.toLowerCase().includes(searchTerm.toLowerCase()) ||
    batch.cancellationReason.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [sortConfig, setSortConfig] = useState({
    key: "name", // Default column to sort
    direction: "ascending", // Default direction
  });

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };
  const sortedData = sortData(filteredBatches);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);


  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(prevPage => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleUncancel = async () => {
    if (selectedBatches.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No batch selected!",
        text: "Please select at least one batch to uncancel.",
      });
      return;
    }
  
    const batchIdsQuery = selectedBatches.join(",");
    const apiUrl = `http://localhost:8082/api/academy/${selectedCenter}/delete-cancellation?canceledBatchId=${batchIdsQuery}`;
    console.log("API URL:", apiUrl);
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ", // Replace with your token
        },
      });
  
      let result;
      try {
        result = await response.json();
      } catch {
        throw new Error("Invalid server response. Please try again.");
      }
  
      if (!response.ok || result.success === false) {
        throw new Error(result.data?.errorMessage || "Failed to uncancel batches.");
      }
  
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Batches successfully uncanceled!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
  
      // Remove uncanceled batches from the state
      setBatches((prevBatches) =>
        prevBatches.filter((batch) => !selectedBatches.includes(batch.id))
      );
      setSelectedBatches([]); // Clear selected batches
  
    } catch (error) {
      console.error("Error uncanceling batches:", error);
  
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "Something went wrong! Please try again.",
      });
    }
  };
  
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };
  const totalEntries = filteredBatches.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="challenges-container">
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handleUncancel}>
          Uncancel
        </button>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredBatches.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <div className='space'></div>
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} // Sort by the column key
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> // Ascending arrow
                    ) : (
                      <span> ↓</span> // Descending arrow
                    )
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((batch) => (
              <tr key={batch.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedBatches.includes(batch.id)}
                    onChange={() => handleBatchSelect(batch.id)}
                  />
                </td>
                <td>{batch.batchName}</td>
                <td>{batch.cancelledOn}</td>
                <td>{batch.cancellationReason}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CancelBatchList;
