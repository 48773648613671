import React, { useState, useEffect } from 'react';
import './Challenge.css';
import { CSVLink } from 'react-csv';

const LeaveReport = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [LeaveReport, setLeaveReport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLeaveReports = async () => {
      try {
        const response = await fetch('https://admin.stage.machaxi.com/api/leave/report', {
          method: 'GET',
          headers: {
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
        });

        const data = await response.json();

        if (data.success) {
          const formattedData = data.data.apiData.map((item) => ({
            playerName: item?.name || '-',
            mobileNumber: item?.player_mobile_number || '-',
            parentMobileNumber: item?.parent_mobile_number || '-',
            academyName: item?.academy_name || '-',
            batchName: item?.batch_name || '-',
            leaveCredit: `${item?.leave_credit} days` || '-',
            leaveDays: `${item?.leave_days} days` || '-',
            amount: item?.amount ? item?.amount?.toString() : '0',
            status: item?.status === 0 ? 'Pending' : 'Approved',
            appliedOn: item?.applied_on || '-',
            missedSession: item?.missed_session?.toString() || '-',
            startDate: formatDate(item?.start_date) || '-',
            endDate: formatDate(item?.end_date) || '-',

          }));

          setLeaveReport(formattedData);
        } else {
          console.error('API Error:', data.success_message);
          setError('Failed to load data: ' + data.success_message);
        }
      } catch (error) {
        setError('Error fetching data: ' + error.message);
        console.error('Error fetching data:', error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchLeaveReports();
  }, []);

  const headers = [
    { label: "Player Name", key: "playerName" },
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Parent Mobile Number", key: "parentMobileNumber" },
    { label: "Academy Name", key: "academyName" },
    { label: "Batch Name", key: "batchName" },
    { label: "Leave Credit", key: "leaveCredit" },
    { label: "Leave Days", key: "leaveDays" },
    { label: "Amount", key: "amount" },
    { label: "Status", key: "status" },
    { label: "Applied On", key: "appliedOn" },
    { label: "Missed Sessions", key: "missedSession" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const sortData = (data) => {
    if (sortConfig.key) {
      const sortedData = [...data].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        const extractNumericValue = (value) => {
          const numericValue = value.match(/[\d.-]+/g);
          return numericValue ? parseFloat(numericValue[0]) : value;
        };

        const parseDateValue = (value) => {
          const parsedDate = new Date(value);
          return isNaN(parsedDate.getTime()) ? value : parsedDate;
        };

        let aNumeric, bNumeric;
        if (sortConfig.key === 'startDate' || sortConfig.key === 'endDate' || sortConfig.key === 'appliedOn') {
          aNumeric = parseDateValue(aValue);
          bNumeric = parseDateValue(bValue);
        } else {
          aNumeric = extractNumericValue(aValue);
          bNumeric = extractNumericValue(bValue);
        }

        if (aNumeric < bNumeric) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aNumeric > bNumeric) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });

      return sortedData;
    }
    return data;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLeaveReport = LeaveReport.filter((Leave) =>
    Object.values(Leave)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const sortedData = sortData(filteredLeaveReport);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredLeaveReport.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredLeaveReport.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="challenges-container">
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredLeaveReport}
          headers={headers}
          filename="Leave_Report.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredLeaveReport.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">

          <thead>
            <tr className="challenges-header-row">
              <th onClick={() => handleSort('playerName')}>
                Player Name
                {sortConfig.key === 'playerName' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('mobileNumber')}>
                Mobile Number
                {sortConfig.key === 'mobileNumber' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('parentMobileNumber')}>
                Parent Mobile Number
                {sortConfig.key === 'parentMobileNumber' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('academyName')}>
                Academy Name
                {sortConfig.key === 'academyName' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('batchName')}>
                Batch Name
                {sortConfig.key === 'batchName' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('leaveCredit')}>
                Leave Credit
                {sortConfig.key === 'leaveCredit' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('leaveDays')}>
                Leave Days
                {sortConfig.key === 'leaveDays' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('amount')}>
                Amount
                {sortConfig.key === 'amount' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('status')}>
                Status
                {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('appliedOn')}>
                Applied On
                {sortConfig.key === 'appliedOn' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('missedSession')}>
                Missed Session
                {sortConfig.key === 'missedSession' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('startDate')}>
                Start Date
                {sortConfig.key === 'startDate' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => handleSort('endDate')}>
                End Date
                {sortConfig.key === 'endDate' && (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓')}
              </th>
            </tr>
          </thead>

          <tbody>
            {currentRows.reverse().map((Leave) => (
              <tr key={Leave} className="challenges-row">
                <td className="challenges-cell">{Leave.playerName}</td>
                <td className="challenges-cell">{Leave.mobileNumber}</td>
                <td className="challenges-cell">{Leave.parentMobileNumber}</td>
                <td className="challenges-cell">{Leave.academyName}</td>
                <td className="challenges-cell">{Leave.batchName}</td>
                <td className="challenges-cell">{Leave.leaveCredit}</td>
                <td className="challenges-cell">{Leave.leaveDays}</td>
                <td className="challenges-cell">{Leave.amount}</td>
                <td className="challenges-cell">{Leave.status}</td>
                <td className="challenges-cell">{Leave.appliedOn}</td>
                <td className="challenges-cell">{Leave.missedSession}</td>
                <td className="challenges-cell">{Leave.startDate}</td>
                <td className="challenges-cell">{Leave.endDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LeaveReport
