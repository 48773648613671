import React, { useState, useEffect } from 'react';
import '../Academy/NewAcademy.css';
import { Link, Navigate } from 'react-router-dom';
import Select from "react-select";
import { useLocation } from 'react-router-dom';
import axios from "axios";

const NewAcademy = () => {
  const location = useLocation();
  const academyData = location.state ? location.state.academy : null;
  const [isParentAcademy, setIsParentAcademy] = useState(true);
  const [academy, setAcademy] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [about, setAbout] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [sport, setSport] = useState('');
  const [facility, setFacility] = useState('');
  const [locality, setLocality] = useState('');
  const [coverPicture, setCoverPicture] = useState(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [maxActiveMembership, setMaxActiveMembership] = useState('');
  const [sportOptions, setSportOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [academyOptions, setAcademyOptions] = useState([]); // New state for academy options
  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState({
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    about: "",
    email: "",
    password: "",
    mobileNumber: "",
    isNotificationEnabled: true,
    isAvailableForMachaxiTrial: false,
    sportIds: [],
  });
  const [coverPic, setCoverPic] = useState(null);
  useEffect(() => {
    if (academyData) {
      setName(academyData.name);
      setIsParentAcademy(academyData.isParentAcademy);
      setAcademy(academyData.academy || ''); // Handle if not a parent academy
      setEmail(academyData.email);
      setPassword(academyData.password || '******8'); // If you want to keep it pre-filled for edit
      setAbout(academyData.about);
      setMobile(academyData.mobileNumber);
      setAddress(academyData.address);
      setSport(academyData.sport || []);
      setFacilityOptions(academyData.facilities || []);
      setLocality(academyData.locality);
      setNotificationsEnabled(academyData.notification || false);
      setMaxActiveMembership(academyData.maxActiveMembership);
      setCoverPicture(academyData.coverPicture || null);
      setEditMode(true);
    }
  }, [academyData]);
  console.log("this data is for editing that came", academyData)

  useEffect(() => {
    // Fetch sport and facility options from the API
    const fetchOptions = async () => {
      try {
        const response = await fetch('https://admin.stage.machaxi.com/api/academy/edit', {
          method: 'GET',
          headers: {
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
        });
        const data = await response.json();
  
        // Log the entire response and the data for inspection
        console.log('API Response:', response);
        console.log('Data:', data);
  
        if (data && data.success && data.data) {
          // Ensure data is properly structured for sportOptions and facilityOptions
          const sports = data.data.sports.map(s => ({ value: s.id, label: s.name }));
          const facilities = data.data.facilities.map(f => ({ value: f.name, label: f.name }));
  
          setSportOptions(sports);
          setFacilityOptions(facilities);
  
          const academies = data.data.parentAcademies.map(academy => ({
            value: academy.id,
            label: academy.name
          }));
          setAcademyOptions(academies);
        }
        
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
  
    fetchOptions();
  }, []);
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverPic(file);
      console.log("File selected:", file);
    } else {
      console.error("No file selected");
    }
  };


  useEffect(() => {
    // Ensure that sportOptions are loaded before setting the sport values
    if (sportOptions.length > 0 && academyData?.sport?.length > 0) {
      setSport(
        academyData.sport.map(id =>
          sportOptions.find(option => option.value === id)
        ).filter(option => option) // Filter out undefined values
      );
    }

    // Ensure that facilityOptions are loaded before setting the facility values
    if (facilityOptions.length > 0 && academyData?.facilities?.length > 0) {
      setFacility(
        academyData.facilities.map(id =>
          facilityOptions.find(option => option.value === id)
        ).filter(option => option) // Filter out undefined values
      );
    }
  }, [sportOptions, facilityOptions, academyData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Prepare the data to submit
    const formData = {
      id: academyData?.id || null,  // Replace with actual dynamic id if needed
      name,
      address,
      about,
      email,
      password,
      maxActiveMembership,
      mobileNumber: mobile,
      isNotificationEnabled: notificationsEnabled,
      isAvailableForMachaxiTrial: false,
      sportIds: sport.map(s => s.value), // Ensure the selected sports are in the correct format
      facilityIds: facility.map(f => f.value),
      coverPicture: coverPicture || null,
    };
    console.log("Form Data:", formData); // Log the form data for debugging
  
    // Construct the API URL with the dynamic query parameter (if necessary)
    const apiUrlBase = 'https://admin.stage.machaxi.com/api/academy/new';
    const apiUrl = formData.id ? `${apiUrlBase}?userId=${formData.id}` : apiUrlBase;
  
    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("academyForm", new Blob([JSON.stringify(form)], { type: "application/json" }));

      if (coverPic) {
        formData.append("coverPic", coverPic);
      } else {
        console.warn("No cover picture provided");
      }

      // Debugging: Log FormData content
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}:`, pair[1] instanceof Blob ? "Blob/File object" : pair[1]);
      }

      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-authorization": `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ`,
        },
      });

      console.log("Response:", response.data);
      alert(response.data.message || "Academy created successfully!");
    } catch (error) {
      console.error("Error creating academy:", error);
      alert("Failed to create academy. Please try again.");
    }
  };
  

  useEffect(() => {
    if (academyData) {
      // Map sport options correctly from the fetched options
      const selectedSports = academyData.sport && academyData.sport.length > 0
        ? sportOptions.filter(option => academyData.sport.includes(option.value))
        : [];
      setSport(selectedSports);

      // Map facility options correctly from the fetched options
      const selectedFacilities = Array.isArray(academyData.facilities)
        ? facilityOptions.filter(option => academyData.facilities.includes(option.value))
        : [];
      setFacility(selectedFacilities);
    }
  }, [sportOptions, facilityOptions, academyData]);

  const handleSportChange = (selectedOptions) => {
    setSport(selectedOptions); // Set the selected options as an array
  };

  // Handler for updating selected facilities
  const handleFacilityChange = (selectedOptions) => {
    setFacility(selectedOptions); // Set the selected options as an array
  };


  return (
    <div className="form-container">
      <div className="breadcrumb">
        <Link to="/academylist" className="breadcrumb-link">Academy List</Link> &gt; New Academy
      </div>
      <form onSubmit={handleSubmit} className="academy-form">
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-input"
          />
        </div>

        {/* <div className="form-group">
          <label className="form-label">Parent Academy:</label>
          <input
            type="checkbox"
            checked={isParentAcademy}
            onChange={handleParentAcademyChange}
            className="form-checkbox"
          />
          {!isParentAcademy && (
            <div className="form-group">
              <label className="form-label">Academy:</label>
              <select
                value={academy}
                onChange={(e) => setAcademy(e.target.value)}
                required
                className="form-select"
              >
                <option value="">Select Academy</option>
                {academyOptions.map(academy => (
                  <option key={academy.value} value={academy.value}>
                    {academy.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div> */}

        <div className="form-group">
          <label className="form-label">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="form-input"
            disabled={editMode && !!password} // Disable password field only if in edit mode and password is prefilled
          />
        </div>

        <div className="form-group">
          <label className="form-label">About:</label>
          <textarea
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            required
            className="form-textarea"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Mobile Number:</label>
          <input
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
            className="form-input"
            maxLength={10}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Address:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Sport:</label>
          <Select
            isMulti
            options={sportOptions}
            value={sport} // Set the current value for the sport multi-select
            onChange={handleSportChange} // Update state on selection change
          />
        </div>
        <div className="form-group">
          <label className="form-label">Facilities:</label>
          <Select
            isMulti
            options={facilityOptions}
            value={facility} // Set the current value for the facility multi-select
            onChange={handleFacilityChange} // Update state on selection change
          />
        </div>

        <div className="form-group">
          <label className="form-label">Locality:</label>
          <input
            type="text"
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            required
            className="form-input"
          />
        </div>
        <label>
        Cover Picture (optional):
        <input type="file" name="coverPic" accept="image/*" onChange={handleFileChange} />
      </label>



        <div className="form-group">
          <label className="form-label">Enable Notifications:</label>
          <input
            type="checkbox"
            checked={notificationsEnabled}
            onChange={() => setNotificationsEnabled(!notificationsEnabled)}
            className="form-checkbox"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Max Active Playing Membership:</label>
          <input
            type="text"
            value={maxActiveMembership}
            onChange={(e) => setMaxActiveMembership(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <button type="submit" className="form-submit-btn">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default NewAcademy;