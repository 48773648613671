import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../sliders/NewSlider.css';

const NewAttributeQA = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const progressAttributeId = location.state?.progressAttributeId || null;
  const academyId = location.state?.academyId || null;

  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchQAData = async () => {
      if (!academyId) return; // Skip fetching if academyId is not provided

      try {
        const response = await fetch(
          `http://localhost:8082/api/admin/edit-progress-attribute-qa?progressAttributeQAId=${academyId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.success && data.data.sportProgressAQAEditForm) {
            setHeading(data.data.sportProgressAQAEditForm.question || '');
            setSubHeading(data.data.sportProgressAQAEditForm.answer || '');
          }
        } else {
          setError('Failed to fetch QA details.');
        }
      } catch (error) {
        setError('Error fetching QA details.');
      }
    };

    fetchQAData();
  }, [academyId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!heading || !subHeading) {
      setError('Both Question and Answer fields are required.');
      return;
    }

    setError('');
    setSuccess('');

    const requestData = {
      id: academyId || null, // Allow null if academyId is missing
      question: heading,
      answer: subHeading,
    };

    try {
      const response = await fetch(
        `http://localhost:8082/api/admin/new-progress-attribute-qa?progressAttributeId=${progressAttributeId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        setSuccess('QA submitted successfully!');
        setHeading('');
        setSubHeading('');

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'QA submitted successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to submit QA.');
      }
    } catch (error) {
      console.error('Error submitting QA:', error);
      setError('An error occurred while submitting QA.');
    }
  };

  return (
    <div className="new-slider-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt; Create QA
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Question:</label>
          <input
            type="text"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            placeholder="Enter Question"
          />
        </div>
        <div>
          <label>Answer:</label>
          <input
            type="text"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Enter Answer"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default NewAttributeQA;
