import React, { useState, useEffect } from 'react';
import './Challenge.css';
import { CSVLink } from 'react-csv';
import { useUser } from '../context/UserContext';
import Select from 'react-select';

const LearnTrailReports = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const getFormattedDate = (date) => date.toISOString().split('T')[0];
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const [fromDate, setFromDate] = useState(getFormattedDate(tenDaysAgo));
  const [toDate, setToDate] = useState(getFormattedDate(today));
  const [LearnTrail, setLearnTrail] = useState([]);
  const [academyNameMap, setAcademyNameMap] = useState({});
  const { selectedCenter, setSelectedCenter, userData } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedCenter && academyNameMap[selectedCenter]) {
      setSelectedAcademy(selectedCenter);
    }
  }, [selectedCenter, academyNameMap]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const apiUrl = "https://admin.stage.machaxi.com/api/academy/learn-trials";
      const headers = {
        "Content-Type": "application/json",
        'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            fromDate: fromDate,
            toDate: toDate,
          }),
        });

        const result = await response.json();
        console.log("API Response on date change:", result);

        if (result.success) {
          setAcademyNameMap(result.data.academyNameMap); // Ensure academyNameMap is set here
          const bookings = result.data.bookings.map((booking) => ({
            name: booking?.name || '-',
            phone: booking?.contact || '-',
            batch: booking?.batch?.name || '-',
            date: booking?.trialBookDate ? new Date(booking.trialBookDate).toISOString().split('T')[0] : '-',
            time: formatTime(booking?.startTime) || '-',
            academy: booking?.academyId || '-',
          }));
          setLearnTrail(bookings);
        } else {
          setError("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  const formatTime = (time) => {
    const [hour, minute] = time.split(':');
    const hours = parseInt(hour, 10);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = hours % 12 || 12;
    return `${formattedHour}:${minute} ${period}`;
  };

  const headers = [
    { label: "Academy", key: "batch" },
    { label: "Name", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "Date", key: "date" },
    { label: "Time", key: "time" },
    { label: "academy", key: "academy" },
  ];

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedAcademy, fromDate, toDate]);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const filteredLearnTrail = LearnTrail.filter((learn) => {
    const matchesSearch = [learn.name, learn.phone, learn.batch].some((field) =>
      field.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const isAcademyUser = userData?.userType === "ACADEMY";
    const matchesAcademy = isAcademyUser
    ? (selectedAcademy === "" 
        ? userData.academyIds.includes(Number(learn.academy)) 
        : Number(learn.academy) === Number(selectedAcademy))
    : (selectedAcademy === "" || Number(learn.academy) === Number(selectedAcademy));

    const matchesUserAcademy =
      !isAcademyUser ||
      (selectedAcademy === "" && Array.isArray(userData.academyIds) && userData.academyIds.length > 0) ||
      (Array.isArray(userData.academyIds) && userData.academyIds.includes(Number(selectedAcademy)));

    console.log("isAcademyUser:", isAcademyUser);
    console.log("userData.academyIds:", userData.academyIds);
    console.log("selectedAcademy:", selectedAcademy);
    const matchesDate =
      (fromDate === "" || new Date(learn.date).getTime() >= new Date(fromDate).getTime()) &&
      (toDate === "" || new Date(learn.date).getTime() <= new Date(toDate).getTime());

    return matchesSearch && matchesAcademy && matchesDate && matchesUserAcademy;
  });
console.log(filteredLearnTrail)
  const sortedLearnTrail = React.useMemo(() => {
    let sortableItems = [...filteredLearnTrail];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredLearnTrail, sortConfig]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedLearnTrail.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredLearnTrail.length / rowsPerPage);

  const handleSort = (column) => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const renderPagination = () => {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
      <button
        key={page}
        className={`page-number ${currentPage === page ? 'active' : ''}`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    ));
  };
  const totalEntries = filteredLearnTrail.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  const getAcademyOptions = () => {
    const academyOptions =
      userData?.userType === 'ACADEMY'
        ? userData.academyIds
          .map(id => ({
            value: id,
            label: academyNameMap[id],
          }))
          .filter(option => option.label)
        : Object.keys(academyNameMap)
          .map(academyId => ({
            value: academyId,
            label: academyNameMap[academyId],
          }));

    return academyOptions;
  };

  return (
    <div className="challenges-container">
      <div className="sales-filters-login">

        <div className="floating-select">
          <Select
            id="selectedAcademy"
            className={`sales-filter-court-booking ${selectedAcademy ? "has-value" : ""}`}
            options={getAcademyOptions()}
            value={
              selectedAcademy
                ? { value: selectedAcademy, label: academyNameMap[selectedAcademy] }
                : null
            }
            onChange={(selectedOption) => {
              setSelectedAcademy(selectedOption?.value || "");
            }}
            isClearable
            placeholder="Select Academy"
            styles={{
              placeholder: (base) => ({
                ...base,
                color: "black",
              }),
            }}
          />
          {selectedAcademy && <label htmlFor="selectedAcademy">Academy</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${fromDate ? "has-value" : ""}`}
            value={fromDate}
            onChange={(e) => {
              console.log("New fromDate:", e.target.value);
              setFromDate(e.target.value);
            }}
            onFocus={(e) => e.target.showPicker()}
          />

          {fromDate && <label htmlFor="fromDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${toDate ? "has-value" : ""}`}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {toDate && <label htmlFor="toDate">End Date</label>}
        </div>
      </div>

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredLearnTrail}
          headers={headers}
          filename="LearnTrialReportreports.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredLearnTrail.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div style={{ maxWidth: '1150px', overflowY: 'auto' }}>
        <table className="challenges-table">
          <thead>
            <tr className="challenges-header-row">
              <th onClick={() => handleSort('name')}>
                Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('phone')}>
                Phone {sortConfig.key === 'phone' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('batch')}>
                Batch {sortConfig.key === 'batch' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('date')}>
                Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('time')}>
                Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>

            </tr>
          </thead>
          <tbody>
            {currentRows.map((learn) => (
              <tr key={learn.id} >
                <td>{learn.name}</td>
                <td>{learn.phone}</td>
                <td>{learn.batch}</td>
                <td>{learn.date}</td>
                <td>{learn.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* )} */}
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LearnTrailReports
