import React, { useState } from 'react';
import { useUser } from '../context/UserContext';
import './Profile.css';

const Profile = () => {
  const { userData } = useUser();
  const [showAllPrivileges, setShowAllPrivileges] = useState(false);

  if (!userData) {
    return <div className="loading">Loading...</div>;
  }
console.log(userData)
  // Set academyId to 'All' if userType is admin
  const academyId = userData.userType === 'ADMIN' ? 'All' : userData.academyId;

  // Extract the first object from privileges array
  const privilegesData = userData.privileges[0] || {};

  // Convert privilegesData to an array of [section, privileges]
  const privilegesEntries = Object.entries(privilegesData);

  // Limit the number of sections shown initially
  const privilegesToShow = showAllPrivileges 
    ? privilegesEntries 
    : privilegesEntries.slice(0, 2);

  const handleShowMoreClick = () => {
    setShowAllPrivileges(true);
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <div className="profile-header">
          <h1 className="profile-title">Profile Details</h1>
        </div>
        <div className="profile-info">
          <div className="profile-row">
            <span className="label">User ID:</span>
            <span className="value">{userData.userId}</span>
          </div>
          <div className="profile-row">
            <span className="label">Email:</span>
            <span className="value">{userData.email}</span>
          </div>
          <div className="profile-row">
            <span className="label">User Type:</span>
            <span className="value">{userData.userType}</span>
          </div>
          <div className="profile-row">
            <span className="label">Academy ID:</span>
            <span className="value">{academyId}</span>
          </div>
          <div className="profile-row-pre">
            <span className="label">Privileges:</span>
            <div className="privileges">
              <ul>
                {privilegesToShow.map(([section, privileges], index) => (
                  <li key={index}>
                    <strong>{section}:</strong>
                    <ul>
                      {Array.isArray(privileges) ? (
                        privileges.map((priv, idx) => (
                          <li key={idx}>{priv}</li>
                        ))
                      ) : (
                        <li>No privileges available</li>
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
              {!showAllPrivileges && privilegesEntries.length > 2 && (
                <button onClick={handleShowMoreClick} className="show-more-button">
                  Show More
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
