import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './RazorPayauth.css'; // Import the CSS file
import { useUser } from '../context/UserContext';

const RazorPayauth = () => {
  const [orderType, setOrderType] = useState(null);
  const [dueOrderId, setDueOrderId] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const { userData } = useUser();

  // Extract userId
  const userId = userData?.userId;

  // Fetch orderType options dynamically with headers
  useEffect(() => {
    const fetchOrderTypes = async () => {
      try {
        const response = await fetch(`http://localhost:8082/api/razorpay/auth?userId=2`, {
          method: 'GET',
          headers: {
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with actual token
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();

        if (result.success && result.data?.orderType) {
          const formattedOptions = result.data.orderType.map((type) => ({
            value: type,
            label: type.replace(/_/g, ' '),
          }));
          setOrderTypeOptions(formattedOptions);
        }
      } catch (error) {
        console.error('Error fetching order types:', error);
      }
    };

    fetchOrderTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      dueOrderId: Number(dueOrderId),
      amount: Number(amount),
      amountPaid: Number(amount), // Assuming amountPaid is the same as amount
      razorpayPaymentId: paymentId,
      orderType: orderType?.value || '',
      participantUserId: userId, // Using extracted userId if available
    };

    try {
      const response = await fetch(`http://localhost:8082/api/razorpay/auth?userId=${userId}`, {
        method: 'POST',
        headers: {
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with actual token
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();
      console.log('API Response:', result);

      if (result.success) {
        alert('Payment Authorized Successfully!');
      } else {
        alert('Payment Authorization Failed!');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while processing your request.');
    }
  };


  return (
    <div className="razorpayauth-container">
      <h3>Razorpay Authorization</h3>
      <form onSubmit={handleSubmit} className="razorpayauth-form">
        <div className="input-group">
          <label htmlFor="orderType">Order Type *</label>
          <Select
            id="orderType"
            options={orderTypeOptions} // Dynamically populated options
            value={orderType}
            onChange={setOrderType}
            placeholder="Select Order Type"
            isClearable
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="dueOrderId">Due Order ID *</label>
          <input
            type="text"
            id="dueOrderId"
            value={dueOrderId}
            onChange={(e) => setDueOrderId(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="paymentId">Razorpay Payment ID *</label>
          <input
            type="text"
            id="paymentId"
            value={paymentId}
            onChange={(e) => setPaymentId(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default RazorPayauth;
