import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './BookingConfirmation.css';
import Swal from "sweetalert2";
const BookingConfirmation = ({ bookingDetails }) => {
  const location = useLocation();
  const { sportId, date, academyId, courtTimingIds } = bookingDetails || {};
console.log("bookingsetails recieved ",bookingDetails)
  const [academyName, setAcademyName] = useState('');
  const [bookingDate, setBookingDate] = useState('');
  const [price, setPrice] = useState(0);
  const [gstPrice, setGstPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [courts, setCourts] = useState([]);
  const [userName, setUserName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collectionMethod, setCollectionMethod] = useState('');

  const collectionMethods = ["Venue_qr", "Cash", "RazorPay"];

  useEffect(() => {
    const fetchSlotDetails = async () => {
      try {
        if (!sportId || !date || !academyId || !courtTimingIds || courtTimingIds.length === 0) {
          throw new Error('Invalid booking details');
        }
    
        const selectedSlots = courtTimingIds.join(',');
        const apiUrl = `http://localhost:8082/api/react/court/getSlotsDetails?guestCount=&selectedSlots=${selectedSlots}&date=${date}&sportId=${sportId}&academyId=${academyId}`;
        console.log(apiUrl)
        const headers = {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with actual token or get from env
        };
    
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: headers,
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        if (data.success) {
          const slotDetails = data.data.slotDetails;
          setAcademyName(slotDetails.academyName);
          setBookingDate(new Date(slotDetails.date).toLocaleDateString());
          setPrice(slotDetails.price);
          setGstPrice(slotDetails.gstPrice);
          setTotalPrice(slotDetails.totalPrice);
    console.log(slotDetails)
          const courtDetails = slotDetails.courtAndTimingDtos.map(court => ({
            name: court.courtName,
            timeslots: [court.displayTIme],
            courtId: court.courtId,
            courtTimingId: court.courtTimingId,
            startTime: court.startTime,
            endTime: court.endTime,
          }));
          setCourts(courtDetails);
          
        } else {
          throw new Error(data.success_message || 'Failed to fetch slot details');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    

    fetchSlotDetails();
  }, [sportId, date, academyId, courtTimingIds]);

  const handleConfirmBooking = async () => {
    const payload = {
      username: userName,
      userMobileNumber: `+91${mobileNumber}`,
      academyId: academyId,
      date: date,
      courtAndTimingDtos: courts.map(court => ({
        academyId: academyId,
        sportId: sportId,
        courtId: court.courtId, // Assuming courtId is available in court details
        courtTimingId: court.courtTimingId, // Assuming courtTimingId is available in court details
        startTime: court.startTime, // Assuming startTime is available in court details
        endTime: court.endTime, // Assuming endTime is available in court details
      })),
      guestCount:2,
      paymentMethodOffline: collectionMethod.toUpperCase(),
    };
console.log(payload)
    try {
      const response = await fetch('http://localhost:8082/api/react/court/book-court', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Court submitted successfully!",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        throw new Error(data.message || 'Failed to confirm booking');
      }
    } catch (error) {
      setError(error.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };


  return (
    <div className="booking-container-confirmation">
    <h2 className="booking-title">Booking Confirmation Details</h2>
    <div className="booking-section-court">
      <h3 className="section-title">Court Details:</h3>
      <table className="court-table">
        <thead>
          <tr>
            <th>Court Name</th>
            <th>Time Slots</th>
          </tr>
        </thead>
        <tbody>
          {courts.map((court, index) => (
            <tr key={index}>
              <td>{court.name}</td>
              <td>
                <ul className="timeslot-list">
                  {court.timeslots.map((timeslot, idx) => (
                    <li key={idx} className="timeslot">{timeslot}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
    <div className="booking-section">
      <p className="amount">Academy:  <span>{academyName}</span></p>
      <p className="amount">Date: <span>{bookingDate}</span></p>
      <p className="amount">Booking Amount: <span>₹{price}</span></p>
      <p className="amount">GST Amount: <span>₹{gstPrice}</span></p>
      <p className="total-amount">Total Amount: <span>₹{totalPrice}</span></p>

      <div className="user-input-container">
        <div className="input-group-court">
          <label className="user-label">Name:</label>
          <input
            type="text"
            className="user-input-court"
            placeholder="Enter your name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="input-group-court">
          <label className="user-label">Mobile Number:</label>
          <input
            type="tel"
            className="user-input-court"
            placeholder="Enter your phone number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            maxLength={10}
          />
        </div>
      </div>
      <h3 className="section-title">Collection Method:</h3>
      {collectionMethods.map((method, index) => (
        <label key={index} className="collection-option">
          <input
            type="radio"
            name="collectionMethod"
            value={method}
            className="radio-input"
            onChange={() => setCollectionMethod(method)}
          />
          {method}
        </label>
      ))}
    </div>
    <button className="confirm-booking-btn" onClick={handleConfirmBooking}>Confirm Booking</button>
  </div>
  );
};

export default BookingConfirmation;