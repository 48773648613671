import React from 'react'

const  RepeatRate = () => {
  return (
    <div>
       Repeat Rate
    </div>
  )
}

export default  RepeatRate
