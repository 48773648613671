import React, { useState, useEffect ,useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import '../UserRole/UserList.css';
import DeleteUserModal from '../modal/DeleteUserModal';
import Swal from 'sweetalert2';
import { useUser } from '../context/UserContext';
const UserList = () => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({ name: '', email: '', mobile: '', userType: '', roles: [] });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };

    if (dropdownOpen !== null) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownOpen]);
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);  

      try {
        const response = await fetch( process.env.REACT_APP_API_ASSIGN_USER, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        setUsers(data.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError(error.message);  
      } finally {
        setLoading(false); 
      }
    };

    fetchUsers();
  }, []);
  const headers = [
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "User Type", key: "userType" },
    { label: "Role", key: "role" },
  ];
  
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
const filteredUsers = users.filter((user) =>
  Object.values(user).some((value) =>
    value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
);
const handleSort = (columnKey) => {
  let direction = "ascending";
  if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
    direction = "descending";
  }
  setSortConfig({ key: columnKey, direction });
};

const sortData = (data) => {
  const { key, direction } = sortConfig;
  return [...data].sort((a, b) => {
    if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
    if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
    return 0;
  });
};
const sortedData = sortData(filteredUsers);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData .reverse().slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCreateUser = () => navigate('/create-user');

  const toggleDropdown = (userId) => {
    setDropdownOpen((prev) => (prev === userId ? null : userId));
  };

  const handleEditClick = (user) => {
    navigate('/create-user', {
      state: {
        userId: user.id,
        name: user.name,
        email: user.email,
        mobile: user.mobile_number,
        userType: user.user_type,
        roles: user.roles,
        academyIds:user.academy_ids,
        academyNames:user.academy_names,
      },
    });
    setDropdownOpen(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'roles') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.split(',').map((role) => role.trim()), 
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
    setDropdownOpen(null);
  };

  const handleConfirmDelete = async () => {
    if (!userToDelete) return;
  
    const apiUrlBase =  process.env.REACT_APP_API_USER_DELETE; 
    const apiUrl = `${apiUrlBase}?userId=${userToDelete.id}`;
  
    try {
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
        },
      });
      if (response.ok) {
        const data = await response.text();
  
        if (data) {
          const jsonData = JSON.parse(data); 
        
        } else {
        
        }
  
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User deleted successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        setShowDeleteModal(false)
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));
  
      } else {
        const error = await response.json(); 
        console.error('Failed to delete user:', error.message || error);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the user.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      
    }
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const closeDeleteModal = () => setShowDeleteModal(false);
   const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const totalEntries = filteredUsers.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="user-list-container">
      <div className="search-bar">
      <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handleCreateUser}>
          + Create User
        </button>
      </div>
           <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredUsers.length === 0 && (
        <div className="error-message">No data available</div>
      )}
      <div className="table-container"style={{ maxWidth: '1150px', overflowY: 'auto' }}>
        <table className="user-table">
          <thead>
            <tr>
                  {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} 
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> 
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
              {userData.roles?.includes("super admin") && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((user) => (
              <tr key={user.id|| '-'}>
                <td>{user.name|| '-'}</td>
                <td>{user.mobile_number || '-'}</td> 
                <td>{user.user_type|| '-'}</td>
                <td>{user.roles.join(', ')|| '-'}</td> 
                {userData.roles?.includes("super admin") && (<td className="action-buttons">
  
      <button className="edit-btn" onClick={() => handleEditClick(user)}>Edit</button>
      <button  className="delete-btn" onClick={() => handleDeleteClick(user)}>Delete</button>
</td>
)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
  <div className="showing-entries">
    Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
  </div>
  <button
    className="prev-button"
    onClick={handlePrevPage}
    disabled={currentPage === 1}
  >
    Previous
  </button>
  {renderPagination()}
  <button
    className="next-button"
    onClick={handleNextPage}
    disabled={currentPage === totalPages}
  >
    Next
  </button>
</div>
      {showDeleteModal && (
        <DeleteUserModal
          userToDelete={userToDelete}
          handleConfirmDelete={handleConfirmDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
};

export default UserList;