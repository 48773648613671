import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CourtList.css';
import { useUser } from '../context/UserContext';
import axios from 'axios';
import Swal from "sweetalert2";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CourtTimingsChart = ({ courts }) => {
  const labels = courts.map(court => court.name);
  const timings = courts.map(court => {
    const timingStrings = court.timing.split(', ');
    const totalMinutes = timingStrings.reduce((acc, timing) => {
      const [start, end] = timing.split(' - ');
      const startTime = new Date(`1970-01-01T${start}`);
      const endTime = new Date(`1970-01-01T${end}`);
      const duration = (endTime - startTime) / (1000 * 60);
      return acc + duration;
    }, 0);
    return totalMinutes;
  });

  const timingDetails = courts.map(court => court.timing);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Total Court Time (minutes)',
        data: timings,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Court Timings',
      },
      tooltip: {
        callbacks: {
          afterBody: (context) => {
            const index = context[0].dataIndex;
            return `Timings: ${timingDetails[index]}`;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

const CourtList = () => {
  const { selectedCenter, setSelectedCenter } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [courts, setCourts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    const fetchCourts = async () => {
      try {
        const response = await axios.get(
          `http://localhost:8082/api/react/court/inventory?academyId=${selectedCenter}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
            },
          }
        );

        if (response.data.success && response.data.data?.courts) {
          const { courts: fetchedCourts } = response.data.data;

          const formattedCourts = fetchedCourts.map((court) => ({
            id: court?.id,
            name: court?.name || '-',
            sport: court?.sportName || '-',
            timing: court?.courtTimings?.map(timing => `${timing.openTime} - ${timing.closeTime}`).join(', ') || '-',
          }));

          setCourts(formattedCourts);
        } else {
          console.log('No courts found for this academy.');
        }
      } catch (err) {
        console.error('Failed to fetch court data:', err);
        setError('Error fetching data: ' + error.message);

      } finally {
        setLoading(false);
      }
    };

    if (selectedCenter) {
      fetchCourts();
    }
  }, [selectedCenter]);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Sport', key: 'sport' },
    { label: 'Timing', key: 'timing' },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateCourt = () => {
    navigate('/newcourt');
  };

  const filteredCourts = courts.filter((court) =>
    Object.values(court)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(filteredCourts);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const toggleDropdown = (courtId) => {
    setDropdownOpen(dropdownOpen === courtId ? null : courtId);
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`courtlist-page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="courtlist-pagination-dots">
          {page}
        </span>
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.courtlist-dropdown-menu');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.courtlist-dots-btn')) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleEdit = (court) => {
    navigate('/newcourt', { state: { courtData: court } });
  };

  const handleDelete = async (courtId) => {
    if (!selectedCenter) {
      Swal.fire({
        icon: "warning",
        title: "No academy selected",
        text: "Please select an academy before deleting a court.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      return;
    }

    const confirmDelete = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Do you really want to delete this court?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    });
    if (!confirmDelete.isConfirmed) return;

    try {
      const response = await axios.get(`http://localhost:8082/api/react/court/${courtId}/delete`, {
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        }
      });

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Court deleted successfully.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

        setCourts((prevCourts) => prevCourts.filter((court) => court.id !== courtId));
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to delete court.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("Error deleting court:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || error.message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

    }
  };

  const handleUnavailableDate = (court) => {
    navigate('/unavailableDate', { state: { courtData: court } });
  };
  const totalEntries = filteredCourts.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="challenges-container">
      {!loading && !error && courts.length > 0 && (
        <div className="court-timings-container">
          <CourtTimingsChart courts={courts} />
        </div>
      )}

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handleCreateCourt}>
          + Create Court
        </button>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredCourts.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <div className='space'></div>
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr className="courtlist-header-row">
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="courtlist-column courtlist-column-action"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>

              ))}
              <th className="courtlist-column courtlist-column-action">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((court) => (
              <tr key={court.id} className="courtlist-row">
                <td className="courtlist-cell courtlist-cell-name">{court.name}</td>
                <td className="courtlist-cell courtlist-cell-sport">{court.sport}</td>
                <td className="courtlist-cell courtlist-cell-timing">{court.timing}</td>
                <td className="courtlist-cell courtlist-cell-action">
                  <button
                    className="courtlist-dots-btn"
                    onClick={() => toggleDropdown(court.id)}
                  >
                    &#x22EE;
                  </button>
                  <div
                    className={`courtlist-dropdown-menu ${dropdownOpen === court.id ? 'visible' : ''
                      }`}
                  >
                    <button onClick={() => handleEdit(court.id)} className="courtlist-dropdown-item">Edit</button>
                    <button onClick={() => handleDelete(court.id)} className="courtlist-dropdown-item">Delete</button>
                    <button onClick={() => handleUnavailableDate(court.id)} className="courtlist-dropdown-item">Unavailable Dates</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CourtList;
