import React, { useState, useEffect } from 'react';
import './BankDetails.css';
import { useUser } from '../context/UserContext';

const BankDetails = () => {
  const { selectedCenter } = useUser();

  const [formData, setFormData] = useState({
    beneficiaryName: '',
    accountNumber: '',
    accountType: '',
    ifscCode: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bankTransferEnabled, setBankTransferEnabled] = useState(false);

  useEffect(() => {
    const fetchBankDetails = async () => {
      if (!selectedCenter) return;

      const url = `http://localhost:8082/api/academy/${selectedCenter}/bank-details`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your actual auth token
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch bank details');
        }

        const result = await response.json();
        if (result.success && result.data.bankDetailForm) {
          const { beneficiaryName, accountNumber, accountType, ifscCode } = result.data.bankDetailForm;
          setFormData({ beneficiaryName, accountNumber, accountType, ifscCode });
          setBankTransferEnabled(result.data.bankTransferEnabled);
        }

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBankDetails();
  }, [selectedCenter]);

  const handleToggleBankTransfer = async () => {
    if (!selectedCenter) {
      console.error("No academy selected.");
      return;
    }

    const apiUrl = `http://localhost:8082/api/academy/${selectedCenter}/${bankTransferEnabled ? 'disable' : 'enable'}-bank-transfer`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ", // Replace with your actual auth token
        },
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to update bank transfer status");
      }

      setBankTransferEnabled(!bankTransferEnabled);
    } catch (error) {
      console.error("Error toggling bank transfer:", error.message);
    }
  };

  return (
    <div className="update-bank-container">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="beneficiaryName">Beneficiary Name</label>
          <input
            type="text"
            id="beneficiaryName"
            name="beneficiaryName"
            value={formData.beneficiaryName}
            className="form-input"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="accountNumber">Account Number</label>
          <input
            type="text"
            id="accountNumber"
            name="accountNumber"
            value={formData.accountNumber}
            className="form-input"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="accountType">Account Type</label>
          <select id="accountType" name="accountType" value={formData.accountType} className="form-input" disabled>
            <option value="">Select Account Type</option>
            <option value="SAVING">Savings</option>
            <option value="CURRENT">Current</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="ifscCode">IFSC Code</label>
          <input
            type="text"
            id="ifscCode"
            name="ifscCode"
            value={formData.ifscCode}
            className="form-input"
            disabled
          />
        </div>

        <div className="bank-transfer-container">
          <button
            type="button"
            onClick={handleToggleBankTransfer}
            className={`bank-transfer-button ${bankTransferEnabled ? 'disable' : 'enable'}`}
          >
            {bankTransferEnabled ? 'Disable Bank Transfer' : 'Enable Bank Transfer'}
          </button>
          {!bankTransferEnabled && <span className="status-text">Bank Transfer is Disabled</span>}
        </div>
      </form>
    </div>
  );
};

export default BankDetails;
