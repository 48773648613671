import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../coupons/Coupon.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { CSVLink } from 'react-csv';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const Summer = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { selectedCenter, setSelectedCenter } = useUser();
  // Filters
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const [startDate, setStartDate] = useState(tenDaysAgo.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const headers = [
    { label: "Academy Name", key: "academy_name" },
    { label: "Name", key: "name" },
    { label: "Mobile Number", key: "mobile_number" },
    { label: "Sport Name", key: "sport_name" },
    { label: "Joining Date", key: "joining_date" },
    { label: "Expiry Date", key: "expiry_date" },
    { label: "Sessions", key: "sessions" },
    { label: "Start Time", key: "start_time" },
    { label: "End Time", key: "end_time" },
    { label: "Plan/Batch", key: "plan_name" },
    { label: "Amount", key: "total_amount" },
    { label: "Purchase at", key: "created_at" },
  ];
  const [academies, setAcademies] = useState([]);  // Define state to store academies

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {

    if (selectedCenter) {
      // If a center is manually selected, use it
      setSelectedAcademy(selectedCenter);
    } else if (userData?.userType === "ACADEMY") {
      if (userData.academyIds?.length > 0) {

        // Find a valid academyId that exists in apiData
        const validAcademyId = userData.academyIds?.find((academyId) =>
          apiData.some((item) => Number(item.academy_id) === Number(academyId))
        );


        if (validAcademyId) {
          setSelectedAcademy("allAcademies");
        } else {
          setSelectedAcademy(""); // Reset if no valid academyId is found
        }
      } else {
        setSelectedAcademy(""); // Reset if no academyIds are available
      }
    } else {
      setSelectedAcademy(""); // Default case if user is not of type 'ACADEMY'
    }

    // Cleanup function
    return () => {
      setSelectedAcademy(""); // Reset the selected academy when the component unmounts or dependencies change
    };
  }, [userData, selectedCenter, apiData]);
  // Add apiData to the dependency array

  useEffect(() => {
    setLoading(true); // Start loading
    setError(null);   // Reset error state

    fetch(process.env.REACT_APP_API_PURCHASE, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          const fetchedData = data.data.apiData || [];
          const fetchedAcademies = data.data.academies || []; // Extract academies data


          setApiData(fetchedData);
          setFilteredData(fetchedData);
          setAcademies(fetchedAcademies); // Set the academies state

          if (fetchedData.length > 0) {
            const earliestDate = fetchedData
              .map((item) => new Date(item.joining_date))
              .reduce((min, date) => (date < min ? date : min), new Date());


            // Set today's date as end date
            const today = new Date();
            setEndDate(today.toISOString().split('T')[0]);
          }
        } else {
          throw new Error('API response unsuccessful');
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }, []);



  const adjustedEndDate = endDate ? new Date(new Date(endDate).setHours(23, 59, 59, 999)) : null;

  useEffect(() => {
    let updatedData = apiData.filter((item) => {
      const itemJoiningDate = item.created_at ? new Date(item.created_at) : new Date();
      const itemExpiryDate = item.created_at
        ? new Date(new Date(item.created_at).setHours(23, 59, 59, 999))
        : new Date();

      const matchesSearch = searchTerm
        ? Object.values(item).join(' ').toLowerCase().includes(searchTerm.toLowerCase())
        : true;

      // Ensure we filter based on joining date (created date)
      const matchesStartDate = startDate ? itemJoiningDate >= new Date(startDate) : true;
      const matchesEndDate = adjustedEndDate ? itemExpiryDate <= adjustedEndDate : true;

      // Handle the case where selectedAcademy is "allAcademies"
      const matchesAcademy = selectedAcademy === "allAcademies"
        ? userData.academyIds.includes(Number(item.academy_id)) // Include all academies associated with the user
        : selectedAcademy
          ? Number(item.academy_id) === Number(selectedAcademy)
          : true;

      const matchesSport = selectedSport ? item.sport_name === selectedSport : true;

      return matchesSearch && matchesStartDate && matchesEndDate && matchesAcademy && matchesSport;
    });

    setFilteredData(updatedData);
    setCurrentPage(1);
  }, [searchTerm, startDate, endDate, selectedAcademy, selectedSport, apiData, userData.academyIds]);


  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });
  const handleSort = (columnKey) => {
    let direction = 'ascending';

    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;

    if (!key) return data;

    const sorted = [...data].sort((a, b) => {
      // If sorting by created_at, sort in descending order to show latest entries on top
      if (key === 'created_at') {
        const aDate = new Date(a[key]);
        const bDate = new Date(b[key]);
        return direction === 'ascending' ? aDate - bDate : bDate - aDate;
      }

      const aValue = a[key];
      const bValue = b[key];

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    return sorted;
  };
  const sortedData = sortData(filteredData);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
    const hours = String(date.getHours()).padStart(2, '0'); // Ensure two-digit hours
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two-digit minutes

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const createdAt = "2025-01-23T06:22:09.000+00:00";
  const getSportDistribution = () => {
    const sportCount = { Badminton: 0, Swimming: 0 };

    filteredData.forEach((item) => {
      if (item.sport_name === 'Badminton') {
        sportCount.Badminton += 1;
      } else if (item.sport_name === 'Swimming') {
        sportCount.Swimming += 1;
      }
    });

    return {
      labels: ['Badminton', 'Swimming'],
      datasets: [
        {
          data: [sportCount.Badminton, sportCount.Swimming],
          backgroundColor: ['#A79277', '#D1BB9E'],
        },
      ],
    };
  };

  const getAcademyDistribution = () => {
    const academyCount = {};

    filteredData.forEach((item) => {
      const academy = item.academy_name;
      academyCount[academy] = (academyCount[academy] || 0) + 1;
    });

    const academyLabels = Object.keys(academyCount);
    const academyData = Object.values(academyCount);
    const academyColors = [
      '#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0', '#9966FF', '#FF6384', '#C9CBCF', '#8BC34A',
      '#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#FF5733', '#33FF57', '#FF33A1', '#33FFF5',
      '#A833FF', '#FF3380', '#BFFF00', '#FF9900', '#00BFFF', '#FF1493', '#FFD700', '#32CD32',
      '#DC143C', '#20B2AA', '#FF4500', '#8B0000', '#ADFF2F', '#9400D3'
    ];

    return {
      labels: academyLabels, // Labels for tooltips
      datasets: [
        {
          data: academyData,
          backgroundColor: academyData.map((_, index) => academyColors[index] || '#FF6384'),
        },
      ],
    };
  };

  const options = {
    plugins: {
      legend: { display: false }, // Hide legend to show academy names only on hover
      tooltip: { enabled: true }, // Show tooltips on hover
    },
  };
  const chartData = getAcademyDistribution();


  // Get the distribution for Badminton batch (based on batch_id)
  const getBadmintonBatchDistribution = () => {
    const batchCount = {};

    filteredData.forEach((item) => {
      if (item.sport_name === 'Badminton' && item.plan_name) {
        const batch = item.plan_name;
        if (batchCount[batch]) {
          batchCount[batch] += 1;
        } else {
          batchCount[batch] = 1;
        }
      }
    });

    const batchLabels = Object.keys(batchCount);
    const batchData = Object.values(batchCount);

    // Define a set of different colors for each batch
    const batchColors = ['#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0', '#9966FF', '#FF6384', '#C9CBCF', '#8BC34A', '#F7464A', '#46BFBD', '#FDB45C', '#949FB1']

    return {
      labels: batchLabels,
      datasets: [
        {
          data: batchData,
          backgroundColor: batchLabels.map((_, index) => batchColors[index] || '#FF6384'), // Default color if not enough colors are provided
        },
      ],
    };
  };


  // Get the distribution for Swimming plan (based on plan_id)
  const getSwimmingPlanDistribution = () => {
    const planCount = {};

    filteredData.forEach((item) => {
      if (item.sport_name === 'Swimming' && item.plan_name) {
        const plan = item.plan_name;
        if (planCount[plan]) {
          planCount[plan] += 1;
        } else {
          planCount[plan] = 1;
        }
      }
    });

    const planLabels = Object.keys(planCount);
    const planData = Object.values(planCount);

    // Define a set of different colors for each plan
    const planColors = ['#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0', '#9966FF', '#FF6384', '#C9CBCF', '#8BC34A', '#F7464A', '#46BFBD', '#FDB45C', '#949FB1']// Customize these as per your preference

    return {
      labels: planLabels,
      datasets: [
        {
          data: planData,
          backgroundColor: planLabels.map((_, index) => planColors[index] || '#36A2EB'), // Default color if not enough colors are provided
        },
      ],
    };
  };
  const totalEntries = filteredData.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="coupon-list-container">
      {/* Filters Section */}
      <div className="filter-container-summer">
        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial Start Date"
            name="trialStartDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${startDate ? "has-value" : ""}`}
          />
          {startDate && <label htmlFor="trialStartDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial End Date"
            name="trialEndDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${endDate ? "has-value" : ""}`}
          />
          {endDate && <label htmlFor="trialEndDate">End Date</label>}
        </div>

        <div className="floating-select">
          <select
            name="academy"
            value={selectedAcademy}
            onChange={(e) => setSelectedAcademy(e.target.value)}
            className={`sales-filter-academy ${selectedAcademy ? "has-value" : ""}`}
          >
            {userData.userType === "ACADEMY" ? (
              <>
                <option value="allAcademies">All Academies</option>
                {userData.academyIds.map((academyId) => {
                  const academy = academies.find((item) => item.id === academyId);
                  return academy ? (
                    <option key={academyId} value={academy.id}>
                      {academy.name}
                    </option>
                  ) : (
                    null
                  );
                })}
              </>
            ) : (
              <>
                <option value="">All Academies</option>
                {academies.map((academy) => (
                  <option key={academy.id} value={academy.id}>
                    {academy.name}
                  </option>
                ))}
              </>
            )}
          </select>
          {selectedAcademy && <label htmlFor="academy">Academy</label>}
        </div>


        <div className="floating-select">
          <select
            name="sport"
            value={selectedSport}
            onChange={(e) => setSelectedSport(e.target.value)}
            className={`sales-filter-academy ${selectedSport ? "has-value" : ""}`}
          >
            <option value="">All Sports</option>
            {[...new Set(apiData.map((item) => item.sport_name))].map((sportName) => (
              <option key={sportName} value={sportName}>
                {sportName}
              </option>
            ))}
          </select>
          {selectedSport && <label htmlFor="sport">Sport</label>}
        </div>
      </div>
      <div className="unique-charts-container">
        {getSportDistribution().datasets[0].data.some((value) => value > 0) && (
          <div className="unique-chart-container badminton-vs-swimming">
            <h3>Sport-Wise Enrollment</h3>
            <Pie data={getSportDistribution()} />
          </div>
        )}

        {chartData.datasets[0].data.some((value) => value > 0) ? (
          <div className="unique-chart-container academy-enrollment">
            <h3>Academy Enrollment Distribution</h3>
            <Pie data={chartData} options={options} />
          </div>
        ) : null}

        {getBadmintonBatchDistribution().datasets[0].data.some((value) => value > 0) && (
          <div className="unique-chart-container badminton-batch">
            <h3>Badminton Plan Distribution</h3>
            <Pie data={getBadmintonBatchDistribution()} />
          </div>
        )}

        {getSwimmingPlanDistribution().datasets[0].data.some((value) => value > 0) && (
          <div className="unique-chart-container swimming-plan">
            <h3>Swimming Batch Distribution</h3>
            <Pie data={getSwimmingPlanDistribution()} />
          </div>
        )}
      </div>



      <div className="search-create-container">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredData}
          headers={headers}
          filename="SummerEnrollmentSheet.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }

      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredData.length === 0 && (
        <div className="error-message">No data available</div>
      )}
      {/* Coupon Table */}
      <div style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="coupon-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((item) => (
              <tr key={item.id}>
                <td>{item.academy_name}</td>
                <td>{item.name}</td>
                <td>{item.mobile_number}</td>
                <td>{item.sport_name}</td>
                <td>{item.joining_date}</td>
                <td>{item.expiry_date}</td>
                <td>{item.sessions || '-'}</td>
                <td>
                  {item.start_time
                    ? (() => {
                      let [hours, minutes] = item.start_time.split(':');
                      hours = ((+hours % 12) || 12).toString(); // Convert to 12-hour format
                      return `${hours}:${minutes}`;
                    })()
                    : '-'}
                </td>
                <td>
                  {item.end_time
                    ? (() => {
                      let [hours, minutes] = item.end_time.split(':');
                      hours = ((+hours % 12) || 12).toString();
                      return `${hours}:${minutes}`;
                    })()
                    : '-'}
                </td>


                <td>{item.plan_name}</td>
                <td>{item.total_amount}</td>
                <td>{new Date(item.created_at).toISOString().slice(0, 16).replace("T", " ")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Summer;