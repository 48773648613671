import React, { useState, useEffect } from 'react';
import './Challenge.css';
import { CSVLink } from 'react-csv';
import { useUser } from '../context/UserContext';
import axios from "axios";
import Select from 'react-select';

const SalesRegister = () => {
  const { selectedCenter, setSelectedCenter, userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const getFormattedDate = (date) => date.toISOString().split('T')[0];
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const [fromDate, setFromDate] = useState(getFormattedDate(tenDaysAgo));
  const [toDate, setToDate] = useState(getFormattedDate(today));
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [SalesRegister, setSalesRegister] = useState([]);
  const [sportNameMap, setSportNameMap] = useState({});
  const [academyNameMap, setAcademyNameMap] = useState({});
  const [planTypes, setPlanTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedCenter) {
      const academyName = academyNameMap[selectedCenter];
      setSelectedAcademy(academyName);
    }
  }, [selectedCenter, academyNameMap]);

  const isMoreThanTenDaysBack = (fromDate) => {
    const today = new Date();
    const fromDateObj = new Date(fromDate);
    today.setHours(0, 0, 0, 0);
    fromDateObj.setHours(0, 0, 0, 0);
    const differenceMs = today.getTime() - fromDateObj.getTime();
    const differenceDays = differenceMs / (1000 * 60 * 60 * 24);
    return differenceDays > 10;
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        setLoading(true);
        setError('');

        let response;
        const moreThanTenDays = isMoreThanTenDaysBack(fromDate);

        if (moreThanTenDays) {
          response = await axios.post(
            "https://admin.stage.machaxi.com/api/academy/invoice-reports",
            { fromDate, toDate },
            {
              headers: {
                'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
              },
            }
          );
        } else {
          response = await axios.get(
            "https://admin.stage.machaxi.com/api/academy/invoice-reports",
            {
              headers: {
                'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
              },
            }
          );
        }

        if (response.data.success && response.data.data?.invoices) {
          const { sportNameMap, academyNameMap, invoices } = response.data.data;

          setSportNameMap(sportNameMap);
          setAcademyNameMap(academyNameMap);

          const formattedData = invoices.map((invoice) => {
            const sportName = sportNameMap[invoice.sportId] || "-";
            const academyName = academyNameMap[invoice.academyId] || "-";

            return {
              name: invoice?.userName || "-",
              academyName: academyName || "-",
              academyId: invoice.academyId || "-",
              mobileNumber: invoice?.userMobileNumber || "-",
              typeOfPlan: invoice?.typeOfPlan || "-",
              sportName: sportName || "-",
              baseValue: invoice?.baseValue || 0,
              couponAmount: invoice?.couponAmount || 0,
              gstValue: invoice?.gstValue || 0,
              finalAmount: invoice?.finalAmount || 0,
              validity: invoice?.validity || "-",
              invoiceDate: invoice?.createdAt.split("T")[0] || "-",
              pdf: invoice?.invoicePdfLink || "-",
            };
          });

          setSalesRegister(formattedData);
        } else {
          console.error("API response did not contain expected data.");
          setError('Failed to load data');
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
        setError('Error fetching data: ' + error.message);
      }
      finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [fromDate, toDate]);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Academy Name", key: "academyName" },
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Type of Plan", key: "typeOfPlan" },
    { label: "Sport Name", key: "sportName" },
    { label: "Base Value", key: "baseValue" },
    { label: "Coupon Amount", key: "couponAmount" },
    { label: "GST Value", key: "gstValue" },
    { label: "Final Amount", key: "finalAmount" },
    { label: "Validity", key: "validity" },
    { label: "Invoice Date", key: "invoiceDate" },
    { label: "PDF", key: "pdf" },
  ];

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const filteredSalesRegister = SalesRegister.filter((Sales) => {
    const matchesSearch = Object.values(Sales)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const isAcademyUser = userData?.userType === "ACADEMY";
    const matchesUserAcademy = !isAcademyUser ||
      (Array.isArray(userData.academyIds) &&
        userData.academyIds.includes(Number(Sales.academyId)));


    const matchesSelectedAcademy =
      selectedAcademy === "" || Sales.academyName === selectedAcademy;

    const matchesAcademy = matchesUserAcademy && matchesSelectedAcademy;

    const matchesSport =
      selectedSport === '' || Sales.sportName === selectedSport;

    const matchesPlan =
      selectedPlan === '' || Sales.typeOfPlan === selectedPlan;

    const matchesDate =
      (!fromDate || new Date(Sales.invoiceDate) >= new Date(fromDate + 'T00:00:00')) &&
      (!toDate || new Date(Sales.invoiceDate) <= new Date(toDate + 'T23:59:59'));

    return (
      matchesSearch && matchesAcademy && matchesSport && matchesPlan && matchesDate
    );
  });

  const sortedData = sortData(filteredSalesRegister);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  useEffect(() => {
    const uniquePlanTypes = [...new Set(SalesRegister.map((invoice) => invoice.typeOfPlan))];
    setPlanTypes(uniquePlanTypes);
  }, [SalesRegister]);

  const academyOptions = userData?.userType === 'ACADEMY'
    ? userData.academyIds
      .map(id => ({
        value: academyNameMap[id],
        label: academyNameMap[id]
      }))
      .filter(option => option.value)
    : Object.values(academyNameMap).map(academyName => ({
      value: academyName,
      label: academyName
    }));

  const sportOptions = Object.values(sportNameMap).map(sportName => ({
    value: sportName,
    label: sportName
  }));

  const planOptions = planTypes.map(plan => ({
    value: plan,
    label: plan
  }));

  const totalEntries = filteredSalesRegister.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedAcademy, fromDate, toDate, selectedSport, selectedPlan]);

  return (
    <div className="challenges-container">
      <div className="sales-filters-leadManagement">

        <div className="floating-select">
          <Select
            className={`sales-filter-court-booking ${selectedAcademy ? "has-value" : ""}`}
            classNamePrefix="react-select"
            options={academyOptions}
            value={academyOptions.find(option => option.value === selectedAcademy)}
            onChange={(selectedOption) => setSelectedAcademy(selectedOption?.value || '')}
            placeholder="Select Center"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedAcademy && <label htmlFor="selectedAcademy">Academy</label>}
        </div>

        <div className="floating-select">
          <Select
            className={`sales-filter-court-booking ${selectedSport ? "has-value" : ""}`}
            classNamePrefix="react-select"
            options={sportOptions}
            value={sportOptions.find(option => option.value === selectedSport)}
            onChange={(selectedOption) => setSelectedSport(selectedOption?.value || '')}
            placeholder="Select Sport"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedSport && <label htmlFor="selectedSport">Sport</label>}
        </div>

        <div className="floating-select">
          <Select
            className={`sales-filter-court-booking ${selectedPlan ? "has-value" : ""}`}
            classNamePrefix="react-select"
            options={planOptions}
            value={planOptions.find(option => option.value === selectedPlan)}
            onChange={(selectedOption) => setSelectedPlan(selectedOption?.value || '')}
            placeholder="Select Plan"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedPlan && <label htmlFor="selectedPlan">Plan Type</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${fromDate ? "has-value" : ""}`}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {fromDate && <label htmlFor="fromDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${toDate ? "has-value" : ""}`}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {toDate && <label htmlFor="toDate">End Date</label>}
        </div>

      </div>

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredSalesRegister}
          headers={headers}
          filename="filtered_checkin_reports.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredSalesRegister.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr className="challenges-header-row">
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentRows.reverse().map((Sales, index) => (
              <tr key={index} className="challenges-row">
                <td>{Sales.name}</td>
                <td>{Sales.academyName}</td>
                <td>{Sales.mobileNumber}</td>
                <td>{Sales.typeOfPlan}</td>
                <td>{Sales.sportName}</td>
                <td>{Sales.baseValue}</td>
                <td>{Sales.couponAmount}</td>
                <td>{Sales.gstValue}</td>
                <td>{Sales.finalAmount}</td>
                <td>{Sales.validity}</td>
                <td>{Sales.invoiceDate}</td>
                <td className="sales-pdf-cell">
                  {Sales.pdf ? (
                    <a
                      href={Sales.pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="pdf-button"
                    >
                      View PDF
                    </a>
                  ) : (
                    <span className="no-pdf">No PDF Available</span>
                  )}
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SalesRegister;
