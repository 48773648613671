import React, { useState, useEffect } from 'react';
import './CreatePlayer.css';
import { Link, useLocation } from 'react-router-dom';
const CreatePlayer = () => {
  const location = useLocation();
  console.log(location.state)
  const { academyId, playerId, userId } = location.state || {};
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    gender: '',
    playerCategory: '',
    playerLevel: '',
    playerProfile: '',
    dob: '',
    parentInfo: [
      { parentName: '', parentMobileNumber: '', parentRelation: '' },
      { parentName: '', parentMobileNumber: '', parentRelation: '' },
    ],
    hideProfileStats: false,
  });
  useEffect(() => {
    if (!userId) return;

    const fetchPlayerData = async () => {
      try {
        const response = await fetch(`http://localhost:8082/api/player/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token
          },
        });

        const result = await response.json();
        if (result.success) {
          const playerData = result.data.player;
          const userData = playerData.user;

          setFormData({
            name: userData.name || '',
            mobileNumber: userData.mobile_number || '',
            gender: userData.genderType || '',
            playerCategory: playerData.playerCategoryType || '',
            playerLevel: playerData.playerLevelType || '',
            playerProfile: playerData.playerProfile || '',
            dob: playerData.dateOfBirth || '',
            parentInfo: [
              { parentName: '', parentMobileNumber: '', parentRelation: '' },
              { parentName: '', parentMobileNumber: '', parentRelation: '' },
            ],
            hideProfileStats: userData.is_stats_hidden || false,
          });
        }
      } catch (error) {
        console.error('Error fetching player data:', error);
      }
    };

    fetchPlayerData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith('parent')) {
      const index = parseInt(name.charAt(name.length - 1));
      const updatedParentInfo = [...formData.parentInfo];
      updatedParentInfo[index][name] = value;
      setFormData({ ...formData, parentInfo: updatedParentInfo });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      academyId: academyId,
      id: null,
      mobileNumber: formData.mobileNumber,
      name: formData.name,
      playerCategoryType: formData.playerCategory,
      playerLevelType: formData.playerLevel,
      playerProfile: formData.playerProfile || null,
      dateOfBirth: formData.dob,
      isStatsHidden: formData.hideProfileStats,
      joiningDate: "2024-01-01",
      genderType: formData.gender.toUpperCase(),
    };

    try {
      const response = await fetch("http://localhost:8082/api/player", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-authorization":
            "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ", // Replace with a valid token
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();
      if (response.ok) {
        console.log("Player Created Successfully:", result);
        alert("Player created successfully!");
      } else {
        console.error("Error:", result);
        alert("Failed to create player: " + result.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while creating the player.");
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [Attendance, setAttendance] = useState([
    {
      batchName: "Batch A",
      playerName: "hey",
      month: "January",
      year: 2024,
      attendance: "95%",
      compAttendance: "100%",
    },
    {
      batchName: "Batch B",
      playerName: "hu",
      month: "February",
      year: 2024,
      attendance: "88%",
      compAttendance: "100%",
    },
  ]);

  const headers = [
    { label: "Name", key: "batchName" },
    { label: "Academy", key: "playerName" },
    { label: "Batch", key: "month" },
    { label: "Primary", key: "year" },
    { label: "Status", key: "attendance" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAttendance = Attendance.filter((Attend) =>
    Object.values(Attend)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedAttendance = [...filteredAttendance].sort((a, b) => {
    if (sortConfig.key === '') return 0;

    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (sortConfig.direction === 'asc') {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    } else {
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    }
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedAttendance.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAttendance.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    return sortConfig.direction === 'asc' ? '↑' : '↓';
  };

  const totalEntries = filteredAttendance.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="create-player-container">
      <div className="breadcrumb">
        <Link to="/academyPlayerList" className="breadcrumb-link">Academy Player List</Link> &gt; New Player
      </div>
      <form onSubmit={handleSubmit} className="create-player-form">

        <div className="-player-name">
          <label htmlFor="name" className="input-label">Player Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-mobile-number">
          <label htmlFor="mobileNumber" className="input-label">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-gender">
          <label htmlFor="gender" className="input-label">Gender</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="select-field"
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="input-container-player-category">
          <label htmlFor="playerCategory" className="input-label">Player Category</label>
          <input
            type="text"
            id="playerCategory"
            name="playerCategory"
            value={formData.playerCategory}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-player-level">
          <label htmlFor="playerLevel" className="input-label">Player Level</label>
          <input
            type="text"
            id="playerLevel"
            name="playerLevel"
            value={formData.playerLevel}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-player-profile">
          <label htmlFor="playerProfile" className="input-label">Player Profile</label>
          <select
            id="playerProfile"
            name="playerProfile"
            value={formData.playerProfile}
            onChange={handleChange}
            className="select-field"
            required
          >
            <option value="">Select Profile</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
        </div>

        <div className="input-container-dob">
          <label htmlFor="dob" className="input-label">Date of Birth</label>
          <input
            type="date"
            id="dob"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            className="input-field"
            required
            onClick={(e) => e.target.showPicker()}
          />
        </div>

        {/* Hide Profile Stats checkbox */}
        <div className="input-container-hide-profile-stats">
          <input
            type="checkbox"
            id="hideProfileStats"
            name="hideProfileStats"
            checked={formData.hideProfileStats}
            onChange={handleChange}
            className="checkbox-field"
          />
          <label htmlFor="hideProfileStats" className="checkbox-label">Hide Profile Stats</label>
        </div>


        {/* Parent Information Table */}
        <h3 className="parent-info-title">Parent Information</h3>
        <table className="parent-info-table">
          <thead>
            <tr>
              <th>Parent Name</th>
              <th>Parent Mobile Number</th>
              <th>Relation</th>
            </tr>
          </thead>
          <tbody>
            {formData.parentInfo.map((parent, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name={`parentName${index}`}
                    value={parent.parentName}
                    onChange={handleChange}
                    className="input-field"
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`parentMobileNumber${index}`}
                    value={parent.parentMobileNumber}
                    onChange={handleChange}
                    className="input-field"
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`parentRelation${index}`}
                    value={parent.parentRelation}
                    onChange={handleChange}
                    className="input-field"
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {playerId && (
          <div className="challenges-container">
            <h3 className="parent-info-title">Player Batches</h3>
            <div className="search-bar">
              <div className="input-container">
                <input
                  type="text"
                  id="searchInput"
                  placeholder=""
                  className={`search-input ${searchTerm ? "has-value" : ""}`}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <label htmlFor="searchInput" className="floating-label">
                  🔍 Search
                </label>
              </div>
            </div>

            <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
              <option value={10}>10 items per page</option>
              <option value={50}>50 items per page</option>
              <option value={75}>75 items per page</option>
              <option value={100}>100 items per page</option>
            </select>

            <table className="challenges-table">
              <thead>
                <tr className="challenges-header-row">
                  {headers.map((header) => (
                    <th
                      key={header.key}
                      className="challenges-column"
                      onClick={() => handleSort(header.key)}
                    >
                      {header.label} {renderSortArrow(header.key)}
                    </th>
                  ))}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((Attend) => (
                  <tr key={Attend.id} className="challenges-row">
                    <td className="challenges-cell">{Attend.batchName}</td>
                    <td className="challenges-cell">{Attend.playerName}</td>
                    <td className="challenges-cell">{Attend.month}</td>
                    <td className="challenges-cell">{Attend.year}</td>
                    <td className="challenges-cell">{Attend.attendance}</td>
                    <td className="challenges-cell">edit</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination">
              <div className="showing-entries">
                Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
              </div>
              <button
                className="prev-button"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {renderPagination()}
              <button
                className="next-button"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default CreatePlayer;
