import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../UserRole/RoleList.css";
import Swal from 'sweetalert2';
import { useUser } from '../context/UserContext';
const RoleList = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [action, setAction] = useState("");
  const [roleName, setRoleName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rolesPerPage, setrowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      setError(null);  
      try {
        const response = await axios.get( process.env.REACT_APP_API_ROLE_LIST, {
          headers: {
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
            "Content-Type": "application/json",
          },
        });

  
        if (response?.data?.data?.roles && Array.isArray(response.data.data.roles)) {
          setRoles(response?.data?.data?.roles); 
        } else {
  
          setRoles([]);
        }
      } catch (error) {
        console.error("Error fetching roles:", error.message);
        setError(error.message); 
        setRoles([]); 
      }
      finally {
        setLoading(false); 
      }
    };

    fetchRoles();
  }, []);

  const headers = [
    { label: "Role Name", key: "roleName" },
  ];
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateRole = () => {
    navigate("/create-role");
  };

  const handleEditRole = (roleId) => {
    const role = roles.find((r) => r.id === roleId);
    if (role) {
      const privileges = role.privileges[0]; 
      const sectionsAndSubsections = Object.entries(privileges).map(([section, subsections]) => ({
        section,      
        subsections    
      }));
    
      navigate("/create-role", {
        state: {
          roleId: role.id,
          roleName: role.roleName,
          sectionsAndSubsections,
        },
      });
    }
  };
  const handleDeleteRole = (roleId) => {
    const role = roles.find((r) => r.id === roleId);
    if (role) {
      setSelectedRole(role);
      setAction("delete");
      setIsModalOpen(true);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `https://admin.machaxi.com/api/admin/delete-role?roleId=${selectedRole.id}`, 
        {
          headers: {
            "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ", 
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const updatedRoles = roles.filter((role) => role.id !== selectedRole.id);
        setRoles(updatedRoles);
        setIsModalOpen(false);
      
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Role deleted successfully',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
    
        const errorMessage = response.data?.errorMessage || 'Failed to delete the role';
      
        console.error("Failed to delete the role:", errorMessage);
      
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorMessage,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      if (error.response) {
        // Handle the case when there is a response but it's an error (e.g., 403 Forbidden)
        if (error.response.status === 403) {
          Swal.fire({
            icon: 'error',
            title: 'Forbidden',
            text: 'You do not have permission to delete this role.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          // Handle other types of errors with their respective messages
          const errorMessage = error.response.data?.errorMessage || 'An error occurred while deleting the role.';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      setIsModalOpen(false);
    }
  };
  
  
  
  const handleSaveEdit = () => {
    const updatedRoles = roles.map((role) =>
      role.id === selectedRole.id ? { ...role, name: roleName } : role
    );
    setRoles(updatedRoles);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const filteredRoles = roles.filter((role) =>
    role?.roleName?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };
  const sortedData = sortData(filteredRoles);
  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = sortedData.reverse().slice(indexOfFirstRole, indexOfLastRole);

  const totalPages = Math.ceil(sortedData.length / rolesPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
const generatePageNumbers = () => {
  const pages = [];
  const totalPagesToShow = 5;

  if (totalPages <= totalPagesToShow) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    pages.push(1);
    if (currentPage > 3) pages.push('...');
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      pages.push(i);
    }
    if (currentPage < totalPages - 2) pages.push('...');
    pages.push(totalPages);
  }
  return pages;
};

const renderPagination = () => {
  return generatePageNumbers().map((page, index) =>
    typeof page === 'number' ? (
      <button
        key={index}
        className={`page-number ${currentPage === page ? 'active' : ''}`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    ) : (
      <span key={index} className="pagination-dots">
        {page}
      </span>
    )
  );
};
const totalEntries = filteredRoles.length;
const indexOfFirstEntry = (currentPage - 1) * rolesPerPage + 1;
const indexOfLastEntry = Math.min(currentPage * rolesPerPage, totalEntries);
  return (
    <div className="role-list-container">
  
      <div className="search-bar">
      <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handleCreateRole}>
          + Create Role
        </button>
      </div>
      <select className="items-per-page-select" value={rolesPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <div className="space"></div>
      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredRoles.length === 0 && (
        <div className="error-message">No data available</div>
      )}
      <div style={{ maxWidth: '1150px', overflowY: 'auto' }}>
      <table className="role-table">
        <thead>
          <tr>
          {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} 
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> 
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
          {userData.roles?.includes("super admin") && (  <th>Actions</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRoles.map((role) => (
            <tr key={role.id}>
              <td>{role.roleName}</td>
              {userData.roles?.includes("super admin") && (
              <td className="action-buttons">
                <button
                  className="edit-btn"
                  onClick={() => handleEditRole(role.id)}
                >
                  Edit
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteRole(role.id)}
                >
                  Delete
                </button>
              </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={prevPage}  // Changed from handlePrevPage to prevPage
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={nextPage}  // Changed from handleNextPage to nextPage
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>
              &times;
            </button>
            <h2>{action === "edit" ? "Edit Role" : "Delete Role"}</h2>
            {action === "edit" ? (
              <div>
                <label>
                  Role Name:
                  <input
                    type="text"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                </label>
                <div className="modal-actions">
                  <button className="confirm-btn" onClick={handleSaveEdit}>
                    Save Changes
                  </button>
                  <button className="cancel-btn" onClick={cancelAction}>
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Are you sure you want to delete the role: {selectedRole?.roleName}
                </p>
                <div className="modal-actions">
                  <button className="confirm-btn" onClick={confirmDelete}>
                    Confirm Delete
                  </button>
                  <button className="cancel-btn" onClick={cancelAction}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleList;