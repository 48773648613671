import React, { useState } from 'react';
import './CheckinDetails.css';
import { Link } from 'react-router-dom';
const CheckinDetails = () => {
  // Initial state to hold pre-filled data, including payment dues
  const [checkinData] = useState({
    name: 'John Doe',
    date: '2024-12-26',
    checkinStatus: 'Checked In',
    message: 'Looking forward to the stay!',

    paymentDate: '2024-12-31',
  });

  return (
    <div className="checkin-container">
       <div className="breadcrumb">
        <Link to="/searchuser" className="breadcrumb-link">User List</Link> &gt; User Details
      </div>

      <div className="checkin-form">
        <div className="checkin-form-group">
          <label htmlFor="name" className="checkin-form-label">Name:</label>
          <input
            type="text"
            id="name"
            value={checkinData.name}
            disabled
            className="checkin-form-input"
          />
        </div>

        <div className="checkin-form-group">
          <label htmlFor="date" className="checkin-form-label">Date:</label>
          <input
            type="date"
            id="date"
            value={checkinData.date}
            disabled
            className="checkin-form-input"
          />
        </div>

        <div className="checkin-form-group">
          <label htmlFor="checkinStatus" className="checkin-form-label">Check-in Status:</label>
          <input
            type="text"
            id="checkinStatus"
            value={checkinData.checkinStatus}
            disabled
            className="checkin-form-input"
          />
        </div>

        <div className="checkin-form-group">
          <label htmlFor="message" className="checkin-form-label">Message:</label>
          <textarea
            id="message"
            value={checkinData.message}
            disabled
            className="checkin-form-input"
          />
        </div>

        {/* Conditionally render payment dues */}
        {checkinData.paymentDate && (
          <div className="checkin-form-group checkin-payment-dues">
            <label htmlFor="paymentDues" className="checkin-form-label">Payment Dues Date:</label>
            <div className="checkin-payment-date">
             {checkinData.paymentDate}
            </div>
          </div>
        )}
        <button className="checkin-mark-attendance-btn">Mark Attendance</button>
      </div>
    </div>
  );
};

export default CheckinDetails;
