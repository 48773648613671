import React, { useState, useEffect } from 'react';
import './BookingCalendar.css';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import BookingConfirmation from './BookingConfirmation';

const BookingCalendar = () => {
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSlots, setSelectedSlots] = useState(new Set());
  const [sports, setSports] = useState([]);
  const [slotsData, setSlotsData] = useState({});
  const [courtMap, setCourtMap] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const headers = {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace this with your actual token
        };

       
      const today = new Date().toISOString().split('T')[0];
      const dateToFetch = selectedDate || today;

      const response = await fetch(
        `http://localhost:8082/api/react/court/court-slots?academyId=3&date=${dateToFetch}&sportId=${selectedSport || ''}`,
        {
          method: 'GET',
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        const fetchedSports = data.data.academySports.map((academySport) => ({
          label: academySport.sport.name,
          value: academySport.sport.id,
        }));
        setSports(fetchedSports);
        setSlotsData(data.data.slotsData || {});
        setCourtMap(data.data.courtMap || {});
      } else {
        console.error('API response error:', data.success_message || 'No message');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, [selectedDate, selectedSport]); 

  const formatTime = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}Z`);
    const hours = time.getUTCHours();
    const minutes = time.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };

  const toggleSlotSelection = (courtId, timeSlot) => {
    const slotKey = `${courtId}-${timeSlot}`;
    const updatedSlots = new Set(selectedSlots);
    if (selectedSlots.has(slotKey)) {
      updatedSlots.delete(slotKey);
    } else {
      updatedSlots.add(slotKey);
    }
    setSelectedSlots(updatedSlots);
  };

  const handleBookSelectedSlots = async () => {
    if (!selectedSport || !selectedDate) {
      alert('Please select a sport and date before booking.');
      return;
    }

    if (selectedSlots.size === 0) {
      alert('No slots selected!');
      return;
    }

    const bookingDetails = {
      sportId: selectedSport,
      date: selectedDate,
      academyId: 3,
      courtTimingIds: Array.from(selectedSlots).map(slot => {
        const [courtId, timeSlot] = slot.split(/-(.*)/s);
        const normalizedTimeSlot = timeSlot.trim();
        const slotInfo = slotsData[normalizedTimeSlot]?.[courtId];
        return slotInfo ? slotInfo.courtTimingId : null;
      }).filter(id => id !== null),
    };

    setBookingDetails(bookingDetails);
    setLoading(true); // Start loading

    try {
      // Simulate a delay for fetching data (replace with actual API call if needed)
      await new Promise(resolve => setTimeout(resolve, 1000)); // Example delay
      setShowConfirmationModal(true); // Open modal only after data is ready
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };


  useEffect(() => {
    if (sports.length > 0 && !selectedSport) {
      setSelectedSport(sports[0].value);
    }


    if (!selectedDate) {
      const today = new Date().toISOString().split('T')[0];
      setSelectedDate(today);
    }
  }, [sports, selectedDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  const closeModal = () => {
    setShowConfirmationModal(false);
  };
  return (
    <div className="booking-container">
      <div className="booking-controls">
        <div className="booking-control">
          <label htmlFor="sport">Select Sport:</label>
          <Select
            id="sport"
            value={sports.find(sport => sport.value === selectedSport)}
            onChange={(selectedOption) => setSelectedSport(selectedOption ? selectedOption.value : '')}
            options={sports}
            isClearable
            placeholder="Select a sport"
          />
        </div>

        <div className="booking-control">
          <label htmlFor="date">Select Date:</label>
          <input
            type="date"
            id="date"
            value={selectedDate}
            onChange={handleDateChange}
            onFocus={(e) => e.target.showPicker()}
          />
        </div>
      </div>

      <button
        className="booking-main-button"
        onClick={handleBookSelectedSlots}
        disabled={selectedSlots.size === 0 || loading} // Disable button while loading
      >
        Book Selected Slots
      </button>

      <div className="table-container" style={{ maxWidth: '1100px', overflowY: 'auto' }}>
        <table className="booking-table">
          <thead>
            <tr>
              <th>Time</th>
              {Object.keys(courtMap).map((courtId) => (
                <th key={courtId}>{courtMap[courtId]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(slotsData).map((timeSlot) => (
              <tr key={timeSlot}>
                <td>{timeSlot}</td>
                {Object.keys(courtMap).map((courtId) => {
                  const slotInfo = slotsData[timeSlot]?.[courtId];
                  const isAvailable = slotInfo && slotInfo.availability === 'AVAILABLE';
                  const isSelected = selectedSlots.has(`${courtId}-${timeSlot}`);
                  return (
                    <td key={courtId}>
                      <button
                        onClick={() => isAvailable && toggleSlotSelection(courtId, timeSlot)}
                        className={`bc-button ${isSelected ? 'bc-selected' : ''}`}
                        disabled={!isAvailable}
                        aria-pressed={isSelected}
                        aria-label={`Book Court ${courtMap[courtId]} at ${timeSlot}`}
                      >
                        {isSelected ? 'Booked' : (isAvailable ? 'Book' : 'Unavailable')}
                      </button>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='space'></div>
      <button
        className="booking-main-button"
        onClick={handleBookSelectedSlots}
        disabled={selectedSlots.size === 0}
      >
        Book Selected Slots
      </button>
      {showConfirmationModal && (
  <div className="booking-calendar-modal-overlay" onClick={closeModal}>
    <div className="booking-calendar-modal-content" onClick={(e) => e.stopPropagation()}>
      <button className="booking-calendar-close-modal" onClick={closeModal}>×</button>
      <BookingConfirmation bookingDetails={bookingDetails} />
    </div>
  </div>
)}
    </div>
  );
};

export default BookingCalendar;
