import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CourtList.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

const UnavailableDate = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const courtId = location.state?.courtData;
  console.log("Court ID being sent:", courtId);
  console.log("Location State:", location.state);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const courtData = location.state?.courtData || null;

  useEffect(() => {
    const fetchUnavailableDates = async () => {
      try {
        const response = await fetch(`http://localhost:8082/api/react/court/unavailable-dates/?courtId=${courtData}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
        });

        const data = await response.json();

        if (data.success) {
          const transformedData = data.data.courtUnavailableDates.map(item => ({

            id: item.id,
            courtName: data.data.academyCourtEntity.name,
            startDate: formatDateTime(item.startDate),
            endDate: formatDateTime(item.endDate),
            reason: 'Unknown',
          }));

          setUnavailableDates(transformedData);
        } else {
          throw new Error(data.success_message || 'Failed to fetch data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUnavailableDates();
  }, [courtId]);

  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUnavailableDates = unavailableDates.filter((date) =>
    Object.values(date)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleEdit = (id, startDate, endDate) => {
    console.log("Editing ID:", id);
    navigate('/addunavailableDate', {
      state: { courtId, unavailableDateId: id, startDate, endDate }
    });
  };

  const handleDelete = async (id) => {
    const url = `http://localhost:8082/api/react/court/delete-unavailable-date/${courtId}/${id}`;
    console.log("Delete URL:", url);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(url, {
          method: "DELETE",
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
        });

        if (!response.ok) {
          throw new Error("Failed to delete date");
        }
        setUnavailableDates(unavailableDates.filter((item) => item.id !== id));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Unavailable date has been deleted.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        console.error("Error deleting unavailable date:", error);
      }
    }
  };

  return (
    <div className="courtlist-container">
      <div className="breadcrumb">
        <Link to="/courtlist" className="breadcrumb-link">Court List</Link> &gt; Unavailable Date
      </div>
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? 'has-value' : ''}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button
          className="create-button"
          onClick={() => {
            console.log("Court ID being sent:", courtId);
            navigate('/addunavailableDate', { state: { courtId } });
          }}
        >
          + Add Unavailable Date
        </button>

      </div>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredUnavailableDates.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <table className="courtlist-table">
        <thead>
          <tr className="courtlist-header-row">
            <th className="courtlist-column courtlist-column-action">Court Name</th>
            <th className="courtlist-column courtlist-column-action">Start Date</th>
            <th className="courtlist-column courtlist-column-action">End Date</th>
            <th className="courtlist-column courtlist-column-action">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredUnavailableDates.map((item) => (
            <tr key={item.id} className="courtlist-row">
              <td className="courtlist-cell courtlist-cell-name">{item.courtName}</td>
              <td className="courtlist-cell courtlist-cell-date">{item.startDate}</td>
              <td className="courtlist-cell courtlist-cell-date">{item.endDate}</td>
              <td className="courtlist-cell courtlist-cell-action">
                <button
                  className="edit-btn"
                  onClick={() => handleEdit(item.id, item.startDate, item.endDate)}
                >
                  Edit
                </button>

                <button
                  className="delete-btn"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UnavailableDate;
