import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the UserContext
const UserContext = createContext();

// UserProvider to manage global user, selected center, and academyId states
export const UserProvider = ({ children }) => {
  
  const [userData, setUser] = useState(() => {
    const storedUser = JSON.parse(localStorage.getItem('userData'));
    return storedUser
      ? { 
          ...storedUser, 
          academyIds: storedUser.academyIds || [], 
          roles: Array.isArray(storedUser.roles) ? storedUser.roles : [] // Ensure roles is an array
        } 
      : null;
  });
  
  
  const [selectedCenter, setSelectedCenter] = useState(
    localStorage.getItem('selectedCenter') || null
  );
  
  const [academyId, setAcademyId] = useState(() => {
    const storedAcademyIds = JSON.parse(localStorage.getItem('academyIds'));
    return storedAcademyIds && storedAcademyIds.length > 0 ? storedAcademyIds[0] : null;
  });
  
  useEffect(() => {
   
  }, [userData]);
  

  // Sync userData with localStorage
  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
  
      // Store academyIds properly
      if (userData.academyIds && userData.academyIds.length > 0) {
        localStorage.setItem('academyIds', JSON.stringify(userData.academyIds));
        setAcademyId(userData.academyIds[0]); // Set the first academyId as default
      } else {
        localStorage.removeItem('academyIds');
        setAcademyId(null);
      }
    } else {
      localStorage.removeItem('userData');
      localStorage.removeItem('academyIds');
      setAcademyId(null);
    }
  }, [userData]);
  
  // Sync selectedCenter with localStorage
  useEffect(() => {
    if (selectedCenter) {
      localStorage.setItem('selectedCenter', selectedCenter);
    } else {
      localStorage.removeItem('selectedCenter');
    }
  }, [selectedCenter]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUser,
        selectedCenter,
        setSelectedCenter,
        academyId,
        setAcademyId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to consume the UserContext
export const useUser = () => useContext(UserContext);
