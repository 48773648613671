import React, { useState, useEffect } from 'react';
import '../Academy/NewAcademy.css';
import Select from 'react-select';
import axios from 'axios';
import { useUser } from '../context/UserContext';

const EditProfile = () => {
  const { selectedCenter } = useUser();
  const [isParentAcademy, setIsParentAcademy] = useState(true);
  const [academy, setAcademy] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [about, setAbout] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [sport, setSport] = useState([]);
  const [facility, setFacility] = useState([]);
  const [locality, setLocality] = useState('');
  const [coverPicture, setCoverPicture] = useState(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [maxActiveMembership, setMaxActiveMembership] = useState('');
  const [localities, setLocalities] = useState([]);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);
  const [fileName, setFileName] = useState(""); 

  useEffect(() => {
    if (!selectedCenter) return;

    axios.get(`http://localhost:8082/api/academy/edit/${selectedCenter}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
      },
    })
      .then(response => {
        const { data } = response.data;

        // Set initial form values based on API response
        const academyData = data.academyForm;
        setName(academyData.name);
        setEmail(academyData.email);
        setPassword('****'); // Show asterisks to mask password
        setAbout(academyData.about);
        setMobile(academyData.mobileNumber);
        setAddress(academyData.address);
        setLocality(academyData.locality.name);
        setCoverPicture(academyData.coverPicUrl);
        setNotificationsEnabled(academyData.isNotificationEnabled);
        setMaxActiveMembership(academyData.maxActivePlayingMembership);

        // Set options for select fields
        setLocalities(data.localities);
        setSportsOptions(data.sports.map(sport => ({ value: sport.id, label: sport.name })));
        setFacilitiesOptions(data.facilities.map(facility => ({ value: facility.id, label: facility.name })));

        // Set sport and facility selections from API
        setSport(academyData.sportIds);
        setFacility(academyData.facilityId);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, [selectedCenter]); // Add selectedCenter as a dependency to re-fetch data when it changes

  const handleParentAcademyChange = () => {
    setIsParentAcademy(!isParentAcademy);
    if (isParentAcademy) {
      setAcademy(''); // Clear selected academy if unchecked
    }
  };
  const handleCoverPictureChange = (event) => {
    const file = event.target.files[0];
  
    if (file && file.type.startsWith("image/")) {
      setFileName(file.name); // Set the selected file name
      setCoverPicture(file); // Store the file for form submission
    } else {
      alert("Please select a valid image file.");
      setCoverPicture(null);
      setFileName("");
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = {
      id: 72,
      name: "Ankit Nainwal Academy",
      email: "ankit@machaxi.com",
      password: "securePassword123",
      about: "A premier sports academy offering top-notch facilities and coaching.",
      mobileNumber: "9876543210",
      address: "123 Sports Avenue, Bangalore",
      isNotificationEnabled: true,
      isAvailableForMachaxiTrial: true,
      latitude: 12.971598,
      longitude: 77.594566,
      localityId: 2001,
      coverPicName: "academy_cover.jpg",
      offering: "Badminton, Swimming, Tennis",
      facilityId: [3001, 3002, 3003],
      sportIds: [4001, 4002, 4003],
      isParent: true,
      parentAcademyId: null,
      localityJson: JSON.stringify({ city: "Bangalore", state: "Karnataka", country: "India" }),
      localityNameString: "Whitefield, Bangalore, Karnataka, India",
      contactNumber: "080-12345678",
      addressName: "Whitefield Sports Complex",
      maxActivePlayingMembership: 500,
    };
    
  
    console.log("Submitted Data:", JSON.stringify(formData, null, 2));
  
    axios.post(`http://localhost:8082/api/academy/edit`, formData, {
      headers: {
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        'Content-Type': 'application/json',
      },
      mode: 'cors'
    })
    
    
    .then(response => {
      console.log('Form submitted successfully:', response.data);
    })
    .catch(error => {
      console.error('Error submitting form:', error);
    });
  };
  

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="academy-form">
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Parent Academy:</label>
          <input
            type="checkbox"
            checked={isParentAcademy}
            onChange={handleParentAcademyChange}
            className="form-checkbox"
          />
          {!isParentAcademy && (
            <div className="form-group">
              <label className="form-label">Academy:</label>
              <select
                value={academy}
                onChange={(e) => setAcademy(e.target.value)}
                required
                className="form-select"
              >
                <option value="">Select Academy</option>
                <option value="academy1">Academy 1</option>
                <option value="academy2">Academy 2</option>
              </select>
            </div>
          )}
        </div>

        <div className="form-group">
          <label className="form-label">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Password:</label>
          <input
            type="password"
            value={password}
            disabled // Disable the password input field
            className="form-input"
            readOnly
          />
        </div>

        <div className="form-group">
          <label className="form-label">About:</label>
          <textarea
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            required
            className="form-textarea"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Mobile Number:</label>
          <input
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}

            className="form-input"
            maxLength={10}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Address:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Sports:</label>
          <Select
            isMulti
            options={sportsOptions}
            value={sportsOptions.filter(option => sport.includes(option.value))}
            onChange={(selected) => setSport(selected.map(s => s.value))}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Facilities:</label>
          <Select
            isMulti
            options={facilitiesOptions}
            value={facilitiesOptions.filter(option => facility.includes(option.value))}
            onChange={(selected) => setFacility(selected.map(f => f.value))}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Locality:</label>
          <input
            type="text"
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
      <label className="form-label">Cover Picture:</label>

      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleCoverPictureChange}
        className="file-input"
        style={{ display: "none" }}
      />

      <label htmlFor="fileInput" className="upload-button">
        Upload Image
      </label>

      {fileName && <p className="file-name">Selected File: {fileName}</p>}

      {coverPicture && (
    <img
      src={coverPicture instanceof File ? URL.createObjectURL(coverPicture) : coverPicture}
      alt="Cover"
      className="cover-preview"
      style={{ width: '150px', height: '150px', objectFit: 'cover', marginTop: '10px' }}
    />
  )}
    </div>


        <div className="form-group">
          <label className="form-label">Notifications Enabled:</label>
          <input
            type="checkbox"
            checked={notificationsEnabled}
            onChange={() => setNotificationsEnabled(!notificationsEnabled)}
            className="form-checkbox"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Max Active Membership:</label>
          <input
            type="text"
            value={maxActiveMembership}
            onChange={(e) => setMaxActiveMembership(e.target.value)}
            className="form-input"
          />
        </div>

        <button type="submit" className='bank-transfer-toggle-button'>Save</button>
      </form>
    </div>
  );
};

export default EditProfile;
