import React, { useState, useEffect } from 'react';
import './NewCourt.css';
import { Link } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import Select from 'react-select';
import Swal from "sweetalert2";
import { useNavigate, useLocation } from 'react-router-dom';

const NewCourt = () => {
  const { selectedCenter } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const courtData = location.state?.courtData || null;

  const [timings, setTimings] = useState([{ openTime: '', closeTime: '' }]);
  const [courtName, setCourtName] = useState('');
  const [sport, setSport] = useState('');
  const [maxPlayers, setMaxPlayers] = useState('');
  const [sportsList, setSportsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const label = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
        times.push({ value: label, label });
      }
    }
    return times;
  };
  
  const timeOptions = generateTimeOptions();

  useEffect(() => {
    if (!selectedCenter) return;

    const fetchSports = async () => {
      setLoading(true);
      try {
        const response = await fetch(`http://localhost:8082/api/react/court/new?academyId=${selectedCenter}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ`, // Add your token or any other necessary header
          },
        });

        const data = await response.json();
        if (response.ok && data.success) {
          setSportsList(data.data.sports || []);
        } else {
          throw new Error(data.success_message || 'Error fetching sports');
        }
      } catch (err) {
        console.error('Error fetching sports:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSports();
  }, [selectedCenter]);

  useEffect(() => {
    if (!courtData) return;

    const fetchCourtDetails = async () => {
      try {
        const response = await fetch(`http://localhost:8082/api/react/court/${courtData}/edit`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'x-authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ`
          }
        });

        const data = await response.json();
        console.log(data)
        if (response.ok && data.success) {
          const court = data.data.courtForm;
          setCourtName(court.name);
          setSport(court.sportId?.toString() || '');
          setMaxPlayers(court.maxPlayersAllowed?.toString() || '');
          setTimings(court.courtTimings.map(t => ({
            openTime: t.open_time.substring(0, 5),
            closeTime: t.close_time.substring(0, 5)
          })));
        } else {
          throw new Error(data.success_message || 'Error fetching court details');
        }
      } catch (err) {
        console.error("Error fetching court details:", err);
        setError(err.message);
      }
    };

    fetchCourtDetails();
  }, [courtData]);

  const addTiming = () => {
    setTimings([...timings, { openTime: '', closeTime: '' }]);
  };

  const removeTiming = (index) => {
    if (timings.length > 1) {
      setTimings(timings.filter((_, i) => i !== index));
    } else {
      alert('At least one timing row must be present.');
    }
  };

  const updateTiming = (index, field, value) => {
    const updatedTimings = [...timings];
    updatedTimings[index][field] = value;

    if (field === 'openTime' && updatedTimings[index].closeTime < value) {
      updatedTimings[index].closeTime = value;
    } else if (field === 'closeTime' && value < updatedTimings[index].openTime) {
      updatedTimings[index].closeTime = updatedTimings[index].openTime;
    }

    setTimings(updatedTimings);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!courtName || !sport || !maxPlayers || !timings.length) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Form",
        text: "Please fill out all required fields before submitting.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    const formattedTimings = timings.map(({ openTime, closeTime }) => ({
      id: null,
      open_time: new Date(`2024-12-23T${openTime}:00.000Z`).toISOString(),
      close_time: new Date(`2024-12-23T${closeTime}:00.000Z`).toISOString(),
    }));

    const formData = {
      id: courtData || null,
      name: courtName,
      academyId: selectedCenter,
      sportId: Number(sport) || null,
      maxPlayersAllowed: parseInt(maxPlayers, 10),
      courtTimings: formattedTimings,
    };

    setLoading(true);
    setError(null);

    try {
      const response = await fetch("http://localhost:8082/api/react/court/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-authorization": `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ`, // Replace with actual token
        },
        body: JSON.stringify(formData),
      });

      console.log("Payload:", JSON.stringify(formData, null, 2));

      const data = await response.json();
      if (response.ok && data.success) {
        setFormSubmitted(true);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Court submitted successfully!",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        throw new Error(data.success_message || "Error submitting court data");
      }
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: err.message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="new-court-container">
      <div className="breadcrumb">
        <Link to="/courtlist" className="breadcrumb-link">Court List</Link> &gt; New Court
      </div>

      {error && <div className="error-message">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="new-court-form-group">
          <label className="new-court-label">Court Name:</label>
          <input
            type="text"
            value={courtName}
            onChange={(e) => setCourtName(e.target.value)}
            placeholder="Enter court name"
            className="new-court-input"
            required
          />
        </div>

        <div className="new-court-form-group">
          <label className="new-court-label">Sport:</label>
          <div className="sport-options">
            {sportsList.map((sportItem) => (
              <label key={sportItem.id} className="radio-label">
                <input
                  type="radio"
                  name="sport"
                  value={sportItem.id}
                  checked={sport === sportItem.id.toString()}
                  onChange={(e) => setSport(e.target.value)}
                />
                {sportItem.name}
              </label>
            ))}
          </div>
        </div>

        <div className="new-court-form-group">
          <label className="new-court-label">Max Players per Slot:</label>
          <input
            type="number"
            value={maxPlayers}
            onChange={(e) => setMaxPlayers(e.target.value)}
            placeholder="Enter max players"
            className="new-court-input"
            required
          />
        </div>

        <h3>Timings</h3>
        <table className="new-court-table">
          <thead>
            <tr>
              <th>Open Time</th>
              <th>Close Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {timings.map((timing, index) => (
              <tr key={index}>
                <td>
                  <Select
                    value={timeOptions.find(option => option.value === timing.openTime)}
                    onChange={(selectedOption) => updateTiming(index, 'openTime', selectedOption.value)}
                    options={timeOptions}
                    className="new-court-time-select"
                    isSearchable
                  />
                </td>
                <td>
                  <Select
                    value={timeOptions.find(option => option.value === timing.closeTime)}
                    onChange={(selectedOption) => updateTiming(index, 'closeTime', selectedOption.value)}
                    options={timeOptions}
                    className="new-court-time-select"
                    isSearchable
                  />
                </td>

                <td>
                  <button
                    type="button"
                    onClick={() => removeTiming(index)}
                    className="new-court-remove-button"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="new-court-button-group">
          <button
            type="button"
            onClick={addTiming}
            className="new-court-add-button"
          >
            Add More
          </button>
        </div>

        <div className="new-court-submit-container">
          <button
            type="submit"
            className="new-court-submit-button"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewCourt;