import React, { useState, useEffect } from 'react';
import './CreateBenefits.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CreateBenefits = () => {
  const [benefitText, setBenefitText] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { benefitId } = location.state || {};
  const { planId, benefitText: initialBenefitText } = location.state || {};

  useEffect(() => {
    if (planId && initialBenefitText) {
      setIsEditMode(true);
      setBenefitText(initialBenefitText);
    }
  }, [planId, initialBenefitText]);

  const handleInputChange = (e) => {
    setBenefitText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (benefitText.trim() === '') {
      alert('Please enter a benefit before submitting!');
      return;
    }

    setLoading(true);
    setError(null);
    let apiUrl = `http://localhost:8082/api/admin/save-playing-plan-benefit/${planId || ''}`;
    if (benefitId) {
      apiUrl += `?benefitId=${benefitId}`;
    }
    
    
    const headers = {
      'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your actual token
      'Content-Type': 'application/json',
    };

    const body = JSON.stringify({
      benefit: benefitText,
      ...(isEditMode && { planId })
    });

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers,
        body,
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setBenefitText('');
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: isEditMode ? 'Benefit updated successfully!' : 'Benefit submitted successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
        navigate('/planList');
      } else {
        setError(data.message || 'Failed to submit the benefit.');
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message || 'Failed to submit the benefit.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
      }

    } catch (err) {
      setError('An error occurred while submitting the benefit.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-benefits-container">
      <div className="breadcrumb">
        <Link to="/planList" className="breadcrumb-link">Plan List</Link> &gt; {isEditMode ? 'Edit Benefit' : 'New Benefit'}
      </div>

      <h2>{isEditMode ? 'Edit Your Benefit Plan' : 'Create Your Benefit Plan'}</h2>

      <div className="input-container">
        <input
          type="text"
          placeholder={isEditMode ? "Edit your plan benefit..." : "Enter your plan benefit..."}
          value={benefitText}
          onChange={handleInputChange}
          className="benefit-input"
        />
      </div>

      <div className="submit-container">
        <button onClick={handleSubmit} className="submit-btn" disabled={loading}>
          {loading ? 'Submitting...' : (isEditMode ? 'Update Benefit' : 'Submit Your Plan')}
        </button>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default CreateBenefits;
