import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Payment/sportpayment.css'; // Ensure you have the appropriate CSS

const SportPayment = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    // Format the dates as YYYY-MM-DD
    const formattedToday = today.toISOString().split('T')[0];
    const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split('T')[0];

    setEndDate(formattedToday);
    setStartDate(formattedSevenDaysAgo);
  }, []);
  useEffect(() => {
    if (!startDate || !endDate) return;

    const fetchPayments = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `https://admin.stage.machaxi.com/api/sportwise/report?startDate=${startDate}&endDate=${endDate}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',  // Replace with your API token if required
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch payments');
        }

        const data = await response.json();

        if (data.success) {
          const mappedPayments = data.data.apiData.map((payment, index) => ({
            id: index + 1,
            academy: payment.Academy,
            badminton: payment.Badminton,
            swimming: payment.Swimming,
            football: payment.Football,
            karate: payment.Karate,
            date: payment.Date || startDate, // Ensure date is taken correctly
          }));

          setPayments(mappedPayments);
        } else {
          throw new Error('Error fetching data');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, [startDate, endDate]);
  const headers = [
    { key: 'academy', label: 'Academy' },
    { key: 'badminton', label: 'Badminton' },
    { key: 'swimming', label: 'Swimming' },
    { key: 'football', label: 'Football' },
    { key: 'karate', label: 'Karate' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const filteredPayments = payments.filter((payment) => {

    const matchesSearch = Object.values(payment)
      .join(' ') 
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return matchesSearch ;
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };
  const sortedData = sortData(filteredPayments);
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const totalEntries = filteredPayments.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="sport-payment-container">
      {/* Date Filter Section */}
      <div className="sales-filters-login">
        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${startDate ? "has-value" : ""}`}
            value={startDate}
            onChange={handleStartDateChange}
            onFocus={(e) => e.target.showPicker()}
          />
          {startDate && <label htmlFor="startDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${endDate ? "has-value" : ""}`}
            value={endDate}
            onChange={handleEndDateChange}
            onFocus={(e) => e.target.showPicker()}
          />
          {endDate && <label htmlFor="endDate">End Date</label>}
        </div>

      </div>

      {/* Row for search bar and date filters */}

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>

      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <div className='space'></div>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredPayments.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <table className="sport-payment-table">
        <thead>
          <tr>
          {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} // Sort by the column key
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> // Ascending arrow
                    ) : (
                      <span> ↓</span> // Descending arrow
                    )
                  ) : null}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.academy}</td>
              <td>{payment.badminton}</td>
              <td>{payment.swimming}</td>
              <td>{payment.football}</td>
              <td>{payment.karate}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* )} */}
      {/* Pagination */}
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SportPayment;
