import React from 'react'

const PeakHour = () => {
  return (
    <div>
      Peak Hour
    </div>
  )
}

export default PeakHour
