import React, { useState, useEffect } from 'react';
import './Shop.css';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
const Shop = () => {
  const location = useLocation();
  const { academyId, playerId } = location.state || {};

  const [formData, setFormData] = useState({
    playerName: '', 
    rewardPointsBalance: '', 
    transactionType: '',
    rewardPoints: '',
  });
  
  const [transactionOptions, setTransactionOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`http://localhost:8082/api/player/shop?academyId=${academyId}&playerId=${playerId}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token
          },
        });
        const data = await response.json();
  
        console.log("Extracted Data:", data); // Debugging
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          playerName: data.player?.user?.name || prevFormData.playerName,
          // Ensure rewardPoints is empty when data is fetched, and rewardPointsBalance is populated
          rewardPointsBalance: data.rewardBalance !== undefined ? data.rewardBalance : prevFormData.rewardPointsBalance,
          rewardPoints: '', // Reset rewardPoints to empty
        }));
  
        if (data.transactionType) {
          setTransactionOptions(
            data.transactionType.map((type) => ({
              label: type.charAt(0) + type.slice(1).toLowerCase(),
              value: type.toLowerCase(),
            }))
          );
        }
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    if (academyId && playerId) {
      fetchData();
    }
  }, [academyId, playerId]);
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const requestBody = {
      academyId: academyId, // Update this if needed or keep dynamic
      id: playerId, // Using playerId from location state
      points: formData.rewardPoints,
      type: formData.transactionType.toUpperCase(), // Assuming 'CREDIT' or 'DEBIT'
    };
  
    console.log('Form submitted:', formData);
  
    try {
      const response = await fetch('http://localhost:8082/api/player/shop', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token
        },
        body: JSON.stringify(requestBody),
      });
  
      const data = await response.json();
      console.log('API Response:', data);
  
      // Show success notification using Swal
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Your transaction was successfully processed!',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      setFormData({
        transactionType: '',
        rewardPoints: '',
      });
  
      // Handle response as needed (success message, redirect, etc.)
    } catch (error) {
      console.error('Error submitting data:', error);
  
      // Optional: Show error notification if needed
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an issue with the transaction. Please try again.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  

  return (
    <div className="shop-container">
      <div className="breadcrumb">
        <Link to="/academyPlayerList" className="breadcrumb-link">Academy Player List</Link> &gt; Shop
      </div>
      <form onSubmit={handleSubmit} className="shop-form">
        <div className="form-group-player-name">
          <label className="form-label-player-name">Player Name</label>
          <input type="text" value={formData.playerName} className="form-input-player-name" disabled />
        </div>

        <div className="form-group-reward-points-balance">
          <label className="form-label-reward-points-balance">Reward Points Balance</label>
          <input type="number" value={formData.rewardPointsBalance} className="form-input-reward-points-balance" disabled />
        </div>

        <div className="form-group-transaction-type">
          <label className="form-label-transaction-type">Transaction Type</label>
          <div className="radio-group">
            {transactionOptions.map((option) => (
              <label key={option.value}>
                <input 
                  type="radio" 
                  name="transactionType" 
                  value={option.value} 
                  checked={formData.transactionType === option.value}
                  onChange={handleChange} 
                /> {option.label}
              </label>
            ))}
          </div>
        </div>

        <div className="form-group-reward-points">
          <label className="form-label-reward-points">Reward Points*</label>
          <input 
            type="number" 
            name="rewardPoints" 
            value={formData.rewardPoints} 
            onChange={handleChange} 
            className="form-input-reward-points" 
            required 
            placeholder='0' 
          />
        </div>

        <div className="form-group-submit-btn">
          <button type="submit" className="form-submit-btn">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Shop;
