import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import '../sidebar/Sidebar.css';
import { hasPrivilege } from '../utils/hasPrivilege';

const Sidebar = () => {
  const { userData } = useUser();
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(
    localStorage.getItem('selectedCenter') || ''
  );

  const [showOptions, setShowOptions] = useState(
    JSON.parse(localStorage.getItem('showOptions')) || false
  );
  const [activeSection, setActiveSection] = useState(
    localStorage.getItem('activeSection') || null
  );
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('activeSection', activeSection);
  }, [activeSection]);

  useEffect(() => {
    const fetchAcademies = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_CENTER, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
        });
        const data = await response.json();

        if (data && data.data.academies) {
          let filteredCenters = data.data.academies;

          // If the user is NOT an admin, filter based on their academyIds
          if (userData?.userType !== "ADMIN" && userData?.academyIds?.length) {
            filteredCenters = filteredCenters.filter(center =>
              userData.academyIds.includes(center.id)
            );
          }

          setCenters(filteredCenters);
        } else {
        }
      } catch (error) {
      }
    };

    fetchAcademies();
  }, [userData]);

  useEffect(() => {
    if (selectedCenter) {
      localStorage.setItem('selectedCenter', selectedCenter);
    }
  }, [selectedCenter]);

  const handleCenterChange = (event) => {
    const centerId = event.target.value;
    setSelectedCenter(centerId);
    localStorage.setItem('selectedCenter', centerId);
    window.location.reload();
  };

  const handleClick = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const handleSubsectionClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <nav>
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          {isCollapsed ? "▶" : "◀"}
        </div>
        <ul>
          {/* <li>
            <Link to="/" onClick={() => setActiveSection(null)}>
              <span className="icon"> 📊 </span>
              {!isCollapsed && <span className="text">Dashboard</span>}
            </Link>
          </li> */}

          {/* {userData.userType === "ADMIN" && ( */}

          <li>
            <select onChange={handleCenterChange} value={selectedCenter}>

              <option
                value="">
                <span className="icon">📍</span>  {isCollapsed ? '' : <span className="text">Select a Center</span>}
              </option>
              {centers.map((center) => (
                <option key={center.id} value={center.id}>
                  {center.name}
                </option>
              ))}
            </select>
          </li>
          {/* )} */}

          {hasPrivilege(userData, 'ACADEMY') && (
            <li className="menu-item">
              <Link>
                <span className="icon">🏫</span>
                {isCollapsed ? '' : <span className="text">Academy</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'ACADEMY', 'ACADEMY_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/academylist"
                      className={window.location.pathname === '/academylist' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Academy List
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'ACADEMY', 'CREATE_NEW_ACADEMY_VIEW') && (
                  <li>
                    <Link
                      to="/newAcademy"
                      className={window.location.pathname === '/newAcademy' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Create Academy
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'ACADEMY', 'DISABLED_ACADEMY_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/disabledAcademy"
                      className={window.location.pathname === '/disabledAcademy' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Disabled Academy
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

          {hasPrivilege(userData, 'SPORTS') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">🏸</span>
                {!isCollapsed && <span className="text">Sport</span>}
              </Link>
              <ul className='subsection'>

                {hasPrivilege(userData, 'SPORTS', 'SPORT_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/sportList"
                      className={window.location.pathname === '/sportList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Sport List
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'SPORTS', 'CREATE_NEW_SPORT_VIEW') && (
                  <li>
                    <Link
                      to="/newSport"
                      className={window.location.pathname === '/newSport' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Create Sport
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

          {hasPrivilege(userData, 'COUPONS') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">🏷️ </span>
                {!isCollapsed && <span className="text">Coupons</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'COUPONS', 'COUPONS_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/coupon"
                      className={window.location.pathname === '/coupon' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Coupon List
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'COUPONS', 'CREATE_NEW_COUPON') && (
                  <li>
                    <Link
                      to="/newCoupon"
                      className={window.location.pathname === '/newCoupon' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Create Coupon
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

          {hasPrivilege(userData, 'PAYMENT') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">💵</span>
                {!isCollapsed && <span className="text">Payment</span>}
              </Link>

              <ul className='subsection'>
                {hasPrivilege(userData, 'PAYMENT', 'PAYMENT_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/paymentlist"
                      className={window.location.pathname === '/paymentlist' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Payment List
                    </Link>
                  </li>
                )}
              {selectedCenter && hasPrivilege(userData, 'PAYMENT', 'PAYMENT_DUES_VIEW') && (
  <li>
    <Link
      to="/dues"
      className={window.location.pathname === '/dues' ? 'active' : ''}
      onClick={handleSubsectionClick}
    >
      Dues
    </Link>
  </li>
)}


                {hasPrivilege(userData, 'PAYMENT', 'PAYMENT_SUMMARY_VIEW') && (
                  <li>
                    <Link
                      to="/summary"
                      className={window.location.pathname === '/summary' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Summary
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'PAYMENT', 'SPORTS_PAYMENT_SUMMARY_VIEW') && (
                  <li>
                    <Link
                      to="/sportPaymentsummary"
                      className={window.location.pathname === '/sportPaymentsummary' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Sport Payment
                    </Link>
                  </li>
                )}

              </ul>
            </li>
          )}

          {hasPrivilege(userData, 'MARKETING') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">🌐</span>
                {!isCollapsed && <span className="text">Marketing</span>}
              </Link>
              <ul className="subsection">
                {hasPrivilege(userData, 'MARKETING', 'SUMMER_ENROLLMENTS_VIEW') && (
                  <li>
                    <Link
                      to="/Summer"
                      className={window.location.pathname === "/Summer" ? "active" : ""}
                      onClick={handleSubsectionClick}
                    >
                      Summer Enrollments
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'MARKETING', 'DROPOFFS_OF_SUMMER_CAMP_VIEW') && (
                  <li>
                    <Link
                      to="/SummerColdLead"
                      className={window.location.pathname === "/SummerColdLead" ? "active" : ""}
                      onClick={handleSubsectionClick}
                    >
                      Drop Off (Summer Lead)
                    </Link>
                  </li>
                )}
              {userData.roles?.includes("super admin") && (
                  <li>
                    <Link
                      to="/dashboard"
                      className={window.location.pathname === '/dashboard' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      All Leads
                    </Link>
                  </li>
                )}
        {userData.roles?.includes("super admin") && (
                  <li>
                    <Link
                      to="/Trialattended"
                      className={window.location.pathname === '/Trialattended' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Trial attended
                    </Link>
                  </li>
                )}
        {userData.roles?.includes("super admin") && (
                  <li>
                    <Link
                      to="/Trialmissed"
                      className={window.location.pathname === '/Trialmissed' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Trial missed
                    </Link>
                  </li>
                )}
                {userData.roles?.includes("super admin") && (
                  <li>
                    <Link
                      to="/Trialrescheduled"
                      className={window.location.pathname === '/Trialrescheduled' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Trial rescheduled
                    </Link>
                  </li>
                )}
         {userData.roles?.includes("super admin") && (
                  <li>
                    <Link
                      to="/Trialconverted"
                      className={window.location.pathname === '/Trialconverted' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Trial Converted
                    </Link>
                  </li>
                )}
             {userData.roles?.includes("super admin") && (
                  <li>
                    <Link
                      to="/coldleads"
                      className={window.location.pathname === '/coldleads' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Junk leads
                    </Link>
                  </li>
                )}
                                {hasPrivilege(userData, 'MARKETING', 'EQUIRY_VIEW') && (
                  <li>
                    <Link
                      to="/Enquiry"
                      className={window.location.pathname === '/Enquiry' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Enquiry
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

          {/* <li className="menu-item">
            <Link to="/" onClick={() => setActiveSection(null)}>
              <span className="icon">📈</span>
              {!isCollapsed && <span className="text">Analytics</span>}
            </Link>
            <ul className='subsection'>
              <li>
                <Link
                  to="/PeakHour"
                  className={window.location.pathname === '/PeakHour' ? 'active' : ''}
                  onClick={handleSubsectionClick}
                >
                  Peak Hour
                </Link>
              </li>
              <li>
                <Link
                  to="/SportWiseRevenue"
                  className={window.location.pathname === '/SportWiseRevenue' ? 'active' : ''}
                  onClick={handleSubsectionClick}
                >
                  SportWise Revenue
                </Link>
              </li>
              <li>
                <Link
                  to="/RevenueRatio"
                  className={window.location.pathname === '/RevenueRatio' ? 'active' : ''}
                  onClick={handleSubsectionClick}
                >
                  Revenue Ratio
                </Link>
              </li>
              <li>
                <Link
                  to="/CourtUsage"
                  className={window.location.pathname === '/CourtUsage' ? 'active' : ''}
                  onClick={handleSubsectionClick}
                >
                  Court Usage
                </Link>
              </li>
              <li>
                <Link
                  to="/RepeatRate"
                  className={window.location.pathname === '/RepeatRate' ? 'active' : ''}
                  onClick={handleSubsectionClick}
                >
                  Repeat Rate
                </Link>
              </li>
            </ul>
          </li> */}


{selectedCenter && hasPrivilege(userData, 'COACHING') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">👨‍🏫</span>
                {!isCollapsed && <span className="text">Coaching</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'COACHING', 'ACADEMY_PLAYER_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/academyPlayerList"
                      className={window.location.pathname === '/academyPlayerList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Academy Player List
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COACHING', 'ACADEMY_COACH_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/academyCoachList"
                      className={window.location.pathname === '/academyCoachList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Academy Coach List
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COACHING', 'CREATE_NEW_BATCH_VIEW') && (
                  <li>
                    <Link
                      to="/createNewBatch"
                      className={window.location.pathname === '/createNewBatch' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Create New Batch
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COACHING', 'CREATE_NEW_PLAYER_VIEW') && (
                  <li>
                    <Link
                      to="/createNewPlayer"
                      className={window.location.pathname === '/createNewPlayer' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Create New Player
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COACHING', 'BATCH_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/batchList"
                      className={window.location.pathname === '/batchList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Batch List
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COACHING', 'PLAYER_BATCHES_VIEW') && (
                  <li>
                    <Link
                      to="/playerBatch"
                      className={window.location.pathname === '/playerBatch' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Player Batch
                    </Link>
                  </li>
                )}
{hasPrivilege(userData, 'COACHING','BATCH_CANCELLATION_VIEW') && (
                  <li>
                    <Link
                      to="/batchCancellation"
                      className={window.location.pathname === '/batchCancellation' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Batch Cancellation
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COACHING','CANCELLED_BATCHES_VIEW') && (
                  <li>
                    <Link
                      to="/cancelBatchList"
                      className={window.location.pathname === '/cancelBatchList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Cancel Batch List
                    </Link>
                  </li>)}

              </ul>
            </li>
          )}

{selectedCenter && hasPrivilege(userData, 'COURTS') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">🏛️</span>
                {!isCollapsed && <span className="text">Court Booking</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'COURTS', 'COURT_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/courtlist"
                      className={window.location.pathname === '/courtlist' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court List
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'COURTS', 'BOOKING_CALENDAR_VIEW') && (
                  <li>
                    <Link
                      to="/bookingcalendar"
                      className={window.location.pathname === '/bookingcalendar' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Booking Calendar
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COURTS', 'COURT_BOOKINGS_VIEW') && (
                  <li>
                    <Link
                      to="/courtbookings"
                      className={window.location.pathname === '/courtbookings' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court Bookings
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'COURTS', 'COURT_PRICING_VIEW') && (
                  <li>
                    <Link
                      to="/courtprice"
                      className={window.location.pathname === '/courtprice' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court Pricing
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'COURTS', 'COURT_BULK_BOOKING_VIEW') && (
                  <li>
                    <Link
                      to="/courtbulkbooking"
                      className={window.location.pathname === '/courtbulkbooking' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court Bulk Booking
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'COURTS', 'BULK_BOOKING_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/bulkbookinglist"
                      className={window.location.pathname === '/bulkbookinglist' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Bulk Booking List
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'COURTS', 'ADD_NEW_COURT_VIEW') && (
                  <li>
                    <Link
                      to="/newcourt"
                      className={window.location.pathname === '/newcourt' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Add New Court
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'COURTS', 'BLOCK_COURT_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/blockcourt"
                      className={window.location.pathname === '/blockcourt' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Block Court List
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

{selectedCenter && hasPrivilege(userData, 'ACADEMY_PROFILE') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">🎓</span>
                {!isCollapsed && <span className="text">Academy Profile</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'ACADEMY_PROFILE', 'EDIT_PROFILE_VIEW') && (
                  <li>
                    <Link
                      to="/editprofile"
                      className={window.location.pathname === '/editprofile' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Edit Profile
                    </Link>
                  </li>)}

                {hasPrivilege(userData, 'ACADEMY_PROFILE', 'BANK_DETAILS_VIEW') && (
                  <li>
                    <Link
                      to="/bankdetails"
                      className={window.location.pathname === '/bankdetails' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Bank Details
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'ACADEMY_PROFILE', 'FEES_SETTINGS_VIEW') && (
                  <li>
                    <Link
                      to="/feesSetting"
                      className={window.location.pathname === '/feesSetting' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Fees Settings
                    </Link>
                  </li>)}

                {hasPrivilege(userData, 'ACADEMY_PROFILE', 'ACADEMY_GALLERY_VIEW') && (
                  <li>
                    <Link
                      to="/academygallery"
                      className={window.location.pathname === '/academygallery' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Academy Gallery
                    </Link>
                  </li>)}

                {hasPrivilege(userData, 'ACADEMY_PROFILE', 'RESET_PASSWORD_VIEW') && (
                  <li>
                    <Link
                      to="/resetPassword"
                      className={window.location.pathname === '/resetPassword' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Reset Password
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

           {hasPrivilege(userData, 'REPORTS') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">📋</span>
                {!isCollapsed && <span className="text">Reports</span>}
              </Link>
              <ul className='subsection'>

                {hasPrivilege(userData, 'REPORTS', 'LOGIN_REPORT_VIEW') && (
                  <li>
                    <Link
                      to="/loginreport"
                      className={window.location.pathname === '/loginreport' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Login Report
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'REPORTS', 'COUPONS_USAGE_REPORT_VIEW') && (
                  <li>
                    <Link
                      to="/couponsusagereport"
                      className={window.location.pathname === '/couponsusagereport' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Coupons Usage Report
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'REPORTS', 'PLAY_TRIAL_REPORT_VIEW') && (
                  <li>
                    <Link
                      to="/playtrailreport"
                      className={window.location.pathname === '/playtrailreport' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Play Trail Report
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'REPORTS', 'LEARN_TRIAL_REPORT_VIEW') && (
                  <li>
                    <Link
                      to="/learntrailreports"
                      className={window.location.pathname === '/learntrailreports' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Learn Trail Reports
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'REPORTS', 'CHECKIN_REPORT_VIEW') && (
                  <li>
                    <Link
                      to="/checkinreport"
                      className={window.location.pathname === '/checkinreport' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Checkin Reports
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'REPORTS', 'PLAYING_SUBSCRIPTIONS_VIEW') && (
                  <li>
                    <Link
                      to="/playingsubscription"
                      className={window.location.pathname === '/playingsubscription' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Playing Subscription
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'REPORTS', 'LEAVE_REPORT_VIEW') && (
                  <li>
                    <Link
                      to="/leavereport"
                      className={window.location.pathname === '/leavereport' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Leave Report
                    </Link>
                  </li>
                )}

                {hasPrivilege(userData, 'REPORTS', 'SALES_REGISTER_VIEW') && (
                  <li>
                    <Link
                      to="/salesRegister"
                      className={window.location.pathname === '/salesRegister' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Sales Register
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

           {hasPrivilege(userData, 'RAZORPAY_AUTHORIZATION') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">💳</span>
                {!isCollapsed && <span className="text">RazorPay Auth</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'RAZORPAY_AUTHORIZATION', 'RAZORPAY_AUTHORIZATION_VIEW') && (
                  <li>
                    <Link
                      to="/razorpayauth"
                      className={window.location.pathname === '/razorpayauth' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Razorpay Auth Form
                    </Link>
                  </li>
                )}
              </ul>

            </li>
          )}

{selectedCenter && hasPrivilege(userData, 'ATTENDANCE_VIEW') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">🛎️</span>
                {!isCollapsed && <span className="text">Checkin Attendance</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'ATTENDANCE_VIEW','CHECKIN_ATTENDANCE_VIEW') && (
                  <li>
                    <Link
                      to="/searchuser"
                      className={window.location.pathname === '/searchuser' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Search User
                    </Link>
                  </li>
                )}
              </ul>

            </li>
          )}  
       
          {userData.roles?.includes("super admin") && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">👤</span>
                {!isCollapsed && <span className="text">User Role</span>}
              </Link>
              <ul className='subsection'>
                <li>
                  <Link
                    to="/UserList"
                    className={window.location.pathname === '/UserList' ? 'active' : ''}
                    onClick={handleSubsectionClick}
                  >
                    User List
                  </Link>
                </li>
                <li>
                  <Link
                    to="/RoleList"
                    className={window.location.pathname === '/RoleList' ? 'active' : ''}
                    onClick={handleSubsectionClick}
                  >
                    Role List
                  </Link>
                </li>
              </ul>
            </li>
          )}

          {hasPrivilege(userData, 'PLAYING_PLAN') && (
            <li className="menu-item">
              <Link to="/" onClick={() => setActiveSection(null)}>
                <span className="icon">🎮</span>
                {!isCollapsed && <span className="text">Playing Plans</span>}
              </Link>
              <ul className='subsection'>
                {hasPrivilege(userData, 'PLAYING_PLAN', 'PLANS_LIST_VIEW') && (
                  <li>
                    <Link
                      to="/planList"
                      className={window.location.pathname === '/planList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Plan List
                    </Link>
                  </li>
                )}
                {hasPrivilege(userData, 'PLAYING_PLAN', 'CREATE_PLAN_VIEW') && (
                  <li>
                    <Link
                      to="/newPlan"
                      className={window.location.pathname === '/newPlan' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Create Plan
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

        </ul>
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          {isCollapsed ? "▶" : "◀"}
        </div>
      </nav>

    </div>
  );
};

export default Sidebar;