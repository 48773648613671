import React, { useState, useEffect } from 'react';
import '../PlayingPlan/NewPlan.css';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const NewPlan = () => {
  const [sportsType, setSportsType] = useState('');
  const [selectSport, setSelectSport] = useState('');
  const [sportsOptions, setSportsOptions] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [sportsPlanTypes, setSportsPlanTypes] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const location = useLocation();
  const editId = location.state?.id;
  console.log(editId)
  const [imagePreview, setImagePreview] = useState(null);

  const [formValues, setFormValues] = useState({
    name: '',
    planType: '',
    price: '',
    strikethroughPrice: '',
    gstInclusive: '',
    tagLine: '',
    playingHours: '',
    maxSlotBooking: '',
    coinsRewarded: '',
    planIcon: null,
  });

  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        const response = await fetch(
          editId
            ? `http://localhost:8082/api/admin/create-playing-plan?planId=${editId}`
            : 'http://localhost:8082/api/admin/create-playing-plan',
          {
            method: 'GET',
            headers: {
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
              'Content-Type': 'application/json',
            },
          }
        );

        const data = await response.json();

        if (data.success) {
          setSportsPlanTypes(data.data.sportsPlanType);
          setPlanTypes(data.data.playingPlanType);
          setSportsOptions(data.data.sportsType);

          if (editId && data.data.playingPlanForm) {
            setFormValues({
              name: data.data.playingPlanForm.name || '',
              planType: data.data.playingPlanForm.type || '',
              price: data.data.playingPlanForm.price || '',
              strikethroughPrice: data.data.playingPlanForm.strikeThroughPrice || '',
              gstInclusive: data.data.playingPlanForm.gstInclusive ? 'yes' : 'no',
              tagLine: data.data.playingPlanForm.tagline || '',
              playingHours: data.data.playingPlanForm.playingHours || '',
              maxSlotBooking: data.data.playingPlanForm.maxSlotBookingPerDay || '',
              coinsRewarded: data.data.playingPlanForm.coinsRewardedPerHour || '',
              planIconUrl: data.data.playingPlanForm.planIconUrl || '',
            });
            if (data.data.playingPlanForm.planIconUrl) {
              setImagePreview(data.data.playingPlanForm.planIconUrl);
            }

            setSportsType(
              data.data.playingPlanForm.sportsPlanType === 'SINGLE_SPORTS'
                ? 'single_sports'
                : 'multi_sports'
            );

            setSelectSport(
              data.data.playingPlanForm.sportsPlanType === 'SINGLE_SPORTS'
                ? data.data.playingPlanForm.sportsType
                : ''
            );
          }

        } else {
          alert('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Error fetching data');
      }
    };

    fetchPlanData();
  }, [editId]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!file.type.startsWith("image/")) {
        alert("Only image files are allowed!");
        return;
      }
      setSelectedFileName(file.name);
      setFormValues((prevValues) => ({
        ...prevValues,
        planIcon: file,
      }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!sportsType) {
      return alert('Sports Type is required');
    }

    if (sportsType === 'single' && !selectSport) {
      return alert('Select a sport for Single Sport Type');
    }

    const formData = new FormData();

    const playingPlanForm = {
      name: formValues.name,
      type: formValues.planType.toUpperCase(),
      price: parseFloat(formValues.price),
      gstInclusive: formValues.gstInclusive === 'yes',
      strikeThroughPrice: parseFloat(formValues.strikethroughPrice),
      tagline: formValues.tagLine,
      playingHours: parseFloat(formValues.playingHours),
      maxSlotBookingPerDay: parseFloat(formValues.maxSlotBooking),
      coinsRewardedPerHour: parseInt(formValues.coinsRewarded, 10),
      sportsPlanType: sportsType === 'single_sports' ? 'SINGLE_SPORTS' : 'MULTI_SPORTS',
      sportsType: sportsType === 'single_sports' ? selectSport : null,

    };
    console.log("playingPlanForm", playingPlanForm)
    console.log("Plan Icon:", formValues.planIcon);
    formData.append('playingPlanForm', new Blob([JSON.stringify(playingPlanForm)], { type: 'application/json' }));
    if (formValues.planIcon) {
      formData.append('planIcon', formValues.planIcon);
    }
    const url = `http://localhost:8082/api/admin/save-new-playing-plan${editId ? `?planId=${editId}` : ''}`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with actual token
        },
        body: formData,
      });

      const responseText = await response.text();
      console.log('Raw Response:', responseText);

      if (response.ok) {
        if (responseText.trim() === '') {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: editId ? 'Plan updated successfully!' : 'Plan submitted successfully!',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          try {
            const data = JSON.parse(responseText);
            if (data.success) {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: editId ? 'Plan updated successfully!' : 'Plan submitted successfully!',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: data.message || 'Failed to create plan!',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
              });
            }
          } catch (parseError) {
            console.error('Error parsing response:', parseError);
          }
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to create plan!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Error submitting data: ' + error.message);
    }
  };

  return (
    <div className="new-plan-form-container">
      <form onSubmit={handleSubmit} className="new-plan-form">
        <div className="breadcrumb">
          <Link to="/planList" className="breadcrumb-link">Plan List</Link> &gt; New Plan
        </div>

        {/* Sports Type Dropdown */}
        <div className="form-group">
          <label>Sports Type *</label>
          <select
            className="input-select"
            value={sportsType}
            onChange={(e) => setSportsType(e.target.value)}
            required
          >
            <option value="">Select Sports Type</option>
            {sportsPlanTypes.map((type, index) => (
              <option key={index} value={type.toLowerCase()}>{type}</option>
            ))}
          </select>
        </div>

        {sportsType === 'single_sports' && (
          <div className="form-group">
            <label>Select Sport *</label>
            <select
              className="input-select"
              value={selectSport}
              onChange={(e) => setSelectSport(e.target.value)}
              required
            >
              <option value="">Select Sport</option>
              {sportsOptions.map((sport, index) => (
                <option key={index} value={sport}>{sport}</option>
              ))}
            </select>
          </div>
        )}

        {/* Name input field */}
        <div className="form-group">
          <label>Name *</label>
          <input
            className="input-text"
            type="text"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Plan Type Dropdown */}
        <div className="form-group">
          <label>Plan Type *</label>
          <select
            className="input-select"
            name="planType"
            value={formValues.planType}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Plan Type</option>
            {planTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>

            ))}
          </select>
        </div>

        {/* Price input field */}
        <div className="form-group">
          <label>Price *</label>
          <input
            className="input-text"
            type="number"
            name="price"
            value={formValues.price}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Strikethrough Price */}
        <div className="form-group">
          <label>Strikethrough Price *</label>
          <input
            className="input-text"
            type="number"
            name="strikethroughPrice"
            value={formValues.strikethroughPrice}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* GST Inclusive */}
        <div className="form-group">
          <label>GST Inclusive *</label>
          <select
            className="input-select"
            name="gstInclusive"
            value={formValues.gstInclusive}
            onChange={handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        {/* Tagline */}
        <div className="form-group">
          <label>Tag Line *</label>
          <input
            className="input-text"
            type="text"
            name="tagLine"
            value={formValues.tagLine}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Playing Hours */}
        <div className="form-group">
          <label>Playing Hours *</label>
          <input
            className="input-text"
            type="number"
            name="playingHours"
            value={formValues.playingHours}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Max Slot Booking/Day */}
        <div className="form-group">
          <label>Max Slot Booking/Day *</label>
          <input
            className="input-text"
            type="number"
            name="maxSlotBooking"
            value={formValues.maxSlotBooking}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Coins Rewarded/Hour */}
        <div className="form-group">
          <label>Coins Rewarded/Hour *</label>
          <input
            className="input-text"
            type="number"
            name="coinsRewarded"
            value={formValues.coinsRewarded}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Plan Icon *</label>
          <div className="image-preview">
            {imagePreview && !formValues.planIcon && (
              <div className="image-preview">
                <img src={imagePreview} alt="Plan Icon" style={{ width: '100px', height: '100px' }} />
              </div>
            )}
            {formValues.planIcon && (
              <img
                src={URL.createObjectURL(formValues.planIcon)}
                alt="Uploaded Plan Icon"
                style={{ width: '100px', height: '100px', marginTop: '10px' }}
              />
            )}

          </div>
          <div className="choose-button">
            <input
              type="file"
              name="planIcon"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="fileInput"
              accept="image/*"
            />
            <button
              type="button"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Upload File
            </button>
            {selectedFileName && <span>{selectedFileName}</span>}
          </div>
        </div>

        <div className="form-submit">
          <button className="submit-button" type="submit">{editId ? "Update Plan" : "Create Plan"}</button>
        </div>
      </form>
    </div>
  );
};

export default NewPlan;
