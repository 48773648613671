import React, { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import '../Login/LoginPage.css';
import logo from '../images/image.png';
import { useUser } from '../context/UserContext'; 
const LoginPage = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();
  const { setUser } = useUser(); 
  const { selectedCenter, setSelectedCenter } = useUser();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 
    localStorage.clear();
    localStorage.removeItem('selectedAcademy');
    localStorage.removeItem('selectedCenter');
    setSelectedCenter(null);
    try {
      const response = await fetch(process.env.REACT_APP_API_LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
        },
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });
      if (!response.ok) {
        const data = await response.json();
        // Show ONLY the backend error message
        setError(data.error || 'An unexpected error occurred.');
        return; 
      }
      const data = await response.json();
      setUser({
        userId: data.userId,
        email: data.email,
        privileges: data.privileges,
        userType: data.userType,
        academyIds: Array.isArray(data.academyIds) ? data.academyIds : [], // Ensure it's an array
        academyId: data.academyIds?.length > 0 ? data.academyIds[0] : null,
        roles: Array.isArray(data.roles) ? data.roles : [],
      });
      
   
      setIsLoggedIn(true);
      navigate('/');
  
    } catch (error) {
      console.error('Error during login:', error);
      // Show a generic error only if there's an issue communicating with the backend
      setError('An unexpected error occurred. Please check your connection.');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError(''); // Clear the error
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(''); // Clear the error
  };

  return (
    <div className="login-page"  >
      <nav className="navbar">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <h2 className="heading_login">Machaxi</h2>
        </div>
      </nav>
      <div className="login-container">
        <div className="left-section"></div>
        <div className="right-section">
          <h2 >Login</h2>
          <form className="login-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button className="button" type="submit" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>
          {error && <div className="error-message">{error}</div>}

        </div>
      </div>
    </div>
  );
};

export default LoginPage;