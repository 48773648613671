import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../UserRole/CreateUser.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext'; 
import Select from "react-select";
import Swal from 'sweetalert2';
const CreateUserPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = location.state || {};
  const [name, setName] = useState(userData.name || '');
  const [email, setEmail] = useState(userData.email || '');
  const [mobile, setMobile] = useState(userData.mobile || '');
  const [selectedRoles, setSelectedRoles] = useState(userData.roles || []);
  const [userType, setUserType] = useState(userData.userType || ''); // Default to empty or valid value
  const [roles, setRoles] = useState([]);
  const [password, setPassword] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isEditMode = !!userData.userId;
  const [error, setError] = useState('');
  const [academies, setAcademies] = useState([]);
  const [selectedAcademy, setSelectedAcademy] = useState(userData.academyNames || []);
  useEffect(() => {
    if (isEditMode && userData.mobile) {
      // Remove the +91 prefix if it exists in edit mode
      const mobileNumber = userData.mobile.startsWith('+91')
        ? userData.mobile.slice(3) // Remove the +91
        : userData.mobile;
      setMobile(mobileNumber); // Set the mobile number without the +91
    }
  }, [isEditMode, userData.mobile]);
  const dropdownRef = useRef(null);

  
  
  // Fetch roles from the API
  useEffect(() => {
    if (isEditMode) {
    }


    const fetchRoles = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_ROLE_LIST, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          const roles = responseData.data.roles.map((role) => ({
            id: role.id,
            roleName: role.roleName,
          }));
          setRoles(roles);
          const academies = Object.entries(responseData.data.academies).map(([academyId, academyName]) => ({
            academyId,
            academyName,
          }));
          setAcademies(academies);
          // If editing, map userData.roles (strings) to the fetched roles (objects)
          if (isEditMode) {
            const selected = roles.filter((role) =>
              userData.roles.includes(role.roleName)
            );
            setSelectedRoles(selected);
          }
        } else {
      
        }
      } catch (error) {

      }
    };

    fetchRoles();
  }, [isEditMode, userData]);
  useEffect(() => {
  
  
    if (isEditMode && userData.academyIds) {
   
  
      // Map academyIds to the corresponding academyNames
      const selectedAcademies = userData.academyIds.map((academyId) => {
  
  
        // Ensure academyId is compared as the same type (either both numbers or both strings)
        const academy = academies.find((academy) => String(academy.academyId) === String(academyId)); // Convert to string to avoid type mismatch

  
        return academy ? { value: academy.academyId, label: academy.academyName } : null;
      }).filter(Boolean); // Filter out any null values in case an ID doesn't match
  

  
      setSelectedAcademy(selectedAcademies); // Set the selected academies state
    }
  }, [isEditMode, userData.academyIds, academies]);
  
  
  
  // Handle form submission
  useEffect(() => {
    if (isEditMode && userData.userType) {
      setUserType(userData.userType);
    }
  }, [isEditMode, userData]);

  useEffect(() => {
   
  }, [userType]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    // Validation: Check if center is null when userType is "Academy"
    if (userType === 'Academy' ) {
      setError('Please select a center. The center cannot be null for Academy user type.');
      return; // Stop the submission process
    }
    if (mobile && mobile.length !== 10) {
      setError('Please enter a valid 10-digit mobile number.');
      return; // Stop the submission process
    }
    const mobileNumber = mobile ? `+91${mobile}` : null;

    const requestData = {
      id: userData?.userId, // Include ID only for edits
      userType,
      name,
      email,
      password: password || userData?.password,
      mobileNumber:mobileNumber,
      userRoles: selectedRoles.map((role) => ({
        id: role.id,
        roleName: role.roleName,
      })),
      academyIds: selectedAcademy.map((academy) => academy.value),
    };
    
    const apiUrlBase =  process.env.REACT_APP_API_NEW_USER_ROLE;
    const apiUrl = userData?.userId
      ? `${apiUrlBase}?userId=${userData.userId}`
      : apiUrlBase;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
        },
        body: JSON.stringify(requestData),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        
        if (isEditMode) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'User updated successfully',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });
          
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'User created successfully',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });
          
        }
        
        navigate('/UserList');
      } else {
        const errorMessage =
          result.success_message || result.data?.errorMessage || 'An error occurred';
        setError(errorMessage); // Set error message from the response
     
      }
    } catch (error) {

      setError('Unable to connect to the server. Please try again.');
    }
  };


  // Handle role selection/deselection
  const handleRoleChange = (role) => {
    if (selectedRoles.some((selectedRole) => selectedRole.id === role.id)) {
      // Remove role if already selected
      setSelectedRoles(selectedRoles.filter((r) => r.id !== role.id));
    } else {
      // Add role if not selected
      setSelectedRoles([...selectedRoles, role]);
    }
  };


  // Close dropdown when clicking outside
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  // Toggle dropdown
  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  // Add click listener for dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="form-container">
      <div className="breadcrumb">
        <Link to="/UserList" className="breadcrumb-link">
          User List
        </Link>{' '}
        &gt; {isEditMode ? 'Edit User' : 'New User'}
      </div>
      <form onSubmit={handleSubmit}>
        {error && <div className="error-message">{error}</div>}
        <div className="form-group">
          <label>User Type:</label>
          <select
            value={userType} // Ensure this matches the state
            onChange={(e) => setUserType(e.target.value)} // Update the state on change
            required
          >
            <option value="" disabled>
              Select User Type
            </option>
            <option value="ADMIN">ADMIN</option>
            <option value="ACADEMY">ACADEMY</option>
          </select>

        </div>
        <div className="form-group">
          <label>Academy:</label>
          <Select
            isMulti // Enable multiple selection
            options={academies.map((academy) => ({
              value: academy.academyId,
              label: academy.academyName,
            }))}
            value={selectedAcademy}
            onChange={setSelectedAcademy} // Update selected academies on change
            isDisabled={userType !== 'ACADEMY'} // Disable if userType is not 'ACADEMY'
            placeholder="Select Academy"
            required
            closeMenuOnSelect={false} // Prevent dropdown from closing after selection
          />
        </div>



        <div className="form-group">
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name"
            required
          />
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            required
          />
        </div>

        <div className="form-group">
           <label>Password:</label>
            {isEditMode ? (
             <input
              type="text"
                value="********" 
               disabled 
             />
           ) : (
            <input
             type="password"
             value={password}
              onChange={(e) => setPassword(e.target.value)}
             placeholder="Enter password"
             required 
              />
            )}
         </div>


        <div className="form-group">
          <label>Mobile Number:</label>
          <input
            type="tel"
            value={mobile}
            onChange={(e) => {
              const input = e.target.value;
              // Allow only numeric characters
              if (/^\d*$/.test(input)) {
                setMobile(input);
              }
            }}
            placeholder="Enter mobile number"
            maxLength="10"
          />
        </div>

        <div className="form-group role-dropdown" ref={dropdownRef}>
          <label>Role:</label>
          <div className="role-display" onClick={handleDropdownToggle}>
            {selectedRoles.length > 0
              ? selectedRoles.map((role) => role.roleName).join(', ')
              : 'Select Role'}
          </div>

          {dropdownOpen && (
            <div className="role-options">
              {roles.map((role) => (
                <div
                  key={role.id}
                  className="role-option"
                  onClick={() => handleRoleChange(role)}
                >
                  <span>
                    {selectedRoles.some(
                      (selectedRole) => selectedRole.id === role.id
                    )
                      ? '✔'
                      : ''}
                  </span>
                  {role.roleName}
                </div>
              ))}
            </div>
          )}
        </div>

        <button type="submit" className="submit-button">
          {isEditMode ? 'Save Changes' : 'Create User'}
        </button>
      </form>
    </div>
  );
};

export default CreateUserPage;