import React, { useEffect, useState, useRef } from 'react';
import './SettlePayment.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const SettlePaymentModal = ({ isOpen, onClose, dueId }) => {
  const [formData, setFormData] = useState({
    playerName: '',
    academy: '',
    batch: '',
    dueOn: '',
    totalDueAmount: '',
    collectedAmount: '',
    collectionMethod: '',
    remark: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const modalRef = useRef();

  // Fetch payment details when the modal opens or dueId changes
  useEffect(() => {
    if (!isOpen || !dueId) return;

    const fetchPaymentDetails = async () => {
      setLoading(true);
      setError('');

      try {
        const apiUrl = `http://localhost:8082/api/academy/3/settle-payment?due_id=${dueId}`;
        const headers = {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your actual token
        };

        const response = await axios.get(apiUrl, { headers });
        const result = response.data;

        if (result.success) {
          const paymentDue = result.data.paymentDue;
          const player = paymentDue.player.user;
          const batch = paymentDue.batch;

          setFormData({
            playerName: player.name || '-',
            academy: result.data.academies[paymentDue.batch.academyId] || '-',
            batch: batch.name || '-',
            dueOn: paymentDue.dueFrom || '-',
            totalDueAmount: paymentDue.amount || '0',
            collectedAmount: '',
            collectionMethod: '',
            remark: ''
          });
        } else {
          setError('Failed to fetch payment details: ' + result.success_message);
        }
      } catch (error) {
        console.error('Error fetching payment details:', error);
        setError('Error fetching payment details: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [isOpen, dueId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the payload for the POST request
    const payload = {
      dueId: dueId,
      academyId: 3, // Assuming academyId is always 3
      paidAmount: parseFloat(formData.collectedAmount),
      dueAmount: parseFloat(formData.totalDueAmount) - parseFloat(formData.collectedAmount),
      isSettled: parseFloat(formData.collectedAmount) >= parseFloat(formData.totalDueAmount),
      paymentMethod: formData.collectionMethod,
      remark: formData.remark
    };

    setLoading(true);
    setError('');

    try {
      const apiUrl = 'https://admin.stage.machaxi.com/api/academy/3/settle-payment';
      const headers = {
        'Content-Type': 'application/json',
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your actual token
      };

      const response = await axios.post(apiUrl, payload, { headers });
      const result = response.data;

      if (result.success) {
        console.log('Payment settled successfully:', result);
        onClose(); // Close the modal on success
      } else {
        setError('Failed to settle payment: ' + result.success_message);
      }
    } catch (error) {
      console.error('Error settling payment:', error);
      setError('Error settling payment: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="settle-payment-container" ref={modalRef}>
        <div className="breadcrumb">
          <Link to="/dues" className="breadcrumb-link">Payment Dues</Link> &gt; Settle Payment
        </div>
        <button className="close-button" onClick={onClose}>×</button>

        {loading && <div className="loading-spinner">Loading...</div>}
        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleSubmit} className="settle-payment-form">
          <div className="input-container">
            <label htmlFor="playerName" className="input-label">Player Name</label>
            <input
              type="text"
              id="playerName"
              name="playerName"
              value={formData.playerName}
              onChange={handleChange}
              className="input-field"
              required
              disabled
            />
          </div>

          <div className="input-container">
            <label htmlFor="academy" className="input-label">Academy</label>
            <input
              type="text"
              id="academy"
              name="academy"
              value={formData.academy}
              onChange={handleChange}
              className="input-field"
              required
              disabled
            />
          </div>

          <div className="input-container">
            <label htmlFor="batch" className="input-label">Batch</label>
            <input
              type="text"
              id="batch"
              name="batch"
              value={formData.batch}
              onChange={handleChange}
              className="input-field"
              required
              disabled
            />
          </div>

          <div className="input-container">
            <label htmlFor="dueOn" className="input-label">Due On</label>
            <input
              type="date"
              id="dueOn"
              name="dueOn"
              value={formData.dueOn}
              onChange={handleChange}
              className="input-field"
              required
              disabled
            />
          </div>

          <div className="input-container">
            <label htmlFor="totalDueAmount" className="input-label">Total Due Amount</label>
            <input
              type="number"
              id="totalDueAmount"
              name="totalDueAmount"
              value={formData.totalDueAmount}
              onChange={handleChange}
              className="input-field"
              required
              disabled
            />
          </div>

          <div className="input-container">
            <label htmlFor="collectedAmount" className="input-label">Collected Amount</label>
            <input
              type="input"
              id="collectedAmount"
              name="collectedAmount"
              value={formData.collectedAmount}
              onChange={handleChange}
              className="input-field"
              required
            />
          </div>

          <div className="input-container">
            <label className="input-label">Collection Method</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="collectionMethod"
                  value="Cash"
                  checked={formData.collectionMethod === 'Cash'}
                  onChange={handleChange}
                />
                Cash
              </label>
              <label>
                <input
                  type="radio"
                  name="collectionMethod"
                  value="Bank Transfer"
                  checked={formData.collectionMethod === 'Bank Transfer'}
                  onChange={handleChange}
                />
                Bank Transfer
              </label>
              <label>
                <input
                  type="radio"
                  name="collectionMethod"
                  value="Cheque"
                  checked={formData.collectionMethod === 'Cheque'}
                  onChange={handleChange}
                />
                Cheque
              </label>
            </div>
          </div>

          <div className="input-container">
            <label htmlFor="remark" className="input-label">Remark</label>
            <textarea
              id="remark"
              name="remark"
              value={formData.remark}
              onChange={handleChange}
              className="textarea-field"
              style={{ border: "1px solid #ccc" }}
            />
          </div>

          <button type="submit" className="submit-button">Collect</button>
        </form>
      </div>
    </div>
  );
};

export default SettlePaymentModal;