import React, { useState, useEffect } from 'react';
import '../Challenges/challenges.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import Swal from 'sweetalert2';
const BlockCourt = () => {
  const { userData } = useUser();
  const { selectedCenter, setSelectedCenter } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [BlockCourt, setBlockCourt] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    const fetchBlockCourtList = async () => {
      try {
        const response = await axios.get(
          `http://localhost:8082/api/react/court/block/list?academyId=${selectedCenter}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
            },
          }
        );
        if (response.data.success && response.data.data?.courtBlockList) {
          const { courtBlockList: fetchedBlockCourtList } = response.data.data;
          const formatDate = (date) => {
            const d = new Date(date);
            return d.toLocaleDateString();
          };
          console.log(fetchedBlockCourtList)
          const formattedBlockCourtList = fetchedBlockCourtList.map((block) => ({
            id: block.id,
            courtName: block?.academyCourtEntity?.name || 'N/A',
            startDate: block?.startDate ? formatDate(block.startDate) : 'N/A',
            endDate: block?.endDate ? formatDate(block.endDate) : 'N/A',
            startTime: block?.startTime || 'N/A',
            endTime: block?.endTime || 'N/A',
            weekDays: block?.weekDays || 'N/A',
            remark: block?.remark || '-',
          }));

          setBlockCourt(formattedBlockCourtList);
        }
        else {
          console.log('No court blocks found for this academy.');
        }
      } catch (err) {
        console.error('Failed to fetch court block list:', err);
        setError('Error fetching data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCenter) {
      fetchBlockCourtList();
    }
  }, [selectedCenter]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  const headers = [
    { label: "Court Name", key: "courtName" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Start Time", key: "startTime" },
    { label: "End Time", key: "endTime" },
    { label: "Week Days", key: "weekDays" },
    { label: "Remark", key: "remark" },
  ];

  const [editedChallenge, setEditedChallenge] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredBlockCourt = BlockCourt.filter((Court) =>
    Object.values(Court)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedChallenge((prevChallenge) => ({
      ...prevChallenge,
      [name]: value,
    }));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(filteredBlockCourt);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleDelete = async (courtId) => {
    if (!courtId) {
      alert('Please provide a court ID.');
      return;
    }

    const isConfirmed = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this court?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    });

    if (!isConfirmed.isConfirmed) {
      return;
    }


    const updatedCourts = BlockCourt.filter(court => court.id !== courtId);
    setBlockCourt(updatedCourts);

    try {
      const response = await fetch(`http://localhost:8082/api/react/court/delete-court-block?academyId=${selectedCenter}&id=${courtId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ`, // Replace with your actual token
        },
      });

      console.log('Response Status:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error deleting court:', errorData.success_message || 'Unknown error');
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorData.success_message || "Unknown error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

        setBlockCourt(prevCourts => [...prevCourts]);
        return;
      }

      const data = await response.json();
      console.log('Response Data:', data);

      if (data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Court deleted successfully!",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

      } else {
        console.error('Failed to delete court:', data.success_message || 'Unknown error');
        Swal.fire({
          icon: "error",
          title: "Failed to delete court",
          text: data.success_message || "Unknown error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

        setBlockCourt(prevCourts => [...prevCourts]);
      }
    } catch (err) {
      console.error('Error deleting court:', err);
      setBlockCourt(prevCourts => [...prevCourts]);
    }
  };
  
  const totalEntries = filteredBlockCourt.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  const getWeekdayDistribution = () => {
    const weekdayOrder = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekdayColors = {
      Sun: '#FF6384', // Red
      Mon: '#36A2EB', // Blue
      Tue: '#FFCE56', // Yellow
      Wed: '#4BC0C0', // Teal
      Thu: '#9966FF', // Purple
      Fri: '#FF9F40', // Orange
      Sat: '#00D084'  // Green
    };

    const weekdayData = BlockCourt.reduce((acc, court) => {
      if (court.weekDays && court.weekDays !== 'N/A') {
        let days = court.weekDays.split(',').map(d => d.trim());

        // Normalize day names
        const dayMapping = {
          Monday: 'Mon', Tuesday: 'Tue', Wednesday: 'Wed',
          Thursday: 'Thu', Friday: 'Fri', Saturday: 'Sat', Sunday: 'Sun'
        };
        days = days.map(d => dayMapping[d] || d);

        days.forEach(day => {
          if (!acc[day]) acc[day] = { count: 0, details: [] };
          acc[day].count++;
          acc[day].details.push({
            courtName: court.courtName,
            startDate: court.startDate,
            endDate: court.endDate,
            startTime: court.startTime,
            endTime: court.endTime
          });
        });
      }
      return acc;
    }, {});

    const sortedLabels = weekdayOrder.filter(day => weekdayData[day]);
    const counts = sortedLabels.map(day => weekdayData[day].count);
    const details = sortedLabels.map(day => weekdayData[day].details);
    const backgroundColors = sortedLabels.map(day => weekdayColors[day]);

    return {
      labels: sortedLabels,
      datasets: [{
        label: 'Number of Blocks',
        data: counts,
        details: details,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        borderWidth: 1
      }]
    };
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: true, text: 'Court Block' },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => `Weekday: ${tooltipItems[0].label}`,
          label: (context) => {
            const dataset = context.dataset;
            const details = dataset.details && dataset.details[context.dataIndex];
            if (!details) return [];

            return details.map(block => (
              `${block.courtName}\n`
            ));
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: { stepSize: 1 }
      }
    }
  };

  return (
    <div className="challenges-container">
      {getWeekdayDistribution()?.datasets?.[0]?.data?.length > 0 && (
        <div className="chart-container-court">
          <Bar
            data={getWeekdayDistribution()}
            options={chartOptions}
          />
        </div>
      )}

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredBlockCourt.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}

      <div className='space'></div>
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="courtlist-column courtlist-column-action"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>

              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((Court) => (
              <tr key={Court.id}>
                <td>{Court.courtName}</td>
                <td>{Court.startDate}</td>
                <td>{Court.endDate}</td>
                <td>{Court.startTime}</td>
                <td>{Court.endTime}</td>
                <td>{Court.weekDays}</td>
                <td>{Court.remark}</td>
                <td>
                  <button className="delete-button" onClick={() => handleDelete(Court.id)}>
                    Delete
                  </button>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BlockCourt;
