import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AcademyCoachList.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { useLocation } from 'react-router-dom';
const AcademyCoachList = () => {
  const { userData } = useUser();
  const location = useLocation();
  const { academy } = location.state || {};
  const { selectedCenter, setSelectedCenter } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [coaches, setCoaches] = useState([]);
  const [playingMemberStatus, setPlayingMemberStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  useEffect(() => {
    const centerToUse = academy && academy.id ? academy.id : selectedCenter;
    if (centerToUse) {

      const fetchCoachesData = async () => {
        try {
          const response = await fetch(
            `http://localhost:8082/api/academy/${centerToUse}/coaches`, 
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // If authentication is needed
              },
            }
          );

          const result = await response.json();
          if (result.success) {
            const fetchedPlayingMemberStatus = result.data.playingMember;
            setPlayingMemberStatus(fetchedPlayingMemberStatus);
            const updatedCoaches = result.data.coaches.map((coach) => {
              const coachDetails = {
                id: coach.id,
                name: coach.user.name || 'Unknown',
                about: coach.about || 'No description available',
                experience: coach.experienceMonths
                  ? `${Math.floor(coach.experienceMonths / 12)} years ${coach.experienceMonths % 12} months`
                  : 'No experience available',

                playingMember: fetchedPlayingMemberStatus[coach.id] ? 'Yes' : 'No',
                mobileNumber: coach.user.mobile_number || 'Unknown',
                profilePicUrl: coach.user.profile_pic_url || 'https://default-profile-pic-url.com',
              };
              return coachDetails;
            });

            setCoaches(updatedCoaches);
          }
        } catch (error) {
          console.error('Error fetching coaches data:', error);
          setError('Failed to load data');
        }
        finally {
          setLoading(false);
        }
      };

      fetchCoachesData();
    }
  }, [academy, selectedCenter]);

  const headers = [
    { label: "Name", key: "name" },
    { label: "About", key: "about" },
    { label: "Experience", key: "experience" },
    { label: "Playing Member", key: "playingMember" },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCoaches = coaches.filter((coach) =>
    Object.values(coach)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending", 
  });
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(filteredCoaches);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredCoaches.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="coach-list-container">
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredCoaches.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((coach) => (
              <tr key={coach.id}>
                <td>{coach.name}</td>
                <td>{coach.about}</td>
                <td>{coach.experience}</td>
                <td>{coach.playingMember}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AcademyCoachList;
