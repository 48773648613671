import React, { useState, useEffect } from 'react';
import '../sliders/NewSlider.css';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const NewProgressParameter = () => {
  const [subHeading, setSubHeading] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const location = useLocation();
  const progressAttributeId = location.state?.progressAttributeId;
  const sportId = location.state?.sportId;
  const academyId = location.state?.academyId;
console.log(academyId)
useEffect(() => {
  if (academyId) {
    const fetchData = async () => {
      try {
        const response = await fetch(`http://localhost:8082/api/admin/edit-progress-parameter?progressParameterId=${academyId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with actual token
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          const name = responseData.data?.sportProgressAttributeEditForm?.name || ''; 
          setSubHeading(name);
        } else {
          console.error('Failed to fetch academy data');
        }
      } catch (error) {
        console.error('Error fetching academy data:', error);
      }
    };

    fetchData();
  }
}, [academyId]);


const handleSubmit = async (e) => {
  e.preventDefault();
  const apiUrl = `http://localhost:8082/api/admin/new-progress-parameter/${sportId}/${progressAttributeId}`;
  
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with actual token
      },
      body: JSON.stringify({ id: academyId || null, name: subHeading, progressAttributeId: progressAttributeId }),
    });

    if (response.ok) {
      const successMessage = academyId ? 'Progress parameter updated successfully!' : 'Progress parameter created successfully!';
      setSuccessMessage(successMessage);
      setSubHeading('');

      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: successMessage,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });

    } else {
      const errorData = await response.json();
      setErrorMessage(errorData.message || 'Failed to create/update progress parameter.');
    }
  } catch (error) {
    setErrorMessage('An error occurred. Please try again.');
  }
};



  return (
    <div className="new-slider-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">
          Sport List
        </Link>{' '}
        &gt; Create Progress Parameter
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name-input">Name:</label>
          <input
            id="name-input"
            type="text"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Enter Name"
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default NewProgressParameter;
