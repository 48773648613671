import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../sport/sport.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import Swal from 'sweetalert2';

const SportList = () => {
    const { userData } = useUser();
    const [searchTerm, setSearchTerm] = useState('');
    const [sports, setSports] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    useEffect(() => {
        fetch('https://admin.stage.machaxi.com/api/admin/sports', {
            method: 'GET',
            headers: {
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token or other header values
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    console.log(data.success);
                    const formattedSports = data.data.sports.map((sport) => ({
                        id: sport.id || '-',
                        name: sport.name || '-',
                        description: sport.description|| '-',
                        area: sport.playingAreaName|| '-',
                        bookingAllowed: sport.allowEntireCourtBooking|| '-',
                        proficiencies: sport.proficiencies.join(', ')|| '-',
                        enabled: sport.enabled|| '-',
                        createdAt: sport.createdAt|| '-',
                        updatedAt: sport.updatedAt|| '-',
                        image: sport.image|| '-',
                        courtBookingNotes: sport.courtBookingNotes|| '-',
                        requestAllowed: sport.requestAllowed|| '-',
                        isRegistrationFees: sport.isRegistrationFees|| '-',
                        registrationFees: sport.registrationFees|| '-',
                        renewalFees: sport.renewalFees|| '-',
                        imageUrl: sport.imageUrl|| '-',
                    }));
                    setSports(formattedSports);
                }
            })
            .catch((error) => {
                console.error('Error fetching sports data:', error);
                setError('Failed to load data');
            })
            .finally(() => {
                setLoading(false);
                console.log('Fetch request completed.');
            });
    }, []);

    const headers = [
        { label: "Sport Name", key: "sportName" },
        { label: "Description", key: "description" },
        { label: "Playing Area", key: "playingArea" },
        { label: "Entire Court Booking Allowed?", key: "entireCourtBookingAllowed" },
        { label: "Proficiencies", key: "proficiencies" },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSport, setSelectedSport] = useState(null);
    const [action, setAction] = useState('');
    const [formData, setFormData] = useState({ name: '', description: '', area: '', bookingAllowed: '', proficiencies: '' });
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const dropdownRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleCreateSport = () => {
        navigate('/newsport');
    };

    const handleEditSport = (sportId) => {
        const sport = sports.find(s => s.id === sportId);
        const proficiencies = sport.proficiencies ? sport.proficiencies.split(', ') : [];

        setSelectedSport(sport);
        setFormData({
            name: sport.name,
            description: sport.description,
            area: sport.area,
            bookingAllowed: sport.bookingAllowed,
            proficiencies: sport.proficiencies,
            enabled: sport.enabled,
            createdAt: sport.createdAt,
            updatedAt: sport.updatedAt,
            image: sport.image,
            courtBookingNotes: sport.courtBookingNotes,
            requestAllowed: sport.requestAllowed,
            isRegistrationFees: sport.isRegistrationFees,
            registrationFees: sport.registrationFees,
            renewalFees: sport.renewalFees,
            imageUrl: sport.imageUrl,
        });

        setAction('edit');
        setIsModalOpen(true);
        navigate('/newsport', { state: { action: 'edit', sport } });
        console.log("Sport sending", sport);
    };

    const handleDeleteSport = (sportId) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(sportId); 
            }
        });
    };
    
    const confirmDelete = (sportId) => {
        fetch(`https://admin.stage.machaxi.com/api/admin/delete-sport/${sportId}`, {
            method: 'DELETE',
            headers: {
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
            },
        })
        .then(response => {
            if (!response.ok) throw new Error('Failed to delete sport');
            return response.json();
        })
        .then(() => {
            setSports(sports.filter(sport => sport.id !== sportId));
            Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Sport deleted successfully!',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
            });
        })
        .catch(error => {
            console.error('Error deleting sport:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: 'Failed to delete the sport. Please try again later.',
            });
        });
    };
    

    const handleSaveEdit = () => {
        const updatedSports = sports.map(sport =>
            sport.id === selectedSport.id ? { ...sport, ...formData } : sport
        );
        setSports(updatedSports);
        setIsModalOpen(false);
    };

    const cancelAction = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const filteredSports = sports.filter((sport) => {
        const bookingAllowedText = sport.bookingAllowed ? 'yes' : 'no';
        return Object.values(sport)
            .concat(bookingAllowedText)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
    });
    const totalPages = Math.ceil(filteredSports.length / itemsPerPage);
    const [sortConfig, setSortConfig] = useState({
        key: "name",
        direction: "ascending",
    });

    const handleSort = (columnKey) => {
        let direction = "ascending";
        if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key: columnKey, direction });
    };

    const sortData = (data) => {
        const { key, direction } = sortConfig;
        return [...data].sort((a, b) => {
            if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
            if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
            return 0;
        });
    };

    const sortedData = sortData(filteredSports);

    const paginatedSports = sortedData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const toggleDropdown = (sportId) => {
        setDropdownOpen(dropdownOpen === sportId ? null : sportId);
    };

    const handleProgressAttribute = (sportId) => {
        navigate(`/ProgresAttribute?sportId=${sportId}`);
    };
    // const handleProgressAttribute = (sportId) => {
    //     navigate('/ProgresAttribute', { state: { sportId } });
    // };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const generatePageNumbers = () => {
        const pages = [];
        const totalPagesToShow = 5;

        if (totalPages <= totalPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1);
            if (currentPage > 3) pages.push('...');
            for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                pages.push(i);
            }
            if (currentPage < totalPages - 2) pages.push('...');
            pages.push(totalPages);
        }
        return pages;
    };

    const renderPagination = () => {
        return generatePageNumbers().map((page, index) =>
            typeof page === 'number' ? (
                <button
                    key={index}
                    className={`page-number ${currentPage === page ? 'active' : ''}`}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </button>
            ) : (
                <span key={index} className="pagination-dots">
                    {page}
                </span>
            )
        );
    };

    const totalEntries = filteredSports.length;
    const indexOfFirstEntry = (currentPage - 1) * itemsPerPage + 1;
    const indexOfLastEntry = Math.min(currentPage * itemsPerPage, totalEntries);

    return (
        <div className="sport-list-container">
            <div className="search-bar">
                <div className="input-container">
                    <input
                        type="text"
                        id="searchInput"
                        placeholder=""
                        className={`search-input ${searchTerm ? "has-value" : ""}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <label htmlFor="searchInput" className="floating-label">
                        🔍 Search
                    </label>
                </div>
                <button className="create-button" onClick={handleCreateSport}>
                    + Create Sport
                </button>
            </div>

            <select className="items-per-page-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <option value={10}>10 items per page</option>
                <option value={25}>25 items per page</option>
                <option value={50}>50 items per page</option>
                <option value={75}>75 items per page</option>
            </select>

            {loading && <div class="loading-spinner">
                <span>Loading</span>
                <div class="spinner"></div>
            </div>
            }
            {error && <div className="error-message">{error}</div>}
            {!loading && !error && filteredSports.length === 0 && (
                <div className="error-message">No data available</div>
            )}
            <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
                <table className="sport-table">
                    <thead>
                        <tr>
                            {headers.map((header) => (
                                <th
                                    key={header.key}
                                    className="challenges-column"
                                    onClick={() => handleSort(header.key)}
                                >
                                    {header.label}
                                    {sortConfig.key === header.key ? (
                                        sortConfig.direction === "ascending" ? (
                                            <span> ↑</span>
                                        ) : (
                                            <span> ↓</span>
                                        )
                                    ) : null}
                                </th>
                            ))}
                            {hasPrivilege(userData, 'SPORTS', 'SPORT_LIST_MODIFY') && (<th>Action</th>)}
                        </tr>
                    </thead>

                    <tbody>
                        {paginatedSports.map((sport) => (
                            <tr key={sport.id}>
                                <td>{sport.name}</td>
                                <td>{sport.description}</td>
                                <td>{sport.area}</td>
                                <td>{sport.bookingAllowed ? 'Yes' : 'No'}</td>
                                <td>{sport.proficiencies}</td>
                                {hasPrivilege(userData, 'SPORTS', 'SPORT_LIST_MODIFY') && (<td className="sport-actions-column">
                                    <button className="action-button" onClick={() => toggleDropdown(sport.id)}>
                                        &#x22EE;
                                    </button>
                                    {dropdownOpen === sport.id && (
                                        <div className="action-dropdown-menu" ref={dropdownRef}>
                                            <button onClick={() => handleEditSport(sport.id)}>Edit</button>
                                            <button onClick={() => handleDeleteSport(sport.id)}>Delete</button>
                                            <button onClick={() => handleProgressAttribute(sport.id)}>Progress Attribute</button>
                                        </div>
                                    )}
                                </td>)}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="pagination">
                <div className="showing-entries">
                    Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
                </div>
                <button
                    className="prev-button"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {renderPagination()}
                <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default SportList;
