import React, { useState, useEffect } from 'react';
import './NewBatch.css';
import { useUser } from '../context/UserContext';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Link, useLocation } from 'react-router-dom';

const NewBatch = () => {
  const { selectedCenter, setSelectedCenter } = useUser();
  const location = useLocation();
  const { batchId } = location.state || {};
  console.log(batchId)
  const [formData, setFormData] = useState({
    name: '',
    whatsappLink: '',
    batchType: '',
    sports: '',
    proficiency: '',
    batchCapacity: '',
    monthlyFees: '',
    weekType: '',
    startTime: '',
    endTime: '',
    batchTimings: {
      Monday: { startTime: '', endTime: '', routines: [] },
      Tuesday: { startTime: '', endTime: '', routines: [] },
      Wednesday: { startTime: '', endTime: '', routines: [] },
      Thursday: { startTime: '', endTime: '', routines: [] },
      Friday: { startTime: '', endTime: '', routines: [] },
      Saturday: { startTime: '', endTime: '', routines: [] },
      Sunday: { startTime: '', endTime: '', routines: [] },
    },
    sessionPlans: {
      numberOfSessions: '',
      monthlyFee: '',
      quarterlyFee: '',
      halfYearlyFee: '',
      yearlyFee: '',
    },
    gstApplicable: false,
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [batchTypes, setBatchTypes] = useState([]);
  const [sportsList, setSportsList] = useState([]);
  const [proficiencyList, setProficiencyList] = useState([]);
  const [weekTypes, setWeekTypes] = useState([]);
  const [routineOptions, setRoutineOptions] = useState([]);

  const handleRoutineChange = (selectedOptions, day) => {
    setFormData(prevData => ({
      ...prevData,
      batchTimings: {
        ...prevData.batchTimings,
        [day]: {
          ...prevData.batchTimings[day],
          // Store the selected routine IDs
          routines: selectedOptions.map(option => option.value)
        }
      }
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `http://localhost:8082/api/react/batch/new?academyId=${selectedCenter}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // If authentication is needed
            },
          }
        );

        const data = await response.json();

        if (data.success) {
          setBatchTypes(data.data.batchType);
          setSportsList(data.data.sports);
          setProficiencyList(data.data.proficiencies);
          setWeekTypes(data.data.weekTypes);
          // In your useEffect where you fetch routines:
          setRoutineOptions(
            data.data.routines.map(routine => ({
              value: routine.id,  // Use routine.id for value
              label: routine.name // Use routine.name for display
            }))
          );
          console.log(data.data.routines)
        } else {
          console.error("Failed to fetch data", data.success_message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedCenter]);


  const convertTimeTo12HourFormat = (timeString) => {
    if (!timeString) return '';
    const timePart = timeString.includes('T') ? timeString.split('T')[1] : timeString;
    const [hours, minutes] = timePart.split(':');
    let hour = parseInt(hours, 10);
    const suffix = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; 
    return `${hour}:${minutes} ${suffix}`;
  };


  useEffect(() => {
    const fetchBatchDetails = async () => {
      if (batchId) {
        try {
          const response = await fetch(`http://localhost:8082/api/react/batch/edit/${batchId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
            },
          });
          const data = await response.json();

          if (data.success) {
            const batchData = data.data.batch;
            const batchForm = data.data.batchForm;
            const sessionFees = batchForm.batchSessionFeesForms[0] || {};
console.log(data.data)
            // Map weekDay numbers to day names (1=Monday, ..., 7=Sunday)
            const weekDayMap = {
              1: 'Monday',
              2: 'Tuesday',
              3: 'Wednesday',
              4: 'Thursday',
              5: 'Friday',
              6: 'Saturday',
              7: 'Sunday',
            };

            // Initialize batchTimings with API data
            const initialBatchTimings = { ...formData.batchTimings };
            batchForm.batchOperationForms.forEach((op) => {
              if (op.isChecked && op.weekDay) {
                const dayName = weekDayMap[op.weekDay];
                if (dayName) {
                  initialBatchTimings[dayName] = {
                    startTime: convertTimeTo12HourFormat(op.startTime),
                    endTime: convertTimeTo12HourFormat(op.endTime),
                    routines: op.routines ? op.routines.map(r => r.name) : [],
                  };
                }
              }
            });

            // Update form data
            setFormData((prevData) => ({
              ...prevData,
              name: batchData.name || '',
              whatsappLink: batchData.whatsAppUrl || '',
              batchType: batchData.batchType || '',
              sports: batchForm.sportId || '', // Use sportId from batchForm
              proficiency: batchData.proficiencyType || '',
              batchCapacity: batchData.numberOfPlayers?.toString() || '',
              monthlyFees: batchData.monthlyFees?.toString() || '',
              weekType: batchData.weekType || '',
              startTime: convertTimeTo12HourFormat(batchData.startTime),
              endTime: convertTimeTo12HourFormat(batchData.endTime),
              gstApplicable: batchData.isGstApplicable || false,
              sessionPlans: {
                numberOfSessions: sessionFees.numberOfSessionsPerWeek?.toString() || '',
                monthlyFee: sessionFees.monthlyPerSessionFee?.toString() || '',
                quarterlyFee: sessionFees.quarterlyPerSessionFee?.toString() || '',
                halfYearlyFee: sessionFees.halfYearlyPerSessionFee?.toString() || '',
                yearlyFee: sessionFees.yearlyPerSessionFee?.toString() || '',
              },
              batchTimings: initialBatchTimings,
            }));

            // Update selected days based on weekType
            updateSelectedDays(batchData.weekType);
          }
        } catch (error) {
          console.error('Error fetching batch details:', error);
        }
      }
    };

    fetchBatchDetails();
  }, [batchId]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle changes for the session plans and other fields
    if (name.includes('sessionPlans')) {
      const sessionField = name.split('.')[1];
      setFormData((prevData) => ({
        ...prevData,
        sessionPlans: {
          ...prevData.sessionPlans,
          [sessionField]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleBatchTimingChange = (e, day, field) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      batchTimings: {
        ...prevData.batchTimings,
        [day]: {
          ...prevData.batchTimings[day],
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Function to convert 12-hour time to 24-hour format
    const convertTo24HourFormat = (time) => {
      if (!time) return null;

      const [timePart, modifier] = time.split(' ');
      let [hours, minutes] = timePart.split(':');

      if (modifier === 'PM' && hours !== '12') {
        hours = String(parseInt(hours, 10) + 12);
      }
      if (modifier === 'AM' && hours === '12') {
        hours = '00';
      }

      return `${hours.padStart(2, '0')}:${minutes}`;
    };

    // Mapping week days to proper indexes (Monday = 1, Sunday = 7)
    const weekDayMap = {
      Sunday: 7,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    // Constructing batchOperationForms
    const batchOperationForms = Object.entries(formData.batchTimings)
      .filter(([day, timings]) => selectedDays[day] && timings.startTime && timings.endTime)
      .map(([day, timings]) => ({
        weekDay: weekDayMap[day] || null, // Ensure correct weekday mapping
        startTime: `${new Date().toISOString().split('T')[0]}T${convertTo24HourFormat(timings.startTime)}:00`,
        endTime: `${new Date().toISOString().split('T')[0]}T${convertTo24HourFormat(timings.endTime)}:00`,
        isChecked: true,
      }))
      .filter((entry) => entry.weekDay !== null); // Remove invalid weekdays

    const formattedStartTime = convertTo24HourFormat(formData.startTime);
    const formattedEndTime = convertTo24HourFormat(formData.endTime);

    const payload = {
      id: null,
      name: formData.name,
      academyId: selectedCenter,
      batchType: formData.batchType,
      startTime: `${new Date().toISOString().split('T')[0]}T${formattedStartTime}:00`,
      endTime: `${new Date().toISOString().split('T')[0]}T${formattedEndTime}:00`,
      sportId: formData.sports,
      numberOfPlayers: parseInt(formData.batchCapacity, 10),
      monthlyFees: parseFloat(formData.monthlyFees),
      weekType: formData.weekType,
      batchOperationForms,
      proficiencyType: formData.proficiency,
      batchSessionFeesForms: [
        {
          numberOfSessionsPerWeek: parseInt(formData.sessionPlans.numberOfSessions, 10),
          monthlyPerSessionFee: parseFloat(formData.sessionPlans.monthlyFee),
          quarterlyPerSessionFee: parseFloat(formData.sessionPlans.quarterlyFee),
          halfYearlyPerSessionFee: parseFloat(formData.sessionPlans.halfYearlyFee),
          yearlyPerSessionFee: parseFloat(formData.sessionPlans.yearlyFee),
          monthlyPerSessionFeeId: null,
          quarterlyPerSessionFeeId: null,
          halfYearlyPerSessionFeeId: null,
          yearlyPerSessionFeeId: null,
        },
      ],
      operationalDays: batchOperationForms.map((op) => op.weekDay),
      isFlexible: true,
      isGstApplicable: formData.gstApplicable,
      whatsAppUrl: formData.whatsappLink,
    };

    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    try {
      const response = await fetch('http://localhost:8082/api/react/batch/new?academyId=3', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log('API Response:', result);

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Batch Created successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        alert(`Failed to create batch: ${result.message}`);
      }
    } catch (error) {
      console.error('Error submitting batch:', error);
      alert('Error occurred while submitting batch.');
    }
  };


  const handleTimeChange = (selectedOption, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption.value,
    }));
  };

  const generateTimeOptions = () => {
    const options = [];
    let currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);

    for (let i = 0; i < 24 * 4; i++) {
      let hours = currentTime.getHours();
      let minutes = currentTime.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert 24-hour format to 12-hour
      let timeString = `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;

      options.push({ value: timeString, label: timeString });
      currentTime.setMinutes(currentTime.getMinutes() + 15);
    }

    return options;
  };
  const sportsOptions = sportsList.map((sport) => ({
    value: sport.id, // Use sport.id as the value
    label: sport.name, // Use sport.name as the label
  }));
  const timeOptions = generateTimeOptions();
  const [selectedDays, setSelectedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });
  const handleCheckboxChange = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      [day]: !prevSelectedDays[day],
    }));
  };
  const calculateNumberOfSessions = () => {
    return Object.values(selectedDays).filter((selected) => selected).length;
  }; useEffect(() => {
    const numberOfSessions = calculateNumberOfSessions();
    setFormData((prevData) => ({
      ...prevData,
      sessionPlans: {
        ...prevData.sessionPlans,
        numberOfSessions: numberOfSessions.toString(),
      },
    }));
  }, [selectedDays]);

  const weekTypeToDaysMap = {
    WORKING_DAYS: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    WEEKEND: ['Saturday', 'Sunday'],
    WEEK_MWF: ['Monday', 'Wednesday', 'Friday'],
    WEEK_TTS: ['Tuesday', 'Thursday', 'Saturday'],
    WEEK_ALL: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    WEEK_TWTF: ['Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    WEEK_TTFS: ['Tuesday', 'Thursday', 'Friday', 'Saturday'],
    WEEK_TWTFS: ['Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    WEEK_MT: ['Monday', 'Tuesday'],
    WEEK_TF: ['Tuesday', 'Friday'],
    WEEK_MTTFS: ['Monday', 'Tuesday', 'Thursday', 'Friday', 'Saturday'],
    WEEK_MTTF: ['Monday', 'Tuesday', 'Thursday', 'Friday'],
  };

  const updateSelectedDays = (weekType) => {
    const daysToSelect = weekTypeToDaysMap[weekType] || [];
    const newSelectedDays = {
      Monday: daysToSelect.includes('Monday'),
      Tuesday: daysToSelect.includes('Tuesday'),
      Wednesday: daysToSelect.includes('Wednesday'),
      Thursday: daysToSelect.includes('Thursday'),
      Friday: daysToSelect.includes('Friday'),
      Saturday: daysToSelect.includes('Saturday'),
      Sunday: daysToSelect.includes('Sunday'),
    };
    setSelectedDays(newSelectedDays);
  };
  return (
    <div className="academy-player-list-container">
      <div className="breadcrumb">
        <Link to="/batchList" className="breadcrumb-link">
          Batch List
        </Link>
        &gt; Search Player
      </div>
      <form onSubmit={handleSubmit} className="new-batch-form">
        {/* Name Input */}
        <label className="form-label">
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>

        {/* WhatsApp Group Link */}
        <label className="form-label">
          WhatsApp Group Link:
          <input
            type="url"
            name="whatsappLink"
            value={formData.whatsappLink}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>

        {/* Batch Type */}
        <label className="form-label">
          Batch Type:
          <Select
            name="batchType"
            value={{ value: formData.batchType, label: formData.batchType }}
            onChange={(selectedOption) => setFormData({ ...formData, batchType: selectedOption.value })}
            options={batchTypes.map(type => ({ value: type, label: type }))}
            placeholder="Select Batch Type"
            required
          />
        </label>

        {/* Sports Selection */}
        <label className="form-label">
          Sport:
          <Select
            name="sports"
            value={sportsOptions.find(option => option.value.toString() === formData.sports.toString())}
            onChange={(selectedOption) => setFormData({ ...formData, sports: selectedOption.value })}
            options={sportsOptions}
            placeholder="Select Sport"
            required
          />
        </label>
        {/* Proficiency */}
        <label className="form-label">
          Proficiency:
          <Select
            name="proficiency"
            value={{ value: formData.proficiency, label: formData.proficiency }}
            onChange={(selectedOption) => setFormData({ ...formData, proficiency: selectedOption.value })}
            options={proficiencyList.map(p => ({ value: p, label: p }))}
            placeholder="Select Proficiency"
            required
          />
        </label>
        {/* Batch Capacity */}
        <label className="form-label">
          Batch Capacity*:
          <input
            type="number"
            name="batchCapacity"
            value={formData.batchCapacity}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>

        {/* Monthly Fees */}
        <label className="form-label">
          Monthly Fees*:
          <input
            type="number"
            name="monthlyFees"
            value={formData.monthlyFees}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>
        {/* Week Type */}
        <label className="form-label">
          Week Type:
          <Select
            name="weekType"
            value={{ value: formData.weekType, label: formData.weekType }}
            onChange={(selectedOption) => {
              setFormData({ ...formData, weekType: selectedOption.value });
              updateSelectedDays(selectedOption.value);
            }}
            options={weekTypes.map(wt => ({ value: wt, label: wt }))}
            placeholder="Select Week Type"
            required
          />
        </label>

        {/* Start Time and End Time */}
        <label className="form-label">
          Start Time:
          <Select
            name="startTime"
            value={timeOptions.find(option => option.value === formData.startTime)}
            onChange={(selectedOption) => handleTimeChange(selectedOption, 'startTime')}
            options={timeOptions}
            placeholder="Select Start Time"
            required
          />
        </label>

        <label className="form-label">
          End Time:
          <Select
            name="endTime"
            value={timeOptions.find(option => option.value === formData.endTime)}
            onChange={(selectedOption) => handleTimeChange(selectedOption, 'endTime')}
            options={timeOptions}
            placeholder="Select End Time"
            required
          />
        </label>

        {/* Batch Timings Table */}
        <h3 className="section-title">Batch Timings</h3>
        <table className="batch-timings-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Week Day</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Routine</th>
            </tr>
          </thead>
          <tbody>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
              <tr key={day}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedDays[day]}
                    onChange={() => handleCheckboxChange(day)}
                    className="day-checkbox"
                  />
                </td>
                <td>{day}</td>
                <td>
                  <Select
                    value={timeOptions.find(option => option.value === formData.batchTimings[day].startTime)}
                    onChange={(selectedOption) => handleBatchTimingChange({ target: { value: selectedOption.value } }, day, 'startTime')}
                    options={timeOptions}
                    placeholder="Select Start Time"
                  />
                </td>
                <td>
                  <Select
                    value={timeOptions.find(option => option.value === formData.batchTimings[day].endTime)}
                    onChange={(selectedOption) => handleBatchTimingChange({ target: { value: selectedOption.value } }, day, 'endTime')}
                    options={timeOptions}
                    placeholder="Select End Time"
                  />
                </td>
                <td>
                  <Select
                    isMulti
                    value={routineOptions.filter(option =>
                      formData.batchTimings[day].routines.includes(option.value)
                    )}
                    onChange={(selectedOptions) => handleRoutineChange(selectedOptions, day)}
                    options={routineOptions} // Use pre-mapped options
                    placeholder="Select Routine"
                  />

                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Weekly Session Plans Table */}
        <h3 className="section-title">Weekly Session Plans</h3>
        <table className="session-plans-table">
          <thead>
            <tr>
              <th>Number Of Sessions (Per Week)</th>
              <th>Monthly (Per Session Fee)</th>
              <th>Quarterly (Per Session Fee)</th>
              <th>Half Yearly (Per Session Fee)</th>
              <th>Yearly (Per Session Fee)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  value={formData.sessionPlans.numberOfSessions}
                  onChange={handleInputChange}
                  name="sessionPlans.numberOfSessions"
                  className="form-input"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  value={formData.sessionPlans.monthlyFee}
                  onChange={handleInputChange}
                  name="sessionPlans.monthlyFee"
                  className="form-input"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  value={formData.sessionPlans.quarterlyFee}
                  onChange={handleInputChange}
                  name="sessionPlans.quarterlyFee"
                  className="form-input"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  value={formData.sessionPlans.halfYearlyFee}
                  onChange={handleInputChange}
                  name="sessionPlans.halfYearlyFee"
                  className="form-input"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  value={formData.sessionPlans.yearlyFee}
                  onChange={handleInputChange}
                  name="sessionPlans.yearlyFee"
                  className="form-input"
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>

        {/* GST Applicable Checkbox */}
        <label className="checkbox-label">
          GST Applicable:
          <input
            type="checkbox"
            name="gstApplicable"
            checked={formData.gstApplicable}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, gstApplicable: e.target.checked }))}
            className="checkbox-input"
          />
        </label>

        {/* Submit Button */}
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default NewBatch;
