import React, { useState, useEffect } from 'react';
import './Challenge.css';
import { CSVLink } from 'react-csv';
import { useUser } from '../context/UserContext';
import Select from 'react-select';

const SalesRegister = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const getFormattedDate = (date) => date.toISOString().split('T')[0];
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const [fromDate, setFromDate] = useState(getFormattedDate(tenDaysAgo));
  const [toDate, setToDate] = useState(getFormattedDate(today));
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [SalesRegister, setSalesRegister] = useState([]);
  const [academyList, setAcademyList] = useState([]);
  const { selectedCenter, setSelectedCenter, userData } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedCenter && academyList.length > 0) {
      setSelectedAcademy(Number(selectedCenter));
    }
  }, [selectedCenter, academyList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://admin.stage.machaxi.com/api/reports/playing-subscription';

        const headers = {
          'Content-Type': 'application/json',
          'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
        };

        const response = await fetch(apiUrl, { method: 'GET', headers });

        if (!response.ok) throw new Error('Network response was not ok');

        const result = await response.json();

        if (result.success && result.data.subscriptions) {
          let academies = result.data.academies
            ? Object.entries(result.data.academies).map(([id, name]) => ({ id: Number(id), name }))
            : [];

          // Filter academies if userType is 'ACADEMY'
          if (userData?.userType === 'ACADEMY') {
            academies = academies.filter(academy => userData.academyIds.includes(academy.id));
          }

          setAcademyList(academies);

          const transformedData = result.data.subscriptions.map((subscription) => ({
            name: subscription?.user?.name || '-',
            phone: subscription?.user?.mobile_number || '-',
            preferredCenterId: subscription?.academy?.id || '-',
            preferredCenter: subscription?.academy?.name || '-',
            purchaseDate: formatDate(subscription?.courtSubscription?.purchaseDate) || '-',
            joiningDate: formatDate(subscription?.courtSubscription?.joiningDate) || '-',
            expiryDate: formatDate(subscription?.courtSubscription?.expiryDate) || '-',
            planType: subscription?.sportsPlanType || '-',
            sportType: subscription?.sportsType || '-',
            preferredSport: subscription?.sport?.name || '-',
            selfRating: subscription?.selfRating || '-',
            totalPlayingHours: subscription?.courtSubscription?.hoursCredited || '-',
            hoursUsed: Math.max(
              0,
              (subscription?.courtSubscription?.hoursCredited || 0) - (subscription?.courtSubscription?.hoursRemaining || 0)
            ),
          }));

          setSalesRegister(transformedData);
        } else {
          setError('Failed to load data: ' + result.success_message);
        }
      } catch (error) {
        setError('Error fetching data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "Not Available";

    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  const headers = [
    { label: "Name", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "Preferred Center", key: "preferredCenter" },
    { label: "Purchase Date", key: "purchaseDate" },
    { label: "Joining Date", key: "joiningDate" },
    { label: "Expiry Date", key: "expiryDate" },
    { label: "Plan Type", key: "planType" },
    { label: "Sport Type", key: "sportType" },
    { label: "Preferred Sport", key: "preferredSport" },
    { label: "Self Rating", key: "selfRating" },
    { label: "Total Playing Hours", key: "totalPlayingHours" },
    { label: "Hours Used", key: "hoursUsed" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSalesRegister = SalesRegister.filter((Sales) => {
    const matchesSearch = Object.values(Sales)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesAcademy =
      selectedAcademy === '' ||
      Number(Sales.preferredCenterId) === Number(selectedAcademy);

    const matchesDate =
      (fromDate === '' || new Date(Sales.purchaseDate) >= new Date(fromDate)) &&
      (toDate === '' || new Date(Sales.purchaseDate) <= new Date(toDate));

    const belongsToUserAcademy = userData?.userType !== 'ACADEMY' || userData.academyIds.includes(Number(Sales.preferredCenterId));

    return matchesSearch && matchesAcademy && matchesDate && belongsToUserAcademy;
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({ key, direction });
    const sortedData = [...SalesRegister].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setSalesRegister(sortedData);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedAcademy, fromDate, toDate]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredSalesRegister.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredSalesRegister.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const getSortingArrow = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  const totalEntries = filteredSalesRegister.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  const academyOptions = academyList
    .filter(academy =>
      userData?.userType === "ACADEMY"
        ? userData.academyIds.includes(Number(academy.id))
        : true
    )
    .map(academy => ({
      value: academy.id,
      label: academy.name
    }));

  return (
    <div className="challenges-container">
      <div className="sales-filters-login">

        <div className="floating-select">
          <Select
            className={`sales-filter-court-booking ${selectedAcademy ? "has-value" : ""}`}
            classNamePrefix="react-select"
            options={academyOptions}
            value={academyOptions.find(option => option.value === selectedAcademy)}
            onChange={(selectedOption) => setSelectedAcademy(selectedOption?.value || '')}
            placeholder="Select Center"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          {selectedAcademy && <label htmlFor="selectedAcademy">Academy</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${fromDate ? "has-value" : ""}`}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {fromDate && <label htmlFor="fromDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${toDate ? "has-value" : ""}`}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {toDate && <label htmlFor="toDate">End Date</label>}
        </div>

      </div>

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredSalesRegister}
          headers={headers}
          filename="PlayingSubscription.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredSalesRegister.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr className="challenges-header-row">
              {headers.map((header) => (
                <th key={header.key} onClick={() => handleSort(header.key)}>
                  {header.label} {getSortingArrow(header.key)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentRows.map((Sales, index) => (
              <tr key={index}>
                <td>{Sales.name}</td>
                <td>{Sales.phone}</td>
                <td>{Sales.preferredCenter}</td>
                <td>{Sales.purchaseDate}</td>
                <td>{Sales.joiningDate}</td>
                <td>{Sales.expiryDate}</td>
                <td>{Sales.planType}</td>
                <td>{Sales.sportType}</td>
                <td>{Sales.preferredSport}</td>
                <td>{Sales.selfRating}</td>
                <td>{Sales.totalPlayingHours}</td>
                <td>{Sales.hoursUsed}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

    </div>
  );
};

export default SalesRegister;