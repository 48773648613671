import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NewPlayer.css';

const NewPlayer = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [playerData, setPlayerData] = useState(null); // Stores the player data if found
  const [error, setError] = useState(null); // Error state in case the search fails

  // Simulated player data (replace with real data-fetching logic)
  const samplePlayerData = {
    name: 'John Doe',
    mobile: '1234567890',
    action: 'Select this as Guardian, Select this as Player',
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Searching for mobile number:', mobileNumber);

    // Simulating search. Replace this with actual search logic (API call or local data check)
    if (mobileNumber === samplePlayerData.mobile) {
      setPlayerData(samplePlayerData);
      setError(null); // Clear any previous error
    } else {
      setPlayerData(null);
      setError('Player not found');
    }
  };

  return (
    <div className="new-player-wrapper">
      <div className="breadcrumb">
        <Link to="/academyPlayerList" className="breadcrumb-link">
          Academy Player List
        </Link>
        &gt; Search Player
      </div>

      <form onSubmit={handleSearch} className="new-player-form">
        <div className="input-container">
          <label htmlFor="mobileNumber" className="mobile-label">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter mobile number"
            required
            className="mobile-input-field"
          />
        </div>
        <button type="submit" className="submit-search-button">
          Search
        </button>
      </form>

      {/* Displaying player data if found */}
      {playerData ? (
        <div className="player-details">
          <h3>Player Found:</h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{playerData.name}</td>
                <td>{playerData.mobile}</td>
                <td>
                  <div className="action-links">
                    <Link to="/setGuardian" className="action-link">
                      Select this as Guardian
                    </Link>
                    <Link to="/setPlayer" className="action-link">
                      Select this as Player
                    </Link>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-player">
          {error && <p className="error-message">{error}</p>}
          {/* Only show this link if no player is found */}
          {!playerData && error && (
            <Link to="/createplayer" className="create-new-player-button">
              Create New Player
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default NewPlayer;
