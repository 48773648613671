import React, { useState, useEffect } from 'react';
import '../Challenges/challenges.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Select from 'react-select';

const CourtBooking = () => {
  const { selectedCenter, setSelectedCenter } = useUser();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedChallenge, setEditedChallenge] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [CourtBooking, setCourtBooking] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  useEffect(() => {
    const today = new Date();
    const tenDaysBefore = new Date();
    tenDaysBefore.setDate(today.getDate() - 10);

    setStartDate(tenDaysBefore.toISOString().split("T")[0]);
    setEndDate(today.toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    if (!selectedCenter) return;

    const fetchCourtBookings = async () => {
      try {
        const apiUrl = `http://localhost:8082/api/react/court/bookings?academyId=${selectedCenter}`;
        const headers = {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        };

        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.success && data.data?.combinedListMapTrial) {


          const baseEntityKey = Object.keys(data.data.combinedListMapTrial)[0];
          const baseEntityIdMatch = baseEntityKey.match(/BaseEntity\(id=(\d+)/);
          const baseEntityId = baseEntityIdMatch ? baseEntityIdMatch[1] : null;

          if (baseEntityId) {

          } else {

          }

          const formattedCourtBookings = Object.values(data.data.combinedListMapTrial).flatMap((entries) =>
            entries.map((entry) => {
              const courtBooking = entry.CourtBookingEntity;
              return {
                id: courtBooking.id,
                baseEntityId: baseEntityId,
                courtName: courtBooking?.academyCourtEntity?.name || 'N/A',
                startDate: courtBooking?.bookingDate ? formatDate(courtBooking.bookingDate) : 'N/A',
                endDate: courtBooking?.bookingDate ? formatDate(courtBooking.bookingDate) : 'N/A',
                openTime: courtBooking?.startTime || 'N/A',
                closeTime: courtBooking?.endTime || 'N/A', bookingDate: formatDate(courtBooking?.bookingDate || '-'),
                user: courtBooking?.userEntity?.name || 'N/A',
                contactNo: courtBooking?.userEntity?.mobile_number || 'N/A',
                guestCount: courtBooking.guestCount || 0,
                trialBooking: courtBooking.isTrial ? 'Yes' : 'No',
              };
            })
          );

          setCourtBooking(formattedCourtBookings);
        } else {
        }
      } catch (error) {
        setError('Error fetching data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourtBookings();
  }, [selectedCenter]);

  const headers = [
    { label: "Court Name", key: "courtName" },
    { label: "Open Time", key: "openTime" },
    { label: "Close Time", key: "closeTime" },
    { label: "Booking Date", key: "bookingDate" },
    { label: "User", key: "user" },
    { label: "Contact No", key: "contactNo" },
    { label: "Guest Count", key: "guestCount" },
    { label: "Trial Booking", key: "trialBooking" },
  ];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const generateTimeOptions = (openTime, closeTime) => {
    const times = [];
    let startTime = new Date(`1970-01-01T${openTime}:00`);
    const endTime = new Date(`1970-01-01T${closeTime}:00`);

    while (startTime < endTime) {
      const formattedTime = startTime.toTimeString().slice(0, 5);
      times.push({ value: formattedTime, label: formattedTime });
      startTime.setMinutes(startTime.getMinutes() + 30);
    }
    return times;
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const filteredCourtBooking = CourtBooking.filter((booking) => {
    const isSearchMatch = Object.values(booking)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const bookingDate = new Date(booking.bookingDate);
    const isDateInRange =
      (!startDate || bookingDate >= new Date(startDate)) &&
      (!endDate || bookingDate <= new Date(endDate));

    const parseTime = (timeString) => {
      if (!timeString) return null;
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      return new Date(1970, 0, 1, hours, minutes, seconds || 0);
    };

    const bookingStartTime = parseTime(booking.openTime);
    const bookingEndTime = parseTime(booking.closeTime);
    const filterStartTime = parseTime(startTime);
    const filterEndTime = parseTime(endTime);

    console.log("Booking Start:", booking.openTime, "Booking End:", booking.closeTime);
    console.log("Filter Start:", startTime, "Filter End:", endTime);

    let isTimeInRange = true;
    if (filterStartTime && filterEndTime) {
      isTimeInRange =
        (bookingStartTime >= filterStartTime && bookingStartTime <= filterEndTime) ||
        (bookingEndTime >= filterStartTime && bookingEndTime <= filterEndTime) ||
        (bookingStartTime <= filterStartTime && bookingEndTime >= filterEndTime);
    } else if (filterStartTime) {
      isTimeInRange = bookingStartTime >= filterStartTime;
    } else if (filterEndTime) {
      isTimeInRange = bookingEndTime <= filterEndTime;
    }

    return isSearchMatch && isDateInRange && isTimeInRange;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedChallenge((prevChallenge) => ({
      ...prevChallenge,
      [name]: value,
    }));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(filteredCourtBooking);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };


  const handleDelete = async (baseEntityId) => {
    const confirmDelete = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Do you really want to delete this court booking?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (!confirmDelete.isConfirmed) {
      return;
    }

    try {
      const apiUrl = `http://localhost:8082/api/react/court/cancel-booking?academyId=${selectedCenter}&bookingId=${baseEntityId}`;


      const headers = {
        "Content-Type": "application/json",
        "x-authorization":
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
      };

      const response = await axios.post(apiUrl, {}, { headers });

      if (response.status === 200) {
        setCourtBooking((prevBookings) =>
          prevBookings.filter((b) => b.baseEntityId !== baseEntityId)
        );

        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Court deleted successfully.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to delete the court. Please try again.",
      });
    }
  };


  const handleCourtBooking = () => navigate('/bookingcalendar');
  const totalEntries = filteredCourtBooking.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="challenges-container">
      <div className="sales-filters-court">

        <div className="floating-select">
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className={`sales-filter-academy ${startDate ? 'has-value' : ''}`}
            placeholder="Start Date"
            onFocus={(e) => e.target.showPicker()}
          />
          {startDate && <label htmlFor="startDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className={`sales-filter-academy ${endDate ? 'has-value' : ''}`}
            placeholder="End Date"
            onFocus={(e) => e.target.showPicker()}
          />
          {endDate && <label htmlFor="endDate">End Date</label>}
        </div>

        <div className="floating-select">
          <Select
            name="startTime"
            value={startTime ? { value: startTime, label: startTime } : null}
            onChange={(selectedOption) => setStartTime(selectedOption ? selectedOption.value : '')}
            options={generateTimeOptions("00:00", "23:30")}
            placeholder="Select Start Time"
            className={`sales-filter-court-booking ${startTime ? "has-value" : ""}`}
          />
          {startTime && <label htmlFor="startTime">Start Time</label>}
        </div>

        <div className="floating-select">
          <Select
            name="endTime"
            value={endTime ? { value: endTime, label: endTime } : null}
            onChange={(selectedOption) => setEndTime(selectedOption ? selectedOption.value : '')}
            options={generateTimeOptions("00:00", "23:30")}
            placeholder="Select End Time"
            className={`sales-filter-court-booking ${endTime ? "has-value" : ""}`}
          />
          {endTime && <label htmlFor="endTime">End Time</label>}
        </div>

      </div>

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handleCourtBooking}>
          Book Court
        </button>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredCourtBooking.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <table className="challenges-table">
        <thead>
          <tr>
            {headers.map((header) => (
              <th
                key={header.key}
                className="courtlist-column courtlist-column-action"
                onClick={() => handleSort(header.key)} // Sort by the column key
              >
                {header.label}
                {sortConfig.key === header.key ? (
                  sortConfig.direction === "ascending" ? (
                    <span> ↑</span> // Ascending arrow
                  ) : (
                    <span> ↓</span> // Descending arrow
                  )
                ) : null}
              </th>

            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((booking) => (
            <tr key={booking.id}>
              <td>{booking.courtName}</td>
              <td>{booking.openTime}</td>
              <td>{booking.closeTime}</td>
              <td>{booking.bookingDate}</td>
              <td>{booking.user}</td>
              <td>{booking.contactNo}</td>
              <td>{booking.guestCount}</td>
              <td>{booking.trialBooking}</td>
              <td>
                <button className="delete-button" onClick={() => handleDelete(booking.baseEntityId)}>Cancel</button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CourtBooking;
