import React, { useState, useEffect } from 'react';
import '../Coaching/academyplayerlist.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

const BatchPlayerList = () => {
    const location = useLocation();
    const { batchId } = location.state || {};
    const { userData } = useUser();
    const { academy } = location.state || {};
    const { selectedCenter, setSelectedCenter } = useUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [players, setPlayers] = useState([]);
console.log(batchId)
    useEffect(() => {
        const fetchPlayersData = async () => {
            if (!batchId) {
                setError('No batchId provided.');
                setLoading(false);
                return;
            }
    
            setLoading(true);
            try {
                const url = `http://localhost:8082/api/batch/${batchId}/players`;
                console.log(url);
    
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token
                    },
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch players data');
                }
    
                const result = await response.json();
                console.log(result)
                if (result.success) {
                    const updatedPlayers = result.data.players.map((player) => {
                        const playerId = player.id; // Fix this line
                        return {
                            id: playerId,
                            user: player.user_id, // Fix: 'user_id' exists in the response
                            name: player.name || '-',
                            parentName: result.data.parentPlayer?.[playerId] || '-',
                            parentMobile: result.data.parentPlayerMobileNumber?.[playerId] || '-',
                            category: player.player_category || '-', // Fix: Use 'player_category' instead of 'playerCategoryType'
                            level: player.player_level || '-', // Fix: Use 'player_level' instead of 'playerLevelType'
                            mobile: '-', // 'mobile_number' isn't present in the response
                            dob: formatDate(player.dateOfBirth) || '-',
                            isEnrolled: result.data.isEnrollmentMap?.[playerId] || false,
                            playingMember: result.data.playingMember?.[playerId] || false,
                        };
                    });
    
                    setPlayers(updatedPlayers);
                } else {
                    setError('No players found.');
                }
            } catch (error) {
                console.error('Error fetching players data:', error);
                setError('Error fetching data: ' + error.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchPlayersData();
    }, [batchId]);
    

    const formatDate = (dateString) => {
        if (!dateString || isNaN(Date.parse(dateString))) {
            return '-';
        }
        const dateOnly = dateString.split('T')[0];

        return dateOnly;
    };

    const headers = [
        { label: "Name", key: "name" },
        { label: "Parent Name", key: "parentName" },
        { label: "Category", key: "category" },
        { label: "Level", key: "level" },
        { label: "Date of Birth", key: "dob" },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    const [formData, setFormData] = useState({
        name: '',
        parentName: '',
        parentMobile: '',
        category: '',
        level: '',
        mobile: '',
        dob: '',
        isEnrolled: false,
        playingMember: false,
    });

    const [dropdownOpen, setDropdownOpen] = useState(null);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: name === 'isEnrolled' || name === 'playingMember' ? e.target.checked : value });
    };

    const filteredPlayers = players.filter((player) =>
        Object.values(player)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    const toggleDropdown = (playerId) => {
        setDropdownOpen(dropdownOpen === playerId ? null : playerId);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [sortConfig, setSortConfig] = useState({
        key: "name",
        direction: "ascending",
    });

    const handleSort = (columnKey) => {
        let direction = "ascending";
        if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key: columnKey, direction });
    };

    const sortData = (data) => {
        const { key, direction } = sortConfig;
        return [...data].sort((a, b) => {
            if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
            if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
            return 0;
        });
    };

    const sortedData = sortData(filteredPlayers);
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(sortedData.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleEdit = (playerId) => {
        navigate('/createPlayer', { state: { academyId: academy?.id || selectedCenter, playerId } });
    };
    const handleDetails = (event, playerId) => {
        event.stopPropagation();
        const selectedPlayer = players.find(player => player.id === playerId);
        const userId = selectedPlayer ? selectedPlayer.user : null; // Extracting userId

        const academyId = academy?.id ? Number(academy.id) : Number(selectedCenter);
        navigate('/createPlayer', { state: { academyId, playerId, userId } });
    };


    const hanldeshop = (playerId) => {
        navigate('/shop', { state: { academyId: academy?.id || selectedCenter, playerId } });
    };

    const generatePageNumbers = () => {
        const pages = [];
        const totalPagesToShow = 5;

        if (totalPages <= totalPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1);
            if (currentPage > 3) pages.push('...');
            for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                pages.push(i);
            }
            if (currentPage < totalPages - 2) pages.push('...');
            pages.push(totalPages);
        }
        return pages;
    };

    const renderPagination = () => {
        return generatePageNumbers().map((page, index) =>
            typeof page === 'number' ? (
                <button
                    key={index}
                    className={`page-number ${currentPage === page ? 'active' : ''}`}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </button>
            ) : (
                <span key={index} className="pagination-dots">
                    {page}
                </span>
            )
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdown = document.querySelector('.custom-dropdown-player');
            if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.dots-btn-player')) {
                setDropdownOpen(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleCreateplayer = () => navigate('/createNewPlayer');

    const cancelAction = () => {
        setIsModalOpen(false);
    };

    const handleItemsPerPageChange = (event) => {
        setrowsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };


    const hanldeplayerAttedance = () => navigate('/playerAttendance')

    const totalEntries = filteredPlayers.length;
    const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
    const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
    const handlePlayerAttendance = (id) => {
        navigate("/playerAttendance", { state: { playerId: id } });
    };

    return (
        <div className="academy-player-list-container">

            <div className="search-bar">
                <div className="input-container">
                    <input
                        type="text"
                        id="searchInput"
                        placeholder=""
                        className={`search-input ${searchTerm ? "has-value" : ""}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <label htmlFor="searchInput" className="floating-label">
                        🔍 Search
                    </label>
                </div>
                <button className="create-button" onClick={handleCreateplayer}>
                    + Create Player
                </button>
            </div>

            <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
                <option value={10}>10 items per page</option>
                <option value={50}>50 items per page</option>
                <option value={75}>75 items per page</option>
                <option value={100}>100 items per page</option>
            </select>

            <div className='space'></div>
            {loading && <div class="loading-spinner">
                <span>Loading</span>
                <div class="spinner"></div>
            </div>
            }
            {error && <div className="error-message">{error}</div>}
            {!loading && !error && filteredPlayers.length === 0 && (
                <div className="error-message">No data available for the selected Academy</div>
            )}

            <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
                <table className="payment-list-table">
                    <thead>
                        <tr>
                            {headers.map((header) => (
                                <th
                                    key={header.key}
                                    className="challenges-column"
                                    onClick={() => handleSort(header.key)}
                                >
                                    {header.label}
                                    {sortConfig.key === header.key ? (
                                        sortConfig.direction === "ascending" ? (
                                            <span> ↑</span>
                                        ) : (
                                            <span> ↓</span>
                                        )
                                    ) : null}
                                </th>
                            ))}
                            {hasPrivilege(userData, 'COACHING', 'ACADEMY_PLAYER_LIST_MODIFY') && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((player) => (
                            <tr key={player.id}>
                             <td>{player.name}</td>
                <td>{player.parentName}</td>
                <td>{player.category}</td>
                <td>{player.level}</td>
                <td>{player.dob}</td>
                                {hasPrivilege(userData, 'COACHING', 'ACADEMY_PLAYER_LIST_MODIFY') && (<td className="sport-actions-column">
                                    <button
                                        className="dots-btn-player"
                                        onClick={() => toggleDropdown(player.id)}
                                        style={{
                                            background: "none",
                                            border: "none",
                                            cursor: "pointer",
                                            fontSize: "24px",
                                        }}
                                    >
                                        &#x22EE;
                                    </button>
                                    {dropdownOpen === player.id && (
                                        <div className="custom-dropdown-player">
                                            <button className="dropdown-item" onClick={(event) => handleDetails(event, player.id)}>View Details</button>


                                            <button className="dropdown-item" onClick={() => handlePlayerAttendance(player.id)}>
                                                Attendance
                                            </button>
                                            <button className="dropdown-item" onClick={() => handleEdit(player.id)}>Edit</button>
                                            <button className="dropdown-item" >Remove</button>
                                            <button className="dropdown-item" onClick={() => hanldeshop(player.id)}>Shop</button>
                                        </div>
                                    )}
                                </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="pagination">
                <div className="showing-entries">
                    Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
                </div>
                <button
                    className="prev-button"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {renderPagination()}
                <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="close-btn" onClick={cancelAction}>&times;</button>
                        <div>
                            <p>
                                Are you sure you want to delete?
                            </p>
                            <div className="modal-actions">
                                <button className="confirm-btn-academy" >
                                    Confirm Disable
                                </button>
                                <button className="cancel-btn-academy" onClick={cancelAction}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BatchPlayerList
