import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../UserRole/CreateRole.css';
import Select from "react-select";
import Swal from 'sweetalert2';
const CreateRolePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [formList, setFormList] = useState([{ section: '', subSections: [] }]);
  const [sections, setSections] = useState([]);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (location.state) {
      const { roleName, sectionsAndSubsections } = location.state;
      setRoleName(roleName);
      setFormList(sectionsAndSubsections.map(item => ({
        section: item.section,
        subSections: item.subsections || []
      })));
      setDropdownOpen(sectionsAndSubsections.map(() => false)); 
    }
  }, [location.state]);

  useEffect(() => {
    setDropdownOpen(formList.map(() => false));
  }, [formList]);

  if (location.state) {
    const roleId = location.state.roleId;
  }



  // Fetch privileges on page load
  useEffect(() => {
    const fetchPrivileges = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_CREATE_ROLE, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
        });
        if (response.data.success) {
          const privileges = response.data.data.privileges;
          const sectionsArray = Object.entries(privileges).map(([key, value]) => ({
            name: key,
            subsections: value,
          }));
          setSections(sectionsArray);
        } else {
        }
      } catch (error) {
      }
    };
    fetchPrivileges();
  }, []);

  const handleSectionChange = (index, selectedOption) => {
    const newFormList = [...formList];
    newFormList[index].section = selectedOption.value;  // Get the selected value
    newFormList[index].subSections = [];  // Reset subsections when section is changed
    setFormList(newFormList);
  };

  const handleSubSectionSelect = (index, selectedOptions) => {
    const selectedSubSections = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    const newFormList = [...formList];
    newFormList[index].subSections = selectedSubSections;
    setFormList(newFormList);
  };

  const handleSubSectionClick = (index, subsection) => {
    const newFormList = [...formList];
    const selectedSubsections = newFormList[index].subSections;
    if (selectedSubsections.includes(subsection)) {
      newFormList[index].subSections = selectedSubsections.filter((item) => item !== subsection);
    } else {
      newFormList[index].subSections = [...selectedSubsections, subsection];
    }
    setFormList(newFormList);
  };

  const handleSave = async () => {
    if (!roleName.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Role name is required!',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }


    const hasValidSection = formList.some(
      (form) => form.section && form.subSections.length > 0
    );

    if (!hasValidSection) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'At least one section with subsections is required!',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }


    // Construct privilegeList in the required format
    const privilegeList2 = formList.reduce((acc, form) => {
      if (form.section && form.subSections.length > 0) {
        acc[form.section] = form.subSections;
      }
      return acc;
    }, {});


    // Prepare the payload
    const payload = {
      roleName,
      privilegeList2,
    };

    try {
      const apiUrlBase =  process.env.REACT_APP_API_NEW_ROLE;
      const apiUrl = location.state && location.state.roleId ? `${apiUrlBase}?roleId=${location.state.roleId}` : apiUrlBase;

      const response = await axios.post(apiUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
        },
      });

      if (response.data.success) {
        if (location.state) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Role updated successfully!',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });
        }

        else {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Role created successfully!',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
          });
        }

        navigate('/RoleList');
      } else {
      }
    } catch (error) {
    }
  };


  const handleAddMore = () => {
    setFormList([...formList, { section: '', subSections: [] }]);
  };



  const handleRemove = (index) => {
    const newFormList = formList.filter((_, formIndex) => formIndex !== index);
    const newDropdownOpen = dropdownOpen.filter((_, dropIndex) => dropIndex !== index);
    setFormList(newFormList);
    setDropdownOpen(newDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current.every(
          (ref) => ref.current && !ref.current.contains(event.target)
        )
      ) {
        setDropdownOpen(formList.map(() => false));
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [formList]);



  const toggleDropdown = (index) => {
    setDropdownOpen((prevState) =>
      prevState.map((state, idx) => (idx === index ? !state : state))
    );
  };

  const dropdownRefs = useRef([]);
  dropdownRefs.current = formList.map(
    (_, i) => dropdownRefs.current[i] || React.createRef()
  );


  const selectedSection = (section) => sections.find((sec) => sec.name === section);
  const selectedSections = formList.map((form) => form.section);

  return (
    <div>
      <div className="form-container" style={{ minHeight: '500px' }}>
        <div className="breadcrumb">
          <Link to="/RoleList" className="breadcrumb-link">Role List</Link>  &gt;  New Role
        </div>

        <div className="form-group">
          <label>Role Name:</label>
          <input
            type="text"
            placeholder="Enter role name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            className="input-field"
            required
          />
        </div>

        {formList.map((form, index) => {
          const subsections = selectedSection(form.section)?.subsections || [];
          const availableSections = sections.filter((sec) => !selectedSections.includes(sec.name) || sec.name === form.section);

          return (
            <form key={index} className="role-form">
              {index > 0 && (
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => handleRemove(index)}
                >
                  ✖
                </button>
              )}
              <div className="form-group">
                <label>Section:</label>
                <div className="dropdown-container">
                  <Select
                    value={availableSections.find((sec) => sec.name === form.section) ? { value: form.section, label: form.section } : null}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleSectionChange(index, selectedOption);
                      }
                    }}
                    options={availableSections.map((sec) => ({ value: sec.name, label: sec.name }))}
                    isClearable
                  />

                </div>
              </div>


              {form.section && (
                <div className="form-group">
                  <label>Subsections:</label>
                  <div ref={dropdownRefs.current[index]} className="dropdown-container">
                    <Select
                      isMulti
                      options={subsections.map((sub) => ({ label: sub, value: sub }))}
                      value={form.subSections.map((sub) => ({ label: sub, value: sub }))}
                      onChange={(selectedOptions) => handleSubSectionSelect(index, selectedOptions)}
                      placeholder="Select Subsections"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>
              )}
            </form>
          );
        })}

        {formList.length < sections.length && (
          <div className="button-container">
            <button
              type="button"
              onClick={handleAddMore}
              className="add-more-button"
            >
              Add More
            </button>
          </div>
        )}

        <div className="button-container">
          <button
            type="button"
            onClick={handleSave}
            className="save-button"
          >
            Save Role
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRolePage;