import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../coupons/Coupon.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import Swal from "sweetalert2";

const CouponList = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ title: '', couponCode: '', minOrderAmount: '', maxDiscount: '', maxUsagePerUser: '', percentage: '', startTime: '', endTime: '', description: '' });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    setError('');
  
    fetch('https://admin.stage.machaxi.com/api/react/coupons/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const fetchedCoupons = data.data.coupons.map((coupon) => ({
            id: coupon.id,
            title: coupon.title,
            couponCode: coupon.couponCode,
            minOrderAmount: coupon.minOrderAmount,
            maxDiscount: coupon.maxDiscountAmount,
            maxUsagePerUser: coupon.maxUsagePerUser,
            percentage: coupon.percentage,
            startTime: new Date(coupon.startTime).toISOString().split('T')[0],
            endTime: new Date(coupon.endTime).toISOString().split('T')[0],
            description: coupon.description,
            forAllUsers: coupon.forAllUsers,
            mobileNumbers: coupon.mobileNumbers,
          }));
          setCoupons(fetchedCoupons);
        } else {
          setError('Failed to fetch coupons.');
        }
      })
      .catch((error) => {
        console.error('Error fetching coupons:', error);
        setError('Error fetching data: ' + error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
  const sortCoupons = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedCoupons = [...coupons].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setCoupons(sortedCoupons);
    setSortConfig({ key, direction });
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateCoupon = () => {
    navigate('/newCoupon');
  };

  const handleDetails = (couponData) => {
    console.log("couponData:", couponData);
    navigate('/newCoupon', { state: { couponData, name: "sonam" } });
  };
  

  const handleEditCoupon = (couponData) => {
    navigate('/newCoupon', { state: { couponData } });
    console.log(couponData)
  };

  const handleDeleteCoupon = (couponId) => {
    const coupon = coupons.find(coupon => coupon.id === couponId);
    setSelectedCoupon(coupon);
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`https://admin.stage.machaxi.com/api/react/coupons/remove-coupon-academy?coupon_id=${selectedCoupon.id}&user_type=admin`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with a valid token if needed
        },
      });
  
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to delete coupon: ${errorDetails.message || response.statusText}`);
      }
  
      const updatedCoupons = coupons.filter(coupon => coupon.id !== selectedCoupon.id);
      setCoupons(updatedCoupons);
      setIsModalOpen(false);
  
      // Success Alert
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "Coupon deleted successfully.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
  
    } catch (error) {
      console.error('Error deleting coupon:', error);
  
      // Error Alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredCoupons = coupons.filter((coupon) =>
    Object.values(coupon)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCoupons.length / itemsPerPage);
  const displayedCoupons = filteredCoupons.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen !== null && !event.target.closest('.dropdown-container')) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = (couponId) => {
    setDropdownOpen(dropdownOpen === couponId ? null : couponId);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredCoupons.length;
  const indexOfFirstEntry = (currentPage - 1) * itemsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * itemsPerPage, totalEntries);

  return (
    <div className="coupon-list-container" >
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        {hasPrivilege(userData,'COUPONS', 'COUPONS_LIST_MODIFY') && (
        <button className="create-button" onClick={handleCreateCoupon}>
          + Create Coupon
        </button>
        )}
      </div>

      <select className="items-per-page-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={25}>25 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
      </select>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredCoupons.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>

            <tr>
              <th onClick={() => sortCoupons('title')}>
                Title
                {sortConfig.key === 'title' ? (sortConfig.direction === 'asc' ? ' ↑' : ' ↓') : null}
              </th>

              <th onClick={() => sortCoupons('couponCode')}>
                Coupon Code
                {sortConfig.key === 'couponCode' ? (sortConfig.direction === 'asc' ? ' ↑' : ' ↓') : null}
              </th>

              <th onClick={() => sortCoupons('minOrderAmount')}>
                Min Order Amount
                {sortConfig.key === 'minOrderAmount' ? (sortConfig.direction === 'asc' ? ' ↑' : ' ↓') : null}
              </th>

              <th onClick={() => sortCoupons('maxDiscount')}>
                Max Discount
                {sortConfig.key === 'maxDiscount' ? (sortConfig.direction === 'asc' ? ' ↑' : ' ↓') : null}
              </th>

              <th onClick={() => sortCoupons('maxUsagePerUser')}>Max Usage Per User
                {sortConfig.key === 'maxUsagePerUser' ? (sortConfig.direction === 'asc' ? ' ↑' : ' ↓') : null}
              </th>

              <th onClick={() => sortCoupons('percentage')}>
                Percentage
                {sortConfig.key === 'percentage' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : null}
              </th>

              <th onClick={() => sortCoupons('startTime')}>
                Start Time
                {sortConfig.key === 'startTime' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : null}
              </th>

              <th onClick={() => sortCoupons('endTime')}>
                End Time
                {sortConfig.key === 'endTime' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : null}
              </th>

              <th onClick={() => sortCoupons('description')}>
                Description
                {sortConfig.key === 'description' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : null}
              </th>

              {hasPrivilege(userData,'COUPONS', 'COUPONS_LIST_MODIFY') && (
              <th>Action</th>
               )}
            </tr>

          </thead>

          <tbody>
            {displayedCoupons.reverse().map((coupon) => (
              <tr key={coupon.id}>
                <td>{coupon.title}</td>
                <td>{coupon.couponCode}</td>
                <td>{coupon.minOrderAmount}</td>
                <td>{coupon.maxDiscount}</td>
                <td>{coupon.maxUsagePerUser}</td>
                <td>{coupon.percentage}%</td>
                <td>{coupon.startTime}</td>
                <td>{coupon.endTime}</td>
                <td>{coupon.description}</td>
                {hasPrivilege(userData,'COUPONS','COUPONS_LIST_MODIFY') && (
                <td >
                  <button
                    className="dropdown-toggle-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown(coupon.id);
                    }}
                  >
                    &#x22EE;
                  </button>
                  {dropdownOpen === coupon.id && (
                    <div className="dropdown-menu-container">
                      <button onClick={() => handleEditCoupon(coupon.id)}>Edit</button>
                      <button onClick={() => handleDeleteCoupon(coupon.id)}>Disable</button>
                      <button onClick={() => handleDetails(coupon.id)}>Details</button>
                    </div>
                  )}
                </td>
                 )} 

              </tr>
            ))}
          </tbody>

        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>&times;</button>
            <div>
              <p>
                Are you sure you want to delete the coupon: {selectedCoupon.title}?
              </p>
              <div className="modal-actions">
                <button className="confirm-btn" onClick={confirmDelete}>
                  Confirm Disable
                </button>
                <button className="cancel-btn" onClick={cancelAction}>
                  Cancel
                </button>
              </div>
            </div>

          </div>
        </div>
      )}

    </div>
  );
};

export default CouponList;
