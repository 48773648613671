import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import './NewCoach.css'; // Import the CSS file for styling

const NewCoach = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [coachData, setCoachData] = useState(null); // Stores the coach data if found
  const [error, setError] = useState(''); // Error state for failed search

  // Simulated coach data (replace with actual data-fetching logic)
  const sampleCoachData = {
    name: 'Jane Smith',
    mobile: '9876543210',
    action: 'Select this as coach',
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Searching for mobile number:', mobileNumber);

    // Simulate search logic (replace with real API or database search)
    if (mobileNumber === sampleCoachData.mobile) {
      setCoachData(sampleCoachData);
      setError(''); // Clear error if coach is found
    } else {
      setCoachData(null);
      setError('Coach not found. Please try again.');
    }
  };

  // Function to handle the coach selection confirmation
  const handleSelectCoach = () => {
    const isConfirmed = window.confirm('Are you sure you want to select this coach?');
    if (isConfirmed) {
      // Add logic for coach selection here
      alert('Coach selected!');
      // You can perform any additional action like saving the coach selection here
    }
  };

  return (
    <div className="new-player-wrapper">
      <div className="breadcrumb">
        <Link to="/academyCoachList" className="breadcrumb-link">
          Coach List
        </Link>
        &gt; Search Coach
      </div>

      <form onSubmit={handleSearch} className="new-player-form">
        <div className="input-container">
          <label htmlFor="mobileNumber" className="mobile-label">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter mobile number"
            required
            className="mobile-input-field"
          />
        </div>
        <button type="submit" className="submit-search-button">
          Search
        </button>
      </form>

      {/* Coach Details Section */}
      {coachData ? (
        <div className="player-details">
          <h3>Coach Found:</h3>
          <table className="player-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{coachData.name}</td>
                <td>{coachData.mobile}</td>
                <td>
                  {/* Action Link for selecting coach */}
                  <button onClick={handleSelectCoach} className="select-coach-button">
                    {coachData.action}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-player">
          {error && <p className="error-message">{error}</p>}
          {/* Show "Create New Coach" button if no coach found */}
          {error && (
            <Link to="/createcoach" className="create-new-player-button">
              Create New Coach
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default NewCoach;
