import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Curriculum = () => {
  // State to store activities for each weekday
  const [activities, setActivities] = useState({
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
  });

  // State to store the selected date from the calendar
  const [selectedDate, setSelectedDate] = useState(new Date());

  // State to store selected days for applying the activity
  const [selectedDays, setSelectedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  // Options for dropdown activity selection
  const options = ['Activity 1', 'Activity 2', 'Activity 3', 'Activity 4'];

  // Handler for calendar date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Handler for activity selection in each dropdown
  const handleActivityChange = (day, activity) => {
    setActivities(prevState => ({
      ...prevState,
      [day]: activity,
    }));
  };

  // Handler for checkbox selection (select multiple days)
  const handleCheckboxChange = (day) => {
    setSelectedDays(prevState => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  // Handler to apply the activity to the selected days
  const handleApplyActivity = () => {
    const currentActivity = activities[selectedDate.toLocaleDateString()];
    if (currentActivity) {
      // Update selected days with the current activity
      setActivities(prevState => {
        const updatedActivities = { ...prevState };
        Object.keys(selectedDays).forEach(day => {
          if (selectedDays[day]) {
            updatedActivities[day] = currentActivity;
          }
        });
        return updatedActivities;
      });
    }
  };

  return (
    <div>
      <h2>Curriculum</h2>

      {/* Calendar component */}
      <div style={{ marginBottom: '20px' }}>
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
        />
      </div>

      {/* Table for weekdays and activities */}
      <table border="1" style={{ width: '100%', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th>Day</th>
            <th>Activity</th>
            <th>Select Days</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(activities).map((day) => (
            <tr key={day}>
              <td>{day}</td>
              <td>
                <select
                  value={activities[day]}
                  onChange={(e) => handleActivityChange(day, e.target.value)}
                >
                  <option value="">Select an activity</option>
                  {options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedDays[day]}
                  onChange={() => handleCheckboxChange(day)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Button to apply activity to selected days */}
      <button onClick={handleApplyActivity} style={{ marginTop: '20px' }}>
        Apply Activity to Selected Days
      </button>
    </div>
  );
};

export default Curriculum;
