import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import '../sliders/SliderList.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const BenefitList = () => {
  const location = useLocation();
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { planId } = location.state || {};
  const apiUrlBase = 'https://admin.stage.machaxi.com/api/admin/playing-plan-benefits';

  useEffect(() => {
    if (planId) {
      const fetchBenefits = async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await fetch(`${apiUrlBase}/${planId}`, {
            method: 'GET',
            headers: {
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with actual token
              'Content-Type': 'application/json',
            },
          });

          const data = await response.json();

          if (response.ok && data.success) {
            setBenefits(data.data.planBenefits);
          } else {
            setError(data.message || 'Failed to fetch benefits');
          }
        } catch (err) {
          setError('An error occurred while fetching benefits');
        } finally {
          setLoading(false);
        }
      };

      fetchBenefits();
    }
  }, [planId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ title: '', description: '' });
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewBenefit = (planId) => {
    navigate('/createbenefit', { state: { planId } });
  };

  const handleEditBenefit = (planId, benefitText, benefitId) => {
    navigate('/createbenefit', { state: { planId, benefitText, benefitId } });
    console.log("benefit id", benefitId)
  };


  const handleDeleteBenefit = async (benefitId) => {
    const apiUrl = `https://admin.stage.machaxi.com/api/admin/delete-playing-plan-benefit/${planId}?benefitId=${benefitId}`;
    
    const headers = {
      'x-authorization': `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ`,  // Use 'Authorization' instead of 'x-authorization'
      'Content-Type': 'application/json',
    };
  
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        setError(null);
  
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers,
          });
  
          if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
          }
  
          const data = await response.json();
  
          if (data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'Benefit deleted successfully!',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
            });
  
            setBenefits((prevBenefits) => prevBenefits.filter((benefit) => benefit.id !== benefitId));
          } else {
            throw new Error(data.message || 'Failed to delete the benefit.');
          }
        } catch (err) {
          setError(err.message);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.message,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };
  


  const confirmDelete = () => {
    const updatedBenefits = benefits.filter(benefit => benefit.id !== selectedBenefit.id);
    setBenefits(updatedBenefits);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredBenefits = benefits.filter((benefit) =>
    Object.values(benefit)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredBenefits.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredBenefits.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const totalEntries = filteredBenefits.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="slider-container">
      <div className="breadcrumb">
        <Link to="/planList" className="breadcrumb-link">Plan List</Link> &gt; Benefit List
      </div>

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={() => handleNewBenefit(planId)} >
          + Create Benefit
        </button>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredBenefits.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}

      <div className='space'></div>

      <table className="academy-table">
        <thead>
          <tr>
            <th>Benefit</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((benefit) => (
            <tr key={benefit.id}>
              <td>{benefit.benefit}</td>
              <td className="action-buttons">
                <button
                  className="edit-btn"
                  onClick={() => handleEditBenefit(benefit.id, benefit.benefit, benefit.id)}
                >
                  Edit
                </button>
                <button className="delete-btn" onClick={() => handleDeleteBenefit(benefit.id)}>
                  Delete
                </button>
              </td>

            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>
              &times;
            </button>
            <div>
              <p>Are you sure you want to delete this Benefit?</p>
              <div className="modal-actions">
                <button className="confirm-btn-academy" onClick={confirmDelete}>
                  Confirm Delete
                </button>
                <button className="cancel-btn-academy" onClick={cancelAction}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BenefitList;
