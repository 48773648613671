import React ,{useState,useEffect}from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import logo from '../Navbar/logo.png';
import './Navbar.css'; 
import { useLocation } from 'react-router-dom';
import { getPageTitle } from '../utils/getPageTitle'; 

const Navbar = ({ onLogout }) => {  // Accept onLogout as a prop

 const location = useLocation();
 const pageTitle = getPageTitle(location.pathname);
  const [anchorEl, setAnchorEl] = React.useState(null);
 
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear(); 
   
    onLogout(); // Call onLogout when logging out
    navigate('/login'); // Redirect to login page after logout
  };
  return (
    <AppBar position="static"   elevation={2}>
      <Toolbar className="navbar-toolbar">
        {/* Logo Section */}
        <Box display="flex" alignItems="center" className="navbar-logo-container" onClick={() => navigate('/')}>
          <img src={logo} alt="Company Logo" className="navbar-logo" />
        </Box>
        <Typography variant="h5" className="navbar-title">
        {pageTitle}
        </Typography>
        {/* Icon Section */}
        <Box display="flex" alignItems="center">
          <IconButton color="inherit">
          </IconButton>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Box>
      </Toolbar>

      {/* User Menu */}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom', // Align menu below the navbar
          horizontal: 'right', // Align menu to the right
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
  onClick={() => {
    handleClose(); // Close the menu
    navigate('/profile'); // Navigate to profile
  }}
>
  Profile
</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem> {/* Logout option */}
      </Menu>
    </AppBar>
  );
};

export default Navbar;
