import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../context/UserContext'; // Assuming you are using this to check user privileges
import { CSVLink } from 'react-csv';
import axios from "axios";
import './Enquiry.css';
import Modal from "react-modal";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { hasPrivilege } from '../utils/hasPrivilege';
import Swal from 'sweetalert2';
ChartJS.register(ArcElement, Tooltip, Legend);
const Enquiries = () => {
  const { userData } = useUser();
  const [areaFilter, setAreaFilter] = useState('');
  const [createdAtFilter, setCreatedAtFilter] = useState('');
  const [academies, setAcademies] = useState([]);
  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [academyFilter, setAcademyFilter] = useState('');
  const [sourceFilter, setSourceFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("");
  const today = new Date();
  const tenDaysAgo = new Date(today);
  const { selectedCenter, setSelectedCenter } = useUser();

  tenDaysAgo.setDate(today.getDate() - 10);
  const [startDate, setStartDate] = useState(tenDaysAgo.toISOString().split('T')[0]);  // Set to 10 days ago

  today.setDate(today.getDate() + 1);  // Add one day to today's date
  const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);  // Set to tomorrow's date
  useEffect(() => {

    if (selectedCenter) {
      // If a center is manually selected, use it
      setAcademyFilter(selectedCenter);
    } else if (userData?.userType === 'ACADEMY' && userData.academyId) {
      // If user is an academy type and has an academyId, use that
      setAcademyFilter("allAcademies");
    } else {
      // Default case if neither is available
      setAcademyFilter(null); // or some default value
    }
  }, [userData, selectedCenter]);

  useEffect(() => {
    // Fetch data from the API
    fetch(process.env.REACT_APP_API_VIEW, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const transformedData = data.data.apiData.map(item => ({
            id: item.id || '-',
            name: item.name || '-',
            number: item.mobile_number || '-',
            academy: item.academy_name || '-',
            enquiry: item.remarks,
            source: item.source || 'Unknown',
            status: item.status || 'LEAD',
            createdAt: item.created_at,
            date_at: item.created_at,
            email: item.email,
            area: item.area,
            academyId: item.academy_id,
            query_message: item.query_message,
            external: item.is_external || 0,
          }));
          setEnquiries(transformedData);
        } else {
          setError('Failed to fetch data');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Number', key: 'number' },
    { label: 'Area', key: 'area' },
    { label: 'Centre', key: 'academy' },
    { label: 'Remark', key: 'enquiry' },
    { label: 'Date', key: 'date_at' },
    { label: 'Program', key: 'source' },
    { label: 'Status', key: 'status' },
    { label: 'Query', key: 'query_message' },


  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleItemsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Get unique values for academy and source
  const uniqueAcademies = [...new Set(enquiries.map((enquiry) => enquiry.academy))];
  const uniqueSources = [...new Set(enquiries.map((enquiry) => enquiry.source))];
  const uniqueAreas = [...new Set(enquiries.map((enquiry) => enquiry.area))];
  useEffect(() => {
    // Reset to page 1 whenever any filter changes
    setCurrentPage(1);
  }, [academyFilter, sourceFilter, areaFilter, startDate, endDate, searchTerm]);
  // Filter enquiries based on search, academy, source, and date range
  const filteredEnquiries = enquiries.filter((enquiry) => {
    const createdAtDate = new Date(enquiry.createdAt); // Assuming createdAt is in a date-compatible format

    // Ensure endDate includes the full day until 23:59:59.999
    const adjustedEndDate = endDate ? new Date(new Date(endDate).setHours(23, 59, 59, 999)) : null;

    // Check if the date is within the range
    const isWithinDateRange =
      (!startDate || createdAtDate >= new Date(startDate)) &&
      (!adjustedEndDate || createdAtDate <= adjustedEndDate);

    // Check if the academy matches the selected filter
    const matchesAcademy = academyFilter === "allAcademies"
      ? userData.academyIds.includes(Number(enquiry.academyId)) // If "All Academies", match against all academyIds of the user
      : academyFilter
        ? Number(enquiry.academyId) === Number(academyFilter) // Match against the selected academy
        : true; // If no academy selected, include everything

    return (
      Object.values(enquiry)
        .join(' ')
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) &&
      matchesAcademy && // Use the new logic here
      (sourceFilter ? enquiry.source.toLowerCase() === sourceFilter.toLowerCase() : true) &&
      (areaFilter ? enquiry.area.toLowerCase() === areaFilter.toLowerCase() : true) &&
      isWithinDateRange // Include date range filter here
    );
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const handleSort = (columnKey) => {
    let direction = 'ascending';

    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({ key: columnKey, direction });
  }; const sortData = (data) => {
    const { key, direction } = sortConfig;

    if (!key) return data;

    const sorted = [...data].sort((a, b) => {
      // If sorting by created_at, sort in descending order to show latest entries on top
      if (key === 'created_at') {
        const aDate = new Date(a[key]);
        const bDate = new Date(b[key]);
        return direction === 'ascending' ? aDate - bDate : bDate - aDate;
      }

      const aValue = a[key];
      const bValue = b[key];

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    return sorted;
  };

  const sortedData = sortData(filteredEnquiries);
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [resolvedStatus, setResolvedStatus] = useState('unsolved'); // Default to unresolved
  const handleOpenModal = (enquiry) => {


    // Find the academy by name
    const matchedAcademy = academies.find((academy) => academy.name === enquiry.academy);

    setSelectedEnquiry(enquiry);
    setRemarks(enquiry.enquiry || ""); // If remarks exist, use them
    setResolvedStatus(enquiry.status || "LEAD"); // Default to "LEAD" if no status

    // Set the academy ID based on the matched name or default to the first academy
    setSelectedAcademy(matchedAcademy ? matchedAcademy.id : academies[0]?.id);

    setModalIsOpen(true);
  };



  const handleCloseModal = () => {
    setModalIsOpen(false);
    setRemarks('');
  };

  const handleSaveStatus = async () => {
    if (selectedEnquiry) {

      if (!selectedEnquiry.id) {
        return;
      }
      if (!remarks || remarks.trim() === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Remarks are required!",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }



      // Convert academyId to a consistent type for matching
      const selectedAcademyId = String(selectedAcademy);

      // Find the matching academy
      const matchedAcademy = academies.find((academy) => String(academy.id) === selectedAcademyId);

      if (matchedAcademy) {

      } else {

      }

      // Prepare data for API request
      const data = {
        academyId: selectedAcademyId, // Ensuring it's a string for consistency
        status: resolvedStatus,
        remarks: remarks,
      };



      const baseUrl =
        selectedEnquiry.external === 1
          ? `${process.env.REACT_APP_API_UPDATE}/${selectedEnquiry.id}`
          : `${process.env.REACT_APP_API_UPDATE_INTERNAL}/${selectedEnquiry.id}`;



      try {
        const response = await fetch(baseUrl, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
          body: JSON.stringify(data),
        });



        if (response.ok) {
          const responseData = await response.json();

          // Update the enquiry in state
          const updatedEnquiries = enquiries.map((enquiry) =>
            enquiry.id === selectedEnquiry.id
              ? {
                ...enquiry,
                academy: matchedAcademy ? matchedAcademy.name : enquiry.academy, // Update with Academy Name
                status: resolvedStatus,
                enquiry: remarks,
              }
              : enquiry
          );

          setEnquiries(updatedEnquiries);

          handleCloseModal();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Successfully updated! Academy: ${matchedAcademy ? matchedAcademy.name : "Unknown"}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });

        } else {
          const errorText = await response.text();
          Swal.fire({
            icon: "error",
            title: "Update Failed",
            text: `Error updating enquiry status: ${errorText}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Request Failed",
          text: "There was an error making the API request. Please try again.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No enquiry selected!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };


  const getResolvedAndUnresolvedCounts = (source) => {
    const filtered = enquiries.filter((enquiry) => enquiry.source === source);
    const resolvedCount = filtered.filter((enquiry) => enquiry.isResolved).length;
    const unresolvedCount = filtered.length - resolvedCount;
    return { resolvedCount, unresolvedCount };
  };

  const sources = [
    "Swimming-SummerCamp",
    "Badminton-SummerCamp",
    "Badminton-Coaching",
    "Swimming-playing",
    "Swimming-Coaching",
    "EXTERNAL"
  ];

  const pieChartData = (source) => {
    const statuses = ['LEAD', 'CALLED', 'CONVERTED', 'INTERESTED', 'JUNK'];
    const colors = ['#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0', '#9966FF', '#FF6384', '#C9CBCF', '#8BC34A']

    let dataValues = statuses.map(
      (status) => filteredEnquiries.filter((enquiry) => enquiry.source === source && enquiry.status === status).length
    );

    // Filter out zero values along with corresponding labels and colors
    const filteredData = dataValues
      .map((value, index) => (value > 0 ? { value, label: statuses[index], color: colors[index] } : null))
      .filter(Boolean);

    // If no valid data exists, return null to avoid rendering an empty chart
    if (filteredData.length === 0) return null;

    return {
      labels: filteredData.map((item) => item.label),
      datasets: [
        {
          data: filteredData.map((item) => item.value),
          backgroundColor: filteredData.map((item) => item.color),
          hoverBackgroundColor: filteredData.map((item) => item.color),
        },
      ],
    };
  };


  const [file, setFile] = useState(null);
  const [response, setResponse] = useState('');
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');

  };
  const handleClose = () => {
    setShowModal(false);
    setFile(null);
    setFileName("");
    setResponse(""); // Clear response message
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input field
    }
  };

  const uploadCSV = () => {
    if (!file) {
      setResponse("Please select a CSV file.");
      return;
    }

    let formData = new FormData();
    formData.append('file', file);

    axios.post(process.env.REACT_APP_API_CSV, formData, {
      headers: {
        'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(response => {
        setResponse(response.data);
      })
      .catch(error => {
        setResponse("Error: " + error.response.data);
      });
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  // Adding event listener for clicks outside the modal
  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const handleSendMessage = async (enquiry) => {

    const apiUrl = process.env.REACT_APP_API_SEND_MESSAGE;

    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY,
    };

    const payload = {
        name: enquiry.name,
        academy: enquiry.academy,
        area: enquiry.area,
        number: `+91${enquiry.number}`, 
        source: enquiry.source,
        status: enquiry.status,
    };
    console.log(payload)
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
        });

        const responseData = await response.json();
        console.log('API Response:', responseData);

        if (responseData.message === 'Message sent successfully') {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Message sent successfully!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Failed",
                text: `Error: ${responseData.message}`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    } catch (error) {
        console.error('Error sending data:', error);
        Swal.fire({
            icon: "error",
            title: "Update Failed",
            text: `Error updating enquiry status: ${error.message}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
        });
    }
};


  useEffect(() => {
    const fetchAcademies = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_QUERY_VIEW, {
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
        });

        const filteredAcademies = response.data.data.academies.map((academy) => ({
          id: academy.id,
          name: academy.name,
        }));

        setAcademies(filteredAcademies);

        // Set selectedAcademy after academies are fetched
        if (filteredAcademies.length > 0) {
          setSelectedAcademy(filteredAcademies[0].id);
        }
      } catch (error) {
      }
    };

    fetchAcademies();
  }, []);
  const totalEntries = filteredEnquiries.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="coupon-list-container">
      {/* Dropdown for filtering by academy */}
      <div className="filter-container-summer">

        <div className="floating-select">
          <select
            name="academy"
            value={academyFilter} // Ensure the selectedAcademy state is reflected in the dropdown
            onChange={(e) => setAcademyFilter(e.target.value)} // Update state when user changes selection
            className={`sales-filter-academy ${academyFilter ? "has-value" : ""}`}
          >
            {userData.userType === "ACADEMY" ? (
              <>
                <option value="allAcademies">All Academies</option>
                {userData.academyIds.map((academyId) => {
                  const academy = academies.find((item) => item.id === academyId); // Get academies based on academyIds
                  if (academy) {
                    return (
                      <option key={academyId} value={academyId}>
                        {academy.name}
                      </option>
                    );
                  }
                  return null; // Skip academies without matching data
                })}
              </>
            ) : (
              // If the user is not an academy, show all academies
              <>
                <option value="">All Academies</option>
                {[...new Set(academies.map((item) => item.id))].map((academyId) => {
                  const academy = academies.find((item) => item.id === academyId);
                  if (academy) {
                    return (
                      <option key={academyId} value={academyId}>
                        {academy.name}
                      </option>
                    );
                  }
                  return null;
                })}
              </>
            )}
          </select>
          {academyFilter && <label htmlFor="academy">Academy</label>}
        </div>
        <div className="floating-select">
          <select
            className={`sales-filter-academy ${sourceFilter ? "has-value" : ""}`}
            value={sourceFilter}
            onChange={(e) => setSourceFilter(e.target.value)}
          >
            <option value="">All Sources</option>
            {uniqueSources.map((source, index) => (
              <option key={index} value={source}>
                {source}
              </option>
            ))}
          </select>
          {sourceFilter && <label htmlFor="source">Source</label>}
        </div>

        <div className="floating-select">
          <select
            className={`sales-filter-academy ${areaFilter ? "has-value" : ""}`}
            value={areaFilter}
            onChange={(e) => setAreaFilter(e.target.value)}
          >
            <option value="">All Areas</option>
            {uniqueAreas.map((area, index) => (
              <option key={index} value={area}>
                {area}
              </option>
            ))}
          </select>
          {areaFilter && <label htmlFor="area">Area</label>}
        </div>


        <div className="floating-select">
          <input
            type="date"
            placeholder="Start Date"
            name="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${startDate ? "has-value" : ""}`}
          />
          {startDate && <label htmlFor="startDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            placeholder="End Date"
            name="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${endDate ? "has-value" : ""}`}
          />
          {endDate && <label htmlFor="endDate">End Date</label>}
        </div>

      </div>

      <div className="unique-charts-query">
        {sources.map((source, index) => {
          const chartData = pieChartData(source);

          if (!chartData) return null; // Do not render if no valid data

          return (
            <div key={index} className="unique-chart-query">
              <h3>{source}</h3>
              <Pie data={chartData} />
            </div>
          );
        })}
      </div>


      <div className="search-create-container">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? 'has-value' : ''}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>

        <CSVLink
          data={filteredEnquiries}
          headers={headers}
          filename="Enquiry.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <div className="items-container">
        <select
          className="items-per-page-select"
          value={rowsPerPage}
          onChange={handleItemsPerPageChange}
        >
          <option value={10}>10 items per page</option>
          <option value={50}>50 items per page</option>
          <option value={75}>75 items per page</option>
          <option value={100}>100 items per page</option>
        </select>

        {hasPrivilege(userData, 'MARKETING', 'EQUIRY_MODIFY') && (
          <div style={{ marginTop: '20px' }}>

            <button onClick={() => setShowModal(true)} className="create-button">
              Upload CSV
            </button>

            <br />
            <a
              href="https://machaxidev.blob.core.windows.net/frontend-machaxi/sample_query.xlsx"
              download
              className="download-csv-link"
            >
              Download Sample CSV
            </a>



            {showModal && (
              <div style={styles.modal}>
                <div ref={modalRef} style={styles.modalContent}>
                  <h2>Upload CSV File</h2>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv"
                    ref={fileInputRef}
                    style={{ display: 'none' }} // Hide the file input
                  />
                  <button onClick={handleUploadButtonClick} style={styles.button}>
                    Choose File
                  </button>
                  <button onClick={uploadCSV} style={styles.button}>
                    Upload
                  </button>
                  <p id="response" style={styles.response}>{response}</p>
                  {fileName && <p style={styles.fileName}>Selected file: {fileName}</p>}
                  <button onClick={handleClose} style={styles.closeButton}>
                    Close
                  </button>

                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredEnquiries.length === 0 && (
        <div className="error-message">No data available</div>
      )}
      <div className="space"></div>
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
              {hasPrivilege(userData, 'MARKETING', 'EQUIRY_MODIFY') && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((enquiry) => (
              <tr key={`${enquiry.id}-${enquiry.createdAt}`}>
                <td>{enquiry.name}</td>
                <td>{enquiry.email || '-'}</td>
                <td>{enquiry.number}</td>
                <td>{enquiry.area}</td>
                <td>{enquiry.academy}</td>
                <td>{enquiry.enquiry || '-'} </td>
                <td>{new Date(enquiry.date_at).toISOString().slice(0, 16).replace("T", " ")}</td>
                <td>{enquiry.source}</td>
                <td>{enquiry.status}</td>
                <td>{enquiry.query_message}</td>

                {hasPrivilege(userData, 'MARKETING', 'EQUIRY_MODIFY') && (
                  <td>
                    {
                      <button className="resolve-button" onClick={() => handleOpenModal(enquiry)}>
                        {enquiry.enquiry ? 'Update Remark' : 'Add Remark'}
                      </button>

                    }
                    <button className="resolved-button" onClick={() => handleSendMessage(enquiry)}>
                      Send Message
                    </button>
                  </td>

                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Update Enquiry Status"
        ariaHideApp={false}
        className="update-status-modal"
        overlayClassName="update-status-overlay"
      >
        <h2 className="modal-title">Update Status for {selectedEnquiry?.name}</h2>



        <div>
          <textarea
            placeholder="Add remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            className="remarks-textarea"
            required
          />
        </div>

        {/* Radio Buttons for Status */}
        <div className="status-radio-group">
          <h4 className='status-query'> Select Status:</h4>
          {["LEAD", "CALLED", "CONVERTED", "INTERESTED", "JUNK"].map((status) => (
            <label key={status}>
              <input
                type="radio"
                value={status}
                checked={resolvedStatus === status}
                onChange={(e) => setResolvedStatus(e.target.value)}
              />
              {status}
            </label>
          ))}
        </div>
        {/* Dropdown for Selecting Academy (at the top) */}
        <div className="academy-dropdown">
          <label>
            <span className='status-query'>Select Academy:</span>
            <select
              value={selectedAcademy}
              onChange={(e) => setSelectedAcademy(e.target.value)}
              className="academy-select"
            >
              {academies.map((academy) => (
                <option key={academy.id} value={academy.id}>
                  {academy.name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="modal-actions">
          <button onClick={handleSaveStatus} className="save-button">Save</button>
          <button onClick={handleCloseModal} className="cancel-button">Cancel</button>
        </div>
      </Modal>

      {/* Pagination */}
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  button: {
    padding: '10px 20px',
    margin: '10px',
    cursor: 'pointer',
  },
  response: {
    marginTop: '20px',
    fontSize: '14px',
    color: 'green',
  },
  fileName: {
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '10px',
    textAlign: 'center',
    width: '400px',
  },
  closeButton: {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};


export default Enquiries;