import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Payment/dues.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import Select from 'react-select';
import SettlePaymentModal from './SettlePayment';

const DuesList = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [dueWithFilter, setDueWithFilter] = useState(null);
  const [playerFilter, setPlayerFilter] = useState(null);
  const [batchFilter, setBatchFilter] = useState(null);
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const { selectedCenter, setSelectedCenter } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedDueId, setSelectedDueId] = useState(null);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [dues, setDues] = useState([]);

  useEffect(() => {
    const today = new Date();
    const fromDate = new Date(today);
    fromDate.setDate(today.getDate() - 7);
    const formatDate = (date) => date.toISOString().split('T')[0];
    setFromDateFilter(formatDate(fromDate));
    setToDateFilter(formatDate(today));
  }, []);

  useEffect(() => {
    if (!selectedCenter || loading) return;

    const apiUrl = `http://localhost:8082/api/academy/${selectedCenter}/payment-dues`;
    const headers = {
      'Content-Type': 'application/json',
      'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
    };

    const fetchDues = async () => {
      setLoading(true);
      try {
        const payload = {
          // dueWithIn: dueWithFilter ? dueWithFilter.value : "week",
          fromDate: fromDateFilter,
          toDate: toDateFilter
        };
        console.log(payload)
        const response = await axios.post(apiUrl, payload, { headers });
        const result = response.data;

        if (result.success) {

          const transformedDues = result.data.dueList.map(due => ({
            id: due.id,
            player: due.player?.user?.name || '-',
            mobile: due.player?.user?.mobile_number || '-',
            parentMobile: due.player?.parentPlayers?.[0]?.parentUser?.mobile_number || '-',
            batch: due?.batch?.name,
            amount: due?.amount,
            dueOn: due?.dueFrom,
            term: due.batch.batchType,
            startDate: due?.dueFrom,
            endDate: due?.dueEndDate,
            totalDues: response.data.sumOfDues,

          }));

          console.log("Selected center:", selectedCenter);
          console.log("Response from dues:", result);

          setDues(transformedDues);
        } else {
          console.error('Failed to fetch dues:', result.success_message);
          setError('Failed to load data: ' + result.success_message);
        }
      } catch (error) {
        console.error('Error fetching dues:', error);
        setError('Error fetching data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDues();
  }, [selectedCenter, fromDateFilter, toDateFilter]);

  const headers = [
    { label: "Player", key: "player" },
    { label: "Mobile", key: "mobile" },
    { label: "Parent Mobile", key: "parentMobile" },
    { label: "Batch", key: "batch" },
    { label: "Amount", key: "amount" },
    { label: "Due On", key: "dueOn" },
    { label: "Term", key: "term" },
  ];

  const [dropdownVisible, setDropdownVisible] = useState(null);
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleDropdown = (id) => {
    setDropdownVisible((prev) => (prev === id ? null : id));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSettlePayment = (dueId) => {
    setSelectedDueId(dueId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDueId(null);
  };


  const playersList = [...new Set(dues.map((due) => due.player))];
  const batchesList = [...new Set(dues.map((due) => due.batch))];

  const handleDueWithFilterChange = (selectedOption) => {
    setDueWithFilter(selectedOption);
  };

  const handlePlayerFilterChange = (selectedOption) => {
    setPlayerFilter(selectedOption);
  };

  const handleBatchFilterChange = (selectedOption) => {
    setBatchFilter(selectedOption);
  };

  const handleFromDateChange = (e) => {
    setFromDateFilter(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDateFilter(e.target.value);
  };

  const dueWithOptions = [
    { value: 'Week', label: 'Week' },
    { value: 'Month', label: 'Month' },
    { value: 'Year', label: 'Year' },
  ];

  const playerOptions = playersList.map(player => ({
    value: player,
    label: player,
  }));

  const batchOptions = batchesList.map(batch => ({
    value: batch,
    label: batch,
  }));

  const filteredDues = dues.filter((due) => {

    const matchesPlayer = !playerFilter || due.player === playerFilter.value;
    const matchesBatch = !batchFilter || due.batch === batchFilter.value;
    const matchesFromDate = !fromDateFilter || new Date(due.dueOn) >= new Date(fromDateFilter);
    const matchesToDate = !toDateFilter || new Date(due.dueOn) <= new Date(toDateFilter);

    const matchesSearchTerm =
      !searchTerm ||
      due.player.toLowerCase().includes(searchTerm.toLowerCase()) ||
      due.mobile.includes(searchTerm) ||
      due.batch.toLowerCase().includes(searchTerm.toLowerCase()) ||
      due.amount.toString().includes(searchTerm);

    return (
      matchesPlayer &&
      matchesBatch &&
      matchesFromDate &&
      matchesToDate &&
      matchesSearchTerm
    );
  });

  const totalDues = Math.round(dues.reduce((acc, due) => acc + due.amount, 0));

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-dues');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.three-dots-btn')) {
        setDropdownVisible(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const sortedData = sortData(filteredDues);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredDues.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="dues-list-container">
      <div className="sales-filters-login">
        {/* <div className="floating-select">
          <Select
            options={dueWithOptions}
            value={dueWithFilter}
            onChange={handleDueWithFilterChange}
            placeholder="Due With in"
            className={`sales-filter-court-booking ${dueWithFilter ? "has-value" : ""}`}
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
            isClearable
          />
          {dueWithFilter && <label htmlFor="dueWithFilter">Due With in</label>}

        </div> */}

        <div className="floating-select">
          <Select
            options={playerOptions}
            value={playerFilter}
            onChange={handlePlayerFilterChange}
            placeholder="Player"
            className={`sales-filter-court-booking ${playerFilter ? "has-value" : ""}`}
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
            isClearable
          />
          {playerFilter && <label htmlFor="playerFilter">Player</label>}
        </div>

        <div className="floating-select">
          <Select
            options={batchOptions}
            value={batchFilter}
            onChange={handleBatchFilterChange}
            placeholder="Batch"
            className={`sales-filter-court-booking ${batchFilter ? "has-value" : ""}`}
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
            isClearable
          />
          {batchFilter && <label htmlFor="batchFilter">Batch</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${fromDateFilter ? "has-value" : ""}`}
            value={fromDateFilter}
            onChange={handleFromDateChange}
            onFocus={(e) => e.target.showPicker()}
          />
          {fromDateFilter && <label htmlFor="fromDateFilter">From Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${toDateFilter ? "has-value" : ""}`}
            value={toDateFilter}
            onChange={handleToDateChange}
            onFocus={(e) => e.target.showPicker()}
          />
          {toDateFilter && <label htmlFor="toDateFilter">To Date</label>}
        </div>

      </div>


      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>

        <CSVLink
          data={filteredDues}
          headers={headers}
          filename="RewardDuesCoach.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <div className="items-and-dues">
        <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
          <option value={10}>10 items per page</option>
          <option value={50}>50 items per page</option>
          <option value={75}>75 items per page</option>
          <option value={100}>100 items per page</option>
        </select>
        <div className="total-dues">
          <strong>Total Dues: ₹{totalDues}</strong>
        </div>
      </div>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredDues.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="dues-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}

              {hasPrivilege(userData, 'PAYMENT','PAYMENT_DUES_MODIFY') && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((due) => (
              <tr key={due.id}>
                <td>{due.player}</td>
                <td>{due.mobile}</td>
                <td>{due.parentMobile}</td>
                <td>{due.batch}</td>
                <td>{due.amount}</td>
                <td>{due.dueOn}</td>
                <td>
                  {new Date(due.startDate).toLocaleDateString()} :{' '}
                  {new Date(due.endDate).toLocaleDateString()}
                </td>
                {hasPrivilege(userData, 'PAYMENT','PAYMENT_DUES_MODIFY') && (
                  <td className="sales-pdf-cell">
                    <button onClick={() => handleSettlePayment(due.id)} className="pdf-button">
                      Settle Payment
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <SettlePaymentModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        dueId={selectedDueId} 
      />
    </div>
  );
};

export default DuesList;
