import React, { useState, useEffect } from 'react';
import './Challenge.css';
import { CSVLink } from 'react-csv';
import { useUser } from '../context/UserContext';
import Select from 'react-select';
const PlayTrailReport = () => {
  const { selectedCenter, setSelectedCenter, userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const getFormattedDate = (date) => date.toISOString().split('T')[0];
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const [fromDate, setFromDate] = useState(getFormattedDate(tenDaysAgo));
  const [toDate, setToDate] = useState(getFormattedDate(today));
  const [PlayTrail, setPlayTrail] = useState([]);
  const [academyMap, setAcademyMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedCenter) {
      setSelectedAcademy(selectedCenter);
    }
  }, [selectedCenter]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAcademy === null || selectedAcademy === undefined) {
        setPlayTrail([]);
        setError("Please select an academy.");
        return;
      }

      setLoading(true);

      try {
        let apiUrl = "https://admin.stage.machaxi.com/api/academy/play-trials";
        if (userData?.userType === "ACADEMY" && userData.academyIds?.length > 0) {
          const academyIdsString = userData.academyIds.join(",");
          apiUrl += `?academyIds=${academyIdsString}`;
        }
        console.log(apiUrl)
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-authorization": process.env.REACT_APP_AUTH_TOKEN,
          },
          body: JSON.stringify({
            fromDate: fromDate,
            toDate: toDate,
            academyId: selectedAcademy || null,
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        setError("");

        if (data.success) {
          const academyMap = data.data.academyNameMap;
          setAcademyMap(academyMap);

          const bookings = data.data.bookings.map((booking) => ({
            name: booking.name,
            phone: booking.contact,
            court: booking.courtName,
            date: new Date(booking.bookingDate).toLocaleDateString(),
            time: booking.bookingTime,
          }));

          setPlayTrail(bookings);
        } else {
          setPlayTrail([]);
          setError("Failed to load data: " + data.success_message);
          console.error("Failed to fetch data:", data.success_message);
        }
      } catch (error) {
        setError("Error fetching data. Please try again.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedAcademy, fromDate, toDate]);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "Type", key: "type" },
    { label: "Date", key: "date" },
    { label: "Time", key: "time" },
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedAcademy, fromDate, toDate]);

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPlayTrail = PlayTrail.filter((play) => {
    const matchesSearch = Object.values(play)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());


    return matchesSearch;
  });

  const sortedPlayTrail = React.useMemo(() => {
    let sortableItems = [...filteredPlayTrail];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredPlayTrail, sortConfig]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedPlayTrail.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPlayTrail.length / rowsPerPage);

  const handleSort = (column) => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const renderPagination = () => {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
      <button
        key={page}
        className={`page-number ${currentPage === page ? 'active' : ''}`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    ));
  };

  const totalEntries = filteredPlayTrail.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  const academyOptions = Object.entries(academyMap)
    .filter(([academyId]) =>
      userData?.userType === "ACADEMY" ? userData.academyIds.includes(Number(academyId)) : true
    )
    .map(([value, label]) => ({
      value,
      label,
    }));

  return (
    <div className="challenges-container">
      <div className="sales-filters-login">

        <div className="floating-select">
          <Select
            id="selectedAcademy"
            className={`sales-filter-court-booking ${selectedAcademy ? "has-value" : ""}`}
            classNamePrefix="select"
            options={academyOptions}
            value={academyOptions.find(option => option.value === selectedAcademy)}
            onChange={(selectedOption) => {
              setSelectedAcademy(selectedOption ? selectedOption.value : '');
            }}
            placeholder="Select Academy"
            isClearable
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${fromDate ? "has-value" : ""}`}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {fromDate && <label htmlFor="fromDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${toDate ? "has-value" : ""}`}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {toDate && <label htmlFor="toDate">End Date</label>}
        </div>
      </div>
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredPlayTrail}
          headers={headers}
          filename="filtered_checkin_reports.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredPlayTrail.length === 0 && (
        <div className="error-message">No data available</div>
      )}

      <div>
        <table className="challenges-table">
          <thead>
            <tr className="challenges-header-row">
              <th onClick={() => handleSort('name')}>
                Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('phone')}>
                Phone {sortConfig.key === 'phone' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('court')}>
                Court {sortConfig.key === 'court' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('date')}>
                Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('time')}>
                Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>

          <tbody>
            {currentRows.map((play) => (
              <tr key={play.id} >
                <td>{play.name}</td>
                <td>{play.phone}</td>
                <td>{play.court}</td>
                <td>{play.date}</td>
                <td>{play.time}</td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlayTrailReport
