import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../coupons/Coupon.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { CSVLink } from 'react-csv';
import Modal from "react-modal";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Pie } from 'react-chartjs-2';
import Swal from 'sweetalert2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
const Summer = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(""); // State for status
  const today = new Date();
  const { selectedCenter, setSelectedCenter } = useUser();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const [academies, setAcademies] = useState([]); 
  const [startDate, setStartDate] = useState(tenDaysAgo.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);

  const [selectedAcademy, setSelectedAcademy] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  useEffect(() => {

    if (selectedCenter) {
        // If a center is manually selected, use it
        setSelectedAcademy(selectedCenter);
    } else if (userData?.userType === "ACADEMY") {
        if (userData.academyIds?.length > 0) {

            // Find a valid academyId that exists in apiData
            const validAcademyId = userData.academyIds?.find((academyId) =>
            apiData.some((item) => Number(item.academy_id) === Number(academyId))
        );

            if (validAcademyId) {
              setSelectedAcademy("allAcademies");
            } else {
                setSelectedAcademy(""); // Reset if no valid academyId is found
            }
        } else {
            setSelectedAcademy(""); // Reset if no academyIds are available
        }
    } else {
        setSelectedAcademy(""); // Default case if user is not of type 'ACADEMY'
    }

    // Cleanup function
    return () => {
        setSelectedAcademy(""); // Reset the selected academy when the component unmounts or dependencies change
    };
}, [userData, selectedCenter, apiData]);


  const headers = [
    { label: "Name", key: "user_name" },
    { label: "Mobile Number", key: "mobile_number" },
    { label: "Academy Name", key: "academy_name" },
    { label: "Sport Name", key: "sport_name" },
    { label: "Date", key: "created_at" },
    { label: "Status", key: "status" },
    { label: "Remarks", key: "remarks" },
  ];

  // Fetch data
  useEffect(() => {
    setLoading(true);
    setError(null);

    fetch(process.env.REACT_APP_API_PURCHASE, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          const fetchedData = data.data.dropOffLeads || [];
          const fetchedAcademies = data.data.academies || []; 
          setApiData(fetchedData);
          setFilteredData(fetchedData);
          setAcademies(fetchedAcademies); 
        } else {
          throw new Error('API response unsuccessful');
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Filtering logic
  useEffect(() => {
    let updatedData = apiData.filter((item) => {
      const itemCreatedAt = item.created_at ? new Date(item.created_at) : new Date();

      const matchesSearch = searchTerm
        ? Object.values(item).join(' ').toLowerCase().includes(searchTerm.toLowerCase())
        : true;
      const adjustedEndDate = endDate ? new Date(new Date(endDate).setHours(23, 59, 59, 999)) : null;
  
      const matchesStartDate = startDate ? itemCreatedAt >= new Date(startDate) : true;
      const matchesEndDate = adjustedEndDate ? itemCreatedAt <= adjustedEndDate : true;
  
      // Handle the case where selectedAcademy is "allAcademies"
      const matchesAcademy = selectedAcademy === "allAcademies"
        ? userData.academyIds.includes(Number(item.academy_id)) // Include all academies associated with the user
        : selectedAcademy
        ? Number(item.academy_id) === Number(selectedAcademy)
        : true;
  
      const matchesSport = selectedSport ? item.sport_name === selectedSport : true;
  
      return matchesSearch && matchesStartDate && matchesEndDate && matchesAcademy && matchesSport;
    });
  
    setFilteredData(updatedData);
    setCurrentPage(1);
  }, [searchTerm, startDate, endDate, selectedAcademy, selectedSport, apiData, userData.academyIds]);
  

  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });
  const handleSort = (columnKey) => {
    let direction = 'ascending';

    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;

    if (!key) return data;

    const sorted = [...data].sort((a, b) => {
      if (key === 'created_at') {
        const aDate = new Date(a[key]);
        const bDate = new Date(b[key]);
        return direction === 'ascending' ? aDate - bDate : bDate - aDate;
      }

      const aValue = a[key];
      const bValue = b[key];

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    return sorted;
  };

  const sortedData = sortData(filteredData);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [remarks, setRemarks] = useState("");

  const openModal = (item) => {
    setSelectedItem(item);
    setRemarks(item.remarks || '');
    const normalizedStatus = item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase() : '';
    setStatus(normalizedStatus);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
    setRemarks("");
    setStatus("");
  };
  const handleSaveRemarks = async () => {
    if (remarks.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Remark cannot be empty!',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    
    if (status.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please select a status!',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    

    const upperCaseStatus = status.toUpperCase();
    const updatedItem = {
      ...selectedItem,
      remarks: remarks, // Ensure remarks are included
      status: upperCaseStatus
    };

    const payload = {
      status: upperCaseStatus,
      remarks: remarks,
    };

    try {
      const response = await fetch(`https://admin.machaxi.com/api/summer-camp-purchase/update/${selectedItem.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
        body: JSON.stringify(payload),
      });

      const responseBody = await response.json(); 

      if (response.ok && responseBody.success) {
        // Handle successful response
        const data = responseBody.data || {}; 

        setApiData((prevData) =>
          prevData.map((item) =>
            item.id === selectedItem.id ? updatedItem : item
          )
        );

        setFilteredData((prevData) =>
          prevData.map((item) =>
            item.id === selectedItem.id ? updatedItem : item
          )
        );

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Remark updated successfully!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });        

        closeModal();
      } else {
        throw new Error('Failed to update the item.');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error while updating the remark.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });      
    }
  };


  const filterChartData = (data) => {
    return {
      labels: data.filter(item => item.value !== 0).map(item => item.label),
      datasets: [
        {
          data: data.filter(item => item.value !== 0).map(item => item.value),
          backgroundColor: data.filter(item => item.value !== 0).map(item => item.color),
          hoverOffset: 4
        }
      ]
    };
  };
  // State for pie chart data
  const [pieData, setPieData] = useState({
    badminton: { Called: 0, Interested: 0, Lead:0,Junk: 0, Converted: 0 },
    swimming: { Called: 0, Interested: 0,Lead:0, Junk: 0, Converted: 0 }
  });

  useEffect(() => {
    // Pie chart logic
    const updatePieData = () => {
      const updatedPieData = {
        badminton: { Called: 0, Interested: 0,Lead:0,Junk: 0, Converted: 0 },
        swimming: { Called: 0, Interested: 0,Lead:0,  Junk: 0,Converted: 0 },
      };

      filteredData.forEach((item) => {
        const status = item.status ? item.status.toLowerCase() : '';
        const sport = item.sport_name ? item.sport_name.toLowerCase() : '';

        // Count status for Badminton
        if (sport === 'badminton') {
          if (status === 'called') updatedPieData.badminton.Called += 1;
        
          else if (status === 'interested') updatedPieData.badminton.Interested += 1;
          else if (status === 'lead') updatedPieData.badminton.Lead += 1;
          else if (status === 'converted') updatedPieData.badminton.Converted += 1;
          else if (status === 'junk') updatedPieData.badminton.Junk += 1;
        }

        // Count status for Swimming
        if (sport === 'swimming') {
          if (status === 'called') updatedPieData.swimming.Called += 1;
       
          else if (status === 'interested') updatedPieData.swimming.Interested += 1;
          else if (status === 'lead') updatedPieData.swimming.Lead += 1;
          else if (status === 'converted') updatedPieData.swimming.Converted += 1;
          else if (status === 'junk') updatedPieData.swimming.Junk += 1;
        }
      });

      setPieData(updatedPieData);
    };

    updatePieData();
  }, [filteredData]);
  // Chart data for Badminton
  const badmintonChartData = filterChartData([
    { label: 'Called', value: pieData.badminton.Called, color: '#36A2EB' },
    { label: 'Interested', value: pieData.badminton.Interested, color: '#FFCE56' },
    { label: 'Lead', value: pieData.badminton.Lead, color: '#FF9F40' },
    { label: 'Converted', value: pieData.badminton.Converted, color: '#4BC0C0' },
    { label: 'Junk', value: pieData.badminton.Junk, color: '#E74C3C' }, // Changed to a red shade
  ]);
  

  const swimmingChartData = filterChartData([
    { label: 'Called', value: pieData.swimming.Called, color: '#36A2EB'},
    { label: 'Interested', value: pieData.swimming.Interested, color: '#FFCE56'},
    { label: 'Lead', value:pieData.swimming.Lead, color: '#FF9F40' },
    { label: 'Converted', value: pieData.swimming.Converted, color: '#4BC0C0'},
    { label: 'Junk', value: pieData.swimming.Junk, color: '#E74C3C'},
  ]);

  const totalEntries = filteredData.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="coupon-list-container">
      {/* Filters Section */}
      <div className="filter-container-summer">
        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial Start Date"
            name="trialStartDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${startDate ? "has-value" : ""}`}
          />
          {startDate && <label htmlFor="trialStartDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial End Date"
            name="trialEndDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${endDate ? "has-value" : ""}`}
          />
          {endDate && <label htmlFor="trialEndDate">End Date</label>}
        </div>

        <div className="floating-select">
  <select
    name="academy"
    value={selectedAcademy}
    onChange={(e) => setSelectedAcademy(e.target.value)}
    className={`sales-filter-academy ${selectedAcademy ? "has-value" : ""}`}
  >
    {userData.userType === "ACADEMY" ? (
      <>
        <option value="allAcademies">All Academies</option>
        {userData.academyIds.map((academyId) => {
          const academy = academies.find((item) => item.id === academyId);
          return academy ? (
            <option key={academyId} value={academy.id}>
              {academy.name}
            </option>
          ) : (
            null
          );
        })}
      </>
    ) : (
      <>
        <option value="">All Academies</option>
        {academies.map((academy) => (
          <option key={academy.id} value={academy.id}>
            {academy.name}
          </option>
        ))}
      </>
    )}
  </select>
  {selectedAcademy && <label htmlFor="academy">Academy</label>}
</div>



        <div className="floating-select">
          <select
            name="sport"
            value={selectedSport}
            onChange={(e) => setSelectedSport(e.target.value)}
            className={`sales-filter-academy ${selectedSport ? "has-value" : ""}`}
          >
            <option value="">All Sports</option>
            {[...new Set(apiData.map((item) => item.sport_name))].map((sportName) => (
              <option key={sportName} value={sportName}>
                {sportName}
              </option>
            ))}
          </select>
          {selectedSport && <label htmlFor="sport">Sport</label>}
        </div>
      </div>
      <div className="unique-charts-container">
      {(!selectedSport || selectedSport.toLowerCase() === 'badminton') && 
  (pieData.badminton.Called > 0 || pieData.badminton.Interested > 0 || pieData.badminton.Lead > 0 || pieData.badminton.Junk > 0 || pieData.badminton.Converted > 0) && (
   <div className="unique-chart-dropoffs">
      <h3 className="pie-title">Badminton Status</h3>
      <Pie data={badmintonChartData} />
    </div>
)}

{(!selectedSport || selectedSport.toLowerCase() === 'swimming') && 
  (pieData.swimming.Called > 0 || pieData.swimming.Interested > 0 || pieData.swimming.Lead > 0 || pieData.swimming.Junk > 0 || pieData.swimming.Converted > 0) && (
    <div className="unique-chart-dropoffs">
      <h3 className="pie-title">Swimming Status</h3>
      <Pie data={swimmingChartData} />
    </div>
)}
</div>

      <div className="search-create-container">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredData}
          headers={headers}
          filename="SummerDropoffs.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredData.length === 0 && (
        <div className="error-message">No data available</div>
      )}
      {/* Coupon Table */}
      <div style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="coupon-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
              {hasPrivilege(userData,'MARKETING', 'DROPOFFS_OF_SUMMER_CAMP_MODIFY') && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((item) => (
              <tr key={item.id}>
                <td>{item.user_name}</td>
                <td>{item.mobile_number}</td>
                <td>{item.academy_name}</td>
                <td>{item.sport_name}</td>
                <td>{new Date(item.created_at).toISOString().slice(0, 16).replace("T", " ")}</td>
                <td>{item.status || 'No status'}</td> {/* Display status or a default message */}

                <td>
                  {item.remarks ? item.remarks : '-'}
                </td>
                {hasPrivilege(userData, 'MARKETING','DROPOFFS_OF_SUMMER_CAMP_MODIFY') && (
                  <td className="border p-2 text-center relative">
                    <div className="inline-block">
                      <button
                        onClick={() => openModal(item)} // Open the modal to add or update remark
                        className="resolve-button"
                      >
                        {item.remarks ? 'Update Remark' : 'Add Remark'}
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal__Content rounded-lg bg-white p-6 shadow-lg transition-all transform scale-95 hover:scale-100 duration-300"
        overlayClassName="Modal__Overlay fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          {remarks ? "Update Remark" : "Add Remark"}
        </h2>

        <div className="mb-4">
          <textarea
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Enter remarks..."
            rows="4"
          />
        </div>

        <div className="mb-6">
          <label className="block font-semibold text-gray-700 mb-2">Status</label>
          <div className="flex space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="status"
                value="Called"
                checked={status === 'Called'} // Ensure this matches the status state
                onChange={(e) => setStatus(e.target.value)}
                className="mr-2"
              />
              Called
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="status"
                value="Interested"
                checked={status === 'Interested'} // Ensure this matches the status state
                onChange={(e) => setStatus(e.target.value)}
                className="mr-2"
              />
              Interested
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="status"
                value="Junk"
                checked={status === 'Junk'} // Ensure this matches the status state
                onChange={(e) => setStatus(e.target.value)}
                className="mr-2"
              />
              Junk
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="status"
                value="Converted"
                checked={status === 'Converted'} // Ensure this matches the status state
                onChange={(e) => setStatus(e.target.value)}
                className="mr-2"
              />
              Converted
            </label>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="cancel-button"
           
          >
            Cancel
          </button>

          <button
            onClick={handleSaveRemarks}
            className="save-button"
          >
            {remarks ? "Update" : "Save"}
          </button>
        </div>
      </Modal>
      <div className="pagination">
  <div className="showing-entries">
    Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
  </div>
  <button
    className="prev-button"
    onClick={handlePrevPage}
    disabled={currentPage === 1}
  >
    Previous
  </button>
  {renderPagination()}
  <button
    className="next-button"
    onClick={handleNextPage}
    disabled={currentPage === totalPages}
  >
    Next
  </button>
</div>
    </div>
  );
};

export default Summer;