import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import './BatchList.css';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const BatchList = () => {
  const { userData } = useUser();
  const location = useLocation();
  const academyId = location.state?.academyId;
  const { selectedCenter, setSelectedCenter } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { batchId } = location.state || {};
  useEffect(() => {
    const centerToUse = academyId ?? selectedCenter;

    if (centerToUse) {
      const fetchBatchesData = async () => {
        try {
          const response = await fetch(
            `http://localhost:8082/api/academy/${centerToUse}/batches`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // If authentication is needed
              },
            }
          );

          const result = await response.json();

          if (result.success) {
            const fetchedBatches = result.data.batches;
            const fetchedBatchesStrength = result.data.batchesStrength;

            const updatedBatches = fetchedBatches.map((batch) => {
              const batchDetails = {
                id: batch.id,
                name: batch.name || 'Unnamed Batch',
                proficiency: batch.proficiencyType || 'Unknown',
                capacity: batch.numberOfPlayers,
                currentStrength: fetchedBatchesStrength[batch.id] || 0,
                monthlyFees: batch.monthlyFees || 0,
              };
              return batchDetails;
            });

            setBatches(updatedBatches);
          }
        } catch (error) {
          console.error('Error fetching batches data:', error);
          setError('Error fetching data: ' + error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchBatchesData();
    }
  }, [selectedCenter]);
  const headers = [
    { label: "Name", key: "name" },
    { label: "Proficiency", key: "proficiency" },
    { label: "Capacity", key: "capacity" },
    { label: "Current Strength", key: "currentStrength" },
    { label: "Monthly Fees", key: "monthlyFees" },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ name: '', proficiency: '', capacity: '', currentStrength: '', monthlyFees: '' });
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const toggleDropdown = (playerId) => {
    setDropdownOpen(dropdownOpen === playerId ? null : playerId);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewBatch = () => {
    navigate('/createNewBatch');
  };
  const hanldepayment = (batchId) => {
    navigate('/playerpaymentDetail', { state: { batchId } });
  }
  const hanldecoach = () => {
    navigate('/academyCoachList');
  }
  const handleplayer = (batchId) => {
    navigate('/batchPlayerList', { state: { batchId } });
  }
  const handleEditBatch = (batchId) => {
    navigate('/createNewBatch', { state: { batchId } });
  };

  const handleDisableBatch = async (batchId) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, disable it!',
      cancelButtonText: 'No, cancel!',
    });

    if (!confirmResult.isConfirmed) {
      return; // Do nothing if the user cancels
    }

    try {
      const response = await fetch(`http://localhost:8082/api/react/batch/disable/${batchId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
      });

      const result = await response.json();
      console.log('Response:', result);

      if (response.ok && result.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Batch Disabled successfully!",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        const errorMessage = result.data?.errorMessage || result.success_message || 'Failed to disable batch';
        console.error('Error:', errorMessage);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error('Network or Server Error:', error);
    }
  };


  const filteredBatches = batches.filter((batch) =>
    Object.values(batch)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(filteredBatches);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-player');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.dots-btn-player')) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleBatchClick = (batch) => {
    navigate(`/academyPlayerList`, { state: { batchId: batch.id } });
    console.log("The batch ID for details:", batch.id);
  };

  const totalEntries = filteredBatches.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="batch-container">

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handleNewBatch}>
          + Create Batch
        </button>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredBatches.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}
              {hasPrivilege(userData, 'COACHING', 'BATCH_LIST_MODIFY') && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((batch) => (
              <tr key={batch.id}>
                <td>
                  <span
                    onClick={() => handleBatchClick(batch)}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'none' }}
                  >
                    {batch.name}
                  </span>
                </td>
                <td>{batch.proficiency}</td>
                <td>{batch.capacity}</td>
                <td>{batch.currentStrength}</td>
                <td>{batch.monthlyFees}</td>
                {hasPrivilege(userData, 'COACHING', 'BATCH_LIST_MODIFY') && (
                  <td className="action-buttons-player">
                    <button
                      className="dots-btn-player"
                      onClick={() => toggleDropdown(batch.id)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "24px",
                      }}
                    >
                      &#x22EE; {/* Unicode for vertical ellipsis (three dots) */}
                    </button>
                    {dropdownOpen === batch.id && (
                      <div className="dropdown-menu-player">
                        <button className="dropdown-item" onClick={() => handleplayer(batch.id)}>Player List</button>
                        <button className="dropdown-item" onClick={() => handleEditBatch(batch.id)}>Edit</button>
                        <button className="dropdown-item" onClick={() => handleDisableBatch(batch.id)}>Disable</button>
                        <button className="dropdown-item" onClick={() => hanldepayment(batch.id)}>Batch Player Payment Details</button>
                      </div>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BatchList;
