import React, { useState, useRef } from 'react';
import './LandingPageCustomization.css';

const LandingPageCustomization = () => {
  const [selectedPage, setSelectedPage] = useState('Page 1');
  const [images, setImages] = useState({
    'Page 1': {
      heroImage: 'https://via.placeholder.com/1200x400?text=Hero+Image+1',
      offerImage: 'https://via.placeholder.com/400x200?text=Offer+Banner+1',
      bannerImage: 'https://via.placeholder.com/400x100?text=Landing+Banner+1',
    },
    'Page 2': {
      heroImage: 'https://via.placeholder.com/1200x400?text=Hero+Image+2',
      offerImage: 'https://via.placeholder.com/400x200?text=Offer+Banner+2',
      bannerImage: 'https://via.placeholder.com/400x100?text=Landing+Banner+2',
    },
    'Page 3': {
      heroImage: 'https://via.placeholder.com/1200x400?text=Hero+Image+3',
      offerImage: 'https://via.placeholder.com/400x200?text=Offer+Banner+3',
      bannerImage: 'https://via.placeholder.com/400x100?text=Landing+Banner+3',
    }
  });
  const [selectedImages, setSelectedImages] = useState({
    heroImage: null,
    offerImage: null,
    bannerImage: null,
  });
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  
  const fileInputRef = useRef(null);

  const handlePageSelect = (event) => {
    setSelectedPage(event.target.value);
  };

  const triggerFileInput = (imageType) => {
    fileInputRef.current.setAttribute('data-image-type', imageType);
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const imageType = event.target.getAttribute('data-image-type');
    const file = event.target.files[0];

    if (file) {
      const updatedImages = { ...images };
      const reader = new FileReader();
      
      reader.onloadend = () => {
        updatedImages[selectedPage][imageType] = reader.result;
        setImages(updatedImages);
        setSelectedImages((prevState) => ({
          ...prevState,
          [imageType]: reader.result,
        }));

        // Show the success alert
        setAlertMessage('Image updated successfully!');
        setAlertVisible(true);

        // Hide the alert after 3 seconds
        setTimeout(() => {
          setAlertVisible(false);
        }, 3000);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImage = (imageType) => {
    // Show the success alert for the updated image without opening the file input
    setAlertMessage(`${imageType} updated successfully!`);
    setAlertVisible(true);

    // Hide the alert after 3 seconds
    setTimeout(() => {
      setAlertVisible(false);
    }, 3000);
  };

  const getButtonText = (imageType) => {
    return selectedImages[imageType] ? 'Update Image' : 'Choose Image';
  };

  return (
    <div className="landing-page-wrapper">
      {alertVisible && (
        <div className="alert-success">
          <span className="alert-message">{alertMessage}</span>
          <button className="alert-close-btn" onClick={() => setAlertVisible(false)}>
            ×
          </button>
        </div>
      )}

      <div className="landing-page-container">
        <label htmlFor="landing-page-select" className="dropdown-label">Select Landing Page:</label>
        <select 
          id="landing-page-select" 
          value={selectedPage} 
          onChange={handlePageSelect}
          className="landing-page-select"
        >
          {Object.keys(images).map((page) => (
            <option key={page} value={page}>{page}</option>
          ))}
        </select>
      </div>

      <div className="image-customization-container">
        <div className="image-card">
          <h3 className="image-title">Hero Image</h3>
          <img src={images[selectedPage].heroImage} alt="Hero" className="image-display" />
          {selectedImages.heroImage ? (
            <button onClick={() => handleUpdateImage('Hero Image')} className="update-image-btn">
              Update Image
            </button>
          ) : (
            <button onClick={() => triggerFileInput('heroImage')} className="update-image-btn">
              Choose Image
            </button>
          )}
        </div>

        <div className="image-card">
          <h3 className="image-title">Offer Banner</h3>
          <img src={images[selectedPage].offerImage} alt="Offer Banner" className="image-display" />
          {selectedImages.offerImage ? (
            <button onClick={() => handleUpdateImage('Offer Banner')} className="update-image-btn">
              Update Image
            </button>
          ) : (
            <button onClick={() => triggerFileInput('offerImage')} className="update-image-btn">
              Choose Image
            </button>
          )}
        </div>

        <div className="image-card">
          <h3 className="image-title">Landing Banner</h3>
          <img src={images[selectedPage].bannerImage} alt="Landing Banner" className="image-display" />
          {selectedImages.bannerImage ? (
            <button onClick={() => handleUpdateImage('Landing Banner')} className="update-image-btn">
              Update Image
            </button>
          ) : (
            <button onClick={() => triggerFileInput('bannerImage')} className="update-image-btn">
              Choose Image
            </button>
          )}
        </div>
      </div>

      {/* Hidden file input */}
      <input 
        type="file" 
        accept="image/*" 
        ref={fileInputRef} 
        style={{ display: 'none' }} 
        onChange={handleImageChange} 
      />
    </div>
  );
};

export default LandingPageCustomization;
