import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './Dashboard.css';
import { useUser } from '../context/UserContext';
import { CSVLink } from 'react-csv';
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const Dashboard = () => {
  const [centreFilter, setCentreFilter] = useState('');
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const [startDateFilter, setStartDateFilter] =useState(tenDaysAgo.toISOString().split('T')[0]);
  const [endDateFilter, setEndDateFilter] = useState(today.toISOString().split('T')[0]);
  const [statusFilter, setStatusFilter] = useState('');
  const [typeOfLeadFilter, setTypeOfLeadFilter] = useState('');
  const [productSportsFilter, setProductSportsFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { userData } = useUser();
  const { selectedCenter, setSelectedCenter } = useUser();
  const [allAcademies, setAllAcademies] = useState([]);
  const [leadStatusFilter, setLeadStatusFilter] = useState('');
  useEffect(() => {
    if (selectedCenter) {
      // If a center is manually selected, use it
      setCentreFilter(selectedCenter);
    } else if (userData?.userType === 'ACADEMY' && userData.academyId) {
      // If user is an academy type and has an academyId, use that
      setCentreFilter("allAcademies");
    } else {
      // Default case if neither is available
      setCentreFilter(null); // or some default value
    }
  }, [userData, selectedCenter]);

  const handleLeadStatusChange = (e) => setLeadStatusFilter(e.target.value);
  const handleCentreChange = (e) => setCentreFilter(e.target.value);
  const handleStartDateChange = (e) => setStartDateFilter(e.target.value);
  const handleEndDateChange = (e) => setEndDateFilter(e.target.value);
  const handleStatusChange = (e) => setStatusFilter(e.target.value);
  const handleTypeOfLeadChange = (e) => setTypeOfLeadFilter(e.target.value);
  const handleProductSportsChange = (e) => setProductSportsFilter(e.target.value);

  // Handler for search term
  const handleSearch = (e) => setSearchTerm(e.target.value);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    fetch("https://admin.stage.machaxi.com/api/trial/view", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data.apiData && data.data.academies) {
          setAllAcademies(data.data.academies);
          const academiesMap = new Map(
            data.data.academies.map((academy) => [academy.id, academy.name])
          );
          const updatedData = data.data.apiData.map((item) => ({
            centreId: item.academyId, // Include the centre ID
            centre: academiesMap.get(item.academyId) || "Unknown Academy",
            date: item.createdAt,
            status: item.leadStatus,
            newStatus:item.status,
            typeOfLead: item.source,
            productSports: item.sportId === 11 ? "Swimming" : "Badminton",
            name: item?.name,
            mobileNumber: item.mobileNumber,
            trialdate: item.trialDate,
            createdAt: item.createdAt,
            trialTime: `${item.startTime} - ${item.endTime}`,
          }));
          setTableData(updatedData);
        } else {
          setError("Invalid API response structure");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data: " + error.message);
        setLoading(false);
      });
  }, []);

  const filterData = (data) => {
    const searchString = searchTerm?.toLowerCase() ?? ''; // Handle null searchTerm

    return data.filter((row) => {
      const rowDate = new Date(row.date);

      // Ensure both row.centreId and centreFilter are strings and convert to lowercase for comparison
      const centreMatch = !centreFilter ||
        (typeof row.centreId === 'number' && typeof centreFilter === 'string' &&
          String(row.centreId).trim().toLowerCase() === centreFilter.trim().toLowerCase());


      // Check if the row matches the date filters
      const startDateMatch = !startDateFilter || rowDate >= new Date(startDateFilter);
      const endDateMatch = !endDateFilter || rowDate <= new Date(endDateFilter);

      // Check if the row matches the status filter
      const statusMatch = !statusFilter || row.status?.toLowerCase().includes(statusFilter.toLowerCase());

      // Check if the row matches the type of lead filter
      const typeOfLeadMatch = !typeOfLeadFilter || row.typeOfLead?.toLowerCase().includes(typeOfLeadFilter.toLowerCase());

      // Check if the row matches the product sports filter
      const productSportsMatch = !productSportsFilter || row.productSports?.toLowerCase().includes(productSportsFilter.toLowerCase());

      // Check if the row matches the search term
      const searchMatch = (
        (row.centre?.toLowerCase() ?? '').includes(searchString) ||
        (row.date?.toLowerCase() ?? '').includes(searchString) ||
        (row.status?.toLowerCase() ?? '').includes(searchString) ||
        (row.typeOfLead?.toLowerCase() ?? '').includes(searchString) ||
        (row.productSports?.toLowerCase() ?? '').includes(searchString)
      );


      // Combine all conditions
      return (
        centreMatch &&
        startDateMatch &&
        endDateMatch &&
        statusMatch &&
        typeOfLeadMatch &&
        productSportsMatch &&
        searchMatch
      );
    });
  };


  // Helper function to get Combined Leads Data
 

  useEffect(() => {
    setCurrentPage(1);
  }, [
    centreFilter,
    startDateFilter,
    endDateFilter,
    statusFilter,
    typeOfLeadFilter,
    productSportsFilter,
    leadStatusFilter,
    searchTerm
  ]);
  
  const headers = [
    { label: "Centre", key: "centre" },
    { label: "Mobile Number", key: "mobile number" },
    { label: "Name", key: "name" },
    { label: "Trial Date", key: "date" },
    { label: "Trial Time", key: "time" },
    { label: "Lead Status", key: "status" },
    { label: "Landing Page", key: "typeOfLead" },
    { label: "Status", key: "newStatus" },
    { label: "Sports", key: "productSports" }
  ];

  // Update the filteredTableData's centre filter condition
  const filteredTableData = tableData.filter((row) => {
    const searchString = searchTerm.toLowerCase();
    const rowDate = new Date(row.date);
    const startDate = startDateFilter ? new Date(startDateFilter) : null;
    const endDate = endDateFilter ? new Date(endDateFilter) : null;

    const matchesAcademy = centreFilter === "allAcademies"
      ? userData.academyIds.includes(Number(row.centreId)) // If "All Academies", match against all academyIds of the user
      : centreFilter
        ? Number(row.centreId) === Number(centreFilter)
        : true;
  
    // Add lead status filter condition
    const leadStatusMatch = !leadStatusFilter || 
      (row.newStatus?.toLowerCase().includes(leadStatusFilter.toLowerCase()));
  
    return (
      matchesAcademy &&
      (startDate ? rowDate >= startDate : true) &&
      (endDate ? rowDate <= endDate : true) &&
      (statusFilter ? row.status?.includes(statusFilter) : true) &&
      (typeOfLeadFilter ? row.typeOfLead?.includes(typeOfLeadFilter) : true) &&
      (productSportsFilter ? row.productSports?.includes(productSportsFilter) : true) &&
      leadStatusMatch && // Include lead status filter
      (
        (row.centre?.toLowerCase() ?? '').includes(searchString) ||
        (row.status?.toLowerCase() ?? '').includes(searchString) ||
        (row.typeOfLead?.toLowerCase() ?? '').includes(searchString) ||
        (row.productSports?.toLowerCase() ?? '').includes(searchString) ||
        (row.date ? row.date.includes(searchString) : false)
      )
    );
  });


  const handleSort = (columnKey) => {
    let direction = 'ascending';

    // Toggle sort direction
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Update sortConfig
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;

    if (!key) return data; // No sorting applied if no key is selected

    return [...data].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };
  const sortedData = sortData(filteredTableData);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalEntries = filteredTableData.length;
const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  // Get unique filter values from table data
  const getUniqueValues = (key) => {
    return [...new Set(tableData.map((row) => row[key]))];
  };

  const prepareCombinedPieChartData = (data) => {
    const typeOfLeadCounts = {};

    // Calculate total counts for each typeOfLead
    data.forEach((row) => {
      if (row.typeOfLead) {
        typeOfLeadCounts[row.typeOfLead] = (typeOfLeadCounts[row.typeOfLead] || 0) + 1;
      }
    });

    return {
      labels: Object.keys(typeOfLeadCounts),
      datasets: [
        {
          data: Object.values(typeOfLeadCounts),
          backgroundColor: [
            '#FF6384', // Color 1
            '#36A2EB', // Color 2
            '#FFCE56', // Color 3
            '#4BC0C0', // Color 4
          ],
        },
      ],
    };
  };

  // Prepare data for the individual status pie charts
  const prepareStatusPieChartData = (data) => {
    const statusData = {};

    // Group data by status and then by typeOfLead
    data.forEach((row) => {
      if (!statusData[row.newStatus]) {
        statusData[row.newStatus] = {};
      }
      if (!statusData[row.newStatus][row.typeOfLead]) {
        statusData[row.newStatus][row.typeOfLead] = 0;
      }
      statusData[row.newStatus][row.typeOfLead] += 1;
    });

    // Format data for Chart.js
    const pieCharts = Object.keys(statusData).map((status) => {
      const typeOfLeadData = statusData[status];
      return {
        status,
        labels: Object.keys(typeOfLeadData),
        datasets: [
          {
            
            data: Object.values(typeOfLeadData),
            backgroundColor: [
              '#FF6384', // Color 1
              '#36A2EB', // Color 2
              '#FFCE56', // Color 3
              '#4BC0C0', // Color 4
            ],
          },
        ],
      };
    });

    return pieCharts;
  };

  const combinedPieChartData = prepareCombinedPieChartData(filteredTableData);
  const statusPieChartsData = prepareStatusPieChartData(filteredTableData);

  return (
    <div className="payment-list-container">
     <div className="sales-filters-leadManagement">

        <div className="floating-select">
          <select
            name="academy"
            value={centreFilter} // Ensure the selectedAcademy state is reflected in the dropdown
            onChange={(e) => setCentreFilter(e.target.value)} // Update state when user changes selection
            className={`sales-filter-academy ${centreFilter ? "has-value" : ""}`}
          >
            {userData?.userType === "ACADEMY" ? (
              <>
                <option value="allAcademies">All Academies</option>
                {userData.academyIds.map((academyId) => {
                  const academy = allAcademies.find((item) => item.id === academyId); // Get academies based on academyIds
                  if (academy) {
                    return (
                      <option key={academyId} value={academyId}>
                        {academy.name}
                      </option>
                    );
                  }
                  return null; // Skip academies without matching data
                })}
              </>
            ) : (
              // If the user is not an academy, show all academies
              <>
                <option value="">All Academies</option>
                {allAcademies.map((academy) => (
                  <option key={academy.id} value={academy.id}>
                    {academy.name}
                  </option>
                ))}
              </>
            )}
          </select>
          {centreFilter && <label htmlFor="academy">Academy</label>}
        </div>

        <div className="floating-select">
          <select
            className={`sales-filter-academy ${statusFilter ? 'has-value' : ''}`}
            value={statusFilter}
            onChange={handleStatusChange}
          >
            <option value="">Lead Status</option>
            {getUniqueValues('status').filter(status => status).map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
          {statusFilter && <label htmlFor="statusFilter">Status</label>}
        </div>

        <div className="floating-select">
          <select
            className={`sales-filter-academy ${typeOfLeadFilter ? 'has-value' : ''}`}
            value={typeOfLeadFilter}
            onChange={handleTypeOfLeadChange}
          >
            <option value="">All Pages</option>
            {getUniqueValues('typeOfLead').filter(type => type).map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          {typeOfLeadFilter && <label htmlFor="typeOfLeadFilter">Page</label>}
        </div>

        <div className="floating-select">
          <select
            className={`sales-filter-academy ${productSportsFilter ? 'has-value' : ''}`}
            value={productSportsFilter}
            onChange={handleProductSportsChange}
          >
            <option value="">All Sports</option>
            {getUniqueValues('productSports').filter(sport => sport).map((sport, index) => (
              <option key={index} value={sport}>
                {sport}
              </option>
            ))}
          </select>
          {productSportsFilter && <label htmlFor="productSportsFilter">Sport</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${startDateFilter ? 'has-value' : ''}`}
            value={startDateFilter}
            onChange={handleStartDateChange}
            onClick={(e) => e.target.showPicker()}
          />
          {startDateFilter && <label htmlFor="startDateFilter">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${endDateFilter ? 'has-value' : ''}`}
            value={endDateFilter}
            onChange={handleEndDateChange}
            onClick={(e) => e.target.showPicker()}
          />
          {endDateFilter && <label htmlFor="endDateFilter">End Date</label>}
        </div>
        <div className="floating-select">
  <select
    className={`sales-filter-academy ${leadStatusFilter ? 'has-value' : ''}`}
    value={leadStatusFilter}
    onChange={handleLeadStatusChange}
  >
    <option value="">All Status</option>
    {getUniqueValues('newStatus').filter(status => status).map((status, index) => (
      <option key={index} value={status}>
        {status}
      </option>
    ))}
  </select>
  {leadStatusFilter && <label htmlFor="leadStatusFilter">Lead Status</label>}
</div>
      </div>
       {/* Container for all pie charts */}
       {(combinedPieChartData.datasets?.[0]?.data?.length > 0 || statusPieChartsData.some(chart => chart.datasets?.[0]?.data?.length > 0)) && (
  <div className="pie-charts-container">
    {/* Combined Pie Chart for All Type of Lead */}
    {combinedPieChartData.datasets?.[0]?.data?.length > 0 && (
      <div className="pie-chart-container">
        <h3>Overall Type of Lead Distribution</h3>
        <Pie
          data={combinedPieChartData}
          options={{
            plugins: {
              datalabels: {
                color: '#fff',
                formatter: (value) => `${value}%`,
              },
              legend: {
                position: 'bottom',
              },
            },
          }}
        />
      </div>
    )}

    {/* Render Separate Pie Charts for Each Status */}
    {statusPieChartsData.map((chartData, index) =>
      chartData.datasets?.[0]?.data?.length > 0 ? (
        <div key={index} className="pie-chart">
          <h3>{chartData.status}</h3>
          <Pie
            data={{
              labels: chartData.labels,
              datasets: chartData.datasets,
            }}
            options={{
              plugins: {
                datalabels: {
                  color: '#fff',
                  formatter: (value) => `${value}%`,
                },
                legend: {
                  position: 'bottom',
                },
              },
            }}
          />
        </div>
      ) : null
    )}
  </div>
)}

      <div className="search-create-container">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearch}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredTableData}
          headers={headers}
          filename="AllLeads.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>

      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredTableData.length === 0 && (
        <div className="error-message">No data available </div>
      )}
      <div className='space'></div>
      <div className="payment-list-table" style={{ maxWidth: '1300px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} // Sort by the column key
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> // Ascending arrow
                    ) : (
                      <span> ↓</span> // Descending arrow
                    )
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, index) => (
              <tr key={index}>
                <td>{row.centre}</td>
                <td>{row.mobileNumber}</td>
                <td>{row.name}</td>
                <td>{new Date(row.trialdate).toISOString().split('T')[0]}</td>
                <td>{row.trialTime}</td>
                <td>{row.status}</td>
                <td>{row.typeOfLead}</td>
                <td>{row.newStatus}</td>
                <td>{row.productSports}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
  <div className="showing-entries">
    Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
  </div>
  <button
    className="prev-button"
    onClick={handlePrevPage}
    disabled={currentPage === 1}
  >
    Previous
  </button>
  {renderPagination()}
  <button
    className="next-button"
    onClick={handleNextPage}
    disabled={currentPage === totalPages}
  >
    Next
  </button>
</div>
    </div>
  );
};

export default Dashboard;