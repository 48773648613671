import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SetGuardian.css';

const SetGuardian = () => {
  // Simulated data for guardians and selected guardian
  const [guardian, setGuardian] = useState({
    name: 'John Doe',
    mobile: '9876543210',
  });

  const [guardianList, setGuardianList] = useState([
    { name: 'Jane Smith', mobile: '1234567890' },
    { name: 'Robert Johnson', mobile: '2345678901' },
  ]);
  const [selectedGuardian, setSelectedGuardian] = useState(null);
  const [isGuardianCreated, setIsGuardianCreated] = useState(false);

  const handleGuardianSelect = (guardian) => {
    setSelectedGuardian(guardian);
  };

  const handleAddPlayer = () => {
    if (selectedGuardian) {
      alert(`Player added with selected guardian: ${selectedGuardian.name}`);
    }
  };

  const handleCreateGuardian = () => {
    setIsGuardianCreated(true);
    alert('Create new guardian option');
  };

  const handleSubmit = () => {
    // Logic for form submission goes here
    alert('Form Submitted');
  };

  return (
    <div className="sg-container">
      <h2 className="sg-page-title">Selected Guardian's Information</h2>

      <div className="sg-guardian-info">
        <label>Name:</label>
        <input type="text" value={guardian.name} disabled className="sg-input" />

        <label>Mobile:</label>
        <input type="text" value={guardian.mobile} disabled className="sg-input" />
      </div>

      <div className="sg-guardian-selection">
        <h3>Select Guardian</h3>
        {guardianList.length > 0 ? (
          <table className="sg-guardian-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {guardianList.map((guardian, index) => (
                <tr key={index}>
                  <td>{guardian.name}</td>
                  <td>{guardian.mobile}</td>
                  <td>
                    <button
                      onClick={() => handleGuardianSelect(guardian)}
                      className="sg-select-btn"
                    >
                      Select
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No guardians found</p>
        )}

        {/* Button to add player with selected guardian */}
        {selectedGuardian ? (
          <div className="sg-action-buttons">
            <button onClick={handleAddPlayer} className="sg-add-player-btn">
              Add Player with Selected Guardian
            </button>
          </div>
        ) : (
          <div className="sg-action-buttons">
            <button onClick={handleCreateGuardian} className="sg-create-guardian-btn">
              Create New Guardian
            </button>
          </div>
        )}

        {/* Show a table for entries if creating guardian */}
        {isGuardianCreated && (
          <div className="sg-guardian-entry-table">
            <h3>Enter Guardian Information</h3>
            <table>
              <thead>
                <tr>
                  <th>Parent Number</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" placeholder="Enter parent number" className="sg-input" /></td>
                  <td><input type="text" placeholder="Enter name" className="sg-input" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="sg-submit-btn-container">
        <button className="sg-submit-btn" onClick={handleSubmit}>
          Submit
        </button>

        {/* Proceed to Sibling button */}
        <Link to="/sibling-page" className="sg-proceed-sibling-btn">
          Proceed to Sibling
        </Link>
      </div>
    </div>
  );
};

export default SetGuardian;
