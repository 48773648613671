import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Academy/AcademyList.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
const DisableAcademy = () => {
  const { userData } = useUser();
  const { selectedCenter, setSelectedCenter } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [academies, setAcademies] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  useEffect(() => {
    // Set loading to true when the request starts
    setLoading(true);
    setError(null); // Reset the error state before making the request

    // Set the headers for the request
    const headers = {
      'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
    };

    // Fetch data from the API with headers
    axios.get('http://localhost:8082/api/academy/list-disabled', { headers })
      .then((response) => {
        if (response.data.success) {
          console.log("Academy list fetched successfully:", response.data);

          // Map through the academies to set the state correctly
          const academyData = response.data.data.academies.map((academy) => ({
            id: academy?.id,
            name: academy?.name,
            email: academy?.user?.email, // Get email from the user object
            password: academy?.user?.otp, // Assuming password or similar field, adjust as needed
            about: academy?.about,
            mobileNumber: academy?.user?.mobile_number, // Mobile number from the user object
            address: academy?.address,
            sport: academy?.sport || "", // You can add sports data if available in your structure
            facilities: academy?.facilities?.map(facility => facility.name), // Map facility names
            locality: academy?.locality || "", // Locality can be extracted if available
            coverImage: academy?.coverPic, // Assuming cover image URL is stored here
            notification: academy?.isNotificationEnabled,
            maxActiveMembership: academy?.maxActiveMembership || 0, // Assuming a field for max active membership exists
          }));

          setAcademies(academyData); // Update state with fetched data
        } else {
          // Handle unsuccessful response (e.g., error in the data)
          console.error("Error: API response was not successful", response.data.message || "Unknown error");
          setError(response.data.message || "Unknown error"); // Set the error state
        }
      })
      .catch((error) => {
        // Handle errors during the fetch request
        console.error("There was an error fetching the academies:", error);
        setError("There was an error fetching the academies: " + (error.message || error)); // Set the error state
      })
      .finally(() => {
        // Set loading to false once the request is complete
        setLoading(false);
        console.log("API request has finished (success or failure)");
      });
  }, []);
  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [action, setAction] = useState('');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10); // Default to 10 items per page
  const [items, setItems] = useState([]); // Your data (e.g., from API)

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen !== null && !event.target.closest('.dropdown-container')) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    console.log("Selected Academy:", selectedAcademy);
  }, [selectedAcademy]);

  const filteredAcademies = academies.filter((academy) => {
    const notificationStatus = academy.notification ? 'Yes' : 'No';
    return (
      Object.values(academy)
        .join(' ')
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      notificationStatus.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const toggleDropdown = (academyId) => {
    setDropdownOpen(dropdownOpen === academyId ? null : academyId);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredAcademies.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAcademies.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleDeleteAcademy = (academyId) => {
    const selectedAcademy = academies.find(academy => academy.id === academyId);
    setSelectedAcademy(selectedAcademy);

    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to delete the academy: ${selectedAcademy.name}. This action cannot be undone!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(selectedAcademy);
      }
    });
  };

  const confirmDelete = async (selectedAcademy) => {
    try {
      const deleteUrl = `http://localhost:8082/api/academy/enable/${selectedAcademy.id}`;
      console.log(deleteUrl);

      const response = await fetch(deleteUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with the actual token
        },
      });

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        throw new Error('Failed to delete academy');
      }

      const updatedAcademies = academies.filter(academy => academy.id !== selectedAcademy.id);
      setAcademies(updatedAcademies);
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'The academy has been deleted.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (error) {
      console.error('Error deleting academy:', error);

      Swal.fire({
        title: 'Error!',
        text: 'There was an error deleting the academy.',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleCreateAcademy = () => navigate('/newAcademy');

  const handleActionClick = (academyId, action) => {
    const selectedAcademy = academies.find(academy => academy.id === academyId);
    setSelectedAcademy(selectedAcademy);

    switch (action) {
      case 'edit':
        navigate('/newAcademy', { state: { academy: selectedAcademy } });
        console.log("the things to be edit ", selectedAcademy)
        break;
      case 'disable':
        handleDeleteAcademy(academyId);
        break;

      case 'player_list':
        navigate('/academyPlayerList', { state: { academy: selectedAcademy } });
        console.log("the academy for player list", selectedAcademy);
        break;
      case 'coach_list':
        navigate('/academyCoachList', { state: { academy: selectedAcademy } });
        console.log("the academy for coach list", selectedAcademy);
        break;

      default:
        break;
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedAcademies = [...academies].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const getSortArrow = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  const handleAcademyClick = (academy) => {
    navigate(`/batchList`, { state: { academyId: academy.id } });
    console.log("The academy ID for batch list", academy.id);
  };

  const totalEntries = filteredAcademies.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  const mergedAcademies = [...sortedAcademies, ...currentRows];
  
  return (
    <div className="academy-list-container">
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredAcademies.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}

      <table className="academy-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('name')}>Name <span className="sort-arrow">{getSortArrow('name')}</span></th>
            <th onClick={() => handleSort('about')}>About <span className="sort-arrow">{getSortArrow('about')}</span></th>
            <th onClick={() => handleSort('address')}>Address <span className="sort-arrow">{getSortArrow('address')}</span></th>
            <th onClick={() => handleSort('notification')}>Notification <span className="sort-arrow">{getSortArrow('notification')}</span></th>
            {hasPrivilege(userData, 'ACADEMY', 'DISABLED_ACADEMY_LIST_MODIFY') && (<th>Action</th>)}
          </tr>

        </thead>
        <tbody>

          {currentRows.map((academy) => (
            <tr key={academy.id}>
              <td>
                <span
                  onClick={() => handleAcademyClick(academy)}  // Make the academy name clickable
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'none' }}  // Styling to indicate clickable text
                >
                  {academy.name}
                </span>
              </td>
              <td>{academy.about}</td>
              <td>{academy.address}</td>
              <td>{academy.notification ? 'Yes' : 'No'}</td>
              {hasPrivilege(userData, 'ACADEMY', 'DISABLED_ACADEMY_LIST_MODIFY') && (
                <td className="action-buttons">
                  <button
                    className="ellipsis-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown(academy.id);
                    }}
                  >
                    &#x22EE;
                  </button>
                  {dropdownOpen === academy.id && (
                    <div className="custom-dropdown">
                      <button onClick={() => handleActionClick(academy.id, 'player_list')}>Player List</button>
                      <button onClick={() => handleActionClick(academy.id, 'coach_list')}>Coach List</button>
                      <button onClick={() => handleActionClick(academy.id, 'edit')}>Edit</button>
                      <button onClick={() => handleActionClick(academy.id, 'disable')}>Enable</button>
                    </div>
                  )}
                </td>)}

            </tr>
          ))}
        </tbody>
      </table>
      {/* )
} */}
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

    </div>
  );
};

export default DisableAcademy;
