import React, { useState, useEffect } from 'react';
import './CourtBulkBooking.css';
import { Link } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import Select from 'react-select';
import Swal from 'sweetalert2';
const CourtBulkBooking = () => {
  const { selectedCenter } = useUser();
  const [formData, setFormData] = useState({
    court: [],
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    weekdays: [],
    mobile: '',
    name: '',
    remark: '',
  });

  const [courtList, setCourtList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
        times.push({ value: time, label: time });
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();
  const getEndTimeOptions = () => {
    if (!formData.startTime) return timeOptions;
    return timeOptions.filter((option) => option.value > formData.startTime);
  };

  const getEndDateOptions = () => {
    if (!formData.startDate) return '';
    return formData.startDate;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'startTime') {
      setFormData((prev) => ({ ...prev, endTime: '' }));
    }
    if (name === 'startDate') {
      setFormData((prev) => ({ ...prev, endDate: '' }));
    }
  };

  const handleCourtChange = (selectedOptions) => {
    setFormData((prev) => ({
      ...prev,
      court: selectedOptions,
    }));
  };

  const handleWeekdaysChange = (selectedOptions) => {
    setFormData({ ...formData, weekdays: selectedOptions });
  };

  const handleTimeChange = (selectedOption, field) => {
    setFormData({ ...formData, [field]: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const getTodayDate = () => {
      const today = new Date();
      return today.toISOString().split("T")[0];
    };

    const formatDateTime = (time) => {
      const todayDate = getTodayDate();
      const [hours, minutes] = time.split(":").map(Number);
      const formattedDate = new Date(todayDate);
      formattedDate.setHours(hours, minutes, 0, 0);
      return formattedDate.toISOString();
    };

    const payload = {
      academyId: selectedCenter,
      courtIds: formData.court.map((court) => court.value),
      startDate: formData.startDate,
      endDate: formData.endDate,
      startTime: formData.startTime,
      endTime: formData.endTime,
      weekdays: formData.weekdays,
      username: formData.name,
      userMobileNumber: formData.mobile,
      remark: formData.remark,
      isConfirm: true,
    };

    console.log(JSON.stringify(payload, null, 2));

    try {
      const response = await fetch(`http://localhost:8082/api/react/court/bulk/booking?academyId=${selectedCenter}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-authorization": `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ`, // If authentication is required
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log(result)
      if (response.ok) {
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Booking submitted successfully",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 100);
      } else {
        throw new Error(result.message || "Something went wrong!");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      console.log(error.message)
    }
  };

  useEffect(() => {
    if (selectedCenter) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `http://localhost:8082/api/react/court/bulk/booking?academyId=${selectedCenter}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
              },
            }
          );
          const data = await response.json();
          console.log(data)
          if (data.success) {
            setCourtList(data.data.courtList);
          } else {
            setError('Failed to fetch court data.');
          }
        } catch (error) {
          setError('Error fetching data.');
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedCenter]);

  const courtOptions = courtList.map((court) => ({
    value: court.id,
    label: court.name,
  }));

  return (
    <div className="bulk-booking-container">
      <div className="breadcrumb">
        <Link to="/" className="breadcrumb-link">Dashboard</Link> &gt; Bulk Booking
      </div>

      {loading && <p>Loading court list...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <form className="bulk-booking-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="court">Select Court:</label>
          <Select
            id="court"
            name="court"
            options={courtOptions}
            value={formData.court}
            onChange={handleCourtChange}
            isMulti
            required
            closeMenuOnSelect={false}
          />
        </div>
        <div className="form-field">
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            className="input-field"
            value={formData.startDate}
            onChange={handleChange}
            required
            min={new Date().toISOString().split('T')[0]}
            onClick={(e) => e.target.showPicker()}
          />
        </div>

        <div className="form-field">
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            className="input-field"
            value={formData.endDate}
            onChange={handleChange}
            required
            min={getEndDateOptions()}
            onClick={(e) => e.target.showPicker()}
          />
        </div>

        <div className="form-field">
          <label htmlFor="startTime">Start Time:</label>
          <Select
            id="startTime"
            name="startTime"
            options={timeOptions}
            value={timeOptions.find((option) => option.value === formData.startTime)}
            onChange={(selectedOption) => handleTimeChange(selectedOption, 'startTime')}
            required
            onClick={(e) => e.target.showPicker()}
          />
        </div>

        <div className="form-field">
          <label htmlFor="endTime">End Time:</label>
          <Select
            id="endTime"
            name="endTime"
            options={getEndTimeOptions()}
            value={timeOptions.find((option) => option.value === formData.endTime)}
            onChange={(selectedOption) => handleTimeChange(selectedOption, 'endTime')}
            required
            onClick={(e) => e.target.showPicker()}
          />
        </div>

        <div className="form-field">
          <label>Select Weekdays:</label>
          <div className="weekdays-checkbox-container">
            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => (
              <label key={day} className="weekday-checkbox-label">
                <input
                  type="checkbox"
                  name="weekdays"
                  value={day}
                  checked={formData.weekdays.includes(day)}
                  onChange={(e) => {
                    const { value, checked } = e.target;
                    setFormData((prev) => ({
                      ...prev,
                      weekdays: checked
                        ? [...prev.weekdays, value] // Add the day if checked
                        : prev.weekdays.filter((d) => d !== value), // Remove the day if unchecked
                    }));
                  }}
                />
                {day}
              </label>
            ))}
          </div>
        </div>

        <div className="form-field">
          <label htmlFor="mobile">Mobile Number:</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            className="input-field"
            value={formData.mobile}
            onChange={handleChange}
            pattern="[0-9]{10}"
            placeholder="Enter 10-digit number"
            maxLength={10}
          />
        </div>

        <div className="form-field">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            className="input-field"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter name"
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="remark">Remark:</label>
          <textarea
            id="remark"
            name="remark"
            className="input-textarea"
            value={formData.remark}
            onChange={handleChange}
            placeholder="Enter remarks"
          ></textarea>
        </div>

        <div className="form-action">
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CourtBulkBooking;