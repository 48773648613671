import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Coaching/NewPlayer.css';

const SearchUser = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [playerData, setPlayerData] = useState(null); // Stores the player data if found
  const [error, setError] = useState(null); // Error state in case the search fails

  // Simulated player data (replace with real data-fetching logic)
  const samplePlayerData = {
    name: 'sonam',
    mobile: '9383232810',
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Searching for mobile number:', mobileNumber);

    // Simulating search. Replace this with actual search logic (API call or local data check)
    if (mobileNumber === samplePlayerData.mobile) {
      setPlayerData(samplePlayerData);
      setError(null); // Clear any previous error
    } else {
      setPlayerData(null);
      setError('User not found');
    }
  };

  return (
    <div className="new-player-wrapper">
      <div className="breadcrumb">
        <Link to="/" className="breadcrumb-link">
          Dashboard
        </Link>
        &gt; Search User
      </div>

      <form onSubmit={handleSearch} className="new-player-form">
        <div className="input-container">
          <label htmlFor="mobileNumber" className="mobile-label">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter mobile number"
            required
            className="mobile-input-field"
          />
        </div>
        <button type="submit" className="submit-search-button">
          Search
        </button>
      </form>

      {/* Displaying player data if found */}
      {playerData ? (
        <div className="player-details">
          <h3>Player Found:</h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{playerData.name}</td>
                <td>{playerData.mobile}</td>
                <td>
                  {/* Link to redirect to the SearchUser page */}
                  <Link to="/checkinDetails" className="view-details-link">
                    Details
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-player">
          {error && <p className="error-message">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default SearchUser;
