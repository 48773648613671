import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';
import { CSVLink } from 'react-csv';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import './TrialConverted.css';

const Trialmissed = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [academies, setAcademies] = useState([]);
  const { selectedCenter, setSelectedCenter } = useUser();
  // Fetching data from API

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ'; // Replace with your actual token


        const response = await fetch('https://admin.stage.machaxi.com/api/trial/view', {
          method: 'GET',
          headers: {
            'x-authorization': token,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data, Status: ${response.status}`);
        }

        const data = await response.json();

        const apiData = data?.data?.apiData || [];
        const filteredLeads = apiData.filter(lead => lead.isTrialMissed === true);
        const allAcademies = data?.data?.academies || [];

        setLeads(filteredLeads);
        setAcademies(allAcademies); // Set all academies
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchLeads();
  }, [userData]);



  const headers = [
    { label: "Source", key: "source" },
    { label: "Sport", key: "sportId" },
    { label: "Centre", key: "area" },
    { label: "User Name", key: "name" },
    { label: "Phone Number", key: "mobileNumber" },
    { label: "Lead Gen Date", key: "updatedAt" },
    { label: "Trial Date", key: "trialDate" },
    { label: "Trial Time Slot", key: "trialTimeSlot" },
    { label: "Feedback", key: "feedback" },
    { label: "Remark", key: "remarks" },
    { label: "Status", key: "status" },
  ];
  const getAcademyNameById = (academyId) => {
    const academy = academies.find(academy => academy.id === academyId);
    return academy ? academy.name : ''; // Return empty string if academy is not found
  };

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  // Format the dates to YYYY-MM-DD format
  const formatDate = (date) => date.toISOString().split('T')[0];

  const [filters, setFilters] = useState({
    landingPage: '',
    sport: '',
    centre: selectedCenter || '',
    leadGenStartDate: formatDate(tenDaysAgo), // Default 10 days ago
    leadGenEndDate: formatDate(today), // Default today
    trialStartDate: formatDate(tenDaysAgo), // Default 10 days ago
    trialEndDate: formatDate(today), // Default today
    status: ''
  });

  useEffect(() => {
    const selectedAcademy = academies.find(academy => academy.id === parseInt(selectedCenter));
    const centreName = selectedAcademy ? selectedAcademy.name : '';
    setFilters((prevFilters) => ({
      ...prevFilters,
      centre: centreName
    }));
  }, [selectedCenter, academies]);
  
  console.log("selectedcenter", selectedCenter);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const filteredLeads = leads.filter((lead) => {
    const searchMatch = Object.values(lead)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const sourceMatch = filters.source ? lead.source.toLowerCase().includes(filters.source.toLowerCase()) : true;

    const sportName = lead.sportId === 11 ? 'Swimming' : 'Badminton';
    const sportMatch = filters.sport ? sportName.toLowerCase().includes(filters.sport.toLowerCase()) : true;

    // Filter by selected centre
    const selectedAcademy = academies.find(academy => academy.name === filters.centre);
    const centreMatch = filters.centre
      ? lead.academyId === selectedAcademy?.id
      : true;

    // Filter by accessible academies if user type is ACADEMY
    const accessibleAcademyMatch = userData?.userType === "ACADEMY"
      ? userData.academyIds.includes(lead.academyId)
      : true;

    const leadGenStartDate = filters.leadGenStartDate ? new Date(filters.leadGenStartDate) : null;
    const leadGenEndDate = filters.leadGenEndDate ? new Date(filters.leadGenEndDate) : null;
    const trialStartDate = filters.trialStartDate ? new Date(filters.trialStartDate) : null;
    const trialEndDate = filters.trialEndDate ? new Date(filters.trialEndDate) : null;
    const leadUpdatedAt = new Date(lead.updatedAt);
    const leadTrialDate = new Date(lead.trialDate);

    const leadGenDateMatch =
      (!leadGenStartDate || leadUpdatedAt >= leadGenStartDate) &&
      (!leadGenEndDate || leadUpdatedAt <= leadGenEndDate || leadUpdatedAt.toDateString() === leadGenEndDate.toDateString());

    const trialDateMatch =
      (!trialStartDate || leadTrialDate >= trialStartDate) &&
      (!trialEndDate || leadTrialDate <= trialEndDate || leadTrialDate.toDateString() === trialEndDate.toDateString() || leadTrialDate > trialEndDate);

    const statusMatch = filters.status ? lead.status.toLowerCase().includes(filters.status.toLowerCase()) : true;

    const result = (
      searchMatch &&
      sourceMatch &&
      sportMatch &&
      centreMatch &&
      accessibleAcademyMatch && // Ensure lead is from an accessible academy
      leadGenDateMatch &&
      trialDateMatch &&
      statusMatch
    );

    return result;
  });


  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const handleSort = (columnKey) => {
    let direction = 'ascending';

    // Toggle sort direction
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Update sortConfig
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;

    if (!key) return data; // No sorting applied if no key is selected

    return [...data].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(filteredLeads);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  // Extract unique values for dynamic filter options
  const landingPageOptions = [...new Set(leads.map(lead => lead.landingPage))];
  const sportOptions = ['Swimming', 'Badminton'];
  const centreOptions = [...new Set(academies.map(academy => academy.name))];
  const sourceOptions = [...new Set(leads.map(lead => lead.source))];

  const generatePieChartDataForLandingPages = (leads) => {
    const landingPageCounts = leads.reduce((acc, lead) => {
      const source = lead.source || 'Unknown'; // Handle undefined or missing source
      if (!acc[source]) {
        acc[source] = 0;
      }
      acc[source]++;
      return acc;
    }, {});

    const data = {
      labels: Object.keys(landingPageCounts),
      datasets: [
        {
          data: Object.values(landingPageCounts),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        },
      ],
    };

    return data;
  };

  const handleEditClick = (lead) => {
    console.log('Selected Lead:', lead); // Debugging
  
    // Ensure status mapping follows consistent casing
    const statusMapping = {
      "CALLED": "Called",
      "INTERESTED": "Interested",
      "JUNK": "Junk",
      "CONVERTED": "Converted"
    };
  
    setSelectedLead({
      ...lead,
      remark: lead.remarks || '', // Ensure remark is set
      status: statusMapping[lead.leadStatus] || lead.leadStatus, // Map to modal status
    });
  
    setIsModalOpen(true);
  };
  

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (leadId, status, remarks) => {
    try {
      const token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ'; // Replace with your actual token

      console.log('Updating Lead:', { leadId, status: status.toUpperCase(), remarks });

      const response = await fetch(`https://admin.stage.machaxi.com/api/trial/update-status/${leadId}`, {
        method: 'POST',
        headers: {
          'x-authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          remarks: remarks, // Use "remarks" instead of "remark"
          status: status.toUpperCase() // Convert status to uppercase
        })
      });

      console.log('API Response Status:', response.status);

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('API Error Response:', errorResponse);
        throw new Error(`Failed to update lead, Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Update Response:', data);

      // Update the local state to reflect the changes
      setLeads((prevLeads) =>
      prevLeads.map((lead) =>
        lead.id === leadId ? { ...lead, leadStatus: status, remarks: remarks } : lead
      )
    );

      // Close the modal
      setIsModalOpen(false);
    } catch (error) {
      console.error('Update Error:', error.message);
      setError(error.message);
    }
  };

  const handleSaveChanges = () => {
    if (selectedLead) {
      handleSubmit(selectedLead.id, selectedLead.status, selectedLead.remarks);
    }
  };
  const totalEntries = filteredLeads.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="payment-list-container">
      {/* Filters Section */}
      <div className="sales-filters-leadManagement">
      <div className="floating-select">
          <select
            name="centre"
            onChange={handleFilterChange}
            value={filters.centre}
            className={`sales-filter-academy ${filters.centre ? "has-value" : ""}`}
          >
            <option value="">Centre</option>
            {userData?.userType === "ACADEMY" ? (
              academies
                .filter(academy => userData.academyIds.includes(academy.id))
                .map((academy) => (
                  <option key={academy.id} value={academy.name}>
                    {academy.name}
                  </option>
                ))
            ) : (
              academies.map((academy) => (
                <option key={academy.id} value={academy.name}>
                  {academy.name}
                </option>
              ))
            )}
          </select>
          {filters.centre && <label htmlFor="centre">Centre</label>}
        </div>

        <div className="floating-select">
          <select
            name="source"
            onChange={handleFilterChange}
            value={filters.source}
            className={`sales-filter-academy ${filters.source ? 'has-value' : ''}`}
          >
            <option value="">Source</option>
            {sourceOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {filters.source && <label htmlFor="source">Source</label>}
        </div>
        <div className="floating-select">
          <input
            type="date"
            name="leadGenStartDate"
            value={filters.leadGenStartDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.leadGenStartDate ? "has-value" : ""}`}
          />
          {filters.leadGenStartDate && <label htmlFor="leadGenStartDate">Lead Gen Start Date</label>}
        </div>
        <div className="floating-select">
          <input
            type="date"
            placeholder="Lead Gen End Date"
            name="leadGenEndDate"
            value={filters.leadGenEndDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.leadGenEndDate ? "has-value" : ""}`}
          />
          {filters.leadGenEndDate && <label htmlFor="leadGenEndDate">Lead Gen End Date</label>}
        </div>
        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial Start Date"
            name="trialStartDate"
            value={filters.trialStartDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.trialStartDate ? "has-value" : ""}`}
          />
          {filters.trialStartDate && <label htmlFor="trialStartDate">Trial Start Date</label>}
        </div>
        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial End Date"
            name="trialEndDate"
            value={filters.trialEndDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.trialEndDate ? "has-value" : ""}`}
          />
          {filters.trialEndDate && <label htmlFor="trialEndDate">Trial End Date</label>}
        </div>
        <div className="floating-select">
          <select
            name="sport"
            onChange={handleFilterChange}
            value={filters.sport}
            className={`sales-filter-academy ${filters.sport ? "has-value" : ""}`}
          >
            <option value="">Sport</option>
            {sportOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {filters.sport && <label htmlFor="sport">Sport</label>}
        </div>
      </div>
      {filteredLeads.length > 0 && (
        <div className="unique-charts-container">
          <div className="unique-chart-dropoffs">
            <h3>Landing Pages Entries</h3>
            <Pie data={generatePieChartDataForLandingPages(filteredLeads)} />
          </div>
        </div>
      )}

      {/* Search Bar */}
      <div className="search-create-container">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredLeads}
          headers={headers}
          filename="LeadManagement.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <div className='space'></div>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredLeads.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      {/* Leads Table */}
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} // Sort by the column key
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> // Ascending arrow
                    ) : (
                      <span> ↓</span> // Descending arrow
                    )
                  ) : null}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((lead) => (
              <tr key={lead.id}>
                <td>{lead.source}</td>
                <td>{lead.sportId === 11 ? 'Swimming' : 'Badminton'}</td>
                <td>{getAcademyNameById(lead.academyId)}</td>
                <td>{lead.name}</td>
                <td>{lead.mobileNumber}</td>
                <td>{lead.updatedAt ? new Date(lead.updatedAt).toISOString().split("T")[0] : "N/A"}</td>
                <td>{lead.trialDate ? new Date(lead.trialDate).toISOString().split("T")[0] : "N/A"}</td>

                <td>
                  {(() => {
                    if (!lead.startTime || !lead.endTime) return "N/A"; // Handle null/undefined case

                    const [startHour, startMin] = lead.startTime.split(":").map(Number);
                    const [endHour, endMin] = lead.endTime.split(":").map(Number);

                    const totalStartMinutes = startHour * 60 + startMin;
                    const totalEndMinutes = endHour * 60 + endMin;

                    return `${totalEndMinutes - totalStartMinutes} min`;
                  })()}
                </td>
                <td>{lead.feedback || '-'}</td>
                <td>{lead.remarks || '-'}</td>
                <td>{lead.leadStatus}</td>
                <td>
                  <button className="edit-button" onClick={() => handleEditClick(lead)}>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination">
  <div className="showing-entries">
    Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
  </div>
  <button
    className="prev-button"
    onClick={handlePrevPage}
    disabled={currentPage === 1}
  >
    Previous
  </button>
  {renderPagination()}
  <button
    className="next-button"
    onClick={handleNextPage}
    disabled={currentPage === totalPages}
  >
    Next
  </button>
</div>
      {/* Modal for Editing Lead */}
      {isModalOpen && (
  <div className="firstb-modal-overlay" onClick={handleModalClose}>
    <div className="firstb-modal" onClick={(e) => e.stopPropagation()}>
      <h3>Edit Status</h3>
      <div className="firstb-modal-row">
        <label htmlFor="remark">Remark:</label>
        <textarea
          id="remark"
          value={selectedLead?.remarks || ''} // Use selectedLead.remark
          onChange={(e) => setSelectedLead({ ...selectedLead, remarks: e.target.value })}
        />
      </div>
      <div className="firstb-modal-row">
        <label>Status:</label>
        <div className="firstb-status-radio-group">
          <div>
            <input
              type="radio"
              id="called"
              name="status"
              value="Called"
              checked={selectedLead?.status === 'Called'} // Use selectedLead.status
              onChange={(e) => setSelectedLead({ ...selectedLead, status: e.target.value })}
            />
            <label htmlFor="called">Called</label>
          </div>
          <div>
            <input
              type="radio"
              id="interested"
              name="status"
              value="Interested"
              checked={selectedLead?.status === 'Interested'} // Use selectedLead.status
              onChange={(e) => setSelectedLead({ ...selectedLead, status: e.target.value })}
            />
            <label htmlFor="interested">Interested</label>
          </div>
          <div>
            <input
              type="radio"
              id="junk"
              name="status"
              value="Junk"
              checked={selectedLead?.status === 'Junk'} // Use selectedLead.status
              onChange={(e) => setSelectedLead({ ...selectedLead, status: e.target.value })}
            />
            <label htmlFor="junk">Junk</label>
          </div>
          <div>
            <input
              type="radio"
              id="converted"
              name="status"
              value="Converted"
              checked={selectedLead?.status === 'Converted'} // Use selectedLead.status
              onChange={(e) => setSelectedLead({ ...selectedLead, status: e.target.value })}
            />
            <label htmlFor="converted">Converted</label>
          </div>
        </div>
      </div>
      <div className="firstb-modal-row">
        <button onClick={handleSaveChanges}>Save</button>
        <button onClick={handleModalClose}>Cancel</button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default Trialmissed