import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./PlayerPaymentDetail.css";
import { useLocation, Link } from "react-router-dom";

const PlayerPaymentDetail = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const { batchId } = location.state || {};
  console.log(batchId);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [players, setPlayers] = useState([]);
  const [batchName, setBatchName] = useState("");
  const [monthlyFees, setMonthlyFees] = useState("");
  const [paymentTerms, setPaymentTerms] = useState([]);
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(
          `http://localhost:8082/api/react/batch/players-payment-detalis?batchId=${batchId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-authorization":
                "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
            },
          }
        );

        const result = await response.json();
        if (result.success) {
          // Extract batch details
          setBatchName(result.data.batch.name);
          setMonthlyFees(result.data.batch.monthlyFees);
          setPaymentTerms(
            result.data.batchPaymentTerms.map((term) => ({
              value: term,
              label: term,
            }))
          );

          // Extract player data
          const playersData =
            result.data.playerBatchAddForm.batchPaymentDetailsForms.map(
              (player) => ({
                gstApplicable: player.gstApplicable,
                name: player.playerName,
                dateOfJoining: player.joiningDate.split("T")[0],
                dateOfNextPayment: player.nextPaymentDueDate,
                finalMonthlyFees: player.finalMonthlyFee,
                term: player.term,
                extraFee: player.joiningFee,
                total: player.total,
              })
            );

          console.log(playersData);
          setPlayers(playersData);
        }
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    if (batchId) {
      fetchPlayers();
    }
  }, [batchId]);

  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = value;

    if (field === "finalMonthlyFees" || field === "extraFee") {
      updatedPlayers[index].total =
        updatedPlayers[index].finalMonthlyFees +
        updatedPlayers[index].extraFee;
    }

    setPlayers(updatedPlayers);
  };

  const handleGstChange = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].gstApplicable = !updatedPlayers[index].gstApplicable;
    setPlayers(updatedPlayers);
  };

  const handleTermChange = (index, selectedOption) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].term = selectedOption.value;
    setPlayers(updatedPlayers);
  };

  // Filter players by name based on search term
  const filteredPlayers = players.filter((player) =>
    player.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      let valueA = a[key];
      let valueB = b[key];

      // Ensure numbers are compared numerically
      if (!isNaN(valueA) && !isNaN(valueB)) {
        valueA = Number(valueA);
        valueB = Number(valueB);
      }

      if (valueA < valueB) return direction === "ascending" ? -1 : 1;
      if (valueA > valueB) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };


  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const sortedData = sortData(filteredPlayers);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage > 2) pages.push(1, "...");
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 1) pages.push("...", totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredPlayers.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="batch-container">
      <div className="breadcrumb">
        <Link to="/batchList" className="breadcrumb-link">Batch List</Link> &gt; Player Payment Details
      </div>
      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button">Done</button>
      </div>
      <div className="items-and-dues">
        <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
          <option value={10}>10 items per page</option>
          <option value={50}>50 items per page</option>
          <option value={75}>75 items per page</option>
          <option value={100}>100 items per page</option>
        </select>
        <div className="total-dues">
          <div>
            <strong>Batch: {batchName}</strong></div>
          <strong>Monthly Fees: ₹{monthlyFees}</strong>
        </div>
      </div>
      <table className="cancel-batch-table">
        <thead>
          <tr>
            <th className="challenges-column">GST Applicable</th>
            <th className="challenges-columnr">Name</th>
            <th className="challenges-column">Date of Joining</th>
            <th className="challenges-column">Date of Next Payment</th>
            <th className="challenges-column">Final Monthly Fees</th>
            <th className="challenges-column">Term</th>
            <th className="challenges-column">Extra Fee</th>
            <th className="challenges-column">Total</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((player, index) => (
            <tr key={index} className="player-payment-table-row">
              <td className="player-payment-table-cell">
                <input
                  type="checkbox"
                  checked={player.gstApplicable}
                  onChange={() => handleGstChange(index)}
                  className="player-payment-checkbox"
                />
              </td>
              <td className="player-payment-table-cell">{player.name}</td>
              <td className="player-payment-table-cell">
                <input
                  type="date"
                  value={player.dateOfJoining}
                  onChange={(e) =>
                    handleInputChange(index, "dateOfJoining", e.target.value)
                  }
                  className="player-payment-input"
                />
              </td>

              <td className="player-payment-table-cell">
                <input
                  type="date"
                  value={player.dateOfNextPayment}
                  onChange={(e) =>
                    handleInputChange(index, "dateOfNextPayment", e.target.value)
                  }
                  className="player-payment-input"
                />
              </td>
              <td className="player-payment-table-cell">
                <input
                  type="text"
                  value={player.finalMonthlyFees}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "finalMonthlyFees",
                      parseFloat(e.target.value)
                    )
                  }
                  className="player-payment-input"
                />
              </td>
              <td className="player-payment-table-cell">
                <Select
                  options={paymentTerms}
                  value={paymentTerms.find((term) => term.value === player.term)}
                  onChange={(selectedOption) =>
                    handleTermChange(index, selectedOption)
                  }
                  className="player-payment-dropdown"
                />
              </td>
              <td className="player-payment-table-cell">{player.extraFee}</td>
              <td className="player-payment-table-cell">{player.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlayerPaymentDetail;
