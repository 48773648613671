import React from 'react'

const SportWiseRevenue = () => {
  return (
    <div>
      SportWise Revenue
    </div>
  )
}

export default SportWiseRevenue
