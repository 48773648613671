import React, { useState,useEffect } from 'react';
import './PlanList.css';
import EditPlan from './EditPlan';
import DeletePlan from './DeletePlan';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { useNavigate } from 'react-router-dom';

const PlanList = () => {
  const { userData } = useUser();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    // Define your API URL and headers
    const apiUrl = 'https://admin.stage.machaxi.com/api/admin/playing-plan-list';
    const headers = {
      'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // replace with actual token
      'Content-Type': 'application/json',
    };

    // Fetch data from API
    const fetchPlans = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: headers,
        });

        const result = await response.json();

        if (result.success) {
          // Map the API data to match your component's structure
          const mappedPlans = result.data.playingPlans.map(plan => {
            // Count the benefits
            const benefitsCount = plan.benefits.length;
            const benefitsText = plan.benefits.map(benefit => benefit.benefit).join(', ');

            return {
              id: plan.id,
              planType: plan.sportsPlanType, // Assuming this maps to planType
              sportType: plan.sportsType,
              name: plan.name,
              type: plan.type,
              price: `${plan.price}`,
              strikethroughPrice: `${plan.strikeThroughPrice}`,
              gstInclusive: plan.gstInclusive ? 'Yes' : 'No',
              tagline: plan.tagline,
              playingHours: plan.playingHours.toString(),
              maxSlotPerDay: plan.maxSlotBookingPerDay.toString(),
              coinsPerHour: plan.coinsRewardedPerHour.toString(),
              benefitsAdded: `${benefitsCount}`,
              iconUrl: plan.iconUrl, 
            };
          });
          
          setPlans(mappedPlans);
        } else {
          console.error('Failed to fetch plans:', result.success_message);
        }
      } catch (err) {
        console.error('Error fetching plans:', err.message);
      }
    };

    fetchPlans();
  }, []);

  const headers = [
    { key: 'planType', label: 'Plan Type' },
    { key: 'sportType', label: 'Sport Type' },
    { key: 'name', label: 'Plan Name' },
    { key: 'type', label: 'Type' },
    { key: 'price', label: 'Price' },
    { key: 'strikethroughPrice', label: 'Strikethrough Price' },
    { key: 'gstInclusive', label: 'GST Inclusive' },
    { key: 'tagline', label: 'Tagline' },
    { key: 'playingHours', label: 'Playing Hours' },
    { key: 'maxSlotPerDay', label: 'Max Slot Per Day' },
    { key: 'coinsPerHour', label: 'Coins Per Hour' },
    { key: 'benefitsAdded', label: 'Benefits Added' },
  ];
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const navigate = useNavigate();

  const handleUpdate = (updatedPlan) => {
    setPlans(plans.map((plan) => (plan.id === updatedPlan.id ? updatedPlan : plan)));
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://admin.stage.machaxi.com/api/admin/delete-playing-plan?planId=${id}`, {
        method: 'DELETE',
        headers :{
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // replace with actual token
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        setPlans(plans.filter((plan) => plan.id !== id));
      } else {
        console.error('Failed to delete the plan', response);
      }
    } catch (error) {
      console.error('Error during the delete operation:', error);
    }
  };
  
  

  const handlePlanList = () => navigate('/newPlan');

  const filteredPlans = plans.filter((plan) => {
    const searchLower = searchTerm.toLowerCase();
    
    return (
      (plan.planType?.toLowerCase().includes(searchLower) || '') ||
      (plan.sportType?.toLowerCase().includes(searchLower) || '') ||
      (plan.name?.toLowerCase().includes(searchLower) || '') ||
      (plan.tagline?.toLowerCase().includes(searchLower) || '')
    );
  });
  
  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id); // Toggle dropdown visibility
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.plan-dropdown-menu') && !event.target.closest('.plan-dots-button')) {
        setActiveDropdown(null);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(filteredPlans);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };


  const handleEdit = (planId) => {
    navigate('/newPlan', { state: { id: planId } });
  };
  

   const handleBenefits = (planId) => {
    navigate('/benfit', { state: { planId } });
  };
  const totalEntries = filteredPlans.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="challenges-container">
     
      <div className="search-create-container">
      <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearch}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <button className="create-button" onClick={handlePlanList}>Create Plan</button>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <div className='space'></div>
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowX: 'auto' ,overflowY:'visible'}}>
      <table className="payment-list-table">
          <thead>
            <tr>
            {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} // Sort by the column key
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> // Ascending arrow
                    ) : (
                      <span> ↓</span> // Descending arrow
                    )
                  ) : null}
                </th>
              ))}
              {hasPrivilege(userData, 'PLAYING_PLAN','PLANS_LIST_MODIFY') && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((plan) => (
              <tr key={plan.id}>
                <td>{plan.planType}</td>
                <td>{plan.sportType}</td>
                <td>{plan.name}</td>
                <td>{plan.type}</td>
                <td>{plan.price}</td>
                <td>{plan.strikethroughPrice}</td>
                <td>{plan.gstInclusive}</td>
                <td>{plan.tagline}</td>
                <td>{plan.playingHours}</td>
                <td>{plan.maxSlotPerDay}</td>
                <td>{plan.coinsPerHour}</td>
                <td>{plan.benefitsAdded}</td>
                {hasPrivilege(userData, 'PLAYING_PLAN','PLANS_LIST_MODIFY') && (
                  <td>
                    <div className="plan-action-wrapper">
                      <button
                        onClick={() => toggleDropdown(plan.id)}
                        className="plan-dots-button"
                      >
                        &#x22EE;
                      </button>
                      {activeDropdown === plan.id && (
                        <div className="plan-dropdown-menu">
                          <button onClick={() => handleEdit(plan.id)} className="plan-dropdown-item">Edit</button>
                          <button onClick={() => handleDelete(plan.id)} className="plan-dropdown-item">Delete</button>
                          <button onClick={() => handleBenefits(plan.id)}className="plan-dropdown-item">Manage Benefits</button>
                        </div>
                      )}
                    </div>
                  </td>
                )} 

              </tr>
            ))}
          </tbody>
        </table>
       
      {/* )} */}
        </div>
        <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlanList;
