import React, { useState, useEffect } from 'react';
import '../Payment/summary.css';
import axios from 'axios';
const Summary = () => {
  const [summaries, setSummaries] = useState([]);
  const [academies, setAcademies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const apiUrl = 'http://localhost:8082/api/academy/payment-summary';
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  useEffect(() => {
    const today = new Date();
    const tenDaysAgo = new Date();
    tenDaysAgo.setDate(today.getDate() - 10);

    setFromDate(tenDaysAgo.toISOString().split('T')[0]);
    setToDate(today.toISOString().split('T')[0]);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader when date changes

      const headers = {
        'Content-Type': 'application/json',
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
      };

      const requestBody = {
        fromDate,
        toDate,
      };

      try {
        const response = await axios.post(apiUrl, requestBody, { headers });

        if (response.data.success) {
          const paymentData = response.data.data.payments.map(payment => ({
            id: payment.academy.id,
            academyName: payment?.academy?.name,
            amountReceived: payment?.totalTransferableAmount,
            amountPaid: payment?.totalAmountPaid,
            amountDues: payment?.sumOfPaymentDueAcademyWise,
            paymentMethod: payment?.paymentMethod,
            paymentType: payment?.paymentType,
            date: payment?.createdAt,
          }));

          setSummaries(paymentData);
          setPaymentMethods(response.data.data.paymentMethods || []);
          setPaymentTypes(response.data.data.paymentTypes || []);
          const uniqueAcademies = Array.from(new Set(response.data.data.payments.map(payment => payment.academy.name)));
          setAcademies(uniqueAcademies);
        } else {
          console.error('Error fetching data:', response.data.success_message);
        }
      } catch (error) {
        console.error('API call error:', error);
        setError('Error fetching data: ' + error.message);
      } finally {
        setLoading(false); // Hide loader after data fetch is complete
      }
    };

    fetchData();
  }, [fromDate, toDate]);


  const headers = [
    { key: 'academyName', label: 'Academy Name' },
    { key: 'amountReceived', label: 'Amount Received' },
    { key: 'amountPaid', label: 'Amount Paid' },
    { key: 'amountDues', label: 'Amount Dues' },
  ];
  const [searchTerm, setSearchTerm] = useState('');
  const [academyFilter, setAcademyFilter] = useState('All');
  const [paymentMethodFilter, setPaymentMethodFilter] = useState('');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAcademyChange = (event) => {
    setAcademyFilter(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethodFilter(event.target.value);
  };

  const handlePaymentTypeChange = (event) => {
    setPaymentTypeFilter(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  // Filtering logic
  const filteredSummaries = summaries.filter((summary) => {
    const matchesSearchTerm =
      summary.academyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      summary.amountReceived.toString().includes(searchTerm) ||
      summary.amountPaid.toString().includes(searchTerm) ||
      summary.amountDues.toString().includes(searchTerm);

    const matchesAcademy = academyFilter === 'All' || summary.academyName === academyFilter;

    const matchesPaymentMethod = !paymentMethodFilter || summary.paymentMethod === paymentMethodFilter;

    const matchesPaymentType = !paymentTypeFilter || summary.paymentType === paymentTypeFilter;


    return matchesSearchTerm && matchesAcademy && matchesPaymentMethod && matchesPaymentType;
  });
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };
  const sortedData = sortData(filteredSummaries);
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const totalEntries = filteredSummaries.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  return (
    <div className="summary-container">
      <div className="sales-filters-leadManagement">
        <div className="floating-select">
          <select
            value={academyFilter}
            onChange={handleAcademyChange}
            className={`sales-filter-academy ${academyFilter && academyFilter !== "All" ? "has-value" : ""}`}
          >
            <option value="All">All Academies</option>
            {academies.map((academy, index) => (
              <option key={index} value={academy}>
                {academy}
              </option>
            ))}
          </select>
          {academyFilter && academyFilter !== "All" && <label htmlFor="academyFilter">Academy</label>}
        </div>


        <div className="floating-select">
          <select
            value={paymentMethodFilter}
            onChange={handlePaymentMethodChange}
            className={`sales-filter-academy ${paymentMethodFilter ? "has-value" : ""}`}
          >
            <option value="">Payment Method</option>
            {paymentMethods.map((method, index) => (
              <option key={index} value={method}>{method}</option>
            ))}
          </select>
          {paymentMethodFilter && <label htmlFor="paymentMethodFilter">Payment Method</label>}
        </div>


        <div className="floating-select">
          <select
            value={paymentTypeFilter}
            onChange={handlePaymentTypeChange}
            className={`sales-filter-academy ${paymentTypeFilter ? "has-value" : ""}`}
          >
            <option value="">Payment Type</option>
            {paymentTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>
          {paymentTypeFilter && <label htmlFor="paymentTypeFilter">Payment Type</label>}
        </div>


        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${fromDate ? "has-value" : ""}`}
            value={fromDate}
            onChange={handleFromDateChange}
            onFocus={(e) => e.target.showPicker()}
          />
          {fromDate && <label htmlFor="fromDate">Start Date</label>}
        </div>


        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${toDate ? "has-value" : ""}`}
            value={toDate}
            onChange={handleToDateChange}
            onFocus={(e) => e.target.showPicker()}
          />
          {toDate && <label htmlFor="toDate">To Date</label>}
        </div>

      </div>

      <div className="filter-row">
        <div className="search-bar">
          <div className="input-container">
            <input
              type="text"
              id="searchInput"
              placeholder=""
              className={`search-input ${searchTerm ? "has-value" : ""}`}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <label htmlFor="searchInput" className="floating-label">
              🔍 Search
            </label>
          </div>
        </div>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredSummaries.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      <div className='space'></div>

      <table className="summary-table">
        <thead>
          <tr>
            {headers.map((header) => (
              <th
                key={header.key}
                className="challenges-column"
                onClick={() => handleSort(header.key)}
              >
                {header.label}
                {sortConfig.key === header.key ? (
                  sortConfig.direction === "ascending" ? (
                    <span> ↑</span>
                  ) : (
                    <span> ↓</span>
                  )
                ) : null}
              </th>
            ))}

          </tr>
        </thead>
        <tbody>
          {currentRows.map(summary => (
            <tr key={summary.id}>
              <td>{summary.academyName}</td>
              <td>{summary.amountReceived}</td>
              <td>{summary.amountPaid}</td>
              <td>{summary.amountDues}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* )} */}
      {/* Pagination */}
      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Summary;
