import React, { useState } from 'react';
import '../Reports/Challenge.css';
import { CSVLink } from 'react-csv';
const CoachAttendance = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [Attendance, setAttendance] = useState([
    {
      batchName: "Batch A",
      coachName: "John Doe",
      month: "January",
      year: 2024,
      attendance: "95%",
    },
    {
      batchName: "Batch B",
      coachName: "Jane Smith",
      month: "February",
      year: 2024,
      attendance: "88%",
    },
  
  ]);
  const headers = [
    { label: "Batch Name", key: "batchName" },
    { label: "Coach Name", key: "coachName" },
    { label: "Month", key: "month" },
    { label: "Year", key: "year" },
    { label: "Attendance", key: "attendance" }
  ];
  
  
  
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10); 
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAttendance = Attendance.filter((Attend) =>
    Object.values(Attend)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedAttendance = [...filteredAttendance].sort((a, b) => {
    if (sortConfig.key === '') return 0;

    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (sortConfig.direction === 'asc') {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    } else {
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    }
  });
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedAttendance.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAttendance.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1); 
  };
  const renderSortArrow = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    return sortConfig.direction === 'asc' ? '↑' : '↓';
  };

  return (
    <div className="challenges-container">
      <div className="search-bar">
      <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredAttendance}
          headers={headers}
          filename="Leave_Report.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <table className="challenges-table">
        <thead>
          <tr className="challenges-header-row">
          {headers.map((header) => (
              <th
                key={header.key}
                className="challenges-column"
                onClick={() => handleSort(header.key)}
              >
                {header.label} {renderSortArrow(header.key)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((Attend) => (
            <tr key={Attend.id} className="challenges-row">
              <td className="challenges-cell">{Attend.batchName}</td>
              <td className="challenges-cell">{Attend.coachName}</td>
              <td className="challenges-cell">{Attend.month}</td>
              <td className="challenges-cell">{Attend.year}</td>
              <td className="challenges-cell">{Attend.attendance}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CoachAttendance;
