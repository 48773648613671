import React, { useState, useEffect } from 'react';
import './FeesSetting.css';
import { useUser } from '../context/UserContext';
import Swal from 'sweetalert2';

const FeesSetting = () => {
  const { selectedCenter } = useUser();

  const [formData, setFormData] = useState({
    tournamentCommission: '',
    courtBookingCommission: '',
    feesCommission: '',
    gstNumber: '',
    panCardNumber: '',
    coachingEnabled: false,
    tournamentEnabled: false,
    bookAndPlayEnabled: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `http://localhost:8082/api/academy/${selectedCenter}/settings`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch settings data');
        }

        const result = await response.json();
        const { settingMap } = result.data.academySettingForm;
        setFormData({
          tournamentCommission: settingMap.TOURNAMENT_COMMISSION || '',
          courtBookingCommission: settingMap.COURT_BOOKING_COMMISSION || '',
          feesCommission: settingMap.FEE_COMMISSION || '',
          gstNumber: settingMap.GST_NUMBER || '',
          panCardNumber: settingMap.PAN_CARD_NUMBER || '',
          coachingEnabled: settingMap.COACHING_ENABLED === 'true',
          tournamentEnabled: settingMap.TOURNAMENT_ENABLED === 'true',
          bookAndPlayEnabled: settingMap.COURT_BOOKING_ENABLED === 'true',
        });
      } catch (error) {
        console.error('Error fetching settings data:', error);
      }
    };

    fetchData();
  }, [selectedCenter]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      settingMap: {
        TOURNAMENT_COMMISSION: String(formData.tournamentCommission || ''),
        COURT_BOOKING_COMMISSION: String(formData.courtBookingCommission || ''),
        FEE_COMMISSION: String(formData.feesCommission || ''),
        COACHING_ENABLED: formData.coachingEnabled ? 'true' : 'false',
        TOURNAMENT_ENABLED: formData.tournamentEnabled ? 'true' : 'false',
        COURT_BOOKING_ENABLED: formData.bookAndPlayEnabled ? 'true' : 'false',
      }
    };

    console.log("Data being sent to API:", requestData);

    try {
      const url = `http://localhost:8082/api/academy/${selectedCenter}/settings`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
        body: JSON.stringify(requestData),
      });

      const result = await response.json();
      console.log("API Response:", result);

      if (!response.ok) {
        throw new Error(result.success_message || 'Failed to submit form data');
      }

      console.log('Updated successfully:', result);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Form submitted successfully.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

    } catch (error) {
      console.error('Error submitting form:', error);

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.message || "An error occurred while submitting the form.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <div className="fees-setting-container">
      <form onSubmit={handleSubmit} className="fees-form">
        <div className="form-group">
          <label htmlFor="tournamentCommission">Tournament Commission (%)</label>
          <input
            type="number"
            id="tournamentCommission"
            name="tournamentCommission"
            value={formData.tournamentCommission}
            onChange={handleChange}
            placeholder="Enter Tournament Commission (%)"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="courtBookingCommission">Court Booking Commission (%)</label>
          <input
            type="number"
            id="courtBookingCommission"
            name="courtBookingCommission"
            value={formData.courtBookingCommission}
            onChange={handleChange}
            placeholder="Enter Court Booking Commission (%)"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="feesCommission">Fees Commission (%)</label>
          <input
            type="number"
            id="feesCommission"
            name="feesCommission"
            value={formData.feesCommission}
            onChange={handleChange}
            placeholder="Enter Fees Commission (%)"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="gstNumber">GST Number</label>
          <input
            type="text"
            id="gstNumber"
            name="gstNumber"
            value={formData.gstNumber}
            onChange={handleChange}
            placeholder="Enter GST Number"

          />
        </div>

        <div className="form-group">
          <label htmlFor="panCardNumber">PAN Card Number</label>
          <input
            type="text"
            id="panCardNumber"
            name="panCardNumber"
            value={formData.panCardNumber}
            onChange={handleChange}
            placeholder="Enter PAN Card Number"

          />
        </div>

        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              name="coachingEnabled"
              checked={formData.coachingEnabled}
              onChange={handleChange}
            />
            Coaching Enabled
          </label>

          <label>
            <input
              type="checkbox"
              name="tournamentEnabled"
              checked={formData.tournamentEnabled}
              onChange={handleChange}
            />
            Tournament Enabled
          </label>

          <label>
            <input
              type="checkbox"
              name="bookAndPlayEnabled"
              checked={formData.bookAndPlayEnabled}
              onChange={handleChange}
            />
            Book and Play Enabled
          </label>
        </div>

        <button type="submit" className='bank-transfer-toggle-button'>
          Update
        </button>
      </form>
    </div>
  );
};

export default FeesSetting;
