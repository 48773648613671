import React, { useState, useEffect } from 'react';
import './Challenge.css';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, CartesianGrid } from 'recharts';
const CouponsUsageReport = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [CouponsUsage, setCouponsUsage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const apiEndpoint = 'https://admin.stage.machaxi.com/api/react/coupons/usage';
    const headers = {
      'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
      'Content-Type': 'application/json',
    };

    axios.get(apiEndpoint, { headers })
      .then(response => {
        if (response.data.success) {
          const transformedCoupons = response.data.data.coupons.map(coupon => ({
            coupon: coupon?.coupon?.couponCode || '-',
            userName: coupon?.user?.name || '-',
            mobileNumber: coupon?.user?.mobile_number || '-',
            usedFor: coupon?.order?.type || '-',
            orderAmount: `${coupon?.order?.amount}` || '-',
            couponDiscount: `${coupon?.order?.discountAmount}` || '-',
          }));

          setCouponsUsage(transformedCoupons);
        } else {
          setError('Failed to load data: No coupons available.');
        }
      })
      .catch(error => {
        setError('Error fetching data: ' + error.message);
        console.error("Error fetching coupons:", error);
      })
      .finally(() => {
        setLoading(false);
        console.log("API call finished");
      });

  }, []);

  const headers = [
    { label: "Coupon", key: "coupon" },
    { label: "User Name", key: "userName" },
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Used For", key: "usedFor" },
    { label: "Order Amount", key: "orderAmount" },
    { label: "Coupon Discount", key: "couponDiscount" },
  ];

  const [sortConfig, setSortConfig] = useState({ key: 'coupon', direction: 'asc' });

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredCouponsUsage = CouponsUsage.filter((usage) =>
    Object.values(usage)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return data.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const sortedCouponsUsage = sortData(filteredCouponsUsage);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedCouponsUsage.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedCouponsUsage.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const totalEntries = filteredCouponsUsage.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  const chartData = CouponsUsage.map(coupon => ({
    name: coupon.coupon,
    Discount: coupon.couponDiscount,
    AmountPaid: coupon.orderAmount,
  }));

  return (
    <div className="challenges-container">

      {chartData && chartData.length > 0 && (
        <div>
          <h3>Coupon Discount Distribution</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData} margin={{ top: 20, right: 30, left: 10, bottom: 10 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis domain={[0, 15000]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="Discount" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredCouponsUsage}
          headers={headers}
          filename="CouponUsageReport.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>

      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredCouponsUsage.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}

      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr className="challenges-header-row">
              {headers.map(({ label, key }) => (
                <th key={key} className="challenges-column">
                  <span onClick={() => handleSort(key)} className="sortable-column">
                    {label}
                    {sortConfig.key === key && (
                      <span>
                        {sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}
                      </span>
                    )}
                  </span>
                </th>
              ))}

            </tr>
          </thead>
          <tbody>
            {currentRows.map((usage) => (
              <tr key={usage.id} className="challenges-row">
                <td className="challenges-cell">{usage.coupon}</td>
                <td className="challenges-cell">{usage.userName}</td>
                <td className="challenges-cell">{usage.mobileNumber}</td>
                <td className="challenges-cell">{usage.usedFor}</td>
                <td className="challenges-cell">{usage.orderAmount}</td>
                <td className="challenges-cell">{usage.couponDiscount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};


export default CouponsUsageReport
