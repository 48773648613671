import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext'; // Assuming this is a custom hook for user data
import { hasPrivilege } from '../utils/hasPrivilege'; // Assuming you have a utility for checking privileges
import { CSVLink } from 'react-csv';

const LeadManagement = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  // Fetching data from API
  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await fetch('https://6776652912a55a9a7d0b8ec4.mockapi.io/landingDashboard/leadManagement');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setLeads(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchLeads();
  }, []);
  const headers = [
    { label: "Landing Page", key: "landingPage" },
    { label: "Centre", key: "centre" },
    { label: "User Name", key: "userName" },
    { label: "User Phone Number", key: "userPhoneNumber" },
    { label: "Lead Gen Date", key: "leadGenDate" },
    { label: "Lead Gen Time", key: "leadGenTime" },
    { label: "Trial Date", key: "trialDate" },
    { label: "Trial Time Slot", key: "trialTimeSlot" },
    { label: "Status", key: "status" }
];

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  // Format the dates to YYYY-MM-DD format
  const formatDate = (date) => date.toISOString().split('T')[0];

  const [filters, setFilters] = useState({
    landingPage: '',
    sport: '',
    centre: '',
    leadGenStartDate: formatDate(tenDaysAgo), // Default 10 days ago
    leadGenEndDate: formatDate(today), // Default today
    trialStartDate: formatDate(tenDaysAgo), // Default 10 days ago
    trialEndDate: formatDate(today), // Default today
    status: ''
  });


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  // Filtering leads based on user input and dynamic filters
  const filteredLeads = leads.filter((lead) => {
    // General search filter
    const searchMatch = Object.values(lead)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Apply individual filters
    const landingPageMatch = filters.landingPage ? lead.landingPage.toLowerCase().includes(filters.landingPage.toLowerCase()) : true;
    const sportMatch = filters.sport ? lead.sport.toLowerCase().includes(filters.sport.toLowerCase()) : true;
    const centreMatch = filters.centre ? lead.centre.toLowerCase().includes(filters.centre.toLowerCase()) : true;
    
    const leadGenDateMatch = (filters.leadGenStartDate && lead.leadGenDate < filters.leadGenStartDate) ||
      (filters.leadGenEndDate && lead.leadGenDate > filters.leadGenEndDate) ? false : true;

    const trialDateMatch = (filters.trialStartDate && lead.trialDate < filters.trialStartDate) ||
      (filters.trialEndDate && lead.trialDate > filters.trialEndDate) ? false : true;

    const statusMatch = filters.status ? lead.status.toLowerCase().includes(filters.status.toLowerCase()) : true;

    return (
      searchMatch &&
      landingPageMatch &&
      sportMatch &&
      centreMatch &&
      leadGenDateMatch &&
      trialDateMatch &&
      statusMatch
    );
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const handleSort = (columnKey) => {
    let direction = 'ascending';

    // Toggle sort direction
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Update sortConfig
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;

    if (!key) return data; // No sorting applied if no key is selected

    return [...data].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };
  const sortedData = sortData(filteredLeads)

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  // Extract unique values for dynamic filter options
  const landingPageOptions = [...new Set(leads.map(lead => lead.landingPage))];
  const sportOptions = [...new Set(leads.map(lead => lead.sport))];
  const centreOptions = [...new Set(leads.map(lead => lead.centre))];
  const statusOptions = [...new Set(leads.map(lead => lead.status))];

  return (
    <div className="payment-list-container">
      {/* Filters Section */}
      <div className="sales-filters-leadManagement">
      <div className="floating-select">
          <select
            name="centre"
            onChange={handleFilterChange}
            value={filters.centre}
            className={`sales-filter-academy ${filters.centre ? "has-value" : ""}`}
          >
            <option value="">Centre</option>
            {centreOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {filters.centre && <label htmlFor="centre">Centre</label>}
        </div>
        <div className="floating-select">
          <select
            name="landingPage"
            onChange={handleFilterChange}
            value={filters.landingPage}
            className={`sales-filter-academy ${filters.landingPage ? 'has-value' : ''}`}
          >
            <option value="">Page</option>
            {landingPageOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {filters.landingPage && <label htmlFor="landingPage">Landing Page</label>}
        </div>

        <div className="floating-select">
          <select
            name="sport"
            onChange={handleFilterChange}
            value={filters.sport}
            className={`sales-filter-academy ${filters.sport ? "has-value" : ""}`}
          >
            <option value="">Sport</option>
            {sportOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {filters.sport && <label htmlFor="sport">Sport</label>}
        </div>

   

        <div className="floating-select">
          <input
            type="date"
            name="leadGenStartDate"
            value={filters.leadGenStartDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.leadGenStartDate ? "has-value" : ""}`}
          />
          {filters.leadGenStartDate && <label htmlFor="leadGenStartDate">Lead Gen Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            placeholder="Lead Gen End Date"
            name="leadGenEndDate"
            value={filters.leadGenEndDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.leadGenEndDate ? "has-value" : ""}`}
          />
          {filters.leadGenEndDate && <label htmlFor="leadGenEndDate">Lead Gen End Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial Start Date"
            name="trialStartDate"
            value={filters.trialStartDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.trialStartDate ? "has-value" : ""}`}
          />
          {filters.trialStartDate && <label htmlFor="trialStartDate">Trial Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            placeholder="Trial End Date"
            name="trialEndDate"
            value={filters.trialEndDate}
            onChange={handleFilterChange}
            onClick={(e) => e.target.showPicker()}
            className={`sales-filter-academy ${filters.trialEndDate ? "has-value" : ""}`}
          />
          {filters.trialEndDate && <label htmlFor="trialEndDate">Trial End Date</label>}
        </div>

        <div className="floating-select">
          <select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            className={`sales-filter-academy ${filters.status ? "has-value" : ""}`}
          >
            <option value="">Status</option>
            {statusOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {filters.status && <label htmlFor="status">Status</label>}
        </div>
      </div>
      <div className="search-create-container">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredLeads}
          headers={headers}
          filename="LeadManagement.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <div className='space'></div>
      {loading && <div class="loading-spinner">
        <span>Loading</span>
        <div class="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredLeads.length === 0 && (
        <div className="error-message">No data available for the selected Academy</div>
      )}
      {/* Leads Table */}
      <div className="payment-list-scroll-container" style={{ maxWidth: '1130px', overflowY: 'auto' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
            {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)} // Sort by the column key
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span> // Ascending arrow
                    ) : (
                      <span> ↓</span> // Descending arrow
                    )
                  ) : null}
                </th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((lead) => (
              <tr key={lead.id}>
                <td>{lead.landingPage}</td>
                <td>{lead.centre}</td>
                <td>{lead.userName}</td>
                <td>{lead.userPhoneNumber}</td>
                <td>{lead.leadGenDate}</td>
                <td>{lead.leadGenTime}</td>
                <td>{lead.trialDate}</td>
                <td>{lead.trialTimeSlot}</td>
                <td>{lead.status}</td>
                  <td>
                    <button className="edit-button" onClick={() => console.log('Edit clicked', lead)}>Edit</button>
                    <button className="delete-button" onClick={() => console.log('Delete clicked', lead)}>Delete</button>
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LeadManagement;
