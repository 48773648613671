import React, { useState, useEffect } from 'react';
import '../sliders/NewSlider.css';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const NewProgress = () => {
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const location = useLocation();
  const { sportId, progressAttributeId } = location.state || {};
  console.log('Location State:', location.state);

  const AUTH_HEADER = {
    'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    if (progressAttributeId) {
      fetch(`http://localhost:8082/api/admin/edit-PA/${progressAttributeId}/${sportId}`, {
        method: 'GET',
        headers: AUTH_HEADER,
      })
        .then((res) => {
          if (!res.ok) throw new Error('Failed to fetch progress attribute');
          return res.json();
        })
        .then((responseData) => {
          console.log('API Response:', responseData); 
  
          const progressData = responseData.data?.sportProgressAttributeEditForm;
          if (progressData) {
            setHeading(progressData.name || '');
            setSubHeading(progressData.description || '');
          } else {
            console.error('Missing sportProgressAttributeEditForm in API response');
          }
        })
        .catch((error) => setErrorMessage(error.message));
    }
  }, [progressAttributeId, sportId]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      id: progressAttributeId || null,
      name: heading,
      description: subHeading,
      sportId: sportId,
    };
    
    try {
      setIsLoading(true);
      setErrorMessage('');
      setSuccessMessage('');

      const response = await fetch(`http://localhost:8082/api/admin/sports/${sportId}/new-progress-attribute`, {
        method: 'POST',
        headers: AUTH_HEADER,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to submit data');
      }

      const successMessage = progressAttributeId 
        ? 'Progress attribute updated successfully!' 
        : 'Progress attribute created successfully!';

      setSuccessMessage(successMessage);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: successMessage,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });

      setHeading('');
      setSubHeading('');
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred');
      
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setIsLoading(false);
    }
};


  return (
    <div className="new-slider-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt;
        {progressAttributeId ? 'Edit Progress Attribute' : 'New Progress Attribute'}
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            placeholder="Enter Name"
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <input
            type="text"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Enter Description"
            required
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Submitting...' : progressAttributeId ? 'Update' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default NewProgress;
