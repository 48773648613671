import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Swal from 'sweetalert2';
import "./AddUnavailableDate.css";

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of [0, 30]) {
      const value = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
      times.push({ value, label: value });
    }
  }
  return times;
};

const AddUnavailableDate = () => {
  const [courtName, setCourtName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const location = useLocation();

  const { courtId, unavailableDateId, startDate: locationStartDate, endDate: locationEndDate } = location.state || {};

  useEffect(() => {
    if (locationStartDate) {
      const [date, time] = locationStartDate.split(" ");
      setStartDate(date);
      setStartTime({ value: time, label: time });
    }
    if (locationEndDate) {
      const [date, time] = locationEndDate.split(" ");
      setEndDate(date);
      setEndTime({ value: time, label: time });
    }
  }, [locationStartDate, locationEndDate]);

  const allTimeOptions = generateTimeOptions();

  const filteredEndTimeOptions = allTimeOptions.filter(
    (option) => !startTime || option.value > startTime.value
  );

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;
    setStartDate(selectedDate);
    if (endDate && selectedDate > endDate) {
      setEndDate("");
    }
  };

  const handleStartTimeChange = (selectedOption) => {
    setStartTime(selectedOption);
    if (endTime && selectedOption.value >= endTime.value) {
      setEndTime(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!courtId || !startDate || !endDate || !startTime || !endTime) {
      Swal.fire({
        icon: "warning",
        title: "Missing Fields",
        text: "Please fill all fields before submitting.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    const requestData = {
      id: unavailableDateId || null,
      courtId,
      startDate: `${startDate} ${startTime.value}`,
      endDate: `${endDate} ${endTime.value}`,
    };

    console.log("requestData", requestData);

    try {
      const response = await fetch(
        `http://localhost:8082/api/react/court/add-court-unavailable-date/${courtId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'x-authorization': process.env.REACT_APP_AUTH_TOKEN,
          },
          body: JSON.stringify(requestData),
        }
      );
      console.log(response)
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.success_message || `Error: ${response.statusText}`);
      }

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Unavailable date added successfully!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "Failed to add unavailable date. Please try again.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="add-unavailable-date__container">
      <div className="breadcrumb">
        <Link to="/courtlist" className="breadcrumb-link">
          Court List
        </Link>{" "}
        &gt; New Unavailable Date
      </div>

      <form className="add-unavailable-date__form" onSubmit={handleSubmit}>
        <div className="add-unavailable-date__form-group">
          <label htmlFor="startDate" className="add-unavailable-date__label">
            Start Date:
          </label>
          <input
            type="date"
            id="startDate"
            className="add-unavailable-date__input-start-date"
            value={startDate}
            onChange={handleStartDateChange}
            min={today}
            required
            onClick={(e) => e.target.showPicker()}
          />
        </div>
        <div className="add-unavailable-date__form-group">
          <label htmlFor="endDate" className="add-unavailable-date__label">
            End Date:
          </label>
          <input
            type="date"
            id="endDate"
            className="add-unavailable-date__input-end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            min={startDate || today}
            required
            onClick={(e) => e.target.showPicker()}
          />
        </div>
        <div className="add-unavailable-date__form-group">
          <label className="add-unavailable-date__label">Start Time:</label>
          <Select
            options={allTimeOptions}
            value={startTime}
            onChange={handleStartTimeChange}
            placeholder="Select start time"
            required
          />
        </div>
        <div className="add-unavailable-date__form-group">
          <label className="add-unavailable-date__label">End Time:</label>
          <Select
            options={filteredEndTimeOptions}
            value={endTime}
            onChange={setEndTime}
            placeholder="Select end time"
            required
            isDisabled={!startTime}
          />
        </div>
        <button type="submit" className="add-unavailable-date__submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddUnavailableDate;
