import React, { useState, useEffect } from 'react';
import './BatchCancellation.css';
import { useUser } from '../context/UserContext';
import Swal from 'sweetalert2';
import axios from "axios";

const BatchCancellation = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState('');
  const [batches, setBatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [reason, setReason] = useState('');
  const { selectedCenter, setSelectedCenter } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleListBatches = async () => {
    if (fromDate && (isChecked ? toDate : true)) {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.post(
            `http://localhost:8082/api/academy/${selectedCenter}/batch/operational`,
            {
            is_range: isChecked,
            from_date: fromDate,
            to_date: isChecked ? toDate : fromDate, // If range is not selected, use fromDate as toDate            
            academy_id: selectedCenter,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token
            },
          }
        );
        if (response.data.success) {
          console.log(response.data)
          setBatches(
            response.data.data.batchCancelationForm.batches.map(batch => ({
              id: batch.batch_id,
              name: batch.batch_name
            })) || []
          );
        } else {
          setError("Failed to fetch batches");
        }
      } catch (err) {
        setError("Error fetching batches");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setToDate(''); // Reset 'toDate' when unchecked
  };

  const handleDateChange = (e) => {
    if (e.target.name === 'fromDate') {
      setFromDate(e.target.value);
    } else if (e.target.name === 'toDate') {
      setToDate(e.target.value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBatches = batches.filter(batch =>
    batch.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [sortConfig, setSortConfig] = useState({
    key: "name", // Default column to sort
    direction: "ascending", // Default direction
  });

  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };
  const sortedData = sortData(filteredBatches);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);


  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(prevPage => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1); 
  };

  const totalEntries = filteredBatches.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedBatches([]);
    } else {
      setSelectedBatches(batches.map(batch => batch.id));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxSelect = (id) => {
    setSelectedBatches((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(batchId => batchId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleCancelClick = () => {
    Swal.fire({
      title: "Enter Reason for Cancellation",
      input: "textarea",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Enter reason"
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      preConfirm: (reason) => {
        if (!reason) {
          Swal.showValidationMessage("Please enter a reason before submitting.");
        }
        return reason;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setReason(result.value); 
        handleSubmitReason(result.value);
      }
    });
  };

  const handleSubmitReason = async (reason) => {
    if (selectedBatches.length === 0) {
      Swal.fire("Error", "Please select at least one batch before submitting.", "error");
      return;
    }
  
    const requestData = {
      is_range: isChecked,
      from_date: fromDate,
      to_date: isChecked ? toDate : fromDate,
      academy_id: selectedCenter,
      cancelation_reason: reason,
      batchId: selectedBatches,
    };
  
    try {
      const response = await axios.post(
        `http://localhost:8082/api/academy/${selectedCenter}/batchCancellation`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ" // Replace with valid token
          },
        }
      );
  
      if (response.data.success) {
        setBatches((prevBatches) => prevBatches.filter(batch => !selectedBatches.includes(batch.id)));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.data.successMessage,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
          Swal.fire({
          icon: "Error",
          title: "error",
          text: "Batch cancellation failed.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("API Error:", error);
      Swal.fire("Error", "An error occurred while canceling batches.", "error");
    }
  
    // Reset selections
    setReason("");
    setSelectedBatches([]);
    setSelectAll(false);
  };
  
  return (
    <div className="challenges-container">
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="date-range-checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="date-range-checkbox">Select Date Range</label>
      </div>

      <div className="date-range-container">
        <div className="date-inputs">
          <label htmlFor="fromDate">
            {isChecked ? 'From Date' : 'Date'}:
          </label>
          <input
            type="date"
            id="fromDate"
            name="fromDate"
            value={fromDate}
            onChange={handleDateChange}
          />
        </div>

        {isChecked && (
          <div className="date-inputs">
            <label htmlFor="toDate">To Date:</label>
            <input
              type="date"
              id="toDate"
              name="toDate"
              value={toDate}
              onChange={handleDateChange}
            />
          </div>
        )}
      </div>

      <button
        className="list-batches-button"
        onClick={handleListBatches}
        disabled={!fromDate || (isChecked && !toDate)}
      >
        List Batches
      </button>

      {batches.length > 0 && (
        <div className="batch-list">
          <div className="search-bar">
            <div className="input-container">
              <input
                type="text"
                id="searchInput"
                placeholder=""
                className={`search-input ${searchTerm ? "has-value" : ""}`}
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <label htmlFor="searchInput" className="floating-label">
                🔍 Search
              </label>
            </div>
            <button className="create-button" onClick={handleCancelClick}>
              Cancel
            </button>

          </div>
          <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
            <table className="payment-list-table">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                  </th>
                  <th>Batch Name</th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((batch) => (
                  <tr key={batch.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedBatches.includes(batch.id)}
                        onChange={() => handleCheckboxSelect(batch.id)}
                      />
                    </td>
                    <td>{batch.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination */}
          <div className="pagination">
            <div className="showing-entries">
              Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
            </div>
            <button
              className="prev-button"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {renderPagination()}
            <button
              className="next-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>

      )}

    </div>
  );
};

export default BatchCancellation;
