import React, { useState, useEffect } from 'react';
import './PaymentList.css';
import { CSVLink } from 'react-csv';
import { useUser } from '../context/UserContext';
const PaymentList = () => {
  const { selectedCenter, setSelectedCenter } = useUser();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [academyNameMap, setAcademyNameMap] = useState({});
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const apiUrl = 'http://localhost:8082/api/academy/payment-reports';

  useEffect(() => {
    const fetchPayments = async () => {

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-authorization":
              "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
          },
          body: JSON.stringify({
            fromDate: fromDateFilter,
            toDate: toDateFilter,
            academyId: selectedCenter,
          }),
        });

        console.log("Response received:", response);
        const data = await response.json();

        console.log("API Response Data:", data);

        if (data.success && data.data && data.data.payments) {
  
          console.log("see this", data.data.academyNameMap)
          const formattedPayments = data.data.payments.map((payment, index) => {
            return {
              id: payment?.id,
              academyName: payment?.academyEntity?.name,
              paymentType: payment?.paymentType,
              paymentMethod: payment?.paymentMethod,
              paidBy: payment?.paidByUser?.name,
              amountPaid: `${payment?.amountPaid}`,
              commission: `${payment?.commission}`,
              gatewayCharges: `${payment?.paymentGatewayFee}`,
              amountTransferred: `${payment?.transferableAmount}`,
              paymentDate: new Date(payment?.createdAt).toLocaleDateString(),
              status: payment?.status,
              dateOfPaymentTransfer: new Date(payment?.transferOn).toLocaleDateString(),
              referenceID: payment?.refId,
              playerName: payment?.paidByUser?.childName || "-",
              playerMobile: payment?.paidByUser?.mobile_number,
              parentMobile: payment?.academyEntity?.contactNumber || "-",
              remark: payment?.remark || "-",
              batch: payment?.academyEntity?.batches?.name || "-",
              term: "-",
              amount: `${payment.amountPaid}`,
            };
          });
          setAcademyNameMap(data.data.academyNameMap);
          console.log("Formatted Payments:", formattedPayments);
          setPayments(formattedPayments);
          console.log("Selected Center:", selectedCenter);
          console.log("Academy Name Map:", academyNameMap);
          console.log("Academy Name from Map:", academyNameMap[selectedCenter]);

          if (selectedCenter && academyNameMap[selectedCenter]) {
            console.log("Setting academy filter:", academyNameMap[selectedCenter]);
            setAcademyFilter(academyNameMap[selectedCenter]);
          }
        } else {
          console.error("Data fetch failed:", data.success_message);
          setError("Failed to load data: " + data.success_message);
        }
      } catch (error) {
        console.error("Error fetching payments:", error);
        setError("Error fetching data: " + error.message);
      } finally {
        console.log("Fetch process complete. Setting loading state to false.");
        setLoading(false);
      }
    };

    fetchPayments();
  }, [selectedCenter, fromDateFilter, toDateFilter]);
  // useEffect(() => {
  //   console.log("Updated Academy Name Map:", academyNameMap);
  //   console.log("Academy Name from Map:", academyNameMap?.[selectedCenter] || "Not Found");

  // }, [academyNameMap, selectedCenter]);

  const headers = [
    { label: "Academy Name", key: "academyName" },
    { label: "Payment Type", key: "paymentType" },
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Paid By", key: "paidBy" },
    { label: "Amount Paid", key: "amountPaid" },
    { label: "Commission", key: "commission" },
    { label: "Gateway Charges", key: "gatewayCharges" },
    { label: "Amount Transferred", key: "amountTransferred" },
    { label: "Payment Date", key: "paymentDate" },
    { label: "Status", key: "status" },
    { label: "Date of Payment Transfer", key: "dateOfPaymentTransfer" },
    { label: "Reference ID", key: "referenceID" },
    { label: "Player Name", key: "playerName" },
    { label: "Player Mobile", key: "playerMobile" },
    { label: "Parent Mobile", key: "parentMobile" },
    { label: "Remark", key: "remark" },
    { label: "Batch", key: "batch" },
    { label: "Term", key: "term" },
    { label: "Amount", key: "amount" }
  ];
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const academyNames = ['All', ...Object.values(academyNameMap)];
  const paymentMethods = ['All', ...new Set(payments.map(payment => payment.paymentMethod))];
  const paymentStatuses = ['All', ...new Set(payments.map(payment => payment.status))];
  const paymentTypes = ['All', ...new Set(payments.map(payment => payment.paymentType))];

  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [academyFilter, setAcademyFilter] = useState('All');
  const [paymentMethodFilter, setPaymentMethodFilter] = useState('All');
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('All');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('All');

  useEffect(() => {
    // Get today's date
    const today = new Date();

    // Calculate the date 10 days before today
    const fromDate = new Date(today);
    fromDate.setDate(today.getDate() - 400); // Subtract 10 days

    // Format the dates as strings (e.g., 'YYYY-MM-DD')
    const formatDate = (date) => date.toISOString().split('T')[0]; // Get only 'YYYY-MM-DD'

    // Set the state values
    setFromDateFilter(formatDate(fromDate));
    setToDateFilter(formatDate(today));
  }, []);
  useEffect(() => {
    if (selectedCenter && academyNameMap[selectedCenter]) {
      setAcademyFilter(academyNameMap[selectedCenter]);
    }
  }, [selectedCenter, academyNameMap]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  const handleAcademyFilterChange = (e) => {
    setAcademyFilter(e.target.value);
  };

  const handlePaymentMethodFilterChange = (e) => {
    setPaymentMethodFilter(e.target.value);
  };

  const handlePaymentStatusFilterChange = (e) => {
    setPaymentStatusFilter(e.target.value);
  };

  const handlePaymentTypeFilterChange = (e) => {
    setPaymentTypeFilter(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDateFilter(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDateFilter(e.target.value);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen !== null && !event.target.closest('.dropdown-container')) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortData = (data) => {
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };
  const filteredPayments = payments.filter((payment) => {
    return (
      (academyFilter === 'All' || payment.academyName === academyFilter) &&
      (paymentMethodFilter === 'All' || payment.paymentMethod === paymentMethodFilter) &&
      (paymentStatusFilter === 'All' || payment.status === paymentStatusFilter) &&
      (paymentTypeFilter === 'All' || payment.paymentType === paymentTypeFilter) &&
      (fromDateFilter ? new Date(payment.paymentDate) >= new Date(fromDateFilter) : true) &&
      (toDateFilter ? new Date(payment.paymentDate) <= new Date(toDateFilter) : true) &&
      Object.values(payment).some((value) => value.toString().toLowerCase().includes(searchTerm))
    );
  });

  const sortedData = sortData(filteredPayments);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const totalRevenue = payments.reduce((acc, payment) => acc + parseFloat(payment.amount.replace('$', '').replace(',', '')), 0).toFixed(2);
  const numberOfPayments = payments.length;

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const totalEntries = filteredPayments.length;
  const indexOfFirstEntry = (currentPage - 1) * rowsPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * rowsPerPage, totalEntries);

  return (
    <div className="challenges-container">
      <div className="sales-filters-leadManagement">
        <div className="floating-select">
          <select
            onChange={handleAcademyFilterChange}
            value={academyFilter}
            className={`sales-filter-academy ${academyFilter ? "has-value" : ""}`}
          >
            {academyNames.map((academyName, index) => (
              <option key={index} value={academyName}>
                {academyName}
              </option>
            ))}
          </select>

          {academyFilter && <label htmlFor="academyFilter">Academy</label>}
        </div>


        <div className="floating-select">
          <select
            onChange={handlePaymentMethodFilterChange}
            value={paymentMethodFilter}
            className={`sales-filter-academy  ${paymentMethodFilter ? "has-value" : ""}`}
          >
            {paymentMethods.map((method, index) => (
              <option key={index} value={method}>
                {method}
              </option>
            ))}
          </select>

          {paymentMethodFilter && <label htmlFor="paymentMethodFilter">Payment Method</label>}
        </div>


        <div className="floating-select">
          <select
            onChange={handlePaymentStatusFilterChange}
            value={paymentStatusFilter}
            className={`sales-filter-academy ${paymentStatusFilter ? "has-value" : ""}`}
          >
            {paymentStatuses.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>

          {paymentStatusFilter && <label htmlFor="paymentStatusFilter">Payment Status</label>}
        </div>

        <div className="floating-select">
          <select
            onChange={handlePaymentTypeFilterChange}
            value={paymentTypeFilter}
            className={`sales-filter-academy ${paymentTypeFilter ? "has-value" : ""}`}
          >
            {paymentTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>

          {paymentTypeFilter && <label htmlFor="paymentTypeFilter">Payment Type</label>}
        </div>


        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${fromDateFilter ? "has-value" : ""}`}
            value={fromDateFilter}
            onChange={handleFromDateChange}
            onClick={(e) => e.target.showPicker()}
          />
          {fromDateFilter && <label htmlFor="fromDateFilter">From Date</label>}
        </div>


        <div className="floating-select">
          <input
            type="date"
            className={`sales-filter-academy ${toDateFilter ? "has-value" : ""}`}
            value={toDateFilter}
            onChange={handleToDateChange}
            onClick={(e) => e.target.showPicker()}
          />
          {toDateFilter && <label htmlFor="toDateFilter">To Date</label>}
        </div>

      </div>
      <div className="payment-summary">
        <p><strong>Payment Reports</strong></p>
        <p>Total Revenue: ₹{totalRevenue}</p>
        <p>Number of Payments: {numberOfPayments}</p>
      </div>

      <div className="search-create-container">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearch}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
        <CSVLink
          data={filteredPayments}
          headers={headers}
          filename="RewardDuesCoach.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>

      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>
      <div className='space'></div>
      {loading && <div className="loading-spinner">
        <span>Loading</span>
        <div className="spinner"></div>
      </div>
      }
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && filteredPayments.length === 0 && (
        <div className="error-message">No data available</div>
      )}
      <div className="payment-list-scroll-container" style={{ maxWidth: '1130px', overflowY: 'auto', minWidth: '700px' }}>
        <table className="payment-list-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  key={header.key}
                  className="challenges-column"
                  onClick={() => handleSort(header.key)}
                >
                  {header.label}
                  {sortConfig.key === header.key ? (
                    sortConfig.direction === "ascending" ? (
                      <span> ↑</span>
                    ) : (
                      <span> ↓</span>
                    )
                  ) : null}
                </th>
              ))}

            </tr>
          </thead>
          <tbody>
            {currentRows.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.academyName}</td>
                <td>{payment.paymentType}</td>
                <td>{payment.paymentMethod}</td>
                <td>{payment.paidBy}</td>
                <td>{payment.amountPaid}</td>
                <td>{payment.commission}</td>
                <td>{payment.gatewayCharges}</td>
                <td>{payment.amountTransferred}</td>
                <td>{payment.paymentDate}</td>
                <td>{payment.status}</td>
                <td>{payment.dateOfPaymentTransfer}</td>
                <td>{payment.referenceID}</td>
                <td>{payment.playerName}</td>
                <td>{payment.playerMobile}</td>
                <td>{payment.parentMobile}</td>
                <td>{payment.remark}</td>
                <td>{payment.batch}</td>
                <td>{payment.term}</td>
                <td>{payment.amount}</td>

              </tr>
            ))}
          </tbody>
        </table>

      </div>

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaymentList;