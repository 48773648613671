import React, { useState, useEffect } from "react";
import "./AcademyGallery.css";
import { useUser } from "../context/UserContext";
import Swal from "sweetalert2";

const AcademyGallery = () => {
  const [gallery, setGallery] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { selectedCenter } = useUser();
  const entriesPerPage = 1;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!selectedCenter) {
      console.log("Please select a center.");
      return;
    }

    const apiUrl = `http://localhost:8082/api/academy/${selectedCenter}/gallery`;

    const fetchGallery = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-authorization":
              "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch gallery images");
        }

        const result = await response.json();
        if (result.success && result.data.academyGalleryEntities) {
          setGallery(result.data.academyGalleryEntities);
        } else {
          throw new Error("No gallery data available");
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchGallery();
  }, [selectedCenter]);

  const handleUploadClick = () => {
    setShowModal(true);
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (!file.type.startsWith("image/")) {
        alert("Please select an image file (JPG, PNG, etc.)");
        return;
      }

      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      Swal.fire({
        icon: "warning",
        title: "No file selected",
        text: "Please select an image to upload.",
      });
      return;
    }

    const formData = new FormData();

    const academyGalleryForm = { academyId: selectedCenter };
    const jsonBlob = new Blob([JSON.stringify(academyGalleryForm)], {
      type: "application/json",
    });
    formData.append("academyGalleryForm", jsonBlob);

    formData.append("images", selectedFile);

    try {
      const response = await fetch("http://localhost:8082/api/academy/upload-gallery", {
        method: "POST",
        headers: {
          "x-authorization":
            "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
        },
        body: formData, 
      });
      console.log(formData)
      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const result = await response.json();
      if (result.success) {
        setGallery([...gallery, result.data]); 
        Swal.fire({
          icon: "success",
          title: "Uploaded!",
          text: "Image uploaded successfully.",
        });
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      console.error(error.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to upload image. Please try again.",
      });
    }

    setShowModal(false);
    setSelectedFile(null);
  };

  const totalEntries = gallery.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const indexOfFirstEntry = (currentPage - 1) * entriesPerPage + 1;
  const indexOfLastEntry = Math.min(currentPage * entriesPerPage, totalEntries);
  const currentEntries = gallery.slice(indexOfFirstEntry - 1, indexOfLastEntry);
  const handlePageClick = (pageNumber) => {
    if (pageNumber !== '...') setCurrentPage(pageNumber);
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;
  
    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };
  
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (!result.isConfirmed) return;

      const response = await fetch(
        `http://localhost:8082/api/academy/delete-gallery-image?galleryImageId=${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-authorization":
              "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete image");
      }

      setGallery((prevGallery) => prevGallery.filter((image) => image.id !== id));

      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "The image has been deleted successfully.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (error) {
      console.error(error.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete image. Please try again.",
      });
    }
  };

  return (
    <div className="challenges-container">
      <button className="create-button" onClick={handleUploadClick}>
        Upload Image
      </button>
      <div className="payment-list-scroll-container" style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="notification-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {gallery.map((image) => (
              <tr key={image.id}>
                <td>
                  <img
                    src={image.imageUrl}
                    alt="Gallery Image"
                    style={{ width: "200px", height: "200px", objectFit: "cover" }}
                  />
                </td>
                <td>
                  <button className="delete-button" onClick={() => handleDelete(image.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Upload Image</h2>

            <label htmlFor="fileInput" className="file-label">Choose File</label>
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
            <p className="file-name">{selectedFile ? selectedFile.name : "No file chosen"}</p>

            <div className="modal-actions">
              <button className="upload" onClick={handleUpload} disabled={!selectedFile}>Upload</button>
              <button className="cancel" onClick={() => { setShowModal(false); setSelectedFile(null); }}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      <div className="pagination">
        <div className="showing-entries">
          Showing {indexOfFirstEntry} to {indexOfLastEntry} of {totalEntries} entries
        </div>
        <button className="prev-button" onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        {renderPagination()}
        <button className="next-button" onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

    </div>
  );
};

export default AcademyGallery;
